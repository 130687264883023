import React, { useMemo } from "react"
import cls from "common/src/lib/className"

import SummaryCol from "./SummaryCol"

function SummaryRow({ expandable, expandKey, rows, cols, dataStore, variant }) {

    const colCls = useMemo(() => cls([`grid-var-${ variant }`, "grid-summary-col"]), [ variant ]);

    return (
        <tr className="summary-row">
            { (expandable && expandKey) && <td className={ colCls }></td> }
            { cols.map(col => <SummaryCol key={ col.dataKey || col.id } 
                                { ...{ className: colCls, col, rows, dataStore }}/> ) }
        </tr>
    )
}

export default SummaryRow