
import getUrl from "common/src/lib/url/get"
import routes from "web/src/app/routes"

const type2path = {
    //"Style": "/_s/",
    "Style": "/designer-style/",
    //"Occasion": "/_o/",
    "Occasion": "/designer-outfit/",
    "Location": "/_l/",
    //"Season": "/_f/",
    "Season": "/seasonal-fashion/",
    "Designer": "/_d/",
    "ProductStyle": "/_p/",
    "HiddenTag": "/_h/"
};

export default (tag, params, domain, hash) => {

    if (typeof tag === "string" || !tag.__typename || !tag.path) {
        return getUrl(routes.search, params, domain, hash);
    }

    const type = tag.__typename;

    return getUrl(routes.search, 
                    params, domain, hash, 
                    `${type2path[type]}${tag.path}`);
};