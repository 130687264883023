
import React from "react"
import { connect } from "react-redux"
import Loader from "common/src/components/Loader"
import { loadStats } from "app/actions/page/dashboard"
import format from "common/src/lib/format/number"


class Home extends React.Component {

    componentDidMount() {
        loadStats();
    }

    render() {

        let stats = this.props.stats || {},
            loading = this.props.ui.fris.loading ||
                        this.props.ui.stats.loading;

        if (loading) {
            return (
                <div className="page page-w-loading">
                    <Loader/>
                </div>
            )
        }

        return (
            <>
            <div className="page page-dashboard">
                <ul className="stats">
                    <li>
                        <b>{ format(stats.users) }</b>
                        <span>Signed up users</span>
                    </li>
                    <li>
                        <b>{ format(stats.usersPlain) }</b>
                        <span>Customers</span>
                    </li>
                    <li>
                        <b>{ format(stats.usersPse) }</b>
                        <span>PSEs</span>
                    </li>
                </ul>
            </div>

            </>
        )
    }
}

export default connect(state => state.dashboard)(Home)

/*
<li>
                        <b>{ format(stats.looksAwaiting) }</b>
                        <span>Awaiting looks</span>
                    </li>
                    <li>
                        <b>{ format(stats.looksDeleted) }</b>
                        <span>Deleted looks</span>
                    </li>
<li>
                        <b>{ format(stats.users7days) }</b>
                        <span>Active in last 7 days</span>
                    </li>
                    <li>
                        <b>{ format(stats.users30days) }</b>
                        <span>Active in last 30 days</span>
                    </li>
                    */