import React from "react"
import { connect, batch } from "react-redux"
import throttle from "lodash/throttle"
import moment from "moment"

import Checkbox from "common/src/components/material/Checkbox"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Loader from "common/src/components/Loader"
import Pagination from "common/src/components/Pagination"
import NewTag from "app/components/dialog/NewTag"
import MergeTags from "app/components/dialog/MergeTags"
import ExportTags from "app/components/dialog/ExportTags"

import df from "common/src/lib/date/formats"
import { ui as ddUi } from "common/src/store/dialogs"
import { data, ui } from "app/store/page/tags"
import getUrl from "common/src/lib/url/get"
import getTagUrl from "common/src/lib/url/getTagUrl"
import routes from "app/routes"
import * as actions from "app/actions/page/tags"
import settings from "app/settings"
import user from "common/src/user"


const type2Name = {
    "style": "Outfit styles",
    "season": "Style forecast",
    "location": "Where to wear",
    "designer": "Designers",
    "occasion": "Occasions",
    "productStyle": "Product styles",
    "hiddenTag": "Hidden tags"
}

const listName2Type = {
    "styles": "style",
    "seasons": "season",
    "locations": "location",
    "designers": "designer",
    "occasions": "occasion",
    "productStyles": "productStyle",
    "hiddenTags": "hiddenTag"
}

const listName2Store = {
    "styles": "styles",
    "seasons": "seasons",
    "locations": "locations",
    "designers": "designers",
    "occasions": "occasions",
    "productStyles": "productStyles",
    "hiddenTags": "hiddenTags"
}

const type2url = {
    "style": "styles",
    "season": "seasons",
    "location": "locations",
    "designer": "designers",
    "occasion": "occasions",
    "productStyle": "productStyles",
    "hiddenTag": "hiddenTags"
}


const type2tools = {
    "Style": "style",
    "Occasion": "occasion",
    "Location": "location",
    "Season": "season",
    "Designer": "designer",
    "ProductStyle": "productStyle",
    "HiddenTag": "hiddenTag"
};

let defaultState = {
    listName: "style",
    search: "",
    filter: "all",
    sortBy: "name",
    sortDir: "asc",
    selected: [],
    deleting: false,
    picking: false,
    renameId: null,
    descriptionId: null,
    pathId: null
}


const getToolsUrl = (t) => {
    const params = {};
    params[ type2tools[t.__typename] ] = t.id;
    return getUrl("/", params, "tools", "", "/looks");
}

class PageTags extends React.Component {

    state = { ...defaultState }
    _isMounted = false

    constructor(props) {
        super(props);
        this.state.listName = listName2Type[this.props.match.params.listName || "styles"]

        this._loadList = this._loadList.bind(this);
        this._loadList = throttle(this._loadList, 200, { trailing: true });
    }

    componentDidMount() {
        let page = parseInt(this.props.match.params.page) || 1;
        actions.loadTagList(this.state.listName, "", page - 1);
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prev) {
        let listName = this.props.match.params.listName || "styles",
            prevName = prev.match.params.listName || "styles",
            page = this.props.match.params.page || 1,
            prevPage = prev.match.params.page || 1;

        if (listName !== prevName || page !== prevPage) {
            let query = this.props.ui[listName2Store[listName]].search;
            this.setState({
                ...defaultState, 
                selected: [],
                filter: "all",
                listName: listName2Type[listName]
            });
            actions.loadTagList(
                listName2Type[listName], 
                query, 
                "all",
                listName !== prevName ? 0 : page - 1
            );
        }
    }

    setFilter(e, filter) {
        e.preventDefault();
        this.setState({ filter });
        const query = this.state.search;
        actions.loadTagList(
            this.state.listName, 
            query.length > 1 ? query : "", 
            filter
        );
    }

    onSortClick(e, f) {
        e.preventDefault();
        e.stopPropagation();
        const   listName = this.state.listName,
                sui = this.props.ui[listName + "s"],
              { sortBy, sortDir } = sui;

        if (f === sortBy) {
            batch(() => {
                this.props.dispatch(ui[listName + "s"].sortBy(f));
                this.props.dispatch(ui[listName + "s"].sortDir(sortDir === "asc" ? "desc" : "asc"));
            });
        }      
        else {
            batch(() => {
                this.props.dispatch(ui[listName + "s"].sortBy(f));
                this.props.dispatch(ui[listName + "s"].sortDir("asc"));
            });
        }      

        actions.reloadTagList(listName);
    }

    _loadList(listName, query, filter) {
        actions.loadTagList(listName, query, filter);
    }

    onSearchChange(query = "") {
        this.setState({ search: query });
        if (query.length > 1) {
            this._loadList(this.state.listName, query, this.state.filter);
            //actions.loadTagList(this.state.listName, query, this.state.filter);
        }
        else {
            this._loadList(this.state.listName, "", this.state.filter);
            //actions.loadTagList(this.state.listName, "", this.state.filter);
        }
        this.setState({ selected: [] })
    }

    onPageChange(no) {
        this.props.history.push(getUrl(routes.tags, {
            listName: type2url[this.state.listName],
            page: no + 1
        }))
    }

    selectAll() {
        const   sels = this.state.selected,
                listName = this.state.listName,
                list = this.props.data[listName + "s"],
                allSelected = sels.length === list.length;

        if (allSelected) {
            this.setState({ selected: [] });
        }
        else {
            this.setState({ selected: list.map(t => t.id) });
        }
    }

    onSelected(id, state) {
        let sels = this.state.selected,
            inx = sels.indexOf(id);

        if (state && inx === -1) {
            sels.push(id);
        }
        else if (!state && inx !== -1) {
            sels.splice(inx, 1);
        }

        this.setState({selected: sels});
    }

    showTagForm() {
        this.props.dispatch(ddUi.show("new-tag"));
    }

    setRename(renameId) {
        this.setState({ renameId });
    }

    setDescription(descriptionId) {
        this.setState({ descriptionId });
    }
    
    setPath(pathId) {
        this.setState({ pathId });
    }

    saveProp(id, prop, value) {
        const   listName = this.state.listName,
                list = this.props.data[listName + "s"],
                t = list.find(t => t.id === id);
        
        value = value.trim();
        if (!t || t[prop] === value) {
            return;
        }

        actions.update(this.state.listName, id, prop, value);

        this.props.dispatch(data.updateProp({
            type: this.state.listName, id, prop, value
        }))
    }

    saveName(id, name) {
        this.setState({ renameId: null });
        this.saveProp(id, "name", name);
    }

    saveDescription(id, description) {
        this.setState({ descriptionId: null });
        this.saveProp(id, "description", description);
    }

    savePath(id, path) {
        this.setState({ pathId: null });
        this.saveProp(id, "path", path);
    }

    deleteSelected() {
        this.setState({deleting: true});

        let sels = this.state.selected;

        actions.deleteTags(this.state.listName, sels)
            .then(deleted => {
                if (this._isMounted) {
                    let sels = this.state.selected;
                    if (sels.length === deleted.length) {
                        sels = [];
                    }
                    else {
                        deleted.forEach(id => {
                            let inx = sels.indexOf(id);
                            if (inx !== -1) {
                                sels.splice(inx, 1);
                            }
                        })
                    }
                    this.setState({ selected: sels, deleting: false });
                }
            })
            .finally(resp => {
                if (this._isMounted) {
                    this.setState({deleting: false});
                }
            });
    }

    mergeSelected() {
        this.props.dispatch(ddUi.show("tag-merge"))
    }

    onMergeCompleted() {
        this.setState({ selected: [] });
    }

    togglePicked() {
        const { listName, selected  } = this.state,
                list = this.props.data[listName + "s"],
                id = selected[0],
                tag = list.find(t => t.id === id),
                type = this.state.listName;

        this.setState({ picking: true });
        actions.setPicked(type, id, !tag.picked)
            .finally(() => {
                this.setState({ picking: false, selected: [] });
            });
    }

    toggleOnePicked(t) {
        const { listName  } = this.state;

        this.setState({ picking: true });
        actions.setPicked(listName, t.id, !t.picked)
            .finally(() => {
                this.setState({ picking: false });
            });
    }

    showExport(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.dispatch(ddUi.show("tag-export"))
    }

    render() {

        const { listName, selected, picking, 
                deleting, 
                renameId, descriptionId, pathId,
                filter } = this.state,
              { loading, page, count } = this.props.ui[listName + "s"],
              list = this.props.data[listName + "s"],
              tbCls = ["tags-toolbar"];


        let picked = false;

        if (selected.length > 0) {
            tbCls.push("active");
        }

        if (selected.length === 1) {
            let selId = selected[0];
            let tag = list.find(t => t.id === selId);
            picked = tag.picked;
        }

        const allSelected = selected.length === list.length;

        return (
            <>
            <div className="page page-tags page-w-loading">
                { loading && <Loader size={ 64 }/> }

                <div className="toolbar">
                    <Button 
                        variant="contained" 
                        children="Add tags"
                        onClick={ () => this.showTagForm() }/>

                    <nav>
            
                        <a href="/#" 
                            className={ filter === "all" ? "active" : "" }
                            onClick={ e => this.setFilter(e, "all") }
                            children="All"/>
                        <a href="/#" 
                            className={ filter === "featured" ? "active" : "" }
                            onClick={ e => this.setFilter(e, "featured") }
                            children="Featured"/>
                        <a href="/#" 
                            className={ filter === "orphans" ? "active" : "" }
                            onClick={ e => this.setFilter(e, "orphans") }
                            children="Orphans"/>
                        
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            size="small"
                            value={ this.state.search }
                            label={ this.state.search ? "" : "Search" }
                            onChange={ e => this.onSearchChange(e.target.value) }/>
                        <a href="/#" 
                            onClick={ e => this.showExport(e) }
                            children="Export"/>
                    </nav>
                </div>

                <div className="grid-wrapper">
                    <table className="grid">
                        <thead>
                        <tr>
                            <th>
                                <Checkbox
                                    checked={ allSelected }
                                    onChange={ e => this.selectAll() }/>
                            </th>
                            <th className="name">
                                { type2Name[listName] }
                                <a href="/#" 
                                    className="sort"
                                    onClick={ e => this.onSortClick(e, "name") }>sort</a>
                            </th>
                            <th className="description">Description</th>
                            <th className="url">URL</th>
                            <th className="created">
                                Created
                                <a href="/#" 
                                    className="sort"
                                    onClick={ e => this.onSortClick(e, "lastUsed") }>sort</a>
                            </th>
                            <th>{ listName === "designer" || listName === "productStyle" ?
                                    "Products" : "Looks" }</th>
                            { listName !== "hiddenTag" && <th>Featured</th> }
                        </tr>
                        </thead>
                        <tbody>
                        { list.map(t => (
                            <tr key={ t.id }>
                                <td>
                                    <Checkbox
                                        disabled={ deleting }
                                        checked={ selected.indexOf(t.id) !== -1 }
                                        onChange={ e => this.onSelected(t.id, e.target.checked) }/>
                                </td>
                                <td onClick={ () => this.setRename(t.id) }
                                    className={ 
                                        [
                                            "name",
                                            renameId === t.id ? "editing" : ""
                                        ].join(" ") 
                                    }>
                                    { renameId === t.id ? 
                                        <TextField
                                            autoComplete="off"
                                            autoFocus
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            defaultValue={ t.name }
                                            onKeyDown={ e => e.key === "Enter" && this.saveName(t.id, e.target.value) }
                                            onBlur={ e => this.saveName(t.id, e.target.value) }/> :
                                        t.name 
                                    } 
                                </td>
                                <td onClick={ () => this.setDescription(t.id) }
                                    className={ 
                                        [
                                            "description",
                                            descriptionId === t.id ? "editing" : ""
                                        ].join(" ") 
                                    }>
                                    { descriptionId === t.id ? 
                                        <TextField
                                            autoComplete="off"
                                            autoFocus
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            defaultValue={ t.description }
                                            onKeyDown={ e => e.key === "Enter" && this.saveDescription(t.id, e.target.value) }
                                            onBlur={ e => this.saveDescription(t.id, e.target.value) }/> :
                                        t.description 
                                    } 
                                </td>
                                <td onClick={ () => this.setPath(t.id) }
                                    className={ 
                                        [
                                            "path",
                                            pathId === t.id ? "editing" : ""
                                        ].join(" ") 
                                    }>
                                    { pathId === t.id ? 
                                        <TextField
                                            autoComplete="off"
                                            autoFocus
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            defaultValue={ t.path }
                                            onKeyDown={ e => e.key === "Enter" && this.savePath(t.id, e.target.value) }
                                            onBlur={ e => this.savePath(t.id, e.target.value) }/> :
                                        
                                        <>
                                        { t.path ? 
                                            <>
                                            <a href={ getTagUrl(t, {}, "web") } 
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    children={ "Web: " + t.path }/>
                                            &nbsp;/&nbsp;
                                            </> : 
                                            <>
                                            <span>Edit web url</span>
                                            &nbsp;/&nbsp;
                                            </>
                                        }
                                        <a href={ getToolsUrl(t) } 
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                children="Tools"/>
                                        </>
                                    } 
                                </td>
                                <td className="created">{ moment(t.lastUsed).format(df.full) }</td>
                                <td>{ listName === "designer" || listName === "productStyle" ? 
                                        t.products :
                                        t.looks }</td>
                                { listName !== "hiddenTag" && 
                                    <td>
                                    <Checkbox
                                        disabled={ picking }
                                        checked={ !!t.picked }
                                        onChange={ e => this.toggleOnePicked(t) }/>
                                    </td> }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <Pagination
                    page={ page }
                    count={ count }
                    perPage={ settings.tagsPerPage }
                    onChange={ no => this.onPageChange(no) }/>

                <div className={ tbCls.join(" ") }>
                    <div>
                    <Button 
                        variant="outlined"
                        children="Merge selected"
                        disabled={ deleting || picking }
                        onClick={ () => this.mergeSelected() }/>
                    <Button 
                        variant="outlined"
                        children="Delete selected"
                        disabled={ deleting || picking }
                        startIcon={ deleting ? <Loader inline/> : null }
                        onClick={ () => this.deleteSelected() }/>
                    { selected.length === 1 && user.is("Admin") && 
                        <Button 
                        variant="outlined"
                        children={ picked ? "Set not picked" : "Set picked" }
                        disabled={ deleting || picking }
                        startIcon={ picking ? <Loader inline/> : null }
                        onClick={ () => this.togglePicked() }/>
                    }
                    </div>
                </div>
            </div>

            <NewTag type={ listName }/>
            <MergeTags selected={ selected } 
                        type={ listName }
                        onMerge={ () => this.onMergeCompleted() }/>
            <ExportTags/>
            </>
        )
    }
}

export default connect(state => state.tagsPage)(PageTags)
