import React from "react"
import PublicSelector from "common/src/components/PublicSelector"
//import async from "common/src/lib/js/async"
import api from "app/api"


class UserGroupsSelector extends React.Component {

    state = {
        search: "",
        groups: [],
        loading: false
    }


    onSearchChange(search) {
        this.setState({ search }, () => this.load());
    }

    async load() {

        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const where = {};
        const { search } = this.state;
        if (search) {
            where.name = { _ilike: `%${ search }%` }
        }

        const groups = await api.userGroup.list({ where });
        this.setState({ groups, loading: false });
    }

    render() {
        const { groups, loading } = this.state;
        const props = Object.assign({}, this.props);
        props.onSearchChange = s => this.onSearchChange(s);
        props.onLoadItems = () => this.load();
        props.name = props.name || "User Group";
        props.cls = [ "tag-selector-v2", "user-group-selector" ];
        props.items = groups;
        props.filtered = groups;
        props.loading = loading;

        return (
            <PublicSelector { ...props }/>
        )
    }
}

export default UserGroupsSelector