import React from "react"
import TagList from "common/src/components/tag/List"
import Pagination from "common/src/components/Pagination"
import TextField from "@mui/material/TextField"
import api from "app/api"
import NullForm from "common/src/components/NullForm"

const PER_PAGE = 50;

class PageIgnored extends React.Component {

    state = {
        categories: [],
        categoriesPage: 0,
        categoriesCount: 0,
        categoriesSearch: "",

        colors: [],
        colorsPage: 0,
        colorsCount: 0,
        colorsSearch: "",

        materials: [],
        materialsPage: 0,
        materialsCount: 0,
        materialsSearch: "",

        designers: [],
        designersPage: 0,
        designersCount: 0,
        designersSearch: "",
    }

    componentDidMount() {
        this.loadCategories();
        this.loadColors();
        this.loadMaterials();
        this.loadDesigners();
    }

    async loadCategories() {

        const search = this.state.categoriesSearch;
        const where = { 
            _or: [
                { ignore: { _eq: true }},
                { ban: { _eq: true }}
            ]
        };
        if (search) {
            where.name = { _ilike: `%${ search }%` };
        }
        const order = { name: "asc" };
        const limit = PER_PAGE;
        const offset = this.state.categoriesPage * PER_PAGE;
        const { items, count } = await api.catalogueCategoryFeed
                                            .list({ where, order, offset, limit }, null, true);
        this.setState({ categories: items, categoriesCount: count });
    }

    async deleteCategory(t) {
        await api.catalogueCategoryQueue.create({ name: t.name, feedCategoryId: t.feedCategoryId });
        await api.catalogueCategoryFeed.remove(t.id);
        this.loadCategories();
    }

    setCategoriesPage(page) {
        this.setState({ categoriesPage: page }, () => this.loadCategories());
    }


    async loadColors() {
        const search = this.state.colorsSearch;
        const where = { ignore: { _eq: true }};

        if (search) {
            where.name = { _ilike: `%${ search }%` };
        }

        const order = { name: "asc" };
        const limit = PER_PAGE;
        const offset = this.state.colorsPage * PER_PAGE;
        const { items, count } = await api.catalogueColorFeed
                                            .list({ where, order, offset, limit }, null, true);
        this.setState({ colors: items, colorsCount: count });
    }

    async deleteColor(t) {
        await api.catalogueColorQueue.create({ name: t.name, feedColorId: t.feedColorId });
        await api.catalogueColorFeed.remove(t.id);
        this.loadColors();
    }

    setColorsPage(page) {
        this.setState({ colorsPage: page }, () => this.loadColors());
    }



    async loadMaterials() {
        const search = this.state.materialsSearch;
        const where = { ignore: { _eq: true }};
        if (search) {
            where.name = { _ilike: `%${ search }%` };
        }
        const order = { name: "asc" };
        const limit = PER_PAGE;
        const offset = this.state.materialsPage * PER_PAGE;
        const { items, count } = await api.catalogueMaterialFeed
                                            .list({ where, order, offset, limit }, null, true);
        this.setState({ materials: items, materialsCount: count });
    }

    async deleteMaterial(t) {
        await api.catalogueMaterialQueue.create({ name: t.name, feedMaterialId: t.feedMaterialId });
        await api.catalogueMaterialFeed.remove(t.id);
        this.loadMaterials();
    }

    setMaterialsPage(page) {
        this.setState({ materialsPage: page }, () => this.loadMaterials());
    }





    async loadDesigners() {
        const search = this.state.designersSearch;
        const where = { ban: { _eq: true }};

        if (search) {
            where.feedDesignerId = { _ilike: `%${ search }%` };
        }

        const order = { feedDesignerId: "asc" };
        const limit = PER_PAGE;
        const offset = this.state.designersPage * PER_PAGE;
        const { items, count } = await api.catalogueDesignerFeed
                                            .list({ where, order, offset, limit }, null, true);
        this.setState({ 
            designers: items.map(t => { t.name = t.feedDesignerId; return t }), 
            designersCount: count 
        });
    }

    async deleteDesigner(t) {
        await api.catalogueDesignerQueue.create({ name: t.name, feedDesignerId: t.feedDesignerId });
        await api.catalogueDesignerFeed.remove(t.id);
        this.loadDesigners();
    }

    setDesignersPage(page) {
        this.setState({ designersPage: page }, () => this.loadDesigners());
    }


    render() {

        const { categories, categoriesPage, categoriesCount, categoriesSearch,
                colors, colorsPage, colorsCount, colorsSearch,
                materials, materialsPage, materialsCount, materialsSearch,
                designers, designersPage, designersCount, designersSearch } = this.state;

        return (
            <div className="page page-catalogue-ignored">
                <h2>Ignored items</h2>

            
                <NullForm className="page-catalogue-ignore-section">
                    <h3>Categories</h3>
                    <TextField 
                        autoComplete="off"
                        fullWidth 
                        value={ categoriesSearch }
                        variant="outlined"
                        placeholder="Search categories"
                        onKeyDown={ e => e.key === "Enter" && this.setCategoriesPage(0) }
                        onChange={ e => this.setState({ categoriesSearch: e.target.value })}/>
                    <TagList 
                        tags={ categories } 
                        onDelete={ t => this.deleteCategory(t) }/>
                    <Pagination
                        page={ categoriesPage }
                        perPage={ PER_PAGE }
                        count={ categoriesCount }
                        onChange={ no => this.setCategoriesPage(no) }/>
                </NullForm> 

                <NullForm className="page-catalogue-ignore-section">
                    <h3>Designers</h3>
                    <TextField 
                        fullWidth 
                        autoComplete="off"
                        value={ designersSearch }
                        variant="outlined"
                        placeholder="Search designers"
                        onKeyDown={ e => e.key === "Enter" && this.setDesignersPage(0) }
                        onChange={ e => this.setState({ designersSearch: e.target.value })}/>
                    <TagList 
                        tags={ designers } 
                        onDelete={ t => this.deleteDesigner(t) }/>
                    <Pagination
                        page={ designersPage }
                        perPage={ PER_PAGE }
                        count={ designersCount }
                        onChange={ no => this.setDesignersPage(no) }/>
                </NullForm> 
            
                <NullForm className="page-catalogue-ignore-section">
                    <h3>Colors</h3>
                    <TextField 
                        fullWidth 
                        autoComplete="off"
                        value={ colorsSearch }
                        variant="outlined"
                        placeholder="Search colors"
                        onKeyDown={ e => e.key === "Enter" && this.setColorsPage(0) }
                        onChange={ e => this.setState({ colorsSearch: e.target.value })}/>
                    <TagList 
                        tags={ colors } 
                        onDelete={ t => this.deleteColor(t) }/>
                    <Pagination
                        page={ colorsPage }
                        perPage={ PER_PAGE }
                        count={ colorsCount }
                        onChange={ no => this.setColorsPage(no) }/>
                </NullForm> 

                <NullForm className="page-catalogue-ignore-section">
                    <h3>Materials</h3>
                    <TextField 
                        fullWidth 
                        autoComplete="off"
                        value={ materialsSearch }
                        variant="outlined"
                        placeholder="Search materials"
                        onKeyDown={ e => e.key === "Enter" && this.setMaterialsPage(0) }
                        onChange={ e => this.setState({ materialsSearch: e.target.value })}/>
                    <TagList 
                        tags={ materials } 
                        onDelete={ t => this.deleteMaterial(t) }/>
                    <Pagination
                        page={ materialsPage }
                        perPage={ PER_PAGE }
                        count={ materialsCount }
                        onChange={ no => this.setMaterialsPage(no) }/>
                </NullForm> 

            </div>
        )
    }
}

export default PageIgnored
