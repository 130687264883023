import api from "app/api"

export const PER_PAGE = 20;

const dateRangeToString = (dr) => {
    let d1 = new Date(dr[0]);
    let d2 = new Date(dr[1]);
    d1.setUTCHours(0);
    d1.setUTCMinutes(0);
    d1.setUTCSeconds(0);
    d2.setUTCHours(23);
    d2.setUTCMinutes(59);
    d2.setUTCSeconds(59);
    
    return [ d1.toISOString(), d2.toISOString() ];
}

const setBodyDates = (body, name, dateRange) => {
    if (dateRange && dateRange.length > 0) {
        const dr = dateRangeToString(dateRange);
        body[name] = dr;
    }
}

const idmap = (list) => {
    return list.map(item => {
        return typeof item === "string" ? item : item.id;
    });
}


export async function loadFriLooks(filter, page) {

    const body = {};
    setBodyDates(body, "createdAt", filter.createdAt);
    setBodyDates(body, "publishedAt", filter.publishedAt);
    setBodyDates(body, "submittedAt", filter.submittedAt);

    if (filter.publishedBy && filter.publishedBy.length > 0) {
        body.publishedBy = idmap(filter.publishedBy);
    }
    if (filter.declinedBy && filter.declinedBy.length > 0) {
        body.declinedBy = idmap(filter.declinedBy);
    }
    if (filter.fris && filter.fris.length > 0) {
        body.fris = idmap(filter.fris);
    }
    if (filter.group) {
        body.group = filter.group;
    }
    
    const response = await api.reporting.post("/report/fri/byfri", { body });
    const rows = response.rows;

    if (!filter.group || filter.group === "fri") {
        const friIds = rows.map(c => c.friId)
                            .filter((id, inx, self) => self.indexOf(id) === inx);
        const fris = await api.user.list({ where: { id: { _in: friIds }}});
        
        rows.forEach(c => {
            c.fri = fris.find(f => f.id === c.friId);
        });
    }
   
    return rows;

}

export const loadFriDomains = async (filter, page) => {
    const body = {};
    setBodyDates(body, "dateRange", filter.dateRange);
    if (filter.fris && filter.fris.length > 0) {
        body.fris = idmap(filter.fris);
    }
    
    const response = await api.reporting.post("/report/fri/byaffiliate", { body });
    const rows = response.rows;
    const friIds = rows.map(c => c.friId)
                        .filter((id, inx, self) => self.indexOf(id) === inx);
    const fris = await api.user.list({ where: { id: { _in: friIds }}});
    const domains = [];
    const grid = [];
    
    rows.forEach(r => {
        r.fri = fris.find(f => f.id === r.friId);
        let d = domains.find(d => d.friId === r.friId);
        if (!d) {
            d = {
                friId: r.friId,
                fri: r.fri,
                domains: []
            };
            domains.push(d);
        }
        d.domains.push({
            domain: r.domain,
            products: parseInt(r.cnt)
        });
    });

    domains.forEach(r => {
        r.domains.forEach((d, i) => {
            const row = {};
            if (i === 0) {
                row.friId = r.friId;
                row.fri = r.fri;
                row.span = r.domains.length;
            }
            row.domain = d.domain;
            row.products = d.products;
            grid.push(row);
        })
    })

    return grid;
}

export const loadFriDomainClicks = async (filter) => {

    const body = {};
    setBodyDates(body, "dateRange", filter.dateRange);
    if (filter.fris && filter.fris.length > 0) {
        body.fris = idmap(filter.fris);
    }
    
    const response = await api.reporting.post("/report/fri/clicks", { body });
    const rows = response.rows || [];
    const friIds = rows.map(c => c.friId)
                        .filter((id, inx, self) => self.indexOf(id) === inx);
    const fris = await api.user.list({ where: { id: { _in: friIds }}});
    
    let date, domain;
    let dateSpan = 0;
    let domainSpan = 0;
    let dateInx = null;
    let domainInx = null;

    const grid = [];
    rows.forEach((r, inx) => {
        const fri = fris.find(f => f.id === r.friId);
        const row = { fri, clicks: parseInt(r.cnt) };

        if (date !== r.date_group) {

            if (dateInx !== null) {
                grid[dateInx].dateSpan = dateSpan;
            }

            date = r.date_group;
            dateSpan = 0;
            dateInx = inx;
            domain = null;

            row.date = r.date_group;
        }

        if (domain !== r.domain) {

            if (domainInx !== null) {
                grid[domainInx].domainSpan = domainSpan;
            }

            domain = r.domain;
            domainSpan = 0;
            domainInx = inx;

            row.domain = r.domain;
        }

        dateSpan++;
        domainSpan++;

        grid.push(row);
    });

    if (dateInx !== null) {
        grid[dateInx].dateSpan = dateSpan;
    }
    if (domainInx !== null) {
        grid[domainInx].domainSpan = domainSpan;
    }

    return grid;
}