import React from "react"
import { connect } from "react-redux"
import moment from "moment"

import { Link } from "react-router-dom"
import { Button, TextField } from "@mui/material"
import Menu from "common/src/components/Menu"
import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"
import Loader from "common/src/components/Loader"
import Table from "common/src/components/table/Table"

import UserSelector from "common/src/components/user/UserSelector"
import UserGroupsSelector from "../../filter/UserGroupsSelector"
import DateRangeSelector from "common/src/components/filter/DateRange"
import MonthSelector from "app/components/filter/MonthSelector"

import DataStore from "common/src/lib/DataStore"

import formatCurrency from "common/src/lib/format/currency"
import df from "common/src/lib/date/formats"
import { ui as ddUi } from "common/src/store/dialogs"

import async from "common/src/lib/js/async"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import hub from "common/src/hub"
import state2query from "common/src/lib/url/state2query"
import { default as url2state, list2param } from "common/src/lib/url/url2state"
import statesAreEqual from "common/src/lib/statesAreEqual"

import * as columns from "./datastore/commissions"
import { downloadCsv } from "common/src/lib/csv"

import { loadMonths, loadUsers, 
            loadMonthUsers, loadUserMonths, 
            loadUserCommissions,
            appoveCommissionForPayment,
            cancelCommissionPayment,
            setCommissionPaid,
            loadExtraPayments,
            addExtraPayment, removeExtraPayment } from "app/actions/page/accounting"
import NullForm from "common/src/components/NullForm"


const urlParams = [
    { name: "section", defaultValue: "bymonth" },
    { name: "page", type: "int", defaultValue: 1, 
        restore: page => Math.max(1, page) - 1,
        store: page => page + 1 },
    { name: "m", stateName: "months", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "f", stateName: "fris", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "p", stateName: "pses", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "c", stateName: "contributors", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "ug", stateName: "userGroups", type: "array", defaultValue: [],
        store: v => list2param(v) }
];

const getUrlState = (props) => {
    return url2state(urlParams, props);
}

const getFilterUrl = (state) => {
    return getUrl(routes.accountingCommissions, state2query(state, urlParams));
}

const filterSetup = {
    bymonth: ["pse", "fri", "contributor", "month", /*"usergroup"*/ ],
    byuser: ["pse", "fri", "contributor", "month", /*"usergroup"*/ ]
}

const isCommissionApproved = (c, userId) => {
    if (c.approvedPayments && c.approvedPayments.length > 0) {
        if (c.approvedPayments.find(p => p.userId === userId)) {
            return true;
        }
    }
    return false;
}

const isCommissionPaid = (c) => {
    if (c.paid === true) {
        return true;
    }
    if (c.approvedPayments && c.approvedPayments.length > 0) {
        return !!c.approvedPayments.find(p => p.paid);
    }
    return false;
}

class FriPayments extends React.Component {

    state = {
        section: "bymonth",
        startDate: null,
        endDate: null,
        page: 0,
        loading: false,
        data: null,

        loadingMonth: false,
        loadingUser: false,

        expandedMonth: null,
        expandedUser: null,

        monthUsers: [],
        userMonths: [],

        loadingUserCommissions: false,
        userCommissions: [],
        userExtraPayments: [],

        approvingCommissionId: null,
        approvingAllCommissions: false,

        settingPaidCommissionId: null,
        settingPaidAllCommissions: false,

        showExtraPaymentForm: false,
        submittingExtraPayment: false,
        removingExtraPayment: null,
        extraAmount: "",
        extraCurrency: "gbp",
        extraComment: "",

        fris: [],
        pses: [],
        contributors: [],
        months: [],
        userGroups: []
    }

    _isMounted = false

    constructor(props) {
        super(props);
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {
        this.dataStore = new DataStore();
        this.innerDataStore = new DataStore();
        this.commDataStore = new DataStore();
        
        this._isMounted = true;
        const state = getUrlState(this.props);
        this.setState(state, () => this.load());
        
        hub.listen("fri-payment", "created", this.reload);
        hub.listen("fri-payment", "updated", this.reload);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.dataStore = null;
        this.innerDataStore = null;
        this.commDataStore = null;
        hub.remove("fri-payment", "created", this.reload);
        hub.remove("fri-payment", "updated", this.reload);
    }

    componentDidUpdate() {
        const state = getUrlState(this.props);
        const keys = urlParams.map(p => p.stateName || p.name);
        if (!statesAreEqual(state, this.state, keys)) {
            async(() => this.setState({ ...state, data: null }, () => this.load()));
        }
    }

    getFilters() {
        const { startDate, endDate, fris, pses, contributors, months, userGroups } = this.state;
        const filters = { startDate, endDate, fris, pses, contributors, months, userGroups };
        return filters;
    }

    async load() {
        const { section } = this.state;
        let data;
        this.setState({ loading: true });
        switch (section) {
            case "bymonth": {
                data = await loadMonths(this.getFilters());
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.date);
                this.dataStore.trigger("change");
                break;
            }
            case "byuser": {
                data = await loadUsers(this.getFilters());
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.user);
                this.dataStore.trigger("change");
                break;
            }
            default: {}
        }
        
        if (this.state.section === section) {
            this.setState({ loading: false, data });
        }
    }

    reload() {
        this.load();
    }

    download(what) {
        const { section, expandedMonth, expandedUser } = this.state;
        switch (what) {
            case "bymonth": {
                downloadCsv(this.dataStore.getCsv(), what + ".csv");
                break;
            }
            case "byuser": {
                downloadCsv(this.dataStore.getCsv(), what + ".csv");
                break;
            }
            case "bymonth_byuser": {
                const name = moment(expandedMonth).format(df.m).replace(/\s+/g, '_') + 
                            '_by_user.csv';
                downloadCsv(this.innerDataStore.getCsv(), name);
                break;
            }
            case "byuser_bymonth": {
                const user = this.state.data.find(row => row.user.id === expandedUser).user;
                const name = user.givenName + "_" + user.familyName + "_by_month.csv";
                downloadCsv(this.innerDataStore.getCsv(), name);
                break;
            }
            case "commissions": {
                const month = moment(expandedMonth).format(df.m).replace(/\s+/g, '_');
                let user; 
                if (section === "byuser") {
                    user = this.state.data.find(row => row.user.id === expandedUser).user;
                }
                else if (section === "bymonth") {
                    user = this.state.monthUsers.find(row => row.user.id === expandedUser).user;
                }
                const uname = user.givenName + "_" + user.familyName;
                const name = `${ uname }_${ month }_commissions.csv`;

                const mixedStore = new DataStore();
                mixedStore.setColumns(columns.commissions);
                mixedStore.addColumn({
                    id: "status",
                    name: "Status",
                    render: (c) => {
                        if (isCommissionPaid(c)) {
                            return "Paid";
                        }
                        if (isCommissionApproved(c, expandedUser)) {
                            return "Approved";
                        }
                        return "";
                    },
                });
                mixedStore.addColumn({
                    id: "comment",
                    name: "Comment"
                });

                const rows = this.state.userExtraPayments.map(p => {
                    return {
                        orderDate: expandedMonth,
                        userCommissionAmount: p.amount,
                        currency: p.currency,
                        comment: p.comment
                    }
                })
                this.state.userCommissions.forEach(c => rows.push(c));
                mixedStore.setRows(rows);

                downloadCsv(mixedStore.getCsv(), name);
                break;
            }
            default: {}
        }
    }


    getNav() {
        const { loading, section } = this.state;
        const refreshCls = [ 'icon', 'icon-svg-fill', loading ? 'spinning' : ''];

        return [
            {
                name: "By month",
                onClick: () => this.setSection("bymonth"),
                active: section === "bymonth"
            },
            {
                name: "By PSE",
                onClick: () => this.setSection("byuser"),
                active: section === "byuser"
            },
            {
                icon: <IconDownload/>,
                onClick: () => this.download(section)
            },
            {
                icon: <IconRefresh className={ refreshCls.join(" ") }/>,
                onClick: () => this.reload()
            }
        ]
    }

    setFilter(state) {
        const newState = Object.assign({}, this.state, state, { page: 0 });
        const url = getFilterUrl(newState);
        this.props.history.push(url);
    }

    setDateRange(range) {
        const state = Object.assign({}, this.state, {
            startDate: range[0] ? moment(range[0]).utc(true).format("YYYY-MM-DD") : null,
            endDate: range[1] ? moment(range[1]).utc(true).format("YYYY-MM-DD") : null
        });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    setSection(section) {
        const state = Object.assign({}, { section, page: 0 });
        const url = getFilterUrl(state);
        this.setState({ data: null });
        this.props.history.push(url);
    }

    setPage(page) {
        const state = Object.assign({}, this.state, { page });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    openDialog(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.dispatch(ddUi.show("fri-payment-dialog"))
    }

    async onMonthExpand(month) {
        if (month) {
            this.setState({ expandedMonth: month, loadingMonth: true, 
                            userCommissions: [], userExtraPayments: [] });
            const rows = await loadMonthUsers(this.getFilters(), month);
            if (this.state.expandedMonth === month && this._isMounted) {
                this.innerDataStore.setRows(rows);
                this.innerDataStore.setColumns(columns.user);
                this.innerDataStore.trigger("change");
                this.setState({ monthUsers: rows, loadingMonth: false });
            }
        }
        else {
            this.setState({ expandedMonth: null, loadingMonth: false, 
                            monthUsers: [], userCommissions: [], userExtraPayments: [] });
        }
    }

    async onUserExpand(userId) {
        if (userId) {
            this.setState({ expandedUser: userId, expandedMonth: null, 
                            loadingUser: true, userCommissions: [], 
                            userExtraPayments: [] });
            const rows = await loadUserMonths(this.getFilters(), userId);
            if (this.state.expandedUser === userId && this._isMounted) {
                this.innerDataStore.setRows(rows);
                this.innerDataStore.setColumns(columns.date);
                this.innerDataStore.trigger("change");
                this.setState({ userMonths: rows, loadingUser: false });
            }
        }
        else {
            this.setState({ expandedUser: null, expandedMonth: null, 
                            loadingUser: false, userMonths: [], 
                            userCommissions: [], userExtraPayments: [] });
        }
    }

    async onUserMonthExpand(month) {
        if (month) {
            const userId = this.state.expandedUser;
            this.setState({ expandedMonth: month, 
                            userCommissions: [], 
                            userExtraPayments: [],
                            loadingUserCommissions: true });
            const comms = await loadUserCommissions(userId, month);
            const payments = await loadExtraPayments(userId, month);
            this.setState({ 
                userCommissions: comms, 
                loadingUserCommissions: false,
                userExtraPayments: payments
            });
        }
        else {
            this.setState({ expandedMonth: null, 
                            userCommissions: [], 
                            userExtraPayments: [],
                            loadingUserCommissions: false });
        }
    }

    async reloadUserCommissions() {
        const userId = this.state.expandedUser;
        const month = this.state.expandedMonth;
        const rows = await loadUserCommissions(userId, month);
        this.setState({ userCommissions: rows });
    }

    async reloadUserExtraPayments() {
        const userId = this.state.expandedUser;
        const month = this.state.expandedMonth;
        const rows = await loadExtraPayments(userId, month);
        this.setState({ userExtraPayments: rows });
    }

    async onMonthUserExpand(userId) {
        if (userId) {
            const month = this.state.expandedMonth;
            this.setState({ expandedUser: userId, userCommissions: [], userExtraPayments: [],
                            loadingUserCommissions: true });
            const rows = await loadUserCommissions(userId, month);
            const payments = await loadExtraPayments(userId, month);
            this.setState({ userCommissions: rows, 
                            userExtraPayments: payments, 
                            loadingUserCommissions: false });
        }
        else {
            this.setState({ expandedUser: null, userCommissions: [], userExtraPayments: [],
                            loadingUserCommissions: false });
        }
    }

    async approveAllCommissionsForPayment() {
        this.setState({ approvingAllCommissions: true });

        const userId = this.state.expandedUser;
        let ids;
        if (this.state.userCommissions.length > 0) {
            ids = this.state.userCommissions
                    .filter(c => !isCommissionPaid(c))
                    .filter(c => !isCommissionApproved(c, userId))
                    .map(c => c.id);
        }
        else {
            const month = this.state.expandedMonth;
            const rows = await loadUserCommissions(userId, month);
            ids = rows
                    .filter(c => !isCommissionPaid(c))
                    .filter(c => !isCommissionApproved(c, userId))
                    .map(c => c.id);
        }

        for (let i = 0, l = ids.length; i < l; i++) {
            await appoveCommissionForPayment(ids[i], userId);
        }
        await this.reloadUserCommissions();
        this.setState({ approvingAllCommissions: false });
    }

    async setAlreadyPaidAllCommissions() {
        this.setState({ settingPaidAllCommissions: true });

        const userId = this.state.expandedUser;
        let ids;
        if (this.state.userCommissions.length > 0) {
            ids = this.state.userCommissions
                    .filter(c => !isCommissionPaid(c))
                    .map(c => c.id);
        }
        else {
            const month = this.state.expandedMonth;
            const rows = await loadUserCommissions(userId, month);
            ids = rows
                    .filter(c => !isCommissionPaid(c))
                    .map(c => c.id);
        }

        for (let i = 0, l = ids.length; i < l; i++) {
            await setCommissionPaid(ids[i], userId);
        }
        await this.reloadUserCommissions();
        this.setState({ settingPaidAllCommissions: false });
    }

    async appoveCommissionForPayment(commId) {
        const userId = this.state.expandedUser;
        this.setState({ approvingCommissionId: commId });
        await appoveCommissionForPayment(commId, userId);
        await this.reloadUserCommissions();
        this.setState({ approvingCommissionId: null });
    }

    async setAlreadyPaidCommissionPayment(commId) {
        const userId = this.state.expandedUser;
        this.setState({ settingPaidCommissionId: commId });
        await setCommissionPaid(commId, userId);
        await this.reloadUserCommissions();
        this.setState({ settingPaidCommissionId: null });
    }

    async cancelCommissionPayment(commId) {
        const userId = this.state.expandedUser;
        this.setState({ approvingCommissionId: commId });
        await cancelCommissionPayment(commId, userId);
        await this.reloadUserCommissions();
        this.setState({ approvingCommissionId: null });
    }

    async submitExtraPayment() {
        const { extraAmount, extraCurrency, extraComment, expandedUser, expandedMonth } = this.state;
        this.setState({ submittingExtraPayment: true });
        await addExtraPayment(expandedUser, expandedMonth, extraAmount, extraCurrency, extraComment);
        await this.reloadUserExtraPayments();
        this.setState({ 
            submittingExtraPayment: false,
            showExtraPaymentForm: false,
            extraAmount: "",
            extraCurrency: "gbp",
            extraComment: ""
        });
    }

    async removeExtraPayment(id) {
        this.setState({ removingExtraPayment: id });
        await removeExtraPayment(id);
        await this.reloadUserExtraPayments();
        this.setState({ removingExtraPayment: null });
    }

    renderFilters(filters = []) {

        const { pses, fris, contributors, startDate, endDate, months, userGroups } = this.state;

        return (
            <>
                { filters.indexOf("month") !== -1 &&
                    <MonthSelector
                        name="Month"
                        value={ months }
                        onChange={ months => this.setFilter({ months }) }/> }
                { filters.indexOf("date") !== -1 && 
                    <DateRangeSelector
                        name="Date range"
                        value={ startDate ? [ startDate, endDate ] : [] }
                        onChange={ r => this.setDateRange(r) }/> }
                { filters.indexOf("pse") !== -1 && 
                    <UserSelector
                        name="PSE"
                        group={ [ "FRI", "GPS" ] }
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ pses }
                        onChange={ pses => this.setFilter({ pses }) }/> }
                { filters.indexOf("fri") !== -1 && 
                    <UserSelector
                        name="Look by"
                        group="FRI"
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ fris }
                        onChange={ fris => this.setFilter({ fris }) }/> }
                { filters.indexOf("contributor") !== -1 &&
                    <UserSelector
                        name="Link by"
                        group="Contributor"
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ contributors }
                        onChange={ contributors => this.setFilter({ contributors }) }/> }
                { filters.indexOf("usergroup") !== -1 &&
                    <UserGroupsSelector
                        name="User group"
                        value={ userGroups }
                        onChange={ userGroups => this.setFilter({ userGroups }) }/> }
            </>
        )
    }



    renderMonthUsers() {
        const { loadingMonth } = this.state;

        if (loadingMonth) {
            return (
                <Loader/>
            )
        }

        return (
            <>
                <Table 
                    dataStore={ this.innerDataStore }
                    expandable
                    expandKey="userId"
                    onRowExpand={ userId => this.onMonthUserExpand(userId) }
                    renderDetails={ row => this.renderUserMonthDetails(row) }/>
                <div className="page-commission-details-toolbar">
                    <span className="spacer"/>
                    <Button 
                        size="small" 
                        variant="text" 
                        children="Download users"
                        onClick={ () => this.download("bymonth_byuser") }/>
                </div>
            </>
        )
    }

    renderByMonth() {
        return (
            <Table 
                dataStore={ this.dataStore }
                expandable
                expandKey="date_group"
                renderDetails={ row => this.renderMonthUsers(row) }
                onRowExpand={ month => this.onMonthExpand(month) }/>
        )
    }


    renderExtraPaymentForm() {

        const { submittingExtraPayment, extraAmount, extraComment, extraCurrency } = this.state;

        return (
            <tr>
                <td className="grid-var-slim page-accounting-extra-amount">
                    <NullForm>
                        <TextField 
                            autoComplete="off"
                            variant="outlined" 
                            size="small" 
                            value={ extraAmount }
                            onChange={ e => this.setState({ extraAmount: e.target.value }) }
                            disabled={ submittingExtraPayment }
                            fullWidth/>
                        <select
                            value={ extraCurrency }
                            onChange={ e => this.setState({ extraCurrency: e.target.value }) }
                            disabled={ submittingExtraPayment }>
                            <option value="gbp">GBP</option>
                            <option value="usd">USD</option>
                        </select>
                    </NullForm>
                </td>
                <td className="grid-var-slim">
                    <TextField 
                        autoComplete="off"
                        variant="outlined" 
                        size="small" 
                        value={ extraComment }
                        onChange={ e => this.setState({ extraComment: e.target.value }) }
                        disabled={ submittingExtraPayment }
                        fullWidth/>
                </td>
                <td className="grid-var-slim min-width">
                    <Button 
                        variant="outlined" 
                        size="small" 
                        children="Submit"
                        disabled={ submittingExtraPayment }
                        startIcon={ submittingExtraPayment ? <Loader inline/> : null }
                        onClick={ () => this.submitExtraPayment() }/>
                </td>
            </tr>
        )
    }

    renderExtraPayments() {
        const { showExtraPaymentForm, userExtraPayments, removingExtraPayment } = this.state;
        const cols = [
            { id: "amount", name: "Amount", className: "page-accounting-extra-amount",
                render: p => formatCurrency(p.amount, p.currency, 2) },
            { id: "comment", name: "Comment" },
            { id: "action", name: "", className: "min-width", render: (p) => {
                if (!p.paid) {
                    return (
                        <Button 
                            variant="text"
                            size="small"
                            disabled={ removingExtraPayment === p.id }
                            startIcon={ removingExtraPayment === p.id ? <Loader inline/> : null }
                            children="Remove"
                            onClick={ () => this.removeExtraPayment(p.id) }/>
                    )
                }
                else {
                    return "Paid"
                }
            }}
        ]

        return (
            <Table 
                before={ showExtraPaymentForm ? this.renderExtraPaymentForm() : null }
                variant="slim"
                className="page-commission-details-table"
                cols={ cols } 
                rows={ userExtraPayments }/>
        )
    }

    renderCommissionDetails(id) {
        return (
            <div className="page-commission-details-toolbar">
                <Link 
                    children="View commission"
                    to={ getUrl(routes.reportCommissions, { q: id }) }/>
            </div>
        )
    }

    renderUserMonthDetails() {
        const { userCommissions, loadingUserCommissions,
                approvingAllCommissions, approvingCommissionId,
                settingPaidCommissionId, settingPaidAllCommissions,
                showExtraPaymentForm, userExtraPayments } = this.state;

        if (loadingUserCommissions) {
            return (
                <Loader/>
            )
        }

        this.commDataStore.setColumns(columns.commissions);
        this.commDataStore.addColumn({ 
            id: "approve", 
            name: "", 
            className: "min-width grid-nowrap",
            render: (c) => {
                if (isCommissionPaid(c)) {
                    return "Paid";
                }
                if (isCommissionApproved(c, userId)) {
                    return (
                        <>
                        <Button 
                            size="small" 
                            variant="text" 
                            disabled={ !!approvingCommissionId || !!approvingAllCommissions }
                            startIcon={ approvingCommissionId === c.id ? <Loader inline/> : null }
                            onClick={ (e) => {
                                e.stopPropagation();
                                this.cancelCommissionPayment(c.id) 
                            }}
                            children="Cancel payment"/> 
                        <Button 
                            size="small" 
                            variant="text" 
                            disabled={ !!settingPaidCommissionId || !!settingPaidAllCommissions }
                            startIcon={ settingPaidCommissionId === c.id ? <Loader inline/> : null }
                            onClick={ (e) => {
                                e.stopPropagation();
                                this.setAlreadyPaidCommissionPayment(c.id) 
                            }}
                            children="Already paid"/> 
                        </>
                    )
                }
                else {
                    return (
                        <>
                        <Button 
                            size="small" 
                            variant="outlined" 
                            disabled={ !!approvingCommissionId || !!approvingAllCommissions }
                            startIcon={ approvingCommissionId === c.id ? <Loader inline/> : null }
                            onClick={ (e) => {
                                e.stopPropagation();
                                this.appoveCommissionForPayment(c.id) 
                            }}
                            children="Approve"/> 
                        <Button 
                            size="small" 
                            variant="text" 
                            disabled={ !!settingPaidCommissionId || !!settingPaidAllCommissions }
                            startIcon={ settingPaidCommissionId === c.id ? <Loader inline/> : null }
                            onClick={ (e) => {
                                e.stopPropagation();
                                this.setAlreadyPaidCommissionPayment(c.id) 
                            }}
                            children="Already paid"/> 
                        </>
                    )
                }
            }
        });
        this.commDataStore.setRows(userCommissions);
        this.commDataStore.trigger("change");

        const userId = this.state.expandedUser;
        const hasCmsForApproval = userCommissions.length === 0 ||
                                !!userCommissions.find(c => !isCommissionPaid(c) && 
                                                            !isCommissionApproved(c, userId));

        return (
            <>
                <div className="page-commission-details-toolbar">
                    { hasCmsForApproval && 
                        <Button 
                            size="small" 
                            variant="outlined" 
                            disabled={ approvingCommissionId !== null || approvingAllCommissions }
                            startIcon={ approvingAllCommissions ? <Loader inline/> : null }
                            onClick={ () => this.approveAllCommissionsForPayment() }
                            children="Approve all commissions for payment"/> }
                    <Button 
                        size="small" 
                        variant="outlined" 
                        children={ showExtraPaymentForm ? "Cancel extra payment" : "Add extra payment" }
                        onClick={ () => this.setState({ showExtraPaymentForm: !showExtraPaymentForm })}/>
                    { userCommissions.length > 0 && 
                        <Button 
                            size="small" 
                            variant="text" 
                            disabled={ settingPaidCommissionId !== null || settingPaidAllCommissions }
                            startIcon={ settingPaidAllCommissions ? <Loader inline/> : null }
                            onClick={ () => this.setAlreadyPaidAllCommissions() }
                            children="Already paid for the orders"/> }
                    <span className="spacer"/>
                    <Button 
                        size="small"    
                        variant="text" 
                        children="Download commissions"
                        onClick={ () => this.download("commissions") }/>
                </div>
                { (userExtraPayments.length > 0 || showExtraPaymentForm) && this.renderExtraPayments() }
                { userCommissions.length > 0 && 
                    <Table 
                        className="page-commission-details-table"
                        dataStore={ this.commDataStore }
                        variant="slim"
                        expandable
                        expandKey="id"
                        renderDetails={ comm => this.renderCommissionDetails(comm.id) }/>}
            </>
        )
    }

    renderUserMonths() {
        const { loadingUser } = this.state;

        if (loadingUser) {
            return (
                <Loader/>
            )
        }

        return (
            <>
                <Table 
                    dataStore={ this.innerDataStore }
                    expandable
                    expandKey="date_group"
                    onRowExpand={ month => this.onUserMonthExpand(month) }
                    renderDetails={ row => this.renderUserMonthDetails(row) }/>
                <div className="page-commission-details-toolbar">
                    <span className="spacer"/>
                    <Button 
                        size="small"    
                        variant="text" 
                        children="Download months"
                        onClick={ () => this.download("byuser_bymonth") }/>
                </div>
            </>
        )
    }

    renderByUser() {
        return (
            <Table 
                dataStore={ this.dataStore }
                expandable
                expandKey="userId"
                renderDetails={ () => this.renderUserMonths() }
                onRowExpand={ userId => this.onUserExpand(userId) }/>
        )
    }

    render() {
        const menu = this.getNav();
        const { data, loading, section } = this.state;

        return (
            <div className="page page-accounting-commissions">
                <div className="toolbar">
                    <div className="toolbar-title">
                        Commissions
                    </div>
                    <Menu menu={ menu } className="right"/>
                </div>

                { loading && <Loader size={ 64 }/> }

                <div className="page-commissions-layout">
                    <div className="left">
                        { this.renderFilters(filterSetup[section]) }
                    </div>
                    <div className="right">
                        { (data && section === "bymonth") && this.renderByMonth() }
                        { (data && section === "byuser") && this.renderByUser() }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(FriPayments)
