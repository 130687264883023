import React from "react"

//import { ReactComponent as IconGoogle } from "common/src/svg/google.svg"
import { Link } from "react-router-dom"
import { Auth } from "@aws-amplify/auth"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Loader from "common/src/components/Loader"

import hub from "common/src/hub"
import { alert } from "common/src/components/dialog/Alert"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import api from "app/api"
import parseQueryString from "common/src/lib/url/parseQueryString"
import getQueryParam from "common/src/lib/url/getQueryParam"
import isEmail from "validator/es/lib/isEmail"
import isEmptyObject from "common/src/lib/js/isEmptyObject"
import async from "common/src/lib/js/async"


class SignInForm extends React.Component {

    state = {
        loading: false,
        email: "",
        password: "",
        emailError: "",
        passwordError: "",
        b: getQueryParam("b")
    }

    submitClicked = false


    constructor(props) {
        super(props);
        this.onAuthFailure = this.onAuthFailure.bind(this);
        this.submitClicked = false;
    }

    componentDidMount() {
        //super.componentDidMount();
        hub.listen("auth", "signIn_failure", this.onAuthFailure);

        const params = parseQueryString(window.location.search);
        
        if (params.h && params.email) {
            api.unauth.post("/recover/get", { body: { hash: params.h, email: params.email }})
                .then(r => {
                    if (r.p) {
                        this.setState({ email: params.email, password: r.p }, () => {
                            async(() => this.signIn());
                        });
                        //this.s.email = params.email;
                        //this.inputs.password = r.p;
                        //this.signIn();   
                    }
                });
        }
    }

    componentWillUnmount() {
        hub.remove("auth", "signIn_failure", this.onAuthFailure);
    }

    federatedSignin(provider) {
        Auth.federatedSignIn({ provider });
    }

    async onAuthFailure(err) {

        const params = parseQueryString(window.location.search);
        
        let res = await api.unauth.post("/recover/check", 
                            { body: { email: this.state.email.toLowerCase() }});

        if (res.success === true && !params.h) {
            alert({ 
                title: "Oops! Please update your login details",
                html: `We have made some changes to our website since you last visited. 
                            You will need to reset your password to access your account 
                            and all the looks and pieces you have saved in My Favorites. 
                            <b>Please check your email for your reset password link.</b>
                            <br/><br/>
                            If you haven't received our email within a few seconds, 
                            please check your spam or junk mail folder.
                            <br/><br/>
                            Thank you for your patience.`
            });
        }
        else {
            alert({ message: "Incorrect username or password" });
        }
    }

    setInput(field, value) {

        if (field === "email") {
            value = value.toLowerCase();
        }

        this.setState({ [field]: value }, () => {
            if (this.submitClicked) {
                async(() => this.validate());
            }
        });        
    }

    validate() {

        let emailError = "",
            passwordError = "";

        if (!this.state.email) {
            emailError = "Please enter your email";
        }
        else if (!isEmail(this.state.email)) {
            emailError = "Please enter a valid email";
        }
        if (!this.state.password) {
            passwordError = "Please enter your password";
        }

        this.setState({ emailError, passwordError });

        return !emailError && !passwordError;
    }


    onSubmitClick(e) {
        this.submitClicked = true;
        if (this.validate()) {
            this.signIn();
        }
    }

    onForgotClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onAuthStateChange("forgotPassword");
    }




	checkContact(user) {
		Auth.verifiedContact(user).then(data => {
			if (!isEmptyObject(data.verified)) {
                this.props.onAuthStateChange('signedIn', user);
				//this.changeState('signedIn', user);
			} 
            else {
                console.log("Contact verification is not implemented")
				//user = Object.assign(user, data);
                //this.props.onAuthStateChange('verifyContact', user);
			}
		});
	}

	async signIn() {

        const { email, password } = this.state;
		this.setState({ loading: true });

        // this is an edited version of the code from aws-amplify-react
		try {
			const user = await Auth.signIn(email, password);
			//logger.debug(user);

			if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
				//logger.debug('confirm user with ' + user.challengeName);
				//this.changeState('confirmSignIn', user);
                console.error("MFA is not configured")
                //this.props.onAuthStateChange('confirmSignIn', user);
			} 
            else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				//logger.debug('require new password', user.challengeParam);
				//this.changeState('requireNewPassword', user);
                this.props.onAuthStateChange('requireNewPassword', user);
			} 
            else if (user.challengeName === 'MFA_SETUP') {
				///logger.debug('TOTP setup', user.challengeParam);
				//this.changeState('TOTPSetup', user);
                console.error("MFA setup is not configured")
                //this.props.onAuthStateChange('TOTPSetup', user);
			} 
            else if (user.challengeName === 'CUSTOM_CHALLENGE' &&
				    user.challengeParam &&
				    user.challengeParam.trigger === 'true') {
				//logger.debug('custom challenge', user.challengeParam);
				//this.changeState('customConfirmSignIn', user);
                console.error("Custom challenge is not configured")
                //this.props.onAuthStateChange('customConfirmSignIn', user);
			} 
            else {
				this.checkContact(user);
			}
		} 
        catch (err) {
			if (err.code === 'UserNotConfirmedException') {
				//logger.debug('the user is not confirmed');
				//this.changeState('confirmSignUp', { username });
                this.props.onAuthStateChange('confirmSignUp', { email });
			} 
            else if (err.code === 'PasswordResetRequiredException') {
				//logger.debug('the user requires a new password');
				//this.changeState('forgotPassword', { username });
                this.props.onAuthStateChange('forgotPassword', { email });
			} 
            else {
				//this.error(err);
                this.props.onError(err);
			}
		} 
        finally {
			this.setState({ loading: false });
		}
	}

    render() {

        const { loading, b, email, password, emailError, passwordError } = this.state;
        const { showRegister = true, 
                subheader = null,
                loaderSize = 116,
                labelEmail = "Email",
                labelPassword = "Password",
                submitText = "Log In",
                linkForgotText = "Forgot password?" } = this.props;
        const cls = ["form-sign-in"];

        loading && cls.push("hidden");

        return (
            <>
            { loading && <Loader className="page-loading" size={ loaderSize }/> }
            <div className={ cls.join(" ") }>
            <h1>Log In</h1>
            { subheader }

                <TextField 
                    label={ labelEmail }
                    fullWidth
                    required
                    autoFocus
                    autoCorrect="off" 
                    autoCapitalize="none"
                    id="login-email"
                    key="email"
                    name="email"
                    value={ email }
                    onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                    onChange={ e => this.setInput("email", e.target.value) }
                    variant="outlined"
                    error={ !!emailError }
                    helperText={ emailError }
                    inputProps={{ "data-cy": "login-form-email" }}/>

                <TextField 
                    label={ labelPassword }
                    fullWidth
                    required
                    id="login-password"
                    key="password"
                    name="password"
                    value={ password }
                    onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                    onChange={ e => this.setInput("password", e.target.value) }
                    type="password"
                    variant="outlined"
                    error={ !!passwordError }
                    helperText={ passwordError }
                    inputProps={{ "data-cy": "login-form-password" }}/>

                <div className="login-form-actions">
                <Button 
                    data-cy="login-form-submit"
                    variant="contained"
                    size="large" 
                    id="login-submit"
                    onClick={ () => this.onSubmitClick() }
                    children={ submitText }/>
                
                <p className="secondary-action">
                    <a href="/#" 
                        onClick={ e => this.onForgotClick(e) }>
                        { linkForgotText }
                    </a>
                    { showRegister && 
                        <Link to={ getUrl(routes.register, { b }) }>
                            Register 
                        </Link> }
                </p>
                </div>
            </div>
            </>
        )
    }
}

export default SignInForm

/*

                <Button variant="contained"
                        size="large" 
                        startIcon={ <IconGoogle/> }
                        onClick={ () => this.federatedSignin("Google") }
                        children="Log in via Google"/>

                <Button variant="contained"
                        size="large" 
                        onClick={ () => this.federatedSignin("Facebook") }
                        children="Log in via Facebook"/>
                
                        */
