import React, { useState, useMemo, useCallback } from "react"
import hub from "common/src/hub"
import copy from 'copy-to-clipboard'
import { ReactComponent as IconFacebook } from "common/src/svg/facebook.svg"
import { ReactComponent as IconPin } from "common/src/svg/pinterest.svg"
import { ReactComponent as IconEmail } from "common/src/svg/email.svg"
import { ReactComponent as IconLink } from "common/src/svg/link.svg"
import { ReactComponent as IconWhatsapp } from "common/src/svg/whatsapp.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import getShareUrl from "common/src/lib/url/share"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"

function openWindow(url) {
    if (typeof window !== "undefined") {
        window.open(url);
    }
};

function stop(e) {
    e.stopPropagation();
}

function SharePanel(props) {

    const title = props.title || "Share";
    
    const mailto = useMemo(
        () => {
            const url = props.info ? props.info.url : "";
            return `mailto:?body=${encodeURIComponent(url)}`;
        },
        [ props.info?.url ]
    );
    const onClose = useSwallowEventCallback(() => props.onClose(), []);
    const shareTo = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            const provider = e.target.dataset.provider;

            if (provider === "pinterest") {
                const info = props.info;
                if (typeof window !== "undefined") {
                    window.PinUtils.pinOne({
                        'url': info.url,
                        'media': info.image,
                        'description': info.text
                    });
                }
            }
            else {
                openWindow(getShareUrl(provider, props.info.url));
            }
            
            hub.dispatch("log", "share", {
                provider: provider,
                type: "look",
                id: props.info.id
            });
    
            props.onClose();
        },
        [ props.info?.id, props.info?.url ]
    );

    const copyLink = useSwallowEventCallback(
        () => {
            copy(props.info.url);

            hub.dispatch("log", "share", {
                provider: "link",
                type: "look",
                id: props.info.id
            });
        },
        [ props.info?.url, props.info?.id ]
    );



    return (
        <div className="share-panel">
            <h3>{ title }</h3>
            <a href="/#" 
                className="close"
                onClick={ onClose }
                children={ <IconClose/> }/>
            <div className="providers">
                <a href="/#" 
                    title="Share to Facebook"
                    data-provider="facebook"
                    onClick={ shareTo } 
                    children={ <IconFacebook/> }/>
                <a href="/#" 
                    title="Share to Pinterest"
                    data-provider="pinterest"
                    onClick={ shareTo } 
                    children={ <IconPin/> }/>
                <a href={ mailto }
                    title="Send in email"
                    onClick={ stop }
                    target="_blank"
                    rel="noref noopener"
                    children={ <IconEmail/> }/>
                <a href="/#" 
                    title="Send Whatsapp message"
                    data-provider="whatsapp"
                    onClick={ shareTo }
                    children={ <IconWhatsapp/> }/>
                <a href="/#" 
                    title="Copy link"
                    onClick={ copyLink }
                    children={ <IconLink/> }/>
            </div>
        </div>
    )
}


/*
class SharePanel extends React.Component {

    state = {
        info: null,
        copied: false
    }

    _tmt = null
    _isMounted = false

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClose(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClose();
    }

    shareTo(e, provider) {
        e.preventDefault();
        e.stopPropagation();

        if (provider === "pinterest") {
            const info = this.props.info;
            if (typeof window !== "undefined") {
                window.PinUtils.pinOne({
                    'url': info.url,
                    'media': info.image,
                    'description': info.text
                });
            }
        }
        else {
            openWindow(getShareUrl(provider, this.props.info.url));
        }
        
        hub.dispatch("log", "share", {
            provider: provider,
            type: "look",
            id: this.props.info.id
        });

        this.props.onClose();
    }

    copyLink(e) {
        e.preventDefault();
        e.stopPropagation();
        copy(this.props.info.url);
        this.setState({ copied: true });

        hub.dispatch("log", "share", {
            provider: "link",
            type: "look",
            id: this.props.info.id
        });

        if (this._tmt) {
            clearTimeout(this._tmt);
            this._tmt = null;
        }

        this._tmt = setTimeout(() => {
            this.setState({ copied: false });
            this._tmt = null;
        }, 2000);
    }

    render() {
        const title = this.props.title || "Share";
        const url = this.props.info ? this.props.info.url : "";
        const mailto = `mailto:?body=${encodeURIComponent(url)}`;

        return (
            <div className="share-panel">
                <h3>{ title }</h3>
                <a href="/#" 
                    className="close"
                    onClick={ e => this.onClose(e) }
                    children={ <IconClose/> }/>
                <div className="providers">
                    <a href="/#" 
                        title="Share to Facebook"
                        onClick={ e => this.shareTo(e, "facebook") } 
                        children={ <IconFacebook/> }/>
                    <a href="/#" 
                        title="Share to Pinterest"
                        onClick={ e => this.shareTo(e, "pinterest") } 
                        children={ <IconPin/> }/>
                    <a href={ mailto }
                        title="Send in email"
                        onClick={ e => e.stopPropagation() }
                        target="_blank"
                        rel="noref noopener"
                        children={ <IconEmail/> }/>
                    <a href="/#" 
                        title="Send Whatsapp message"
                        onClick={ e => this.shareTo(e, "whatsapp") }
                        children={ <IconWhatsapp/> }/>
                    <a href="/#" 
                        title="Copy link"
                        onClick={ e => this.copyLink(e) }
                        children={ <IconLink/> }/>
                </div>
            </div>
        )
    }
}*/

export default SharePanel