import { useEffect } from "react"
import { useHistory } from "react-router-dom"

function CypressHistorySupport() {

    const history = useHistory();

    useEffect(
        () => {
            if (window.Cypress) {
                window.cyNavigate = (path) => history.push(path);
            }
        },
        [ history ]
    );

    return null;
}

export default CypressHistorySupport