import React from "react"
import { connect } from "react-redux"
import Selector from "../material/Autocomplete"

class MaterialSelector extends React.Component {

    state = {
        materials: []
    }

    onChange(materials) {
        this.setState({ materials });
        this.props.onChange && this.props.onChange(materials);
    }

    render() {
        const { materials } = this.state;
        return (
            <Selector 
                options={ this.props.materials }
                multiple={ this.props.multiple }
                allowEmpty={ this.props.allowEmpty }
                selection={ materials }
                placeholder="Material"
                size={ this.props.size }
                onChange={ materials => this.onChange(materials) }/>
        )
    }
}

export default connect(state => ({ materials: state.catalogue.data.materials }))(MaterialSelector)