import { TYPE_FRI, TYPE_CONTRIBUTOR, TYPE_EXTRA } from "app/actions/page/accounting"
import formatCurrency from "common/src/lib/format/currency"
import formatNumber from "common/src/lib/format/number"
import User from "common/src/components/user/User"





export const payments = [
    { 
        id: "amount", 
        name: "Amount", 
        modes: ["grid"],
        render: p => formatCurrency(p.amount, p.currency, 2) 
    },

    {
        id: "currency",
        name: "Currency",
        modes: ["csv"],
        render: p => p.currency.toUpperCase()
    },

    {
        id: "amountValue",
        name: "Amount",
        modes: ["csv"],
        render: p => formatNumber(p.amount, 2)
    },

    { 
        id: "type", 
        name: "Type", 
        render: p => {
            switch (p.type) {
                case TYPE_FRI: {
                    return "From look/consultation";
                }
                case TYPE_CONTRIBUTOR: {
                    return "From link";
                }
                case TYPE_EXTRA: {
                    return "Extra payment"
                }
                default: {
                    return "";
                }
            }
        }
    },
    { 
        id: "comment", 
        name: "Comment" 
    }
]

export const drafts = [
    ...payments,
    {
        id: "draft",
        name: "Revolut Draft ID",
        className: "min-width grid-nowrap",
        //modes: ["grid"],
        render: (row) => row.revolutDraftId
    },
    {
        id: "reference",
        name: "Reference",
        className: "min-width",
        //modes: ["grid"],
        render: (row) => row.revolutDraft?.reference
    },
    {
        id: "status",
        name: "Status",
        className: "min-width",
        //modes: ["grid"],
        render: (row) => row.revolutDraft?.status
    }
]

export const user = [
    { 
        id: "user", 
        name: "User", 
        render: row => <User user={ row.user } passthruClick/>,
        renderCsv: row => row.user.givenName + " " + row.user.familyName
    },
    { 
        id: "amount", 
        name: "Amount", 
        modes: ["grid", "csv"],
        render: row => formatCurrency(row.amount, "gbp", 2) 
    }
]