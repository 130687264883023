import React from "react"
import moment from "moment"
import PublicSelector from "common/src/components/PublicSelector"
import async from "common/src/lib/js/async"

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const generateMonths = () => {

    // months are from 0 to 11
    let now = new Date();
    let maxYear = now.getFullYear();
    let maxMonth = now.getMonth();
    let minYear = 2020;
    let minMonth = 0;
    let year = maxYear, month = maxMonth;
    const months = [];

    while (true) {

        if (year < minYear || (year === minYear && month < minMonth)) {
            break;
        }
        months.push({
            id: moment(`${ year }-${ ("" + (month + 1)).padStart(2, '0') }-15 00:00:01`)
                .utc()
                .startOf("month")
                .toDate()
                .toISOString(),
            name: `${ monthNames[month] } ${ year }`
        });
        month--;

        if (month < 0) {
            year--;
            month = 11;
        }
    }
    

    return months;
};

class NetworkSelector extends React.Component {

    state = {
        months: [],
        loading: false
    }

    componentDidMount() {
        async(() => this.setState({ months: generateMonths() }));
    }

    load() {}

    render() {

        const { months } = this.state;
        const props = Object.assign({}, this.props);
        props.onLoadItems = () => this.load();
        props.name = props.name || "Month";
        props.cls = [ "tag-selector-v2", "month-selector" ];
        props.items = months;
        props.filtered = months;
        props.loading = false;
        props.noSearch = true;

        return (
            <PublicSelector { ...props }/>
        )
    }
}

export default NetworkSelector