import Loader from "common/src/components/Loader";
import { useCallback, useEffect, useState } from "react";

import api from "app/api"

const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

function RemoveBgBalance() {

    const [ account, setAccount ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const loadAccountData = useCallback(
        async () => {
            setLoading(true);
            const account = await api.backend.get("/removebg/credits");
            setAccount(account);
            setLoading(false);
        },
        []
    );

    useEffect(
        () => {
            loadAccountData();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className="page page-removebg page-w-loading" style={ style }>
            { loading && <Loader/> }
            { account && (
                <p>
                    Available credits: { account.credits.total },<br/>
                    Free requests: { account.api.free_calls }
                </p>
            )}
        </div>
    )
}

export default RemoveBgBalance