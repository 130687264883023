import React, { useMemo, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import { ReactComponent as IconMenu } from "common/src/svg/hamburger.svg"
import { ReactComponent as IconNotification } from "common/src/svg/bell.svg"
import { ReactComponent as IconCurrency } from "common/src/svg/currency.svg"
import Loader from "common/src/components/Loader"
import Avatar from "common/src/components/user/Avatar"
import NotificationsDialog from "common/src/components/dialog/Notifications"
import { signOut } from "common/src/actions/user"
import hub from "common/src/hub"
import routes from "app/routes"
import user from "common/src/user"
import getUrl from "common/src/lib/url/get"
import { data } from "common/src/store/user"
import setCookie from "common/src/lib/cookie/set"
import { ui as userUi } from "common/src/store/user"

import swallowEvent from "common/src/lib/dom/swallowEvent"
import useStateWithGetter from "common/src/hooks/useStateWithGetter"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"


const urls = {
    home: getUrl(routes.home),
    profile: getUrl(routes.profile),
    contacts: getUrl(routes.profileContacts)
};


function Header(props) {

    const { showCurrencies } = props;
    const dispatch = useDispatch();
    const [ menuOpen, setMenuOpen, getMenuOpen ] = useStateWithGetter(false);
    const current = useSelector(s => s.user.current);
    const geo = useSelector(s => s.user.geo || {});
    const { checking, loading } = useSelector(s => s.user.ui);
    const unreadCount = useSelector(s => s.notifications.ui.unreadCount);
    const ntfsEnabled = useSelector(s => s.notifications.ui.enable);

    const currency = geo.currency;
    const originalCurrency = geo.original || false;

    const fullName = useMemo(() => user.getFullName(), [ user.id() ]);
    const cls = useMemo(() => menuOpen ? "active" : "", [ menuOpen ]);

    const onRouteChanged = useCallback(
        () => {
            setMenuOpen(false);
            document.documentElement.classList.remove("modal");
        },
        []
    );

    const setCurrency = useCallback(
        (e) => {
            e && swallowEvent(e);
            const currency = e.target.dataset.currency;

            if (currency === "original") {
                // we do not override currency cookie
                setCookie("currency-original", "1");
                dispatch(data.geo.update({ original: true }));
                hub.dispatch("app", "currency-change", currency);
            }
            else {
                setCookie("currency", currency);
                setCookie("currency-original", null);
                dispatch(data.geo.update({ currency, original: false }));
                hub.dispatch("app", "currency-change", currency);
            }
        },
        []
    );

    const showMenu = useSwallowEventCallback(
        () =>  {
            const menuOpen = !getMenuOpen();
            setMenuOpen(menuOpen);
            document.documentElement.classList[ menuOpen ? "add" : "remove" ]("modal");
        },
        []
    );

    const onChangePasswordClick = useSwallowEventCallback(
        () => dispatch(userUi.form("password")),
        []
    );



    useEffect(
        () => {
            hub.listen("app", "locationChange", onRouteChanged);
            return () => {
                hub.remove("app", "locationChange", onRouteChanged);
            }
        },
        []
    );


    return (
        <div id="header" className={ cls }>

            <a href="/#" 
                id="header-toggle"
                onClick={ showMenu }>
                <IconMenu/>
            </a>

            <Link to={ urls.home } id="header-logo">
                { props.logo }
            </Link>

            { props.children }
        
            <nav className="menu right header-user">

                <div className="menu-item menu-user">
                { (checking || loading || !current) ? 
                    <Loader/> :
                    <>
                    <NavLink 
                        to={ urls.profile }
                        className="menu-link"
                        data-cy="header-username"
                        id="header-username">
                        <span id="header-fullname">{ fullName }</span>
                        <Avatar user={ current } size={ 32 }/>
                    </NavLink> 
                    <nav className="menu-sub">
                        <div className="menu-item">
                            <Link 
                                data-cy="header-account-link"
                                className="menu-link"
                                to={ urls.profile }>Your account</Link>
                        </div>
                        <div className="menu-item">
                            <a href="/#" 
                                className="menu-link"
                                onClick={ onChangePasswordClick }>Change password</a>
                        </div>
                        { props.userMenu }
                        {/*<div className="menu-item">
                            <Link 
                                className="menu-link"
                                to={ urls.contacts }>Your contacts</Link>
                        </div>*/}
                        <div className="menu-item">
                            <Link 
                                data-cy="header-logout-link"
                                className="menu-link"
                                to={ urls.home } onClick={ signOut }>Log out</Link>
                        </div>
                    </nav>
                    </>
                }
                </div>

                { (showCurrencies && current) &&
                    <div className="menu-item menu-currencies">
                        <a href="/#" className="menu-link"
                            onClick={ swallowEvent }>
                            <IconCurrency/>
                            { (currency && !originalCurrency) && <span>{ currency }</span> }
                        </a>
                        <nav className="menu-sub">
                            <div className="menu-item">
                                <span className="menu-label">Show prices in</span>
                            </div>
                            <div className="menu-item">
                                <a href="/#" 
                                    className="menu-link"
                                    data-currency="gbp"
                                    onClick={ setCurrency }>£ GBP</a>
                            </div>
                            <div className="menu-item">
                                <a href="/#" 
                                    className="menu-link"
                                    data-currency="usd"
                                    onClick={ setCurrency }>$ USD</a>
                            </div>
                            <div className="menu-item">
                                <a href="/#" 
                                    className="menu-link"
                                    data-currency="eur"
                                    onClick={ setCurrency }>€ EUR</a>
                            </div>
                            <div className="menu-item">
                                <a href="/#" 
                                    className="menu-link"
                                    data-currency="original"
                                    onClick={ setCurrency }>Original currency</a>
                            </div>
                        </nav>
                    </div> }

                { ntfsEnabled && 
                    <div className="menu-item menu-notifications">
                        <a href="/#" className="menu-link app-header-bell">
                            <IconNotification/>
                            { unreadCount.total > 0 && 
                                <span className="badge">{ unreadCount.total }</span> }
                        </a>
                        <NotificationsDialog/>
                    </div> }
            </nav>
        </div>
    )
}

export default Header
