import React from "react"
import moment from "moment"

import { TextField, Button } from "@mui/material"

import Loader from "common/src/components/Loader"
import { prompt, alert } from "common/src/components/dialog/Alert"

import async from "common/src/lib/js/async"
import api from "app/api"
import df from "common/src/lib/date/formats"
import NullForm from "common/src/components/NullForm"

class PageChats extends React.Component {

    state = {
        loading: false,
        search: "",
        chats: []
    }

    componentDidMount() {
        async(() => this.loadChats());
    }

    async clearWsPool(e, p) {
        e && e.stopPropagation();
        e && e.preventDefault();

        await api.chatParticipant.update(p.id, { whatsappPoolNumber: null });
        this.loadChats();
    }

    async setParticipantRole(e, p) {
        e && e.stopPropagation();
        e && e.preventDefault();
        const res = await prompt({ message: "Type shopper or customer" });
        const role = res.prompt;

        if (role === "shopper" || role === "customer") {
            await api.chatParticipant.update(p.id, { role });
            this.loadChats();
        }
    }

    async getConsultationsForUsers(id1, id2) {
        const where = {
            friId: { _in: [ id1, id2 ] },
            customerId: { _in: [ id1, id2 ] }
        }
        const graph = "id friId customerId";
        const consultations = await api.consultation.list({ where }, graph);
        return consultations;
    }

    async autodetectRoles(e, p1, p2) {
        e && e.stopPropagation();
        e && e.preventDefault();
        const u1id = p1.user.id;
        const u2id = p2.user.id;
        let u1role, u2role;
        let detected = false;

        const consultations = await this.getConsultationsForUsers(u1id, u2id);

        for (let i = 0, l = consultations.length; i < l; i++) {
            let c = consultations[i];
            if (c.friId === u1id) {
                u1role = "shopper";
                u2role = "customer";
            }
            else if (c.friId === u2id) {
                u2role = "shopper";
                u1role = "customer";
            }
            else if (c.customerId === u1id) {
                u1role = "customer";
                u2role = "shopper";
            }
            else if (c.customerId === u2id) {
                u2role = "customer";
                u1role = "shopper";
            }

            if (u1role && u2role) {
                await api.chatParticipant.update(p1.id, { role: u1role });
                await api.chatParticipant.update(p2.id, { role: u2role });
                detected = true;
                this.loadChats();
                break;
            }
        }

        if (!detected) {
            alert({ message: "Could not detect roles" })
        }
    }

    async loadChats() {

        const search = this.state.search;
        let where = null;
        const graph = `
            id
            createdAt
            participants {
                id
                userId
                role
                whatsappPoolNumber
                user {
                    id
                    groups
                    givenName
                    familyName
                    email
                    phone
                    whatsapp
                    handle
                    contacts {
                        type
                    }
                }
            }
        `;
        const order = { createdAt: "desc" };


        if (search) {
            where = {};
            where.participants = {
                user: {
                    _or: [
                        { givenName: { _ilike: `%${ search }%` }},
                        { familyName: { _ilike: `%${ search }%` }},
                        { email: { _ilike: `%${ search }%` }},
                        { phone: { _ilike: `%${ search }%` }}
                    ]
                }
            }
        }

        const chats = await api.chat.list({ where, order }, graph);

        this.setState({ chats });
    }

    getRole(user) {
        return user.groups.indexOf("Admin") !== -1 ||
                user.groups.indexOf("FRI") !== -1 ||
                user.groups.indexOf("GPS") !== -1 ?
                "shopper" : "customer";
    }

    renderParticipant(p, role, otherRole) {
        const user = p.user;
        const wsPool = p.whatsappPoolNumber;
        let contacts = [];

        if (role === "customer") {
            contacts = user.contacts.map(c => c.type);
            if (contacts.indexOf("phone") === -1 && user.phone) {
                contacts.push("phone");
            }
            if (contacts.indexOf("email") === -1 && user.email) {
                contacts.push("email");
            }
        }
        else {
            contacts.push("email");
            if (user.phone) {
                contacts.push("phone");
            }
            if (user.whatsapp) {
                contacts.push("whatsapp");
            }
        }

        return (
            <td>
                { user.givenName } { user.familyName } ({ user.email })<br/>
                { contacts.sort().join(", ") }
                { wsPool ? 
                    <>
                        <br/>ws pool: { wsPool }&nbsp;
                        (<a href="/#" 
                                onClick={ e => this.clearWsPool(e, p) }>clear</a>)
                    </> : null }
                { !p.role && <>
                    <br/><b>role missing</b>&nbsp;
                    (<a href="/#" onClick={ e => this.setParticipantRole(e, p) }>set</a>)
                    </>}
                { (p.role && p.role === otherRole) && 
                    <>
                        <br/>
                        <b>{ p.role }</b>&nbsp;
                        (<a href="/#" onClick={ e => this.setParticipantRole(e, p) }>change role</a>)
                    </>}
            </td>
        )
    }

    renderChatRow(chat) {

        const p1 = chat.participants[0];
        const p2 = chat.participants[1];

        if (!p1 || !p1.user || !p2 || !p2.user) {
            return null;
        }

        const p1role = p1.role || this.getRole(p1.user);
        const p2role = p2.role || this.getRole(p2.user);

        return (
            <tr key={ chat.id }>
                { this.renderParticipant(p1role === "shopper" ? p1 : p2, "shopper", "customer") }
                { this.renderParticipant(p1role === "shopper" ? p2 : p1, "customer", "shopper") }
                <td>{ moment(chat.createdAt).format(df.full) }</td>
                <td>
                    { chat.id }
                    { (!p1role || p1role === p2role || p1.role !== p1role || p2.role !== p2role) && 
                        <>
                            <br/>
                            <a href="/#" onClick={ e => this.autodetectRoles(e, p1, p2) }>
                                autodetect roles
                            </a>
                        </> }
                </td>
            </tr>
        )
    }

    render() {

        const { loading, chats, search } = this.state;

        return (
            <div className="page page-chats page-w-loading">
                { loading && <Loader size={ 64 }/> }

                <NullForm className="toolbar">
                    <div className="toolbar-title">
                        Chats
                    </div>
                    <div className="spacer-full"/>
                    <TextField
                        autoComplete="off"
                        variant="outlined"
                        onKeyDown={ e => e.key === "Enter" && this.loadChats() }
                        value={ search }
                        label={ search ? "" : "Search" }
                        onChange={ e => this.setState({ search: e.target.value }) }/>
                    <Button 
                        variant="contained" 
                        children="Search"
                        onClick={ () => this.loadChats() }/>
                    <div className="menu right"></div>
                </NullForm>

                <div className="grid-wrapper">
                    <table className="grid">
                        <thead>
                        <tr>
                            <th>Shopper</th>
                            <th>Customer</th>
                            <th>Created at</th>
                            <th>ID</th>
                        </tr>
                        </thead>
                        <tbody>
                            { chats.map(c => this.renderChatRow(c))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default PageChats