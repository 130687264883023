import React from "react"
import Selector from "../material/Autocomplete"


const gendersList = [
    {
        id: "",
        name: "All"
    },
    {
        id: "female",
        name: "Women"
    },
    {
        id: "male",
        name: "Men"
    }
]


class GenderSelector extends React.Component {

    state = {
        genders: []
    }

    onChange(genders) {
        this.setState({ genders });
        this.props.onChange && this.props.onChange(genders);
    }

    render() {

        const { genders } = this.state;
        
        return (
            <Selector 
                options={ gendersList }
                multiple={ this.props.multiple }
                allowEmpty={ this.props.allowEmpty }
                selection={ genders }
                placeholder="Women/Men"
                size={ this.props.size }
                onChange={ genders => this.onChange(genders) }/>
        )
    }
}

export default GenderSelector