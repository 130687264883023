import React from "react"
import Selector from "../material/Autocomplete"

let countries;
let countriesLoading = false;

const loadCountries = async () => {
    countriesLoading = true;
    countries = await fetch("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/geo/countries.json")
                            .then(r => r.json())
                            .then(list => list.filter(c => c.code !== "GB" && c.code !== "US"))
                            .then(list => list.map(c => ({ id: c.code, name: c.name })));
    countriesLoading = false;
};

const regionsList = [
    {
        id: "all",
        name: "All"
    },
    {
        id: "gb",
        name: "United Kingdom"
    },
    {
        id: "us",
        name: "United States"
    },
    {
        id: "euro",
        name: "Europe"
    }/*,
    {
        id: "rest",
        name: "Rest of the world"
    }*/
];


class RegionSelector extends React.Component {

    state = {
        regions: []
    }

    componentDidMount() {
        if (this.props.allCountries) {
            if (!countries && !countriesLoading) {
                loadCountries().then(() => this.forceUpdate())
            }
        }
    }

    onChange(regions) {
        this.setState({ regions });
        this.props.onChange && this.props.onChange(regions);
    }

    filterOptions(options) {
        const filter = this.props.filter;
        if (!filter) {
            return options;
        }
        if (typeof filter === "function") {
            return filter(options);
        }
        if (typeof filter === "string") {
            return options.filter(opt => opt.id.toLowerCase() === filter.toLowerCase());
        }
        if (Array.isArray(filter)) {
            return options.filter(opt => filter.indexOf(opt.id) !== -1);
        }
        return options;
    }

    render() {

        const { regions } = this.state;
        const { allCountries = false } = this.props;
        let options = [].concat(regionsList);

        if (allCountries && countries) {
            options = options.concat(countries);
        }

        options = this.filterOptions(options);

        return (
            <Selector 
                options={ options }
                multiple={ this.props.multiple }
                allowEmpty={ this.props.allowEmpty }
                defaultValue={ this.props.defaultValue }
                selection={ regions }
                placeholder="All Regions"
                size={ this.props.size }
                onChange={ regions => this.onChange(regions) }/>
        )
    }
}

export default RegionSelector