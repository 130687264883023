import { useEffect, useMemo, useCallback } from "react"
import { useSelector } from "react-redux"

import Selector from "common/src/components/material/Autocomplete"

import formatCurrency from "common/src/lib/format/currency"
import { loadFacets } from "common/src/actions/catalogue"

function PriceSelector({ allowEmpty = true, size, onChange }) {

    const prices = useSelector(s => s.catalogue.data.prices);
    const currency = useSelector(s => s.user.geo.currency);

    const options = useMemo(
        () => [ { id: null, name: "All" }, 
                ...prices.map(p => ({ id: p, name: formatCurrency(p, currency) })) ],
        [ currency, prices ]
    );

    const optionLabel = useCallback(
        (o) => o.id ? "Up to " + o.name : o.name,
        []
    );

    const onPriceChange = useCallback(
        (price) => {
            if (price && price.id === null) {
                price = null;
            }
            onChange && onChange(price);
        },
        [ onChange ]
    );

    useEffect(
        () => {
            loadFacets();
        },
        []
    );

    return (
        <Selector
            options={ options }
            placeholder="Price"
            allowEmpty={ allowEmpty }
            size={ size }
            getOptionLabel={ optionLabel }
            onChange={ onPriceChange }/>
    )
}

export default PriceSelector