
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import hub from "common/src/hub"
//import routes from "app/routes"
import throttle from "lodash/throttle"
import isActive from "common/src/lib/navActive"
import async from "common/src/lib/js/async"
import getWidth from "common/src/lib/dom/getWidth"
import getHeight from "common/src/lib/dom/getHeight"
import routes from "app/routes"

let prevScrollPos = 0;
let keepScrollOnNext = false;
let restoreOnMount = false;
let pageEl = null;
let current = null;
let ww = null;

const HISTORY_SIZE = 100;
const localHistory = [];

export const getCurrentKey = () => {
    return current ? current.key : null;
};

export const hasKey = (key) => {
    return localHistory.findIndex(h => h.key === key) !== -1;
};

function setLastScrollPosition(st) {
    if (current) {
        current.scrollTop = st;
    }
};

function scrollListener() {
    if (!pageEl) {
        return;
    }
    let st = pageEl.scrollTop;
    if (st < 0) {
        st = 0;
    }
    setLastScrollPosition(st);
    if (Math.abs(st - prevScrollPos) < 10) {
        return;
    }
    hub.dispatch("app", "scroll", {
        scrollTop: st, 
        direction: st > prevScrollPos ? "down" : "up"
    });
    prevScrollPos = st;
};

function windowScrollListener() {
    const st = document.documentElement.scrollTop;
    setLastScrollPosition(st < 0 ? 0 : st);
};

function windowResizeListener() {
    let w = getWidth(window);
    let h = getHeight(window);
    document.documentElement.style.setProperty('--vh-fix', h + 'px');
    document.documentElement.style.setProperty('--ivh', window.innerHeight + 'px');
    ww = w;
};

const windowScrollListenerCb = throttle(windowScrollListener,  200, { trailing: true });
const windowResizeListenerCb = throttle(windowResizeListener,  200, { trailing: true });
const scrollListenerCb = throttle(scrollListener, 200, { trailing: true });


function onBodyClick(e) {
    hub.dispatch("app", "body-click", e);
};

function isScrollable(el) {
    const styles = window.getComputedStyle(el);
    const ov = styles.getPropertyValue("overflow-y");
    return ov && (ov === "auto" || ov === "scroll");
}

function restoreScrollPosition() {
    restoreOnMount = false;

    if (!current || !pageEl) {
        return;
    }

    prevScrollPos = current.scrollTop;
    if (pageEl && isScrollable(pageEl)) {
        pageEl.scrollTop = current.scrollTop;
    }
    else {
        window.scrollTo({ top: current.scrollTop });
    }
}


function getActiveRoute(path) {
    for (let key in routes) {
        if (isActive(routes[key], path)) {
            return key;
        }
    }
}

function goBackToKey(key, history) {
    let inx = localHistory.findIndex(h => h.key === key);
    if (inx !== -1) {
        history.go(inx * -1);
    }
}

function historyListener(loc, action) {
    const route = getActiveRoute(loc.pathname);
    const lastRoute = current ? current.route : null;
    addHistory(loc.key, action, route, loc);
    keepScrollOnNext = false;

    if (action !== "INIT") {
        hub.dispatch("app", "locationChange", loc);
        if (lastRoute && route && lastRoute !== route) {
            hub.dispatch("app", `route--${route}`, action);
        }
    }

    if (pageEl) {
        restoreScrollPosition();
    }
    else {
        restoreOnMount = true;
    }
}

function addHistory(key, action, route, loc) {

    // here we keep history backwards
    // so the last entry is always at index = 0
    let scrollTop = 0;

    if (action === "INIT" || action === "PUSH") {

        if (keepScrollOnNext) {
            scrollTop =current.scrollTop;
        }

        // if new record is being pushed
        // but current page is not at the edge
        // we remove all records between current and edge
        // (history is reversed here)
        if (action === "PUSH" && current) {
            const currInx = localHistory.findIndex(h => h.key === current.key);
            if (currInx > 0) {
                localHistory.splice(0, currInx);
            }
        }

        localHistory.unshift({ key, scrollTop, action, route });

        if (localHistory.length > HISTORY_SIZE) {
            localHistory.pop();
        }

        current = localHistory[0];

    }
    else if (action === "POP") {
        current = localHistory.find(h => h.key === key);
        if (current) {
            current.action = action;
        }
    }
}

function deinitScrollListener() {
    if (pageEl) {
        pageEl.removeEventListener("scroll", scrollListenerCb);
        pageEl = null;
    }
}

function initScrollListener() {
    const page = document.getElementsByClassName("page")[0];

    if (page === pageEl) {
        return;
    }

    deinitScrollListener();

    if (page) {
        page.addEventListener("scroll", scrollListenerCb);
        pageEl = page;

        if (restoreOnMount) {
            async(() => restoreScrollPosition());
        }
    }
}



function init(history) {
    ww = getWidth(window);

    hub.listen("app", "redir", url => history.push(url));
    hub.listen("app", "keepScrollPosition", () => keepScrollOnNext = true);
    hub.listen("app", "back", key => goBackToKey(key, history));
    hub.listen("app", "page-mounted", () => initScrollListener());
    hub.listen("app", "page-unmounted", () => deinitScrollListener());

    history.listen(historyListener);    

    window.addEventListener("scroll", windowScrollListenerCb);
    window.addEventListener("resize", windowResizeListenerCb);
    document.body.addEventListener("click", onBodyClick);

    historyListener(history.location, "INIT");
    async(() => windowResizeListenerCb());
}


function AppDomHelper() {
    const history = useHistory();
    useEffect(() => init(history), []);
    return (
        <></>
    )
}

export default AppDomHelper


/*
class AppDomHelper extends React.Component {

    constructor(props) {
        super(props);
        this.windowScrollListener = this.windowScrollListener.bind(this);
        this.scrollListener = this.scrollListener.bind(this);
        this.historyListener = this.historyListener.bind(this);
        this.onBodyClick = this.onBodyClick.bind(this);

        this.windowScrollListener = throttle(this.windowScrollListener, 200, { trailing: true });
        this.scrollListener = throttle(this.scrollListener, 200, { trailing: true });
        this.windowResizeListener = throttle(this.windowResizeListener, 200, { trailing: true });
    }

    componentDidMount() {

        ww = getWidth(window);

        hub.listen("app", "redir", url => this.props.history.push(url));
        hub.listen("app", "back", key => this.goBackToKey(key))
        hub.listen("app", "keepScrollPosition", () => keepScrollOnNext = true);
        hub.listen("app", "page-mounted", () => this.initScrollListener());
        hub.listen("app", "page-unmounted", () => this.deinitScrollListener());
        //hub.listen("app", "body-scroll-override", state => this.onBodyScrollOverride(state));

        this.props.history.listen(this.historyListener);
        this.historyListener(this.props.history.location, "INIT");
        
        document.body.addEventListener("click", this.onBodyClick);

        async(() => this.windowResizeListener());
    }

    setLastScrollPosition(st) {
        if (current) {
            current.scrollTop = st;
        }
    }

    windowScrollListener() {
        let st = document.documentElement.scrollTop;
        if (st < 0) {
            st = 0;
        }
        this.setLastScrollPosition(st);
    }

    onBodyClick(e) {
        hub.dispatch("app", "body-click", e);
    }

    onBodyScrollOverride(state) {

    }

    windowResizeListener() {
        let w = getWidth(window);
        let h = getHeight(window);
        document.documentElement.style.setProperty('--vh-fix', h + 'px');
        document.documentElement.style.setProperty('--ivh', window.innerHeight + 'px');

        ww = w;
    }

    scrollListener() {
        if (!pageEl) {
            return;
        }
        let st = pageEl.scrollTop;
        if (st < 0) {
            st = 0;
        }
        this.setLastScrollPosition(st);
        if (Math.abs(st - prevScrollPos) < 10) {
            return;
        }
        hub.dispatch("app", "scroll", {
            scrollTop: st, 
            direction: st > prevScrollPos ? "down" : "up"
        });
        prevScrollPos = st;
    }

    deinitScrollListener() {
        if (pageEl) {
            pageEl.removeEventListener("scroll", this.scrollListener);
            pageEl = null;
        }
    }

    initScrollListener() {
        const page = document.getElementsByClassName("page")[0];

        if (page === pageEl) {
            return;
        }

        this.deinitScrollListener();

        if (page) {
            page.addEventListener("scroll", this.scrollListener);
            pageEl = page;

            if (restoreOnMount) {
                async(() => this.restoreScrollPosition());
            }
        }
    }

    getActiveRoute(path) {
        for (let key in routes) {
            if (isActive(routes[key], path)) {
                return key;
            }
        }
    }

    goBackToKey(key) {
        let inx = history.findIndex(h => h.key === key);
        if (inx !== -1) {
            this.props.history.go(inx * -1);
        }
    }

    historyListener(loc, action) {
        const route = this.getActiveRoute(loc.pathname);
        const lastRoute = current ? current.route : null;
        this.addHistory(loc.key, action, route, loc);
        keepScrollOnNext = false;

        if (action !== "INIT") {
            hub.dispatch("app", "locationChange", loc);
            if (lastRoute && route && lastRoute !== route) {
                hub.dispatch("app", `route--${route}`, action);
            }
        }

        if (pageEl) {
            this.restoreScrollPosition();
        }
        else {
            restoreOnMount = true;
        }
    }

    addHistory(key, action, route, loc) {

        // here we keep history backwards
        // so the last entry is always at index = 0
        let scrollTop = 0;

        if (action === "INIT" || action === "PUSH") {

            if (keepScrollOnNext) {
                scrollTop =current.scrollTop;
            }

            // if new record is being pushed
            // but current page is not at the edge
            // we remove all records between current and edge
            // (history is reversed here)
            if (action === "PUSH" && current) {
                const currInx = history.findIndex(h => h.key === current.key);
                if (currInx > 0) {
                    history.splice(0, currInx);
                }
            }

            history.unshift({ key, scrollTop, action, route });

            if (history.length > HISTORY_SIZE) {
                history.pop();
            }

            current = history[0];

        }
        else if (action === "POP") {
            current = history.find(h => h.key === key);
            if (current) {
                current.action = action;
            }
        }
    }

    isScrollable(el) {
        const styles = window.getComputedStyle(el);
        const ov = styles.getPropertyValue("overflow-y");
        return ov && (ov === "auto" || ov === "scroll");
    }

    restoreScrollPosition() {
        restoreOnMount = false;

        if (!current || !pageEl) {
            return;
        }

        prevScrollPos = current.scrollTop;
        if (pageEl && this.isScrollable(pageEl)) {
            pageEl.scrollTop = current.scrollTop;
        }
        else {
            window.scrollTo({ top: current.scrollTop });
        }
    }

    render() {
        return <></>
    }
}

export default withRouter(AppDomHelper)*/