
import formatCurrency from "common/src/lib/format/currency"
import moment from "moment"
import df from "common/src/lib/date/formats"
import { getOrderItemStatusName } from "app/lib/orderStatus";



export const getProductRows = (order) => {

    const details = JSON.parse(order.details);

    return details.items.map(item => {

        return {
            order,
            sku: item.sku,
            name: "",
            brand: "",
            quantity: item.quantity,
            // grossCount: item.grossItemsCount,
            // count: item.itemsCount,
            // cancelledCount: item.cancelledItemsCount,
            // details: details,
            saleAmount: parseFloat(item.perItemSaleAmountPubCurrency) * parseInt(item.quantity),
            commissionAmount: parseFloat(item.totalCommissionPubCurrency) * parseInt(item.quantity),
            currency: 'GBP',
            status: order.actionStatus
        }
    })
}

export const getProducts = async (order) => {
    const details = JSON.parse(order.details);
    const cols = [
        { id: "sku", name: "SKU" },
        { id: "quantity", name: "Quantity" },
        { id: "perItemSaleAmountPubCurrency", name: "Sale", 
            render: i => formatCurrency(
                parseFloat(i.perItemSaleAmountPubCurrency) * parseInt(i.quantity), 
                "gbp", 
                2
            ) },
        { id: "totalCommissionPubCurrency", name: "TF Commission", 
            render: i => formatCurrency(
                parseFloat(i.totalCommissionPubCurrency) * parseInt(i.quantity), 
                "gbp", 
                2
            ) },
        { id: "status", name: "TF Order status", render: i => getOrderItemStatusName(order, i) }
    ];
    const rows = [ ...details.items ];

    return { cols, rows };
}

export const getCorrections = (order) => {

    const cols = [
        { id: "importedAt", name: "Import date",
            render: c => moment(c.importedAt).format(df.full) },
        { id: "correctionDate", name: "Correction date",
            render: c => moment(c.correctionDate).format(df.full)  },
        { id: "sale", name: "Sale", 
            render: c => {
                const details = JSON.parse(c.details);
                return formatCurrency(details.saleAmountPubCurrency, "gbp", 2)
            }},
        { id: "commission", name: "Commission", 
            render: c => {
                const details = JSON.parse(c.details);
                return formatCurrency(details.pubCommissionAmountPubCurrency, "gbp", 2)
            }}
    ]
    const rows = order.corrections;

    return { cols, rows };
}

export const getStatusClass = (order) => {
    switch (order.status) {
        case "closed": {
            return order.saleAmount > 0 ? "ok" : "notok";
        }
        default: {
            return "pending";
        }
    }
}

export const hasHistory = (order) => {
    return false;
}

export const hasCorrections = (order) => {
    return order.corrections && order.corrections.length > 1;
}