import React from "react"
import hub from "common/src/hub"

const provideComponent = (what) => hub.provide(what);
export const CrossPlatformContext = React.createContext();

export function CrossPlatformProvider({ children }) {

    const contextValue = { provideComponent };
    return React.createElement(CrossPlatformContext.Provider, { value: contextValue }, children);
}

