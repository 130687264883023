
import React from "react"
import { connect } from "react-redux"
import Loader from "common/src/components/Loader"
import Pagination from "common/src/components/Pagination"

import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import * as actions from "app/actions/page/searchHistory"

class SearchHistory extends React.Component {

    componentDidMount() {
        let page = parseInt(this.props.match.params.page) || 1;
        actions.loadHistoryList(page - 1);
    }

    componentDidUpdate(prev) {
        let page = parseInt(this.props.match.params.page) || 1,
            prevPage = parseInt(prev.match.params.page) || 1;
        
        if (page !== prevPage) {
            actions.loadHistoryList(page - 1);
        }
    }

    onPageChange(no) {
        this.props.history.push(getUrl(routes.searchHistory, {
            page: no + 1
        }));
    }


    renderHistory(list) {
        return (
            <table className="grid">
                <thead>
                <tr>
                    <th>Time</th>
                    <th>Query</th>
                    <th>User</th>
                    <th>Tags found</th>
                </tr>
                </thead>
                <tbody>
                { list.map(h => (
                    <tr key={ h.id }>
                        <td>{ h.searchedAt } </td>
                        <td>{ h.query }</td>
                        <td>{ h.user ? h.user.email : "" }</td>
                        <td>{ h.tagsFound }</td>
                    </tr>
                ))}
                </tbody>
            </table>
        )
    }

    render() {
        let list = this.props.data.history,
            { loading, page, count } = this.props.ui.history;

        return (
            <>
                <div className="page page-search-history page-w-loading">
                    { loading && <Loader size={ 64 }/> }

                    <div className="grid-wrapper">
                    { this.renderHistory(list) }
                    </div>

                    <Pagination
                        page={ page }
                        count={ count }
                        perPage={ actions.PER_PAGE }
                        onChange={ no => this.onPageChange(no) }/>
                </div>
            </>
        )
    }
}

export default connect(state => state.historyPage)(SearchHistory)

