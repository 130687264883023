
import cloneDeep from "lodash/cloneDeep"
import store from "app/store"
import hub from "common/src/hub"

const defaultGetter = (item) => item;

export default {
    create: function(page, listName, dispatcher, getItem = defaultGetter) {
        return function(obj) {
            let state = store.getState(),
                list = state[page].data[listName],
                item = list ? list.find(i => {
                    const item = getItem(i);
                    return item && item.id === obj.id
                }) : null;
            if (item) {
                store.dispatch(dispatcher[listName].update(obj));
            }
        }
    },

    lookProductUpdater: function(page, listName, dispatcher, getItem = defaultGetter) {
        return function(id, data) {
            const   state = store.getState();

            if (!state[page] || !state[page].data) {
                return;
            }

            const   looks = state[page].data[listName];

            if (!looks) {
                return;
            }
            
            const look = getItem(looks.find(i => {
                const look = getItem(i);
                return look && look.products && 
                        !!look.products.find(p => p.id === id)
            }));

            if (look) {
                const inx = look.products.findIndex(p => p.id === id);

                if (inx !== -1) {
                    const products = cloneDeep(look.products);
                    Object.assign(products[inx], data);
                    store.dispatch(dispatcher[listName].update({
                        id: look.id,
                        products
                    }));
                }
            }
        }
    },

    favUpdater: function(page, listName, dispatcher, getItem) {
        const updater = this.create(page, listName, dispatcher, getItem);
        const prodUpdater = this.lookProductUpdater(page, listName, dispatcher, getItem);

        hub.listen("look", "saved", id => updater({ id, saved: true }));
        hub.listen("look", "unsaved", id => updater({ id, saved: false }));

        hub.listen("product", "saved", id => prodUpdater(id, { saved: true }));
        hub.listen("product", "unsaved", id => prodUpdater(id, { saved: false }));
    }
}

