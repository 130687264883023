import React from "react"
import { connect } from "react-redux"
import SideDialog from "common/src/components/dialog/SideDialog"
import LinkButton from "common/src/components/material/LinkButton"
import Button from "@mui/material/Button"
import Loader from "common/src/components/Loader"
import CopyField from "common/src/components/form/CopyField"
import { ui as ddUi } from "common/src/store/dialogs"
import ProductCard from "common/src/components/catalogue/ProductCard"
//import productUrlById from "common/src/lib/url/productUrlById"
//import normalizeCatalogueProduct from "common/src/lib/catalogue/normalizeCatalogueProduct"
import extractUrlFromClick from "common/src/lambdalib/extractUrlFromClick"
import getShortLink from "common/src/lib/catalogue/getShortLink"
import async from "common/src/lib/js/async"
import user from "common/src/user"
import getClickDomain from "common/src/lib/url/clickDomain"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import settings from "app/settings"

function isEncoded(url) {
    return url !== decodeURIComponent(url);
}

function getClickUrl(p) {
    const cpid = p.catalogueId || p.product_web_id;
    const refid = user.id();
    const uid = refid;
    let url = p.details ?
                    p.details.url || p.details.product_url ||
                    extractUrlFromClick(p.details.click_url) :
                    p.url || this.props.url;
    const clickDomain = getClickDomain();
    let encoded = isEncoded(url) ? 1 : 0;

    if (url.indexOf('{') !== -1 || url.indexOf('[') !== -1) {
        url = encodeURIComponent(url);
        encoded++;
    }

    return `https://${ clickDomain }?` +
            `refid=${ refid }&cpid=${ cpid }`+
            `&uid=${ uid }&encoded=${ encoded }&url=${ url }`;
}

class ProductDialog extends React.Component {

    state = {
        gettingLink: false,
        link: null,
        shareLink: null
    }

    componentDidUpdate(prev) {
        const prodId = this.props.product ? 
                        (this.props.product.catalogueId || this.props.product.product_web_id) : 
                        null;
        const prevProdId = prev.product ? 
                            (prev.product.catalogueId || prev.product.product_web_id) : 
                            null;

        if (prodId !== prevProdId) {
            async(() => this.setState({ link: null, getShortLink: false }));
        }
    }

    generateShareLink() {
        const p = this.props.product;
        const linkFn = this.props.generateShareLink;
        const shareLink = linkFn ? linkFn(p) : getUrl(routes.catalogue, { id: p.uid }, "tools");
        this.setState({ shareLink });
    }

    async generateLink() {
        this.setState({ gettingLink: true });
        const p = this.props.product;
        
        const url = extractUrlFromClick(
                        p.details?.click_url ||
                        p.details?.url || 
                        p.details?.product_url ||
                        p.url || 
                        this.props.url
                    );
        
        const link = await getShortLink({
            catalogueProductId: p.catalogueId || p.product_web_id, 
            url, 
            name: p.name,
            brand: p.brand || null,
            sku: p?.details?.sku || null,
            retailer: p.retailer 
        });
        this.setState({ gettingLink: false, link });
    }

    onDialogClose() {
        this.setState({ shareLink: null });
        this.props.dispatch(ddUi.hide("product-details"));
    }

    onAddToMoodboard() {
        this.props.onAddToMoodboard &&
            this.props.onAddToMoodboard(this.props.product);
    }

    renderProduct() {
        const { gettingLink, link, shareLink } = this.state;
        const { product, showAddToMoodboard = false, normalized } = this.props;
        const moodboardAccess = user.hasAccessTo("tools/moodboards");
        const p = product;
        const hasImages = p.images && p.images.length > 0;
        const details = p.details || {};
        const sizes = details.sizes || [];
        const webId = product.product_web_id || product.catalogueId;
        const accessDenied = p.name.match(/access\s+denied/i);
        const isAdmin = user.is("Admin");
        //const norm = normalizeCatalogueProduct({ ...product });

        return (
            <div>
                { hasImages ? 
                    <ProductCard product={ p } normalized={ normalized }/> :
                    <div className="product-details-no-images">Image is not available</div> }
                { (p.retailer && webId) && 
                    <LinkButton
                        to={ getClickUrl(p) }
                        target="_blank"
                        variant="outlined"
                        rel="noopener noreferrer"
                        children="Open product page"/> }
                { ( showAddToMoodboard && moodboardAccess && hasImages ) && 
                    <Button 
                        variant="outlined"
                        onClick={ () => this.onAddToMoodboard() }
                        children="Add to moodboard"/> }

                <br/>
                
                { settings.app !== "admin" && 
                    <>
                    { (!shareLink) ? 
                        <Button 
                            onClick={ () => this.generateShareLink() }
                            variant="outlined"
                            children="Share product in Tools"/> :
                        <CopyField value={ shareLink } autoCopy/> }
                    <br/>
                    </> }

                { !link ? 
                    <Button
                        data-cy="catalogue-product-details-link-gen"
                        variant="outlined"
                        startIcon={ gettingLink ? <Loader inline/> : null }
                        children="Generate trackable link"
                        onClick={ () => this.generateLink() }/> :
                    <CopyField value={ link } autoCopy/> }

                { accessDenied ?
                    <p>
                        <br/>
                        <b>Unfortunately, we can't scrape this url</b><br/>
                        { p.url }<br/>
                        <b>But you can still generate a trackable link.</b>
                    </p> :
                    <p>
                        <br/><br/>
                        <b>{ p.name }</b><br/>
                        { p.description }<br/>
                        <b>{ p.retailer }</b><br/>
                        <b>{ p.brand }</b><br/>
                        { p.sale_price > 0 && <b>{ p.sale_price } / </b> }
                        { p.price } { p.currency } { p.availability }<br/>
                        { isAdmin && 
                            <>
                            { p.uid }<br/>
                            { p.catalogue_file }<br/>
                            </> }
                        { sizes
                            .filter(s => s.availability === "in-stock")
                            .map(s => s.size).join(', ') } 
                        <s>{ sizes
                            .filter(s => s.availability !== "in-stock")
                            .map(s => s.size).join(', ') }</s>
                        { details.size_system && `(${details.size_system})` }<br/>
                    </p> }
            </div>
        )
    }

    render() {

        const { product } = this.props,
                show = product && this.props.dd['product-details'];

        return (
            <SideDialog 
                attrs={{ "data-cy": "catalogue-product-details-dialogue" }}
                className="dialog-product" 
                title="Product details" 
                onClose={ () => this.onDialogClose() }
                show={ show }>
                { product && this.renderProduct() }
            </SideDialog>
        )
    }
}

export default connect(state => ({ dd: state.dialogs }))(ProductDialog)
