
async function validate(name, value, validator, extraData, values) {

    let error = "";
    let err;

    if (validator) {
        if (Array.isArray(validator)) {
            for (let i = 0, l = validator.length; i < l; i++) {
                err = await validator[i](value, name, extraData, values);
                if (err !== null) {
                    error = err;
                    break;
                }
            }
        }
        else {
            err = await validator(value, name, extraData, values);    
            if (err !== null) {
                error = err;
            }
        }
    }

    return error || null;
}

export default validate