import React from "react"
import { connect } from "react-redux"
import Selector from "../material/Autocomplete"

class ColorSelector extends React.Component {

    state = {
        colors: []
    }

    onChange(colors) {
        this.setState({ colors });
        this.props.onChange && this.props.onChange(colors);
    }

    render() {
        const { colors } = this.state;
        return (
            <Selector 
                options={ this.props.colors }
                multiple={ this.props.multiple }
                allowEmpty={ this.props.allowEmpty }
                selection={ colors }
                size={ this.props.size }
                placeholder="Color"
                onChange={ colors => this.onChange(colors) }/>
        )
    }
}

export default connect(state => ({ colors: state.catalogue.data.colors }))(ColorSelector)