
import validate from "./validate";

function createOnChange(name, setValue, setError, validator, autoValidate = false, values) {
    return async (value) => {
        if (typeof value === "object" && value.target) {
            if (value.target.type === "checkbox") {
                value = value.target.checked;
            }
            else value = value.target.value;
        }
        setValue(value);
        if (autoValidate) {
            if (validator) {
                const res = await validate(name, value, validator, null, values)
                setError(res);
            }
        }
    }
}

export default createOnChange