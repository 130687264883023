import { useState, useCallback } from "react"
import createOnChange from "./createOnChange"
import validate from "./validate"
import useDictRef from "common/src/hooks/useDictRef"

function useFormData(fields = [], defaults = {}) {

    const [ errorMode, setErrorMode ] = useState(false);
    const values = useDictRef({}, false);
    const form = useDictRef({
        errorMode,
        setErrorMode
    });

    for (let i = 0, l = fields.length; i < l; i++) {
        let f = fields[i];
        if (typeof f === "string") {
            f = { name: f };
        }
        const [ value, setValue ] = useState(() => {
            let defaultsValue = typeof defaults[f.name] === "undefined" ? "" : defaults[f.name];
            let defaultValue = typeof f.default === "function" ? f.default() : f.default;
            return typeof defaultValue === "undefined" ? defaultsValue : defaultValue;
        });
        const [ error, setError ] = useState(null);
        const onChange = createOnChange(f.name, setValue, setError, f.validator, errorMode, values);
        form[f.name] = value;
        form[f.name + "Set"] = setValue;
        form[f.name + "Change"] = onChange;
        form[f.name + "Error"] = error;
        form[f.name + "SetError"] = setError;
        values[f.name] = value;
    }

    const validateAll = useCallback(
        async () => {
            let valid = true;
            for (let i = 0, l = fields.length; i < l; i++) {
                let f = fields[i];
                if (typeof f === "string") {
                    f = { name: f };
                }
                if (f.validator) {
                    const res = await validate(f.name, values[f.name], f.validator, null, values);
                    form[f.name + "SetError"](res);
    
                    if (res !== null) {
                        valid = false;
                    }
                }
            }
            return valid;
        },
        []
    );

    const resetAll = useCallback(
        (resetData = {}) => {
            setErrorMode(false);
            fields.forEach(f => {
                if (typeof f === "string") {
                    f = { name: f };
                }
                form[f.name + "Set"](f.default || resetData[f.name] || "");
                form[f.name + "SetError"](null);
            });
        },
        []
    );

    form.validateAll = validateAll;
    form.resetAll = resetAll;

    return form;
}

export default useFormData