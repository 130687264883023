import React from "react"
import { Select, MenuItem, TextField, InputAdornment } from "@mui/material"
import { ReactComponent as IconExpand } from "common/src/svg/expand.svg"
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import getDialCodes from "common/src/lib/getDialCodes"
import async from "common/src/lib/js/async"
import NullForm from "common/src/components/NullForm"

const getDialCodeDisplayName = (c) => {
    return (
        <>
            <span className="dial-code-code">+{ c.dial }</span>
            <span className="dial-code-name">{ c.name }</span>
        </>
    )
};

const swallow = (e, prevent) => {
    prevent && e.preventDefault();
    e.stopPropagation();
    return false;
};

class DialCodeSelector extends React.Component {

    state = {
        dialCodes: [],
        search: ""
    }

    componentDidMount() {
        getDialCodes().then(dialCodes => this.setState({ dialCodes }));
    }

    onChange(dial) {
        this.props.onChange &&
            this.props.onChange(dial);
    }

    render() {

        const { dialCodes, search } = this.state;
        const { value } = this.props;
        const codes = search ?
            dialCodes.filter(c => c.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) :
            dialCodes;

        return (
            <Select 
                className="select-dial-codes"
                IconComponent={ IconExpand }
                MenuProps={{
                    className: "menu-dial-codes"
                }}
                value={ value }
                onChange={ e => this.onChange(e.target.value) }
                onOpen={ e => async(() => { this.searchEl.focus() })}>
                <MenuItem 
                    key="search" 
                    style={{ position: "sticky", top: 0, backgroundColor: "#fff", zIndex: 1 }}
                    onClick={ e => swallow(e, true ) }
                    onMouseDown={ e => swallow(e, true ) }>
                    <NullForm>
                    <TextField 
                        inputRef={ el => this.searchEl = el }
                        variant="outlined" 
                        fullWidth
                        autoComplete="off"
                        value={ search }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconSearch />
                                </InputAdornment>
                            )
                        }}
                        onChange={ e => {
                            this.setState({ search: e.target.value })
                            async(() => this.searchEl.focus());
                        }}
                        onClick={ e => swallow(e) }
                        onMouseDown={ e => swallow(e) }
                        onKeyDown={ e => swallow(e) }
                        onKeyPress={ e => swallow(e) }
                        onKeyUp={ e => swallow(e) }/>
                    </NullForm>
                </MenuItem>
                { codes.map(c => (
                    <MenuItem 
                        key={ c.key } 
                        value={ '+' + c.dial } 
                        dense
                        children={ getDialCodeDisplayName(c) }/>
                ))}
            </Select>
        )
    }
}

export default DialCodeSelector