import React from "react"
import { ToggleButtonGroup, ToggleButton } from "@mui/material"
import GenericExpandSelector from "common/src/components/filter/GenericExpandSelector"
import { ReactComponent as IconOn } from "common/src/svg/tick.svg"
import { ReactComponent as IconOff } from "common/src/svg/close.svg"


const exportValue = (value) => {
    return value === "none" ? null :
            value === "on" ? true : false;
};

const importValue = (value) => {
    return value === null ? "none" :
            value === true ? "on" : "off";
};

class UserAttributionSelector extends React.Component {

    onChange(key, value) {

        if (this.props.onChange) {
            const values = { ...this.props.value };
            values[key] = exportValue(value);

            this.props.onChange(values);
        }
    }

    renderGroup(group) {
        const value = this.props.value;
        const { key, name } = group;
        const toggleValue = importValue(value[key]);

        return (
            <React.Fragment key={ key }>
                <label>{ name }</label>
                <ToggleButtonGroup value={ toggleValue  } exclusive  className="user-group-toggle" 
                        onChange={ (_, value) => this.onChange(key, value) }>
                    <ToggleButton value="on" variant="outlined">
                        <IconOn/>
                    </ToggleButton>
                    <ToggleButton value="none" variant="outlined">
                        Any
                    </ToggleButton>
                    <ToggleButton value="off" variant="outlined">
                        <IconOff/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </React.Fragment>
        )
    }

    render() {
        const { name, groups } = this.props;
        const list = Object.keys(groups).map(key => ({ key, name: groups[key] }))

        return (
            <GenericExpandSelector name={ name || "Toggle users" } className="user-group-toggle-selector">
                { list.map(group => this.renderGroup(group)) }
            </GenericExpandSelector>
        )
    }
}

export default UserAttributionSelector