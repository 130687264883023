import React from "react"
import { connect } from "react-redux"
import moment from "moment"

import { Button, FormControlLabel } from "@mui/material"
import Checkbox from "common/src/components/material/Checkbox"
import SideDialog from "common/src/components/dialog/SideDialog"
import UserBlock from "common/src/components/user/User"
import AccessTree from "app/components/AccessTree"

import df from "common/src/lib/date/formats"
import { ui as ddUi } from "common/src/store/dialogs"
import s3url from "common/src/lib/image/s3url"
import { setGroup, setEnabled, deleteUser, setDefaultShopper } from "app/actions/page/people"
import Loader from "common/src/components/Loader"
import { confirm, alert, prompt } from "common/src/components/dialog/Alert"
import api from "app/api"
import hub from "common/src/hub"
import { default as currentUser } from "common/src/user"
import { defaultAccess, defaultRetailers } from "app/accessTree"
import retailers from "common/src/lib/catalogue/retailers"
import async from "common/src/lib/js/async"


const userIsOnly = (group, groups) => {
    if (typeof groups === "string") {
        groups = groups.split(",");
    }
    if (groups.indexOf(group) === -1) {
        return false;
    }
    if (group === "User") {
        return groups.length === 1;
    }
    return groups.length === 2;
};

const userIs = (group, groups) => {
    if (typeof groups === "string") {
        groups = groups.split(",");
    }
    return groups.indexOf(group) !== -1;
};


class UserDetails extends React.Component {

    state = {
        id: null,
        //changingGroup: [],
        changingAccess: null,
        changingRetailer: null,
        changingUserGroup: null,
        changingUserRole: null,
        defaultShopper: false,
        groups: [],
        retailers: [],
        savedUserGroups: [],
        access: {},
        enabled: false,
        enabling: false,
        deleting: false,
        settingPassword: false,
        settingDefaultShopper: false
    }
    _isMounted = false
    _preventUpdateTrigger = false

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prev) {
        if (this._isMounted) {
            let usr = this.props.user,
                prevUsr = prev.user,
                stateId = this.state.id;

            if ((!prevUsr && usr) || (usr && prevUsr && usr.id !== prevUsr.id) ||
                (usr && usr.id !== stateId)) {
                async(() => {
                    this.setState({
                        id: usr.id,
                        groups: (usr.groups || "").split(","),
                        enabled: usr.enabled,
                        access: { ...usr.details.access },
                        retailers: [ ...( usr.details.retailers || [] ) ],
                        savedUserGroups: usr.userGroups?.map(ug => ug.group) || [],
                        enabling: false,
                        deleting: false,
                        defaultShopper: usr.defaultShopper,
                        changingGroup: [],
                        changingRetailer: null,
                        changingAccess: null
                    });
                })
            }
        }
    }

    onDialogClose() {
        this.props.dispatch(ddUi.hide("user-details"));
        async(() => this.setState({ id: null }));
    }

    grpTagOnClick(g) {
        let grps = this.state.groups,
            ex = grps.indexOf(g.name) !== -1,
            addAccess,
            addRetailers;

        let inx = grps.indexOf(g.name);
        if (ex && inx !== -1) {
            grps.splice(inx, 1);
        }
        else if (!ex && inx === -1) {
            grps.push(g.name);
            addAccess = defaultAccess[ g.name ];
            addRetailers = defaultRetailers[ g.name ];
        }

        this.setState({ changingUserRole: g.name });

        let userDetails = { ...this.props.user.details };

        setGroup(this.props.user.id, grps.join(","))
        .then(async () => {
            if (this._isMounted) {
                this.setState({ groups: grps });
                this._preventUpdateTrigger = true;
                if (addAccess) {
                    for (let i = 0, l = addAccess.length; i < l; i++) {
                        userDetails = await this.setAccessKey(addAccess[i], true, userDetails);
                    }
                }
                if (addRetailers) {
                    for (let i = 0, l = addRetailers.length; i < l; i++) {
                        userDetails = await this.setRetailerAccess(addRetailers[i], true, userDetails);
                    }
                }
                this._preventUpdateTrigger = false;
                hub.dispatch("users", "updated", this.props.user.id);
            }
        })
        .finally(() => {
            if (this._isMounted) {
                this.setState({ changingUserRole: null });
            }
        });
    }

    async setRetailerAccess(retailer, value, userDetails = null) {

        this.setState({ changingRetailer: retailer });

        let retailers = this.state.retailers;
        let inx = retailers.indexOf(retailer);

        if (value === true && inx === -1) {
            retailers.push(retailer);
        }
        else if (value === false && inx !== -1) {
            retailers.splice(inx, 1);
        }

        const details = userDetails ? { ...userDetails } : { ...this.props.user.details };
        details.retailers = retailers;

        await api.user.update(this.props.user.id, { details: JSON.stringify(details) });

        if (!this._preventUpdateTrigger) {
            hub.dispatch("users", "updated", this.props.user.id);
        }

        if (this._isMounted) {
            this.setState({ retailers, changingRetailer: null });
        }

        return details;
    }

    async setUserGroup(gid, value) {

        const { savedUserGroups } = this.state;
        const userGroupsList = this.props.userGroups;
        const uid = this.props.user.id;
        const g = userGroupsList.find(g => g.id === gid);
        const inx = savedUserGroups.findIndex(g => g.id === gid)

        this.setState({ changingUserGroup: gid });

        if (value === true && inx === -1) {
            await api.userGroupUser.create({ userId: uid, userGroupId: gid });
            savedUserGroups.push(g);
        }
        else if (value === false && inx !== -1) {
            await api.userGroupUser.remove({ userId: { _eq: uid }, userGroupId: { _eq: gid }});
            savedUserGroups.splice(inx, 1);
        }

        this.setState({ savedUserGroups, changingUserGroup: null });
        hub.dispatch("users", "updated", uid);
    }

    async setAccessKey(key, value, userDetails = null) {

        this.setState({ changingAccess: key });

        let access = this.state.access;
        if (value === true) {
            access[ key ] = value;
        }
        else {
            delete access[key];
        }

        const details = userDetails ? { ...userDetails } : { ...this.props.user.details };
        details.access = access;

        await api.user.update(this.props.user.id, { details: JSON.stringify(details) });

        if (!this._preventUpdateTrigger) {
            hub.dispatch("users", "updated", this.props.user.id);
        }

        if (this._isMounted) {
            this.setState({ access, changingAccess: null });
        }
        
        return details;
    }

    async removeOldPermissions(keys) {

        this.setState({ changingAccess: "old-permissions" });
        let access = this.state.access;

        keys.forEach(key => delete access[key]);

        const details = { ...this.props.user.details };
        details.access = access;

        await api.user.update(this.props.user.id, { details: JSON.stringify(details) });

        if (!this._preventUpdateTrigger) {
            hub.dispatch("users", "updated", this.props.user.id);
        }

        if (this._isMounted) {
            this.setState({ access, changingAccess: null });
        }
        
        return details;
    }

    async setDefaultShopper(defaultShopper) {
        this.setState({ settingDefaultShopper: true });
        await setDefaultShopper(this.props.user.id, defaultShopper);
        this.setState({ defaultShopper, settingDefaultShopper: false });
    }


    toggleEnabled() {
        let enabled = this.state.enabled;
        this.setState({enabling: true});

        setEnabled(this.props.user.id, !enabled)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({enabled: !enabled});
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({enabling: false})
                }
            })
    }

    deleteUser() {
        confirm({
            title: "Are you sure?",
            message: "This cannot be undone."
        })
        .then(() => {
            if (this._isMounted) {
                this.setState({deleting: true});
                return deleteUser(this.props.user.id);
            }
        })
        .then(() => {
            alert({
                title: "Success",
                message: "User has been deleted"
            })
            if (this._isMounted) {
                this.onDialogClose();
            }
        })
        .finally(() => {
            if (this._isMounted) {
                this.setState({deleting: false})
            }
        }) 
    }

    async setPassword() {

        try {
            const pres = await prompt({ message: "Enter new password" });
            const password = (pres.prompt || "").trim();
            if (password) {
                this.setState({ settingPassword: true });
                const res = await api.backend.post("/user/set-password", {
                    body: {
                        userId: this.props.user.id,
                        password
                    }
                });

                if (res.success) {
                    alert({ message: "User's password updated" });
                }
                else {
                    alert({ 
                        title: "Failed to update user's password",
                        message: res.error?.message || ""
                    });
                }

                this.setState({ settingPassword: false });
            }
        }
        catch(err) {}
    }

    render() {
        let user = this.props.user,
            //userGroupsList = this.props.userGroups,
            show = user && this.props.dd['user-details'],
            avatarUrl = user ? s3url(user.avatar) : null,
            { enabling, deleting, enabled, groups, settingPassword,
                changingRetailer, /*savedUserGroups, changingUserGroup,*/ changingUserRole,
                defaultShopper, settingDefaultShopper } = this.state,
            userRetailers = this.state.retailers,
            allRetailers = userRetailers.indexOf("all") !== -1,
            groupTags = [
                { name: "User" },
                { name: "FRI", skip: true },
                { name: "GPS", label: "PSE" },
                { name: "Admin" },
                { name: "Contributor", skip: true }
            ];

        return (
            <SideDialog 
                className="dialog-user" 
                title="Person details" 
                onClose={ () => this.onDialogClose() }
                show={ show }>

                { user && 
                <div className="user-card">
                    { avatarUrl && 
                        <div className="image" 
                            style={{ backgroundImage: "url("+ avatarUrl +")" }}/> }

                    <UserBlock 
                        variant="reverse"
                        user={ user } 
                        after={ <><br/><span className="email">{ user.email }</span></> }/>

                    <h4>Roles</h4>
                    <div className="user-details-roles">
                        { groupTags.map(g => {
                            if (g.skip && groups.indexOf(g.name) === -1) {
                                return null;
                            }
                            return (
                                <FormControlLabel
                                    key={ g.name }
                                    className="user-details-retailer"
                                    control={ 
                                        changingUserRole === g.name ?
                                        <Loader inline/> :
                                        <Checkbox 
                                            color="primary" 
                                            disabled={ !!changingUserRole }
                                            checked={ groups.indexOf(g.name) !== -1 } 
                                            onChange={ e => this.grpTagOnClick(g) }/> }
                                    label={ g.label || g.name }/> 
                            )
                        })}
                    </div>

                    { (currentUser.is([ "Admin" ]) && 
                        !userIsOnly("Contributor", groups) && 
                        !userIsOnly("User", groups)) &&
                        <FormControlLabel
                            className="default-shopper"
                            control={ 
                                settingDefaultShopper ?
                                <Loader inline/> :
                                <Checkbox 
                                    color="primary" 
                                    checked={ defaultShopper } 
                                    onChange={ e => this.setDefaultShopper(e.target.checked) }/> }
                            label="Default Shopper"/>}

                    <h4>Partial access</h4>
                    <AccessTree 
                        filter={ userIsOnly("Contributor", groups) ? 
                                    ["web", "tools/catalogue"] : 
                                    null }
                        access={ this.state.access }
                        disabled={ !!this.state.changingAccess }
                        loading={ this.state.changingAccess }
                        onChange={ (key, value) => this.setAccessKey(key, value) }
                        onRemoveOldPermissions={ keys => this.removeOldPermissions(keys) }/>

                    { userIs("Contributor", groups) && 
                        <>
                            <h4>Retailers</h4>
                            <div className="user-details-retailers">
                            <FormControlLabel
                                key="all"
                                className="user-details-retailer-all"
                                control={ 
                                    changingRetailer === "all" ?
                                    <Loader inline/> :
                                    <Checkbox 
                                        color="primary" 
                                        checked={ userRetailers.indexOf("all") !== -1 } 
                                        onChange={ e => this.setRetailerAccess("all", e.target.checked) }/> }
                                label="All"/>
                            { !allRetailers && 
                                retailers.map(r => 
                                    <FormControlLabel
                                        key={ r.id }
                                        className="user-details-retailer"
                                        control={ 
                                            changingRetailer === r.id ?
                                            <Loader inline/> :
                                            <Checkbox 
                                                color="primary" 
                                                disabled={ allRetailers }
                                                checked={ allRetailers || 
                                                            userRetailers.indexOf(r.id) !== -1 } 
                                                onChange={ e => 
                                                    this.setRetailerAccess(r.id, e.target.checked) }/> }
                                        label={ r.name }/> )}
                            </div>
                        </>}

                    {/* userGroupsList.length > 0 && 
                        <>
                            <h4>User groups</h4>
                            <div className="user-details-groups">
                                { userGroupsList.map(g => 
                                    <FormControlLabel
                                        key={ g.id }
                                        className="user-details-retailer"
                                        control={ 
                                            changingUserGroup === g.id ?
                                            <Loader inline/> :
                                            <Checkbox 
                                                color="primary" 
                                                disabled={ !!changingUserGroup }
                                                checked={ !!savedUserGroups.find(sg => sg.id === g.id) } 
                                                onChange={ e => 
                                                    this.setUserGroup(g.id, e.target.checked) }/> }
                                        label={ g.name }/> )}
                            </div>
                                                </> */}

                    
                    <p className="phone">{ user.phone }</p>
                    <p className="created">
                        Signed up: { moment(user.createdAt).format(df.date) }<br/>
                        ID: { user.id }
                    </p>

                    <div className="actions">
                        <Button
                            children={ enabled ? "Set disabled" : "Set enabled" }
                            variant={ enabled ? "contained" : "outlined" }
                            startIcon={ enabling ? <Loader inline/> : null }
                            disabled={ enabling }
                            onClick={ () => this.toggleEnabled() }/>
                        <Button
                            children="Delete user"
                            variant="outlined"
                            startIcon={ deleting ? <Loader inline/> : null }
                            disabled={ enabled || deleting }
                            onClick={ () => this.deleteUser() }/>
                    </div>

                    { enabled && 
                        <div className="actions">
                            <Button
                                children="Set password"
                                variant="contained"
                                startIcon={ settingPassword ? <Loader inline/> : null }
                                disabled={ settingPassword }
                                onClick={ () => this.setPassword() }/>
                        </div> }

                </div>
                }
                
            </SideDialog>
        )
    }
}

export default connect(state => ({
    dd: state.dialogs,
    userGroups: state.peoplePage.data.userGroups
}))(UserDetails)


/* 
<LinkButton
                            children="View stats"
                            variant="outlined"
                            to={ getUrl(routes['people-stats'], { id: user.id }) }/>*/