
const accessTree = [
    {
        id: "admin",
        name: "Admin",
        children: [
            {
                id: "dashboard",
                name: "Dashboard"
            },
            {
                id: "people",
                name: "People"
            },
            {
                id: "search",
                name: "Search engine test"
            },
            {
                id: "tags",
                name: "Tags management"
            },
            {
                id: "analytics",
                name: "Analytics"
            },
            {
                id: "accounting",
                name: "Accounting"
            },
            {
                id: "catalogue",
                name: "Catalogue management"
            },
            {
                id: "tools",
                name: "Admin tools"
            },
            {
                id: "debug",
                name: "Admin debug screens"
            }
        ]
    },
    {
        id: "tools",
        name: "Tools",
        children: [
            {
                id: "dashboard",
                name: "Dashboard"
            },
            {
                id: "looks",
                name: "Looks"
            },
            {
                id: "consultations",
                name: "Consultations"
            },
            {
                id: "chat",
                name: "Chat"
            },
            {
                id: "catalogue",
                name: "Catalogue",
                children: [
                    {
                        id: "search",
                        name: "Search"
                    },
                    {
                        id: "category",
                        name: "Search by category"
                    },
                    {
                        id: "retailer",
                        name: "Search by retailer"
                    },
                    {
                        id: "designer",
                        name: "Search by designer"
                    },
                    {
                        id: "gender",
                        name: "Search by gender"
                    },
                    {
                        id: "region",
                        name: "Search by region",
                        children: [
                            {
                                id: "gb",
                                name: "Great Britain"
                            },
                            {
                                id: "us",
                                name: "United States"
                            },
                            {
                                id: "euro",
                                name: "Europe"
                            },
                            {
                                id: "rest",
                                name: "Rest of the world"
                            }
                        ]
                    }/*,
                    {
                        id: "currency",
                        name: "Search by currency",
                        children: [
                            {
                                id: "gbp",
                                name: "GBP"
                            },
                            {
                                id: "usd",
                                name: "USD"
                            },
                            {
                                id: "eur",
                                name: "EURO"
                            },
                            {
                                id: "rest",
                                name: "Rest"
                            }
                        ]
                    }*/
                ]
            },
            {
                id: "moodboards",
                name: "Moodboards"
            }
        ]
    }/*,
    {
        id: "web",
        name: "Website",
        children: [
            {
                id: "consultations",
                name: "Consultations"
            },
            {
                id: "catalogue",
                name: "Catalogue"
            },
            {
                id: "federated",
                name: "Social auth"
            }
        ]
    }*/
]

export const defaultAccess = {
    "Contributor": [
        "tools/catalogue"
    ]
};

export const defaultRetailers = {
    "Contributor": [ "all" ]
};

export default accessTree;