import { useState, useMemo } from "react"
import { TextField, InputAdornment } from "@mui/material"

import { ReactComponent as IconOn } from "common/src/svg/eye.svg"
import { ReactComponent as IconOff } from "common/src/svg/eye-off.svg"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";

function PasswordField(props) {
    
    const [ visible, setVisible ] = useState(false);
    const onClick = useSwallowEventCallback(
        () => setVisible(!visible),
        [ visible ]
    );

    const inputProps = useMemo(
        () => {
            return {
                endAdornment: (
                    <InputAdornment position="end" className="password-field-eye">
                        <a href="/#" onClick={ onClick }>
                        { visible ? <IconOn/> : <IconOff/> }
                        </a>
                    </InputAdornment>
                )
            }
        },
        [ visible ]
    )

    return (
        <TextField 
            autoComplete="off"
            { ...props }
            InputProps={ inputProps }
            type={ visible ? "text" : "password" }/>
    )
}

export default PasswordField