
import singlePromise from "common/src/lib/js/singlePromise"
import prepareSearchQuery from "common/src/lib/prepareSearchQuery"
import api from "app/api"
import store from "app/store"
import * as tags from "common/src/store/tags"
import hub from "common/src/hub"

let newTagIdPool = 0;


export let search = singlePromise({
    mode: singlePromise.KEY_CANCEL,
    throttle: 300,
    pre: (e, q) => {
        return {
            key: e + prepareSearchQuery(q)
        }
    },
    fn: (e, q) => {
        q = prepareSearchQuery(q);
        let where = {
            name: { _ilike: "%" + q + "%" }
        };

        return api[e].list({
            where, 
            order: { name: "asc" },
            limit: 50
        });
    }
});


export function loadRecent(entity) {

    let state = store.getState(),
        data = state[entity+"s"].data;

    // only load once
    if (data.recent.length > 0) {
        return Promise.resolve(data.new.concat(data.recent));
    }

    store.dispatch(tags[entity+"s"].ui.recent.loading(true));

    return api[entity].list({
        order: {lastUsed: "desc"},
        limit: 20
    })
    .then(items => {
        store.dispatch(tags[entity+"s"].data.recent.set(items));
        store.dispatch(tags[entity+"s"].ui.recent.loading(false));
    })
    .then(() => {
        let state = store.getState()
        return state[entity+"s"].data.new.concat(
            state[entity+"s"].data.recent
        )
    })
    .finally(() => {
        store.dispatch(tags[entity+"s"].ui.recent.loading(false));
    })
}

export function remove(entity, id) {
    return api[entity].remove(id)
            .catch(err => {
                hub.dispatch("error", entity + "-delete", err);
            });
}

export function createTemporaryTag(entity, name) {
    let items = store.getState()[entity+"s"].data.new,
        lc = name.toLowerCase(),
        inx = items.findIndex(t => t.name.toLowerCase() === lc),
        tag = {
            id: "tmp_tag_" + (++newTagIdPool),
            name: name,
            lastUsed: (new Date()).toISOString()
        }

    if (inx !== -1) {
        return items[inx];
    }

    store.dispatch(tags[entity+"s"].data.new.add(tag));
    return tag;
}


export async function createTags(type, data) {

    let names = [],
        tags = [],
        ps = [];

    data.forEach(t => {
        if (typeof t === "string") {
            names.push(t.trim());
        }
        else if (t.id.indexOf("tmp") === 0) {
            names.push(t.name.trim());
        }
        else {
            tags.push(t);
        }
    })

    let like = names.map(name => ({ "name": { _ilike: name }})),
        ex = names.length ? 
                await api[type].list({ where: { _or: like }}) : 
                [];

    tags = tags.concat(ex);

    if (names.length > ex.length) {
        names.forEach(n => {
            let ln = n.toLowerCase();
            if (!tags.find(t => t.name.toLowerCase() === ln)) {
                ps.push(
                    api[type].create({ name: n }, 'returning { id name }')
                             .then(t => tags.push(t))
                );
            }
        });
    }

    return Promise.all(ps).then(() => tags);
}



export async function addProductTag(entity, productId, tags) {

    const uc = entity[0].toUpperCase() + entity.substring(1);
    tags = await createTags(entity, tags);
    productId = Array.isArray(productId) ? productId : [ productId ];
    let i, l, j, jl;

    for (i = 0, l = tags.length; i < l; i++) {
        for (j = 0, jl = productId.length; j < jl; j++) {
            try {
                await api["product" + uc].create({
                    [`${entity}Id`]: tags[i].id,
                    productId: productId[j]
                }, "affected_rows");
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    hub.dispatch("product", "added-tag", { productId });
}


export async function addLookTag(entity, lookId, tags) {

    tags = await createTags(entity, tags);
    lookId = Array.isArray(lookId) ? lookId : [ lookId ];
    const uc = entity[0].toUpperCase() + entity.substring(1);
    let i, l, j, jl;

    for (i = 0, l = tags.length; i < l; i++) {
        for (j = 0, jl = lookId.length; j < jl; j++) {
            try {
                await api["look" + uc].create({
                    [`${entity}Id`]: tags[i].id,
                    lookId: lookId[j]
                }, "affected_rows");
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    hub.dispatch("look", "added-tag", { lookId });
}


export function removeLookTag(entity, lookId, tagId) {
    const uc = entity[0].toUpperCase() + entity.substring(1);
    const where = { lookId: Array.isArray(lookId) ? { _in: lookId } : { _eq: lookId } };
    where[`${entity}Id`] = tagId;
    return api["look" + uc].remove(where)
            .then(resp => {
                hub.dispatch("look", "removed-tag", { lookId, tagId });
                return resp;
            })
}



export function removeProductTag(entity, productId, tagId) {
    const uc = entity[0].toUpperCase() + entity.substring(1);
    const where = { productId: Array.isArray(productId) ? { _in: productId } : { _eq: productId } };
    where[`${entity}Id`] = tagId;
    return api["product" + uc].remove(where)
            .then(resp => {
                hub.dispatch("product", "removed-tag", { productId, tagId });
                return resp;
            });
}


