import { useCallback, useState, useMemo } from "react"
import Selector from "../material/Autocomplete"

const sortByRelevancy = { id: "relevancy", name: "Relevancy" };
const sortByRandom = { id: "random", name: "Random" };

const sortings = [
    {
        id: "price-asc",
        name: "Price (low - high)"
    },
    {
        id: "price-desc",
        name: "Price (high - low)"
    }
];


function SortingSelector({ size, allowEmpty, defaultValue, onChange, withSearch = false }) {

    const [ selection, setSelection ] = useState([]);

    const options = useMemo(
        () => {
            return [
                withSearch ? sortByRelevancy : sortByRandom,
                ...sortings
            ]
        },
        [ withSearch ]
    );

    const onSortingChange = useCallback(
        (selection) => {
            setSelection(selection);
            if (selection) {
                const sorting = {
                    sort_by: selection.id,
                    sort_dir: "asc"
                };
                if (selection.id === "price-asc") {
                    sorting.sort_by = "price";
                    sorting.sort_dir = "asc";
                }
                if (selection.id === "price-desc") {
                    sorting.sort_by = "price";
                    sorting.sort_dir = "desc";
                }
                onChange && onChange(sorting);
            }
            else {
                onChange && onChange(null);
            }
        },
        [ onChange ]
    );

    return (
        <Selector 
            options={ options }
            multiple={ false }
            allowEmpty={ false }
            defaultValue={ defaultValue }
            size={ size }
            selection={ selection }
            placeholder="Default sorting"
            onChange={ onSortingChange }/>
    )
}



export default SortingSelector