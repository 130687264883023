import React from "react"
import Loader from "common/src/components/Loader"
import TextField from "@mui/material/TextField"
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from "common/src/components/material/Checkbox"
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import { ReactComponent as IconDelete } from "common/src/svg/tag-delete.svg"
import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"
//import { ReactComponent as IconClear } from "common/src/svg/close.svg"
import Chip from "@mui/material/Chip"
import Autocomplete from "@mui/material/Autocomplete"
import NullForm from "common/src/components/NullForm"


const defaultOptionLabel = (opt) => { 
    return opt ? typeof opt === "string" ? opt : opt.name || "" : "" 
};

class Selector extends React.Component {

    state = {
        numSelected: 0
    }

    onInputClick(e) {
        this.props.onInputClick && this.props.onInputClick(e);
    }

    onSearchChange(query) {
        this.props.onSearchChange && this.props.onSearchChange(query)
    }

    onEnterKey(e) {
        const name = e.target.value.toLowerCase().trim();
        const tag = this.props.options.find(t => t.name.toLowerCase() === name);
        if (tag) {
            const selection = this.props.selection || [];
            if (!selection.find(t => t.name.toLowerCase() === name)) {
                const onChange = this.props.onChange;
                onChange && onChange([].concat(selection, [ tag ]));
            }
        }
    }

    onCheckboxToggle() {

    }

    onChange(selection) {

        const { multiple, onCreate } = this.props;

        if (multiple) {
            selection = selection.map(item => {
                if (typeof item === "string") {
                    if (onCreate) {
                        item = onCreate(item);
                    }
                    else {
                        item = null;
                    }
                }
                return item;
            });
            selection = selection.filter(item => item !== null);
            this.setState({ numSelected: selection.length });
        }
        else {
            if (typeof selection === "string") {
                if (onCreate) {
                    selection = onCreate(selection);
                }
                else {
                    selection = null;
                }
            }
            this.setState({ numSelected: selection ? 1 : 0 });
        }

        this.props.onChange && this.props.onChange(selection);
    }

    onOpen() {
        this.props.onOpen && this.props.onOpen();
    }

    render() {

        const { getOptionLabel, checkboxMode = false,
                multiple = false, loading = false, freeSolo = false, disabled = false,
                options, selection, placeholder, defaultValue,
                size = "small", query, onClose,
                label = "", className = "", extra = {}, loadingMoreRef } = this.props;
        const { numSelected } = this.state;

        return (
            <Autocomplete
                className={ `tag-selector ${ className }` }
                autoComplete={ true }
                multiple={ multiple }
                freeSolo={ freeSolo }
                value={ selection }
                options={ options }
                defaultValue={ defaultValue }
                loading={ loading }
                disabled={ disabled }
                disableCloseOnSelect={ checkboxMode }
                getOptionLabel={ getOptionLabel || defaultOptionLabel }
                onChange={ (e, selected) => this.onChange(selected) }
                onOpen={ () => this.onOpen() }
                onClose={ onClose }
                popupIcon={ <IconDown/> }
                forcePopupIcon
                renderOption={ 
                    !checkboxMode ? 
                    (props, option) => {
                        const name = getOptionLabel ? getOptionLabel(option) : defaultOptionLabel(option);
                        return (
                            <li { ...props } key={ option?.id || option?.value || props.key }>
                                { name }
                            </li>
                        )
                    } :
                    (props, option, state) => {
                        if (option && option.isLoadingMore) {
                            return (<li ref={ loadingMoreRef } key="loading-more">Loading more...</li>)
                        }
                        return (
                            <li { ...props } key={ option?.id || option?.value || props.key }>
                                <Checkbox
                                    style={{ marginRight: 8 }}
                                    checked={ state.selected }/>
                                { option.name }
                            </li>
                        )
                    }
                }
                renderTags={ 
                    checkboxMode ? 
                        () => null :
                        (value, getTagProps) =>
                            value.map((tag, index) => (
                                <Chip variant="outlined" 
                                    size={ size }
                                    label={ tag.name } 
                                    deleteIcon={ <IconDelete className="icon icon-svg-fill"/> }
                                    {...getTagProps({ index })} />
                            ))
                }
                renderInput={ params => {
                    if (!params.InputProps.startAdornment) {
                        params.InputProps.startAdornment = (
                            <InputAdornment position="start">
                                { loading ? 
                                    <Loader/> : 
                                    (checkboxMode && numSelected > 0) ?
                                        <span className="tag-selector-num-selected">{ numSelected }</span> :
                                        <IconSearch /> }
                            </InputAdornment>
                        )
                    }
                    return (
                        <NullForm>
                        <TextField 
                            autoComplete="off"
                            {...params} 
                            label={ label }
                            variant="outlined" 
                            onClick={ (e) => this.onInputClick(e) }
                            placeholder={ placeholder }
                            value={ query }
                            onKeyDown={ e => (e.key === "Enter" && !freeSolo) && this.onEnterKey(e) }
                            onChange={ e => this.onSearchChange(e.target.value) }
                            size={ size }
                            fullWidth />
                        </NullForm>
                    )
                }}
                { ...extra }/>
        );
    }
}

export default Selector
