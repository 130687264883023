import React from "react"
import { getRetailers } from "common/src/lib/catalogue/retailers"
import Selector from "../material/Autocomplete"
import user from "common/src/user"

const filterRetailers = (list, checkUserAccess) => {

    const details = user.getDetails();
    const userRetailers = [ ...(details.retailers || []) ];
    const allRetailers = userRetailers.indexOf("all") !== -1;

    return list.filter(r => {
        if (r.visible === false) {
            return false;
        }
        if (checkUserAccess) {
            return allRetailers || userRetailers.indexOf(r.id) !== -1;
        }
        return true;
    });
}

class RetailerSelector extends React.Component {

    state = {
        retailers: [],
        allRetailers: []
    }

    componentDidMount() {
        getRetailers().then(list => this.setState({ allRetailers: list }));
    }

    onChange(retailers) {
        this.setState({ retailers });
        this.props.onChange && this.props.onChange(retailers);
    }

    render() {

        const { retailers, allRetailers } = this.state;
        const options = filterRetailers(allRetailers, this.props.checkUserAccess);
        const { hideLessThanTwo } = this.props;

        if (hideLessThanTwo && options.length < 2) {
            return <></>
        }
        
        return (
            <Selector 
                options={ options }
                multiple={ this.props.multiple }
                allowEmpty={ this.props.allowEmpty }
                checkboxMode={ this.props.checkboxMode }
                selection={ retailers }
                placeholder="Retailer"
                size={ this.props.size }
                onChange={ retailers => this.onChange(retailers) }/>
        )
    }
}

export default RetailerSelector