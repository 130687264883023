
import React from "react"
import { Helmet } from "react-helmet"
import Authenticator from "common/src/components/authenticator/Authenticator"
import Loader from "common/src/components/Loader"
import hub from "common/src/hub"

import * as appActions from "common/src/actions/app"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"


class PageSignUp extends React.Component {

    state = {
        loading: false,
        invitation: null
    }

    componentDidMount() {
        appActions.setTheme("dark");
        hub.dispatch("app", "page-mounted");   
        document.documentElement.classList.add("page-auth");
    }

    componentWillUnmount() {
        appActions.setTheme("light");
        hub.dispatch("app", "page-unmounted");
        document.documentElement.classList.remove("page-auth");
    }

    render() {

        const { loading } = this.state;
        return (
            <div className="page page-signup">
                <Helmet>
                    <title>THEFLOORR - Registration</title>
                    <link rel="canonical" href={ getUrl(routes.register, {}, "admin") } />
                </Helmet>
                { loading && <Loader/> }
                { !loading && 
                    <Authenticator authState="signUp"/> }
            </div>
        )
    }
}

export default PageSignUp