import React, { useCallback, useState } from "react"
import { Select, MenuItem } from "@mui/material"
import { setRakutenItemOrderStatus } from "app/actions/page/commissions"

function CommissionProductDetails({ row }) {

    const [ manualStatus, setManualStatus ] = useState(row.manualStatus);

    const onChangeManualStatus = useCallback(
        ({ target: { value }}) => {
            if (value === "unset") {
                value = null;
            }
            setManualStatus(value);
            setRakutenItemOrderStatus(row.id, value);
        },
        [ row ]
    );


    return (
        <div className="page-commission-details">
            <div className="page-commission-details-toolbar">
            <p>Manual status:</p>&nbsp;
            <Select 
                size="small"
                value={ manualStatus || "unset" }
                onChange={ onChangeManualStatus }>
                <MenuItem value="unset">Unset</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
            </div> 
        </div>
    )

}

export default CommissionProductDetails

