import StoreModule from "common/src/lib/store/StoreModule"

let tags = new StoreModule("tags.page");
tags.addList("styles");
tags.addList("designers");
tags.addList("locations");
tags.addList("seasons");
tags.addList("occasions");
tags.addList("productStyles");
tags.addList("hiddenTags");

tags.data.add("updateProp", (s, a) => {
    let { type, id, prop, value } = a.payload,
        inx = s.data[type + "s"].findIndex(t => t.id === id);
    if (inx !== -1) {
        s.data[type + "s"][inx][prop] = value;
    }
});

export let { reducer, data, ui, initialState } = tags.export();
