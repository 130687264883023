import React from "react"
import { connect } from "react-redux"
import Selector from "../material/Autocomplete"


const padLevel = (level) => {
    return '.. '.repeat(level);
};

const getParentList = (cats, treePosition) => {
    
    const parents = [];
    treePosition = treePosition.split("/");
    treePosition.pop();

    while (treePosition.length > 0) {
        let parent = cats.find(c => c.treePosition === treePosition.join("/"));
        if (parent) {
            parents.push(parent);
        }
        treePosition.pop();
    }

    return parents;
}

const getPaddedName = (cat, cats, query) => {
    if (!cat || !cat.name) {
        return "";
    }
    if (cat.level === 0) {
        return cat.name;
    }
    if (!query) {
        return padLevel(cat.level) + cat.name;
    }
    if (!cats) {
        return padLevel(cat.level) + cat.name;
    }

    const parents = getParentList(cats, cat.treePosition);

    if (parents.length > 0) {
        return parents.reverse().map(p => p.name).join(' / ') + ' / ' + cat.name;
    }
    else {
        return '/ ' + cat.name;
    }
};

class CategorySelector extends React.Component {

    state = {
        categories: [],
        query: ""
    }

    onChange(categories) {
        this.setState({ categories });
        this.props.onChange && this.props.onChange(categories);
    }

    render() {
        const { categories, query } = this.state;
        return (
            <Selector 
                options={ this.props.categories }
                multiple={ this.props.multiple }
                allowEmpty={ this.props.allowEmpty }
                selection={ categories }
                placeholder="Category"
                size={ this.props.size }
                onChange={ categories => this.onChange(categories) }
                onSearchChange={ query => this.setState({ query }) }
                getOptionLabel={ opt => opt ? getPaddedName(opt, this.props.categories, query) : "" }/>
        )
    }
}

export default connect(state => ({ categories: state.catalogue.data.categories }))(CategorySelector)