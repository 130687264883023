import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import hub from "common/src/hub"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"

function SideDialog(props) {

    const { show, className = "", id } = props;
    const el = useRef(null);
    const prevScroll = useRef(null);
    const propsRef = useRef(null);
    const [ scrolled, setScrolled ] = useState(false);

    propsRef.current = props;

    const cls = useMemo(
        () => {
            const cls = ["side-dialog", className ];
            show && cls.push("active");
            scrolled && cls.push("scrolled");
            return cls.join(" ");
        },
        [ className, show, scrolled ]
    );

    const onCloseClick = useSwallowEventCallback(
        () => props.onClose && props.onClose(),
        [ props.onClose ]
    );

    const onBodyScroll = useCallback(
        () => {
            let scroll = el.current.scrollTop,
                prev = prevScroll.current;
            if (prev !== scroll && (prev === 0 || scroll === 0)) {
                prevScroll.current = scroll;
                setScrolled(scroll > 0);
            }
        },
        [ setScrolled ]
    );

    const closeIfOpen = useCallback(
        () => {
            if (propsRef.current.show) {
                propsRef.current.onClose && propsRef.current.onClose();
            }
        },
        []
    );

    useUpdateEffect(
        () => { 
            hub.dispatch("dialog", show ? "sideShow" : "sideHide");
            (show && propsRef.current.onShow) && propsRef.current.onShow();
            if (propsRef.current.hidePageContent) {
                document.documentElement.classList[show ? "add" : "remove"]("w-side-dialog");
            }
        }, 
        [ show ]
    );

    useEffect(
        () => {
            hub.listen("app", "locationChange", closeIfOpen);
            hub.listen("dialog", "overlayClick", closeIfOpen);
            if (show) {
                hub.dispatch("dialog", "sideShow");
            }
            return () => {
                hub.remove("app", "locationChange", closeIfOpen);
                hub.remove("dialog", "overlayClick", closeIfOpen);
                if (propsRef.current.show) {
                    hub.dispatch("dialog", "sideHide");
                }
                closeIfOpen();
            }
        },
        []
    );




    return (
        <div className={ cls } id={ id } { ...props.attrs }>
            <div className="side-dialog-header">
                <h3>{ props.title }</h3>
                <a href="/#" 
                    className="side-dialog-close"
                    onClick={ onCloseClick }>
                    <IconClose/>
                </a>
            </div>
            <div className="side-dialog-body" 
                ref={ el }
                onScroll={ onBodyScroll }>
                { props.children }
            </div>
        </div>
    )
}

export default SideDialog
