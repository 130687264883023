import Observable from "@kuindji/observable"
import fuc from "common/src/lib/fuc"
import { data2csv } from "common/src/lib/csv"

class DataStore extends Observable {

    columns = []
    rows = []
    customData = {}

    constructor(cols, rows) {
        super();
        cols && this.setColumns(cols);
        rows && this.setRows(rows);
    }

    setColumns(cols) {
        this.columns = [ ...cols ];
    }

    setRows(rows) {
        this.rows = rows;
    }

    getRows() {
        return this.rows;
    }

    setCustomData(data) {
        this.customData = data;
    }

    getCustomData() {
        return { ...this.customData };
    }

    addColumn(col) {
        this.columns.push(col);
    }

    hasColumn(id) {
        return !!this.getColumn(id);
    }

    hasMode(mode) {
        return !!this.columns.find(c => c.modes && c.modes.indexOf(mode) !== -1);
    }

    getColumn(id) {
        return this.columns.find(c => c.id === id);
    }

    getColumns(mode, only = false) {

        if (!mode) {
            return this.columns;
        }

        return this.columns.filter(c => {
            if (!c.modes) {
                return !only;
            }
            return c.modes.indexOf(mode) !== -1;
        })
        .map(c => {
            const col = { ...c };
            const renderFn = "render" + fuc(mode);
            const nameKey = "name" + fuc(mode);
            col.render = c[renderFn] || c["render"] || null;
            col.name = c[nameKey] || c.name || "";
            return col;
        });
    }

    getGrid() {
        const cols = this.getColumns("grid");
        const rows = this.rows;
        return { cols, rows };
    }

    getDetails(dataRow, inx) {
        if (typeof dataRow === "number") {
            dataRow = this.rows[dataRow];
        }
        const details = this.getColumns("details", true);

        if (details.length === 0) {
            return [];
        }

        return details.map(col => {
            const row = {
                name: col.name,
                value: col.render ? col.render(dataRow, inx, this) : dataRow[col.id] || null
            };
            if (row.value === null || row.value === undefined || row.value === "") {
                return null;
            }
            return row;
        })
        .filter(row => row !== null);

    }

    getCsv() {
        const cols = this.getColumns("csv");
        return data2csv(cols, this.rows, this);
    }
}


export default DataStore