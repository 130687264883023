import { useCallback, useEffect, useState } from "react"
import Selector from "common/src/components/material/Autocomplete"
import useDictRef from "common/src/hooks/useDictRef";
import api from "app/api"

function CatalogueDesignerSelector({ preselectName, onChange, selection }) {

    const [ query, setQuery ] = useState("");
    const [ options, setOptions ] = useState([]);
    const ref = useDictRef({ query, preselectName, onChange });

    const loadList = useCallback(
        async () => {
            const order = { name: "asc" };
            const where = {};

            if (ref.query || ref.preselectName) {
                where.name = { _ilike: `%${ ref.query || ref.preselectName }%` }
            }

            const options = await api.catalogueDesigner.list({ where, order });
            setOptions(options);

            if (!ref.query && ref.preselectName && options.length === 1) {
                ref.onChange(options);
            }
        },
        // eslint-disable-next-line
        []
    );

    const getOptionLabel = useCallback(
        (d) => d.name,
        []
    );

    const onSearchChange = useCallback(
        (query) => setQuery(query),
        []
    );

    useEffect(
        () => { loadList() },
        // eslint-disable-next-line
        [ query ]
    )

    return (
        <Selector
            checkboxMode={ false }
            multiple={ true }
            options={ options }
            selection={ selection }
            placeholder="Search"
            allowEmpty={ true }
            size="normal"
            getOptionLabel={ getOptionLabel }
            onChange={ onChange }
            onSearchChange={ onSearchChange }/>
    )
    
}

export default CatalogueDesignerSelector