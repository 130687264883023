import React from "react"
import copy from 'copy-to-clipboard'
import { TextField, InputAdornment } from "@mui/material"
import { ReactComponent as IconCopy } from "common/src/svg/copy.svg"
import async from "common/src/lib/js/async"
import NullForm from "common/src/components/NullForm"

class CopyField extends React.Component {

    state = {
        copied: false,
        animated: false
    }

    componentDidMount() {
        if (this.props.autoCopy) {
            //async(() => { this.onCopy() }, 300);
        }
    }

    onCopy(e) {
        e && e.stopPropagation();
        e && e.preventDefault();
        copy(this.props.value, { debug: true });

        this.setState({ copied: true }, () => {
            async(() => {
                this.setState({ animated: true });
                async(() => this.setState({ copied: false, animated: false }), 1500);
            }, 300);
        });
    }

    render() {
        const { copied, animated } = this.state;

        return (
            <NullForm>
            <TextField 
                className="copy-field"
                fullWidth
                autoComplete="off"
                value={ this.props.value }
                inputProps={{
                    "data-cy": "copy-to-clipboard-input"
                }}
                InputProps={{
                    startAdornment: copied ?
                        <InputAdornment position="start" className={ animated ? "animated" : "" }>
                            <p>Copied</p>
                        </InputAdornment> :
                        null,
                    endAdornment: 
                        <InputAdornment position="end">
                            <a href="/#" 
                                className="copy-to-clipboard-action"
                                title="Copy to clipboard"
                                onClick={ e => this.onCopy(e) }>
                                <IconCopy/>
                                Copy
                            </a>
                        </InputAdornment>
                }}
                { ...this.props.extraProps }/>
            </NullForm>
        )
    }
}

export default CopyField