import { useState, useEffect, useCallback, useMemo } from "react"
import { TextField, Button } from "@mui/material"
import moment from "moment"

import Table from "common/src/components/table/Table"
import Pagination from "common/src/components/Pagination"

import api from "app/api"
import df from "common/src/lib/date/formats"
import NullForm from "common/src/components/NullForm"

const PER_PAGE = 50;

const graph = `
    id
    createdAt
    users {
        role
        user {
            avatar
            givenName
            familyName
            email
        }
    }
`;

function PageConnections() {

    const [ query, setQuery ] = useState("");
    const [ page, setPage ] = useState(0);
    const [ count, setCount ] = useState(0);
    const [ connections, setConnections ] = useState([]);
    const loadConnections = useCallback(
        async (page = 0, query = "") => {
            const where = {};
            const limit = PER_PAGE;
            const offset = page * PER_PAGE;
            const order = { createdAt: "desc" }

            if (query) {
                where.users = {
                    user: {
                        _or: [
                            {  email: { _ilike: `%${query}%` }},
                            {  givenName: { _ilike: `%${query}%` }},
                            {  familyName: { _ilike: `%${query}%` }}
                        ]
                    }
                };
            }

            const connections = await api.connection.list({ where, limit, offset, order }, graph);
            setConnections(connections);

            if (page === 0) {
                const count = await api.connection.count(where).then(resp => resp.count);
                setCount(count);
            }
        },
        // eslint-disable-next-line
        []
    );

    const deleteConnection = useCallback(
        async (e, id) => {
            e.preventDefault();
            e.stopPropagation();
            await api.connection.remove(id);
            loadConnections(page, query);
        },
        [ page, query, loadConnections ]
    );

    const cols = useMemo(
        () => {

            return [
                {
                    id: "id",
                    name: "ID"
                },
                {
                    id: "role1",
                    name: "Role",
                    render: (row) => row.users[0]?.role
                },
                {
                    id: "name1",
                    name: "Name",
                    render: (row) => `${ row.users[0]?.user.givenName } ${ row.users[0]?.user.familyName } (${row.users[0]?.user.email})`
                },
                {
                    id: "role2",
                    name: "Role",
                    render: (row) => row.users[1]?.role
                },
                {
                    id: "name2",
                    name: "Name",
                    render: (row) => `${ row.users[1]?.user.givenName } ${ row.users[1]?.user.familyName } (${row.users[1]?.user.email})`
                },
                {
                    id: "createdAt",
                    name: "Created",
                    render: row => moment(row.createdAt).format(df.full)
                },
                {
                    id: "action",
                    name: "",
                    render: row => {
                        if (row.users.length < 2) {
                            return <a href="/#" onClick={ (e) => deleteConnection(e, row.id) }>delete</a>
                        }
                    }
                }
            ]
        },
        [ deleteConnection ]
    );

    const handleQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        []
    );

    const onEnter = useCallback(
        (e) => {
            if (e.key === "Enter") {
                setPage(0);
                loadConnections(0, query);
            }
        },
        // eslint-disable-next-line
        [ query ]
    );

    const onSearch = useCallback(
        () => {
            setPage(0);
            loadConnections(0, query);
        },
        // eslint-disable-next-line
        [ query ]
    );

    const onPageChange = useCallback(
        (page) => {
            setPage(page);
            loadConnections(page);
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            loadConnections(0);
        },
        // eslint-disable-next-line
        []
    )

    return (
        <div className="page page-connections">
            <NullForm className="toolbar">
                <div className="toolbar-title">
                    Connections
                </div>
                <div className="spacer-full"/>
                <TextField
                    autoComplete="off"
                    variant="outlined"
                    onKeyDown={ onEnter }
                    value={ query }
                    placeholder="Search"
                    onChange={ handleQueryChange }/>
                <Button 
                    variant="contained" 
                    children="Search"
                    onClick={ onSearch }/>
                <div className="menu right"></div>
            </NullForm>
            <Table cols={ cols } rows={ connections }/>
            <Pagination count={ count } page={ page } perPage={ PER_PAGE } onChange={ onPageChange }/>
        </div>
    )

}

export default PageConnections