
import React from "react"
import { connect, batch } from "react-redux"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"

import Loader from "common/src/components/Loader"
import LookCard from "common/src/components/look/Card"

import getUrl from "common/src/lib/url/get"
import store from "app/store"
import api from "app/api"
import { lookLoader } from "common/src/actions/looks"
import sortById from "common/src/lib/sortById"

import { data, ui } from "app/store/page/search"
import unaccent from "common/src/lib/unaccent"
import NullForm from "common/src/components/NullForm"

/*
const uniq = (arr) => {
    return arr.filter((item, i) => arr.indexOf(item) === i);
}


const prepareLook = (lookData) => {
    const look = {  };

    look.description = lookData.description;
    look.styles = lookData.styles ? uniq(lookData.styles.map(ss => ss.name)) : [];
    look.occasions = lookData.occasions ? uniq(lookData.occasions.map(os => os.name)) : [];
    look.seasons = lookData.seasons ? uniq(lookData.seasons.map(ss => ss.name)) : [];
    look.locations = lookData.locations ? uniq(lookData.locations.map(ls => ls.name)) : [];

    const products = [];
    const productDescriptions = [];

    lookData.products.forEach(p => {
        productDescriptions.push(p.name);
        productDescriptions.push(p.description);
        const designers = [];
        const styles = [];
        p.designers.forEach(d => designers.push(d.name));
        p.productStyles.forEach(s => styles.push(s.name));

        products.push([].concat(designers, styles));
    });

    //look.products = productDescriptions.join(". ");
    
    let i;
    for (i = 0; i < 5; i++) {
        look['product' + (i + 1)] = products[i] || [];
    }

    return look;
};*/

class PageSearch extends React.Component {

    state = {
        query: "",
        tpl: "",
        tplQuery: "",
        showEditor: false,
        scores: [],
        highlights: []
    }
    words = []

    onSearchChange(query) {
        this.setState({ query })
    }

    showQueryEditor() {
        this.setState({ showEditor: true })
    }

    highlight(f, inx) {

        const hs = this.state.highlights;

        if (!hs || !hs[inx]) {
            return "";
        }

        const str = (hs[inx][f] || "").replace(/\*[^*]+\*/g, (match) => {
            match = match.replace('*', '').replace('*', '');
            return `<em>${match}</em>`;
        });

        return <span dangerouslySetInnerHTML={ { __html: str }}></span>;
    }

    applySearchTpl() {
        this.setState({ showEditor: false });
        this.search();
    }

    onTplQueryChange(query) {
        query = query.replace(/[^a-zA-Z0-9 ]/gi, "");
        this.setState({ 
            tplQuery: query,
            tpl: query ? 
                    this.prepareStructuredQuery(query.trim().toLowerCase()) :
                    ""
        });
    }

    prepareStructuredQuery(query) {

        const words = query.split(" ");
        const productFields = [
            'product1',
            'product2',
            'product3',
            'product4',
            'product5'
        ];

        const lookFields = [
            'styles',
            'occasions',
            'seasons',
            'locations'
        ];

        const productParts = productFields.map(f => `${f}:'${query}'`);
        const lookParts = lookFields.map(f => `${f}:'${query}'`);

        let wordQuery = '';

        if (words.length > 1) {
            const wordProductParts = [];
            const wordLookParts = [];
            const wordOverallParts = words.map(w => `'${w}'`);
            
            words.forEach(word => {
                productFields.forEach(f => {
                    wordProductParts.push(`${f}:'${word}'`);
                });
                lookFields.forEach(f => {
                    wordLookParts.push(`${f}:'${word}'`);
                });
            });

            wordQuery = `
                (and 
                    (and ${wordOverallParts.join(" ")})
                    (or ${wordProductParts.join(" ")})
                    (or ${wordLookParts.join(" ")})
                )
            `;
        }

        //(near boost=2 distance=3 field=description '${query}')

        const bigQuery = `        
            (or 
                (or boost=3 ${productParts.join(" ")})
                (or boost=2 ${lookParts.join(" ")})
                ${wordQuery}
            )
        `;

        return bigQuery.trim();
    }

    async search() {

        const cleanQuery = unaccent(this.state.query)
                            .replace(/\s+/, " ")
                            .replace(/[^a-zA-Z0-9 ]/gi, "")
                            .trim().toLowerCase();
        const structQuery = this.state.tpl || null;

        store.dispatch(ui.list.loading(true));

        this.words = cleanQuery.split(" ").map(word => 
                    new RegExp(`(^|[^a-z0-9])(${word})([^a-z0-9]|$)`, "gi"));
        const body = {};

        if (structQuery) {
            body['structured'] = structQuery;
        }
        else {
            body['query'] = cleanQuery;
        }

        const { ids, scores, highlights } = 
                            await api.unauth.post(
                                "/search/looks", { body }
                            )
                            .then(response => {
                                return {
                                    ids: response.looks,
                                    scores: response.scores,
                                    count: response.count,
                                    highlights: response.highlights
                                }
                            });

        const looks = await lookLoader({ 
            where: { id: { _in: ids }},
            withLookTags: true,
            withProductTags: true,
            withCount: false,
            limit: 100
        })
        .then(looks => sortById(looks, ids));

        this.setState({ scores, highlights });

        batch(() => {
            store.dispatch(ui.list.loading(false));
            store.dispatch(data.list.set(looks));
        });
    }

    renderLookDetails(look, inx) {

        //const details = prepareLook(look);
        const scores = this.state.scores;

        return (
            <>
                <ul>
                    <li>
                        <p><b>Score: </b> { scores[inx] }</p>
                    </li>

                    <li>
                        <p>[w:3] <b>Product1:</b> 
                        { this.highlight("product1", inx) }</p>
                    </li>
                    <li>
                        <p>[w:3] <b>Product1 text:</b> 
                        { this.highlight("product1text", inx) }</p>
                    </li>

                    <li>
                        <p>[w:3] <b>Product2:</b> 
                        { this.highlight("product2", inx) }</p>
                    </li>
                    <li>
                        <p>[w:3] <b>Product2 text:</b> 
                        { this.highlight("product2text", inx) }</p>
                    </li>

                    <li>
                        <p>[w:3] <b>Product3:</b> 
                        { this.highlight("product3", inx) }</p>
                    </li>
                    <li>
                        <p>[w:3] <b>Product3 text:</b> 
                        { this.highlight("product3text", inx) }</p>
                    </li>

                    <li>
                        <p>[w:3] <b>Product4:</b> 
                        { this.highlight("product4", inx) }</p>
                    </li>
                    <li>
                        <p>[w:3] <b>Product4 text:</b> 
                        { this.highlight("product4text", inx) }</p>
                    </li>

                    <li>
                        <p>[w:3] <b>Product5:</b> 
                        { this.highlight("product5", inx) }</p>
                    </li>
                    <li>
                        <p>[w:3] <b>Product5 text:</b> 
                        { this.highlight("product5text", inx) }</p>
                    </li>

                    <li>
                        <p>[w:2] <b>Styles:</b> 
                        { this.highlight("styles", inx) }</p>
                    </li>
                    <li>
                        <p>[w:2] <b>Occasions:</b> 
                        { this.highlight("occasions", inx) }</p>
                    </li>
                    <li>
                        <p>[w:2] <b>Seasons:</b> 
                        { this.highlight("seasons", inx) }</p>
                    </li>
                    <li>
                        <p>[w:2] <b>Where to wear: </b> 
                        { this.highlight("locations", inx) }</p>
                    </li>
                </ul>
            </>
        )
    }

    lookActions(look) {
        const url = getUrl("/l/:id", { id: look.id }, "web")
        return (
            <a href={ url } 
                onClick={ e => e.stopPropagation() }
                target="_blank"
                rel="noopener noreferrer"
                className="card-link" children=" "/>
        )
    }

    render() {
        let list = this.props.data.list,
            { loading } = this.props.ui.list,
            { query, showEditor, tpl, tplQuery } = this.state;

        return (
            <>
                <div className="page page-search page-w-loading">
                    { loading && <Loader size={ 64 }/> }

                    <NullForm className="toolbar">
                        <div>
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            size="small"
                            value={ query }
                            label={ query ? "" : "Search" }
                            onKeyDown={ e => e.key === "Enter" && this.search() }
                            onChange={ e => this.onSearchChange(e.target.value) }/>
                        <Button 
                            variant="contained"
                            children="Search"
                            disabled={ query.length < 2 }
                            onClick={ e => this.search() }/>
                        <Button 
                            variant="text"
                            children="Query editor"
                            onClick={ e => this.showQueryEditor() }/>
                        </div>
                    </NullForm>

                    <div className="grid-wrapper">
                        <table className="grid">
                            <thead>
                            <tr>
                                <th>Look</th>
                                <th>Details</th>
                            </tr>
                            </thead>
                            <tbody>
                                { list.map((look, inx) => (
                                    <tr key={ look.id }>
                                        <td>
                                            <LookCard
                                                look={ look }
                                                url={ getUrl("/l/:id", { id: look.id }, "web") }/>
                                        </td>
                                        <td>
                                            { this.renderLookDetails(look, inx) }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                { showEditor && 
                <>
                    <div className="dialog-overlay active"
                        onClick={ e => this.setState({ showEditor: false }) }/>
                    <div className="dialog dialog-query-builder active">
                        <NullForm className="dialog-content">
                            <TextField 
                                fullWidth
                                autoComplete="off"
                                value={ tplQuery }
                                onChange={ e => this.onTplQueryChange(e.target.value) }
                                variant="outlined"/>
                            <TextField 
                                value={ tpl }
                                fullWidth
                                autoComplete="off"
                                rowsMax={ 20 }
                                onChange={ e => this.setState({ tpl: e.target.value })}
                                variant="outlined" 
                                multiline/>
                            <Button variant="contained"
                                    onClick={ e => this.applySearchTpl() }
                                    children="Search"/>
                        </NullForm>
                    </div>
                </>
                }
                
            </>
        )
    }
}

export default connect(state => state.searchPage)(PageSearch)


/*
<li>
                        <p>[w:1] <b>Description:</b> 
                        { this.highlight(details.description) }</p>
                    </li>*/