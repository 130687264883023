import { useCallback, useEffect, useState } from "react"
import { TextField, Button } from "@mui/material"

import NullForm from "common/src/components/NullForm"
import Table from "common/src/components/table/Table"

import formatNumber from "common/src/lib/format/number"
import api from "app/api"

function renderTime(time) {
    time = parseInt(time);
    if (time > 1000) {
        return formatNumber(time / 1000, 2) + 's';
    }
    return time + 'ms';
}

const columns = [
    {
        id: "action",
        name: "Action"
    },
    {
        id: "min_duration",
        name: "Min",
        render: row => renderTime(row.min_duration)
    },
    {
        id: "max_duration",
        name: "Max",
        render: row => renderTime(row.max_duration)
    },
    {
        id: "avg_duration",
        name: "Avg",
        render: row => renderTime(row.avg_duration)
    }
]

function Timing() {

    const [ stats, setStats ] = useState([]);
    const [ apps, setApps ] = useState([]);
    const [ app, setApp ] = useState("");
    const [ min, setMin ] = useState("500");

    const loadApps = useCallback(
        async () => {
            const res = await api.backend.get("/timing/apps");
            setApps(res.apps?.map(a => a.app) || []);
        },
        []
    );

    const loadStats = useCallback(
        async () => {
            const res = await api.backend.post("/timing/stats", {
                body: {
                    app, min
                }
            });
            setStats(res.stats);
        },
        [ app, min ]
    );

    const onMinChange = useCallback(
        (e) => setMin(e.target.value),
        []
    );
    const onAppChange = useCallback(
        (e) => setApp(e.target.value),
        []
    );

    useEffect(
        () => {
            loadApps();
        },
        //eslint-disable-next-line
        []
    );

    return (
        <div className="page page-performance-timing">
            <NullForm className="toolbar">
                <div className="toolbar-title">
                    Performance timing
                </div>
                <div className="spacer-full"/>
                <select placeholder="App" value={ app } onChange={ onAppChange }>
                    <option></option>
                    { apps.map(app => (<option key={ app } value={ app }>{ app }</option>))}
                </select>
                <TextField 
                    placeholder="Minimal threshold" 
                    size="small"
                    value={ min }
                    onChange={ onMinChange }/>
                <Button 
                    children="Get" 
                    size="small" 
                    variant="contained"
                    onClick={ loadStats }/>
            </NullForm>
            <Table cols={ columns } rows={ stats } variant="slim"/>
        </div>
    )
}

export default Timing