

import getStyle from "./getStyle"


function getOffsetParent(node) {

    var html = window.document.documentElement,
        offsetParent = node.offsetParent || html;

    while (offsetParent && 
            (offsetParent != html &&
                getStyle(offsetParent, "position") === "static")) {
        offsetParent = offsetParent.offsetParent;
    }

    return offsetParent || html;
};


export default getOffsetParent