import React, { useMemo, useCallback, useContext, useRef } from "react"
import findParent from "common/src/lib/dom/findParent"
import { CrossPlatformContext } from "common/src/cross-platform/Provider"

import swallow from "common/src/lib/dom/swallowEvent"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"

const _dndEnabled = typeof navigator !== "undefined"  ?
                        !(/iPad|iPhone|iPod|Android/.test( navigator.userAgent || "" )) :
                        false;
let xcmp = null;

function getXcmp(context) {
    if (!xcmp) {
        xcmp = context.provideComponent([
            "link", "container", "navigation/link",
            "icon/tick"
        ]);
    }
    return xcmp;
}

function draggableAttrs(attrs, cfg) {

    if (!_dndEnabled) {
        return;
    }

    attrs.draggable = true;
    attrs.onDragStart = e => {
        e.dataTransfer.setData(
            "application/json", 
            JSON.stringify(cfg.data === undefined ? null : cfg.data)
        );
        if (cfg.imageElId) {
            e.dataTransfer.setDragImage(
                document.getElementById(cfg.imageElId), 
                0, 0
            );
        }
        cfg.start && cfg.start(cfg);
    };
    attrs.onDragEnd = e => {
        cfg.end && cfg.end(cfg);
    };
}

function droppableAttrs(attrs, cfg) {

    if (!_dndEnabled) {
        return;
    }

    attrs.onDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    attrs.onDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
        const el = findParent(e.target, cfg.cardSelector || ".card");
        el && el.classList.add("droppable-active");
    };
    attrs.onDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        const el = findParent(e.target, cfg.cardSelector || ".card");
        el && el.classList.remove("droppable-active");
    };
    attrs.onDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        const data = JSON.parse(e.dataTransfer.getData("application/json"));
        const el = findParent(e.target, cfg.cardSelector || ".card");
        el && el.classList.remove("droppable-active");
        cfg.drop && cfg.drop(cfg.data === undefined ? null : cfg.data, data);
    };
}


function CardAction({ action }) {

    const context = useContext(CrossPlatformContext);
    const A = useMemo(() => getXcmp(context)["link"], []);
    const cls = useMemo(() => "card-action " + (action.cls || ""), [ action.cls ]);

    const onClick = useCallback(
        (e) => {
            swallow(e);
            action.onClick(e, action);
        },
        [ action ]
    );

    const onPress = useCallback(() => action.onClick(null, action), [ action ]);

    return (
        <A href="/#" 
            title={ action.title || "" }
            className={ cls }
            webProps={{ cmp: "a", onClick }}
            nativeProps={{ onPress }}
            children={ action.icon }/>
    )
}


function Card(props) {
    const { header, footer, 
            actions = [], layers = [],
            url, onClick, linkProps = {},
            selectable, selected = false, selectIcon, selectionDisabled = false,
            draggable = false, droppable = false } = props;

    const nativeLayout = useRef(null);
    const headerCls = useMemo(() => "card-header " + (props.headerCls || ""), [ props.headerCls ]);
    const footerCls = useMemo(() => "card-footer " + (props.footerCls || ""), [ props.footerCls ]);

    const selCls = useMemo(
        () => {
            return [ 
                "card-selection",
                selected ? "selected" : "",
                selectionDisabled ? "disabled" : ""
            ].join(" ");
        },
        [ selected, selectionDisabled ]
    );

    const cls = useMemo(
        () => {
            let cls = props.cls || [];
            if (typeof cls === "string") {
                cls = [ cls ];
            }
            cls.unshift("card");
            selected && cls.push("selected");
            draggable && cls.push("draggable");
            droppable && cls.push("droppable");

            return cls.join(" ");
        },
        [ props.cls, draggable, droppable ]
    );

    const attrs = useMemo(
        () => {
            const attrs = { ...props.attrs };
            if (_dndEnabled) {
                draggable && draggableAttrs(attrs, typeof draggable === "boolean" ? {} : draggable);
                droppable && droppableAttrs(attrs, typeof droppable === "boolean" ? {} : droppable);
            }
            return attrs;
        },
        [ props.attrs, draggable, droppable ]
    );

    

    const context = useContext(CrossPlatformContext);
    const { Container, A, NavigationLink, IconTick } = useMemo(
        () => {
            const xcmp = getXcmp(context);
            const Container = xcmp["container"];
            const A = xcmp["link"];
            const NavigationLink = xcmp["navigation/link"];
            const IconTick = xcmp["icon/tick"];
            return { Container, A, NavigationLink, IconTick };
        },
        []
    );

    const onLayout = useCallback(
        (event) => {
            const layout = event.nativeEvent.layout;
            const nlt = nativeLayout.current;
            if (!nlt || nlt.width !== layout.width || nlt.height !== layout.height) {
                nativeLayout.current = { ...layout };
                props.onNativeLayoutChange && 
                    props.onNativeLayoutChange(nativeLayout.current);
            }
        },
        []
    );

    const onSelectorClick = useSwallowEventCallback(
        () => {
            !selectionDisabled && props.onSelectionChange && props.onSelectionChange(!selected)
        },
        [ selected, selectionDisabled, props.onSelectionChange ]
    );

    const onLinkClick = useCallback(
        (e) => {
            !url && e && e.preventDefault();
            e && e.stopPropagation();
            if (onClick === "select") {
                onSelectorClick();
            }
            else onClick && onClick(e, url);
        },
        [ onClick, url, onSelectorClick ]
    );

    const onLinkPress = useCallback(
        () => {
            if (onClick === "select") {
                onSelectorClick();
            }
            else onClick && onClick(null, url)
        },
        [ onClick, url, onSelectorClick ]
    );
    
    const linkWebProps = useMemo(
        () => ({ cmp: "a", onClick: onLinkClick }),
        [ onLinkClick ]
    );

    const linkNativeProps = useMemo(
        () => ({
            pressableProps: { className: "card-link" },
            onPress: onLinkPress
        }),
        [ onLinkPress ]
    );

    const selectorWebProps = useMemo(
        () => ({
            cmp: "a",
            onClick: onSelectorClick
        }),
        [ onSelectorClick ]
    );

    return (
        <Container 
            className={ cls } 
            nativeProps={{ onLayout }}
            { ...attrs }>
            { props.before }
            { header && <Container className={ headerCls }>{ header }</Container> }
            { props.children }
            { layers.map((l, inx) => <React.Fragment key={ inx } children={ l }/> )}
            { actions.map((a, inx) => (a ? <CardAction key={ inx } action={ a }/> : null)) }

            { url &&
                <NavigationLink
                    data-cy="card-link" 
                    className="card-link"  
                    to={ url } 
                    onClick={ onLinkClick }
                    { ...linkProps }/> }

            { (!url && onClick) && 
                <A href="/#" 
                    data-cy="card-link"
                    className="card-link" 
                    webProps={ linkWebProps }
                    nativeProps={ linkNativeProps }
                    { ...linkProps }/> }

            { selectable && 
                <A href="/#" 
                    className={ selCls }
                    disabled={ selectionDisabled }
                    webProps={ selectorWebProps }
                    children={ selectIcon || <IconTick/> }/> }

            { footer && <Container className={ footerCls }>{ footer }</Container> }
            { props.after }
        </Container>
    )

    
}


export default Card