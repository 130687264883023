import React from "react"
import { connect } from "react-redux"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import SideDialog from "common/src/components/dialog/SideDialog"
import Loader from "common/src/components/Loader"

import { alert } from "common/src/components/dialog/Alert"
import { resendPhoneVerification, verifyPhone } from "common/src/actions/user"
import { ui as ddUi } from "common/src/store/dialogs"
import NullForm from "common/src/components/NullForm"


class VerifyPhone extends React.Component {

    state = {
        verifying: false,
        resending: false
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClose() {
        this.props.dispatch(ddUi.hide("verify-phone"));
    }

    onFieldChange(field, value) {
        this.setState({[field]: value})
    }

    onSaveClick() {
        this.setState({verifying: true});
        verifyPhone(this.state.code)
            .then(resp => {
                if (this._isMounted) {
                    this.setState({verifying: false});
                    alert({
                        title: "Phone number verified",
                        message: ""
                    })
                    this.onClose();
                }
            })
            .catch(err => {
                alert({
                    title: "Failed to verify the phone number",
                    message: "Please check that the code is correct"
                })
            })
    }

    onResendClick(e) {
        e.preventDefault();
        this.setState({resending: true});
        resendPhoneVerification()
            .finally(resp => {
                if (this._isMounted) {
                    this.setState({resending: false});
                }
            });
    }

    render() {

        let show = this.props.dd['verify-phone'],
            verifying = this.state.verifying,
            resending = this.state.resending;


        return (
            <SideDialog title="Verify phone" 
                        show={ show }
                        className="dialog-user-details"
                        onClose={ () => this.onClose() }>
                <NullForm>
                <TextField 
                    fullWidth
                    autoFocus
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    label="Verification code" 
                    onChange={ e => this.onFieldChange("code", e.target.value) }/>
                </NullForm>
                <div className="resend-code">
                    { resending ? 
                        <Loader/> :
                        <a href="/#" 
                            onClick={ e => this.onResendClick(e) }>
                            Resend code
                        </a>
                    }
                </div>
                <Button 
                    className="submit"
                    variant="contained" 
                    disabled={ verifying || resending }
                    startIcon={ verifying ? <Loader inline/> : null }
                    onClick={ () => this.onSaveClick() }
                    color="primary">Verify</Button>
            </SideDialog>
        )
    }
}

export default connect(state => ({...state.user, dd: state.dialogs}))(VerifyPhone)