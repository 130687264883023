import React, { useState, useMemo, useCallback, useContext, useRef } from "react"
import Card from "../card/Card"
import ProductCard from "./ProductCard"

import { CrossPlatformContext } from "common/src/cross-platform/Provider"

import { getGridStyle } from "common/src/lib/look/layout2style"
import settings from "common/src/settings"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import user from "common/src/user"
import hub from "common/src/hub"
import { saveLook, unsaveLook } from "common/src/actions/save"


const cdn = settings['cloudfront'][process.env.REACT_APP_ENV];
let xcmp = null;

function getXcmp(context) {
    if (!xcmp) {
        xcmp = context.provideComponent([
            "text", "container", "loader",
            "icon/fav", "icon/fav-active", "icon/share", "icon/move", "icon/tick",
            "component/button", "component/share", "component/user"
        ]);
    }
    return xcmp;
}

//look.layouts.template
function getBiggestSlotInx(template) {
    let m = 0, mv = 0;
    template?.forEach((s, i) => {
        const v = s.row + s.col;
        if (v > mv) {
            mv = v;
            m = i;
        }
    });

    return m;
}

//look.layouts.template
function getBiggestSpanSize(template) {
    let maxs = 0;
    template?.forEach((s) => {
        const r = s.row;
        if (r > 1 && r > maxs) {
            maxs = r;
        }
    });
    return maxs;
}

function LookLayoutEmptyItem(props) {

    const { index, look, empty } = props;

    const fullTemplate = look.layouts.template;
    const layoutTemplate = look.layouts.template[index];
    const context = useContext(CrossPlatformContext);
    const Container = useMemo(() => getXcmp(context).container, []);
    const style = useMemo(
        () => getGridStyle(layoutTemplate, { slotIndex: index, template: fullTemplate }),
        [layoutTemplate, fullTemplate, index]
    );

    const item = useMemo(
        () => {
            if (empty) {
                if (typeof empty === "function") {
                    return empty({
                        className: props.className ? props.className + "-empty" : null,
                        look,
                        style,
                        index,
                        layout: layoutTemplate,
                        template: fullTemplate
                    });
                }
                else {
                    return empty;
                }
            }

            let cls = "look-card-empty";
            if (props.className) {
                cls += " " + props.className + "-empty"
            }

            return (
                <Container style={style} className={ cls } />
            )
        },
        [look, style, index, empty]
    );

    return item;
}


function LookLayoutProductItem(props) {

    const { look, index, biggestSpan, biggestInx, onEvent } = props;
    const layoutTemplate = look.layouts.template[index];

    const ProductCmp = useMemo(() => props.productCmp || ProductCard, []);

    const { product, bigSlot } = useMemo(
        () => {
            const { layouts, products, productMode = "multiple" } = look,
                    order = layouts['order'] || [],
                    pid = productMode === "multiple" ?
                        order[index] :
                        products.length > 0 ?
                            products[0].id :
                            null,
                    product = pid ? products.find(p => p.id === pid) : null,
                    bigSlot = layoutTemplate.row === biggestSpan;

            return { product, bigSlot };
        },
        [ index, look ]
    );

    const props_1 = useMemo(
        () => ({
            slotIndex: index,
            biggestSlot: biggestInx === index,
            bigSlot
        }),
        [ index, biggestInx, bigSlot ]
    );

    const props_2 = useMemo(
        () => {
            return typeof props.productProps === "function" ?
                        props.productProps(product, look, props_1) :
                        { ...props.productProps };
        },
        [ props_1, product, look, props.productProps ]
    );

    const onEventCb = useCallback(
        (type, data) => {
            data = { ...data, ...props_1, id: product.id };
            onEvent(type, data);
        },
        [ props_1, product?.id ]
    );

    return (
        <ProductCmp
            className={ props.className ? props.className+"-slot" : "" }
            mode="look"
            look={look}
            product={product}
            {...props_1}
            {...props_2}
            actionState={{ zoomed: false }}
            onEvent={onEventCb} />
    )
}

function LookLayoutItem(props) {

    const { look, index } = props;
    const { product, isEmptyImage } = useMemo(
        () => {
            const { layouts, products, productMode = "multiple" } = look,
                    order = layouts['order'] || [],
                    pid = productMode === "multiple" ?
                            order[index] :
                            products.length > 0 ?
                                products[0].id :
                                null,
                    product = pid ? products.find(p => p.id === pid) : null,
                    isEmptyImage = productMode === "single" && (!product || !product.images[index]);
            return { product, isEmptyImage };
        },
        [ index, look ]
    ); 

    if (product && !isEmptyImage) {
        return (
            <LookLayoutProductItem
                className={ props.className }
                look={ look }
                index={ index }
                biggestSpan={ props.biggestSpan }
                biggestInx={ props.biggestInx }
                productCmp={ props.productCmp }
                productProps={ props.productProps }
                onEvent={ props.onEvent } />
        )
    }
    else {
        return (
            <LookLayoutEmptyItem
                className={ props.className }
                look={ look }
                index={ index }
                empty={ props.empty } />
        );
    }
}

function PreviewCard(props) {
    const { previewId, previewSlotIndex, look, onEvent } = props;

    const product = useMemo(() => look.products.find(p => p.id === previewId), [ previewId ]);
    const props_2 = useMemo(
        () => {
            const productProps = props.productProps || {};
            const props_2 = typeof productProps === "function" ?
                            productProps(product, look) :
                            { ...productProps };
        
            if (look.productMode === "single") {
                props_2.override = {
                    currentImage: previewSlotIndex
                };
            }
            
            return props_2;
        },
        //[ previewId, previewSlotIndex ]
    );

    return (
        <ProductCard
            cls="look-product-card-preview"
            product={ product }
            actionState={{ zoomed: true }}
            {...props_2}
            onEvent={ onEvent } />
    )
}

function SharePanel(props) {
    
    const { look, onClose } = props;

    const context = useContext(CrossPlatformContext);
    const SharePanel = useMemo(() => getXcmp(context)["component/share"], []);
    const image = useMemo(() => `${cdn}/public/images/${look.id}.png?w=1000`, [ look.id ]);
    const url = useMemo(
        () => {
            const urlParams = { id: look.id };
            if (user.loggedIn()) {
                urlParams._refid = user.id();
            }
            return getUrl(routes.look, urlParams, window.location.origin);
        },
        [ look.id, user.loggedIn() ]
    );

    const info = useMemo(
        () => {
            return {
                id: look.id,
                text: look.description,
                image,
                url
            }
        },
        [ look.id, image, url ]
    );
    
    return (
        <SharePanel
            className="look-card-share-panel"
            info={ info }
            onClose={ onClose } />
    )
}

function LookLayout(props) {

    const { look } = props,
            fullTemplate = look.layouts.template;

    const biggestInx = useMemo(() => getBiggestSlotInx(fullTemplate), [fullTemplate]);
    const biggestSpan = useMemo(() => getBiggestSpanSize(fullTemplate), [fullTemplate]);

    return fullTemplate?.map((_, index) => 
        <LookLayoutItem
            className={ props.className }
            key={ index }
            look={ look }
            index={ index }
            biggestInx={ biggestInx }
            biggestSpan={ biggestSpan }
            empty={ props.empty }
            onEvent={ props.onEvent }
            productProps={ props.productProps }
            productCmp={ props.productCmp }/>
    );
}

function LookCard(props) {

    const { look } = props;
    const lookRef = useRef(null);  
    const eventsRef = useRef(null);
    const [previewId, setPreviewId] = useState(null);
    const [previewSlotIndex, setPreviewSlotIndex] = useState(null);
    const [saving, setSaving] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const context = useContext(CrossPlatformContext);

    lookRef.current = look;
    eventsRef.current = props.events;

    const saveLookAction = useCallback(
        async () => {
            if (!user.loggedIn()) {
                hub.dispatch("app", "redir", getUrl(routes.signin));
                return;
            }

            let { id, saved } = lookRef.current;

            setSaving(true);

            if (saved) {
                await unsaveLook(id);
            }
            else {
                await saveLook(id);
            }

            setSaving(false);
        },
        []
    );

    const onActionClick = useCallback(
        (action) => {
            switch (action) {
                case "save": {
                    saveLookAction();
                    break;
                }
                case "share": {
                    setShowShare(true);
                    break;
                }
            }
        },
        [ saveLookAction ]
    );

    const onEvent = useCallback(
        (type, eventData) => {
            const events = eventsRef.current;
            if (events && events[type]) {
                events[type](eventData);
            }
            if (type === "preview") {
                setPreviewId(eventData.id);
                setPreviewSlotIndex(eventData.slotIndex);
            }
            else if (type === "preview-close") {
                setPreviewId(null);
                setPreviewSlotIndex(null);
            }
        },
        [ props.events ]
    );

    const { Container, User, Button, Loader,
            IconShare, IconFav, IconFavActive, IconMove, IconTick } = useMemo(
        () => {
            const cmp = getXcmp(context);
            return {
                Container: cmp.container,
                User: cmp["component/user"],
                Button: cmp["component/button"],
                IconShare: cmp["icon/share"],
                IconFav: cmp["icon/fav"],
                IconFavActive: cmp["icon/fav-active"],
                Loader: cmp["loader"],
                IconMove: cmp["icon/move"],
                IconTick: cmp["icon/tick"]
            }
        },
        []
    );

    const cls = useMemo(
        () => {
            const { showHeader } = props;
            const cls = ["look-card"];
            if (showHeader === false || !look || !look.layouts) {
                cls.push("look-card-no-header");
            };
            if (props.cls) {
                cls.push(props.cls);
            };
            if (props.className) {
                cls.push(props.className)
            }
            return cls;
        },
        [props.showHeader, !!look, props.cls, props.className, look?.id]
    );

    const { classNameFixed, classNameProducts } = useMemo(
        () => {
            const classNameFixed = [ 
                "look-card-fixed", 
                props.className ? props.className + "-look-card-fixed" : "" ].join(" ");
            const classNameProducts = [ 
                "look-card-products", 
                props.className ? props.className + "-look-card-products" : "" ].join(" ");
            return { classNameFixed, classNameProducts };
        },
        [ props.className ]
    );

    const header = useMemo(
        () => {

            if (props.showHeader === false || !look) {
                return null;
            }

            let cls = "look-card-user";
            if (props.className) {
                cls += " " + props.className+"-look-card-user";
            }

            return <User
                className={ cls }
                avatarClassName="look-card-avatar"
                user={ look.fri || props.fri }
                handle link />;
        },
        [props.showHeader, props.cls, look?.fri, props.fri, look?.id]
    );

    const footer = useMemo(
        () => {
            const { actions = [], footer, onView } = props;

            if (footer) {
                return footer;
            }

            if (actions.indexOf("view") !== -1) {
                let cls = "look-card-view-button";
                if (props.className) {
                    cls += " " + props.className+"-look-card-view-button";
                }
                return (
                    <>
                        <Button
                            className={ cls }
                            variant="contained"
                            children="View look"
                            onClick={onView} />
                    </>
                )
            }

            return null;
        },
        [props.footer, props.actions, look?.id]
    );

    const actions = useMemo(
        () => {
            if (!look) {
                return [];
            }
            return (props.actions || []).map(a => {
                const action = typeof a !== "string" ?
                    a :
                    {
                        onClick: () => onActionClick(a),
                        cls: "look-card-action look-card-action-" + a
                    };

                switch (a) {
                    case "view": {
                        return null;
                    }
                    case "share": {
                        action.icon = <IconShare
                            className="icon icon-svg-fill icon-share look-card-icon-share" />;
                        break;
                    }
                    case "save": {
                        if (look.saved) {
                            action.cls += " active";
                        }
                        action.icon = saving ?
                            <Loader className="look-card-icon-fav-loading" /> :
                            look.saved ?
                                <IconFavActive
                                    className="icon icon-svg-fill icon-favorite-active look-card-icon-fav-active" /> :
                                <IconFav
                                    className="icon icon-svg-fill icon-favorite look-card-icon-fav" />;
                        break;
                    }
                    default: {
                        break;
                    }
                }

                return action;
            })
            .filter(a => !!a);
        },
        [props.actions, !!look, look?.saved, saving, look?.id]
    );

    const layers = useMemo(
        () => {
            if (!look) {
                return [];
            }
            return (props.layers || []).map(l => {
                switch (l) {
                    case "moveable": {
                        return (
                            <Container className="look-card-move">
                                <IconMove className="icon icon-svg-fill icon-move look-card-icon-move" />
                            </Container>
                        )
                    }
                    case "published": {
                        if (look.published) {
                            return (
                                <Container className="look-card-published">
                                    <IconTick className="icon icon-svg-fill icon-tick look-card-icon-tick" />
                                </Container>
                            )
                        }
                        else if (!look.published && !!look.publishedAt) {
                            return (
                                <Container className="look-card-was-published">
                                    <IconTick className="icon icon-svg-fill icon-tick look-card-icon-tick-grey" />
                                </Container>
                            )
                        }
                    }
                }
            })
                .filter(l => !!l);
        },
        [props.layers, !!look, look?.published]
    )

    const cardProps = useMemo(
        () => {

            if (!look) {
                return {};
            }

            const { selectable, onSelectionChange, selectIcon, selected,
                    onClick, url, attrs,
                    draggable, droppable, onDrop, onDragStart } = props;

            return {
                cls,
                attrs,

                header,
                footer,
                headerCls: "look-card-header",
                footerCls: "look-card-footer",

                actions,
                layers,

                selected,
                selectable,
                onSelectionChange,
                selectIcon,

                draggable,
                droppable,
                onDrop,
                onDragStart,

                onClick,
                url
            };
        },
        [ actions, layers, header, footer, cls, look?.id, 
            props.selected, props.selectable, props.onSelectionChange,
            props.selectIcon, props.onClick, props.url, props.attrs,
            props.draggable, props.droppable, props.onDrop, props.onDragStart ]
    );

    const onSharePanelClose = useCallback(() => setShowShare(false), []);


    if (!look || !look.layouts) {
        return (
            <Card className={cls.join(" ")}>
                <Container className={ classNameFixed } />
            </Card>
        )
    }

    return (
        <Card {...cardProps}>
            <Container className={ classNameFixed }>
                <Container className={ classNameProducts }>
                    <LookLayout
                        className={ props.className }
                        look={ look }
                        onEvent={ onEvent }
                        empty={ props.empty }
                        productProps={ props.product }
                        productCmp={ props.productCmp }/>
                </Container>
            </Container>
            { previewId && 
                <PreviewCard 
                    look={ look }
                    productProps={ props.product }
                    onEvent={ onEvent }
                    previewId={ previewId }
                    previewSlotIndex={ previewSlotIndex }/> }
            { showShare && 
                <SharePanel
                    look={ look }
                    onClose={ onSharePanelClose }/> }
        </Card>
    )
}


export default LookCard