import React from "react"
import { connect } from "react-redux"

import Button from "@mui/material/Button"
import Dialog from "common/src/components/dialog/Dialog"
import TagList from "common/src/components/tag/List"
import TagSelector from "common/src/components/tag/Selector"
import Loader from "common/src/components/Loader"

import { createTemporaryTag } from "common/src/actions/tags"
import { ui as ddUi } from "common/src/store/dialogs"
import { merge } from "app/actions/page/tags"
import { alert } from "common/src/components/dialog/Alert"
import fuc from "common/src/lib/fuc"
import api from "app/api"


class MergeTags extends React.Component {

    state = {
        to: [],
        merging: false
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getSelectedTags() {
        let type = this.props.type,
            list = this.props.data[type + "s"],
            selected = this.props.selected;

        return list.filter(t => selected.indexOf(t.id) !== -1);
    }

    selectTo(t) {
        if (!t) {
            this.setState({to: []})
        }
        else {
            if (typeof t === "string") {
                t = createTemporaryTag(this.props.type, t);
            }
            this.setState({to: [t]})
        }
    }

    onShowHide(state) {
        if (state) {
            let tags = this.getSelectedTags();
            this.setState({
                to: [tags.pop()],
                merging: false
            })
        }
    }

    merge() {
        this.setState({merging: true});

        let type = this.props.type,
            to = this.state.to.pop(),
            sels = this.props.selected,
            promise;

        if (to.id.indexOf("tmp") === 0) {
            promise = api.backend.post("/tag", {
                body: {
                    type: fuc(type),
                    name: to.name
                }
            })
            .then(resp => resp.tag)
        }
        else {
            promise = Promise.resolve(to);
        }

        promise
        .then(to => merge(type, sels, to.id))
        .then(resp => {
            if (resp.success) {
                this.props.onMerge && this.props.onMerge();
                this.props.dispatch(ddUi.hide("tag-merge"))
            }
            else {
                alert({
                    title: "Failed to merge tags"
                })
            }
        })
        .finally(resp => {
            if (this._isMounted) {
                this.setState({merging: false})
            }
        })
    }

    render() {

        let tags = this.getSelectedTags(),
            { to, merging } = this.state;

        return (
            <Dialog name="tag-merge" 
                    className="dialog-merge"
                    triggerMode="click"
                    closeOnBody={ false }
                    closeOnOverlay={ false }
                    onChange={ state => this.onShowHide(state) }>
                <h3>Merge following tags</h3>
                <TagList tags={ tags } onClick={ t => this.selectTo(t) }/>
                <label>into</label>
                <TagSelector 
                    value={ this.state.to }
                    entity={ this.props.type }
                    disabled={ merging }
                    onChange={ sels => this.selectTo(sels.pop()) }/>
                <Button
                    disabled={ to.length === 0 || merging }
                    variant="contained"
                    children="Merge"
                    startIcon={ merging ? <Loader inline/> : null }
                    onClick={ () => this.merge() }/>
                <Button
                    variant="outlined"
                    children="Cancel"
                    disabled={ merging }
                    onClick={ () => this.props.dispatch(ddUi.hide("tag-merge")) }/>
            </Dialog>
        )
    }
}

export default connect(state => state.tagsPage)(MergeTags)