
import StoreModule from "common/src/lib/store/StoreModule"

let people = new StoreModule("people.page");
people.addList("people");
people.addList("userGroups");
//people.addList("requests");


export let { reducer, data, ui, initialState } = people.export();
