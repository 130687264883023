
import moment from "moment"
import singlePromise from "common/src/lib/js/singlePromise"
import { data, ui } from "app/store/page/dashboard"
import store from "app/store"
//import api from "app/api"
import hub from "common/src/hub"
import client from "common/src/api/hasura/client"
import getUserContext from "common/src/api/getUserContext"
import gql from "graphql-tag"
//import { consultation } from "common/src/api/normalize"


const   last7days = moment().subtract(7, 'days').format(),
        last30days = moment().subtract(30, 'days').format();
        
const statsGraph = `
    query GetStats {
        users: User_aggregate {
            aggregate {
              count
            }
        },
        usersPlain: User_aggregate(where: { groups: { _eq: "User"}}) {
            aggregate {
              count
            }
        },
        usersPse: User_aggregate(where: { 
                _or: [ 
                    { groups: { _like: "%FRI%"}},
                    { groups: { _like: "%GPS%"}},
                    { groups: { _like: "%Contributor%"}}
                ]
            }) {
            aggregate {
              count
            }
        },
        users7days: User_aggregate(where: { lastLoggedIn: { _gt: "${last7days}" }}) {
            aggregate {
              count
            }
        },
        users30days: User_aggregate(where: { lastLoggedIn: { _gt: "${last30days}" }}) {
            aggregate {
              count
            }
        },
        looks: Look_aggregate {
            aggregate {
                count
            }
        },
        looksPublished: Look_aggregate(where: { published: { _eq: true }}) {
            aggregate {
                count
            }
        },
        looksDeleted: Look_aggregate(where: { deleted: { _eq: true }}) {
            aggregate {
                count
            }
        },
        looksAwaiting: Look_aggregate(where: { 
            published: { _eq: false },
            submitted: { _eq: true },
            deleted: { _eq: false }
        }) {
            aggregate {
                count
            }
        }
    }
`;


async function _loadStats() {

    const stats = {};
    const context = await getUserContext();
    const r = await client.query({ 
        query: gql(statsGraph), 
        variables: { }, 
        context 
    })
    .then(r => r.data);

    stats.users = r.users.aggregate.count;
    stats.usersPlain = r.usersPlain.aggregate.count;
    stats.usersPse = r.usersPse.aggregate.count;
    stats.users7days = r.users7days.aggregate.count;
    stats.users30days = r.users30days.aggregate.count;
    stats.looks = r.looks.aggregate.count;
    stats.looksPublished = r.looksPublished.aggregate.count;
    stats.looksAwaiting = r.looksAwaiting.aggregate.count;
    stats.looksDeleted = r.looksDeleted.aggregate.count;

    return stats;
}

export let loadStats = singlePromise({
    mode: singlePromise.ONE_CANCEL,
    pre: force => {
        let state = store.getState();
        if (state.dashboard.stats) {
            if (!force) {
                return {
                    value: state.dashboard.stats,
                    skip: true
                }
            }
            else {
                store.dispatch(data.stats.set(null));
            }
        }
    },
    fn: () => {
        store.dispatch(ui.stats.loading(true));
        return _loadStats();
    },
    resolve: stats => {
        store.dispatch(data.stats.set(stats));
    },
    reject: err => {
        hub.dispatch("error", "dashboard-load-stats", err);
    },
    always: () => {
        store.dispatch(ui.stats.loading(false));
    }
})