import React from "react";
import { Auth } from "@aws-amplify/auth"
import isEmail from "validator/es/lib/isEmail"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Loader from "common/src/components/Loader"

import Alert from "common/src/components/dialog/Alert"
import { alert, loading as loadingAlert } from "common/src/components/dialog/Alert"
import api from "app/api"
import async from "common/src/lib/js/async"
import settings from "app/settings"


class ForgotPasswordForm extends React.Component {

    state = {
        email: '',
        code: '',
        password: '',
        password2: '',
        errors: {},
        loading: false,
        sending: false
    }

    resetClicked = false
    sendCodeClicked = false


	send() {
		const { authData = {} } = this.props;
        const email = this.state.email || authData.email || "";
        
        const resend = function(e) {
            e.preventDefault();
            Alert.self().dismiss("ok")
                .then(() => {
                    loadingAlert({});
                    return api.unauth.post("/resend-email-verification", { body: { email }});
                })
                .finally(() => {
                    Alert.self().dismiss("ok")
                        .then(() => {
                            alert({
                                message: "Check your email for new verification link"
                            });
                        });
                });
        };

        this.setState({ loading: true });

		return Auth.forgotPassword(email.toLowerCase())
			.then(data => {
				//logger.debug(data);
				this.setState({ delivery: data.CodeDeliveryDetails });
            })
            .finally(() => {
                this.setState({ loading: false });
            })
			.catch(err => {
                console.log(err)
                if (err.message.indexOf("limit exceeded") !== -1) {
                    alert({ message: err.message });
                }
                else if (err.message.indexOf("verified email") !== -1) {
                    alert({
                        message: <>
                            <span>{ err.message }</span><br/>
                            <a href="/#" 
                                className="alert-link-accent"
                                onClick={ e => resend(e) }>Re-send verification email</a>
                        </>
                    });
                }
                else if (err.message.indexOf("cannot be reset in the current state") !== -1) {
                    this.setState({
                        errors: {
                            email: "Set up your account using the temporary password provided via email."
                        }
                    })
                }
                else {
                    alert({ message: "Sorry we don't recognise that email address" });
                }

                //this.error(err)
                this.props.onError(err);
            });
	}


	submit() {
		const { authData = {} } = this.props;
		const { code, password } = this.state;
		const email = this.state.email || authData.email || '';
        const clientMetadata = {
            action: "passwordChange",
            origin: "web",
            app: settings.app
        };

        this.setState({ loading: true });
		return Auth.forgotPasswordSubmit(email.toLowerCase(), code.trim(), password, clientMetadata)
			.then(data => {
                alert({
                    title: "Done",
                    message: "Your password has been reset"
                })
				this.props.onAuthStateChange('signIn');
				this.setState({ delivery: null });
            })
            .finally(() => {
                this.setState({ loading: false });
            })
			.catch(err => {
                alert({
                    title: "Ooops",
                    message: err.message
                });
                //this.error(err)
                this.onError(err);
            });
	}

    setInput(field, value) {

        if (field === "email") {
            value = value.toLowerCase();
        }

        this.setState({ [field]: value }, () => {
            if (field === "email" && this.sendCodeClicked) {
                async(() => this.validateEmail());
            }
            if (field === "password" && this.resetClicked) {
                async(() => this.validatePassword());
            }
        });        
    }

    validateEmail() {
        let errors = {},
            valid = true,
            email = this.state.email;

        if (!email) {
            valid = false;
            errors["email"] = "Please enter your email";
        }
        else if (!isEmail(email)) {
            valid = false;
            errors["email"] = "Please enter a valid email";
        }

        this.setState({ errors });

        return valid;
    }

    validatePassword() {
        let errors = {},
            valid = true,
            required = {
                "code": "Please enter the code you received",
                "password": "Please enter you new password",
                "password2": "Please confirm your password"
            };

        ["code", "password"].forEach(name => {
            if (!this.state[name]) {
                valid = false;
                errors[name] = required[name];
            }
        })

        if (this.state.password) {
            if (this.state.password.length < 6) {
                valid = false;
                errors['password'] = "Your password must be at least 6 characters long";
            }
        }

        if (valid && this.state.password !== this.state.password2) {
            valid = false;
            errors['password2'] = "Passwords don't match";
        }

        this.setState({ errors });

        return valid;
    }

    onSubmitClick(e) {
        e && e.preventDefault();
        this.resetClicked = true;
        if (this.validatePassword()) {
            this.submit();
        }
    }

    onSendCodeClick(e) {
        e && e.preventDefault();
        this.sendCodeClicked = true;
        if (this.validateEmail()) {
            this.send();
        }
    }

    onBackToSigninClick(e) {
        e && e.preventDefault();
        this.props.onAuthStateChange('signIn');
    }

    onResendClick(e) {
        e && e.preventDefault();
        this.send();
    }

    emailView() {
        const { email, errors = {} } = this.state;
        //let errs = this.state.errors;
        this.resetClicked = false;
        return (
            <>
                <TextField 
                    label="Email" 
                    fullWidth
                    required
                    autoFocus
                    key="email"
                    name="email"
                    autoComplete="off"
                    value={ email }
                    onKeyDown={ e => e.key === "Enter" && this.onSendCodeClick() }
                    onChange={ e => this.setInput("email", e.target.value) }
                    variant="outlined"
                    error={ !!errors.email }
                    helperText={ errors.email || '' }/>

                <Button variant="contained"
                        size="large" 
                        onClick={ () => this.onSendCodeClick() }
                        children="Send code"/>
        
                <p className="secondary-action">
                    <a href="/#" 
                        onClick={ e => this.onBackToSigninClick(e) }>
                        Back to log in 
                    </a>
                </p>   
            </>
        )
    }

    codeView() {
        const { code, password, password2, errors = {} } = this.state;
        this.sendCodeClicked = false;

        return (
            <>
            <TextField label="Code" 
                fullWidth
                required
                autoFocus
                key="code"
                name="code"
                value={ code }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ e => this.setInput("code", e.target.value) }
                variant="outlined"
                autoComplete="off"
                error={ !!errors.code }
                helperText={ errors.code }/>

            <TextField label="New password" 
                fullWidth
                required
                key="password"
                name="password"
                value={ password }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ e => this.setInput("password", e.target.value) }
                type="password"
                variant="outlined"
                autoComplete="off"
                error={ !!errors.password }
                helperText={ errors.password }/>

            <TextField label="Confirm new password" 
                fullWidth
                required
                key="password2"
                name="password2"
                value={ password2 }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ e => this.setInput("password2", e.target.value) }
                type="password"
                variant="outlined"
                autoComplete="off"
                error={ !!errors.password2 }
                helperText={ errors.password2 }/>

            <div className="resend-link">
                <a href="/#" 
                    onClick={ e => this.onResendClick(e) }>
                    Resend code
                </a>
            </div>

            <Button variant="contained"
                    size="large"
                    onClick={ () => this.onSubmitClick() }
                    children="Submit"/>
            <p className="secondary-action">
                <a href="/#" 
                    onClick={ e => this.onBackToSigninClick(e) }>
                    Back to log in 
                </a>
            </p>
            </>
        )
    }

    render() {

        const { loading, delivery } = this.state;
        const cls = ["form-forgot-password"];
        const { authData = {} } = this.props;

        loading && cls.push("hidden");

        return (
            <>
            { loading && <Loader className="page-loading" size={ 116 }/> }
            <div className={ cls.join(" ") }> 
                <h1>Reset your password</h1>

                { delivery || authData.email ? 
                    this.codeView() :
                    this.emailView() }
            </div>
            </>
        )
    }
}

export default ForgotPasswordForm