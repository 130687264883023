import React, { useMemo, useState, useCallback, useContext, useEffect } from "react"
import Card from "../card/Card"
import cf from "common/src/lib/format/currency"
import { getLayoutClass, getGridStyle, getImageProps, getSlotClass } 
                        from "common/src/lib/look/layout2style"
import s3url from "common/src/lib/image/s3url"
import getRetailerName from "common/src/lib/look/url2retailer"

import { CrossPlatformContext } from "common/src/cross-platform/Provider"

import { saveProduct, unsaveProduct } from "common/src/actions/save"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import user from "common/src/user"
import hub from "common/src/hub"


let xcmp = null;

function getXcmp(context) {
    if (!xcmp) {
        xcmp = context.provideComponent([
            "text", "container", "loader", "image",
            "icon/zoom","icon/close", "icon/back", 
            "icon/heart", "icon/heart-active",
            "icon/basket", "icon/basket-active"
        ]);
    }
    return xcmp;
}

function getAffiliateName(p) {
    let retailer = p.retailer || p.url;
    return getRetailerName(retailer);
}

function LookProductCardSize({ size, className }) {

    const context = useContext(CrossPlatformContext);
    const Container = useMemo(() => getXcmp(context).container, []);
    const cls = useMemo(
        () => {
            const cls = ["product-card-size", `product-card-size-${ size.availability }`];
            className && cls.push(className + '-size');
            return cls.join(" ");
        },
        [ size.availability, className ]
    );
    const content = useMemo(() => size.size === '-' ? 'ONE SIZE' : size.size, [ size.size ]);

    return (
        <Container 
            cmp="li" 
            className={ cls }
            children={ content }/>
    )
}

function LookProductCardSizes({ product, className, showEmpty = false }) {

    const context = useContext(CrossPlatformContext);
    const Container = useMemo(() => getXcmp(context).container, []);

    const sizes = useMemo(
        () => {
            return product.stock ? 
                    product.stock.sizes : 
                    (product.sizes && product.sizes.length) ? 
                        product.sizes : 
                        [];
        },
        [ product.id ]
    );

    const sizesCls = useMemo(
        () => {
            const cls = [ "product-card-sizes" ];
            if (sizes && sizes.length) {
                for (let i = 1; i <= sizes.length; i++) {
                    cls.push(`product-card-sizes-${i}`);
                }
            }
            className && cls.push(className + "-sizes");
            return cls.join(" ");
        },
        [ sizes, className ]
    );

    if (sizes.length === 0 && !showEmpty) {
        return null;
    }

    return (
        <Container cmp="ol" className={ sizesCls }>
        { sizes.map(s => <LookProductCardSize size={ s } key={ s.size } className={ className }/> )}
        </Container>
    )       
}

function LookProductCardImageSlider({ product, currentImage }) {
    
    const context = useContext(CrossPlatformContext);
    const Container = useMemo(() => getXcmp(context).container, []);

    const a = useMemo(
        () => {
            const a = new Array(Math.min(5, product.images.length));
            a.fill(true);
            return a;
        },
        [ product.images.length, product.id ]
    );

    return (
        <Container cmp="ul" className="product-card-images-position">
            { a.map((v, i) => 
                <Container 
                    cmp="li" 
                    key={ i } 
                    className={ currentImage === i ? 
                                "product-card-images-current" : 
                                "product-card-images-other" }/>) }
        </Container>
    )
}

function LookProductCardFooter(props) {

    const { product, look = {}, biggestSlot = false } = props;
    const { productMode = "multiple" } = look;

    const context = useContext(CrossPlatformContext);
    const { Container, Text } = useMemo(
        () => {
            const cmp = getXcmp(context);
            return {
                Container: cmp["container"],
                Text: cmp["text"]
            }
        }
    );

    const { convCls, price, salePrice, currency, availability } = useMemo(
        () => {
            const 
                p = product,
                stock = p.stock || {},
                convCls = (stock ? stock.currencyConverted : p.currencyConverted) ? 
                            'product-card-price-converted' : '',
                price = stock.price || p.price,
                salePrice = stock.salePrice || p.salePrice,
                currency = stock.currency || p.currency,
                availability = stock.availability || p.availability;

            return { convCls, price, salePrice, currency, availability };
        },
        [ product.id, product.stock, product.price, product.currency, product.salePrice ]
    );

    const brand = useMemo(() => product.designers.map(d => d.name).join(", "), [ product.id, product.designers ]);
    const isSale = useMemo(() => !!salePrice && salePrice !== price, [ price, currency, salePrice ]);
    const isSold = useMemo(() => availability === 'out-of-stock', [ availability ]);

    const line1cls = useMemo(
        () => {
            return [ "product-card-footer-line-1", 
                        props.look ? "look-card-product-footer-line-1" : "" ].join(" ")
        },
        [ !!props.look ]
    );

    const line2cls = useMemo(
        () => {
            return [ "product-card-footer-line-2", 
                        props.look ? "look-card-product-footer-line-2" : "" ].join(" ");
        },
        [ !!props.look ]
    );

    const brandCls = useMemo(
        () => {
            return ["product-card-brand", 
                        isSale ? "product-card-brand-with-sale" : ""].join(" ")
        },
        [ isSale ]
    );

    const soldoutCls = useMemo(
        () => `product-card-price product-card-price-sold-out ${ convCls }`,
        [ convCls ]
    );

    const oldPriceCls = useMemo(
        () => `product-card-price product-card-price-old-new ${ convCls }`,
        [ convCls ]
    );

    const priceCls = useMemo(
        () => `product-card-price ${ convCls }`,
        [ convCls ]
    );

    const nativeProps = useMemo(() => ({ numberOfLines: 1 }), []);
    const priceStr = useMemo(() => cf(price, currency), [ price, currency ]);
    const salePriceStr = useMemo(() => cf(salePrice, currency), [ salePrice, currency ]);

    if (productMode === "single" && !biggestSlot) {
        return null;
    }

    return (
        <>
        <Container className={ line1cls }>
        { !!brand && 
            <Text cmp="p" 
                nativeProps={ nativeProps }
                className={ brandCls }
                children={ brand }/> }

        { isSold && 
            <Text cmp="p" 
                nativeProps={ nativeProps }
                className={ soldoutCls }
                children={ priceStr }/> }

        { !isSold && 
            <>
            { isSale &&
                <Text cmp="p" nativeProps={ nativeProps } className={ oldPriceCls }>
                    <Text cmp="span" className="product-card-price-old">
                        { priceStr }
                    </Text>
                    <Text cmp="span">&nbsp;</Text>
                    <Text cmp="span" className="product-card-price-discounted">
                        { salePriceStr }
                    </Text>
                </Text> }
            { (!!price && !salePrice) &&
                <Text cmp="p" nativeProps={ nativeProps } className={ priceCls }>
                    { priceStr }
                </Text> }
            </> }
        </Container>
        <Container className={ line2cls }>
            <Text cmp="p" 
                className="product-card-name" 
                nativeProps={ nativeProps }
                children={ product.name }/>
        </Container>
        </>
    )
}


function LookProductCard(props) {

    const { product, look, onEvent, slotIndex, getImageContainerSize, children } = props;

    const context = useContext(CrossPlatformContext);
    const { Container, Loader, Image, 
            IconZoom, IconClose, IconBack, 
            IconHeart, IconHeartActive } = useMemo(
        () => {
            const cmp = getXcmp(context);
            return {
                Container: cmp["container"],
                Loader: cmp["loader"],
                Image: cmp["image"],
                IconZoom: cmp["icon/zoom"],
                IconClose: cmp["icon/close"],
                IconBack: cmp["icon/back"],
                IconHeart: cmp["icon/heart"],
                IconHeartActive: cmp["icon/heart-active"],
                //IconBasket: cmp["icon/basket"],
                //IconBasketActive: cmp["icon/basket-active"]
            }
        },
        []
    );

    const [ currentImage, setCurrentImage ] = useState(props.override?.currentImage || 0);
    const [ width, setWidth ] = useState(null);
    const [ height, setHeight ] = useState(null);
    const [ saving, setSaving ] = useState(false);
    const [ zoomed, setZoomed ] = useState(false);

    const onNativeLayoutChange = useCallback(
        (l) => {
            setWidth(l.width);
            setHeight(l.height);
        },
        []
    );

    const imContainerSize = useMemo(
        () => getImageContainerSize ? getImageContainerSize() : null,
        [getImageContainerSize]
    );

    const onSaveProduct = useCallback(
        async () => {
            const saved = product.saved;

            if (!user.loggedIn()) {
                hub.dispatch("app", "redir", getUrl(routes.signin))
                return;
            }

            setSaving(true);

            if (saved) {
                await unsaveProduct(product.id);
            }
            else {
                await saveProduct(product.id);
            }

            setSaving(false);
        },
        [ product.saved, product.id ]
    );

    const onAction = useCallback(
        (_, action) => {

            const actionState = props.actionState || {};
            const z = actionState.zoomed !== undefined ? 
                                actionState.zoomed :
                                zoomed;

            switch (action.type) {
                case "preview": {
                    const previewData = {
                        id: product.id,
                        slotIndex: props.slotIndex,
                        ...props.eventData
                    };

                    if (z) {
                        if (actionState.zoomed === undefined) {
                            setZoomed(false);
                        }
                        onEvent && onEvent("preview-close", previewData);
                    }
                    else {
                        if (actionState.zoomed === undefined) {
                            setZoomed(true);
                        }
                        onEvent && onEvent("preview", previewData);
                    }

                    break;
                }
                case "save": {
                    onSaveProduct();
                    break;
                }
                case "left": {
                    let prev = currentImage - 1;
                    if (prev < 0) {
                        prev = product.images.length - 1;
                    }
                    setCurrentImage(prev);
                    break;
                }
                case "right": {
                    let next = currentImage + 1;
                    if (next > product.images.length - 1) {
                        next = 0;
                    }
                    setCurrentImage(next);
                    break;
                }
            }
        },
        [ currentImage, product.id, props.actionState, props.eventData, props.slotIndex, onSaveProduct ]
    );

    const actions = useMemo(
        () => {
            const { actionState = {}, look = {}, override = {} } = props,
                  { productMode = "multiple" } = look,
                    a = props.actions || [],
                    images = product.images,
                    _saving = actionState.saving !== undefined ?
                                actionState.saving :
                                saving,
                    _zoomed = actionState.zoomed !== undefined ? 
                                actionState.zoomed :
                                zoomed;

            let enableImageSlider = false;

            const actions = a
            .map(a => {
                let action;

                if (typeof a === "function") {
                    action = a(product);
                }
                else if (typeof a === "string") {
                    action = { type: a };
                }
                else {
                    action = a;
                }

                !action.cls && 
                    (action.cls = "product-card-action product-card-action-" + action.type);
                !action.onClick && (action.onClick = onAction);

                switch (action.type) {
                    case "images": {
                        enableImageSlider = (override.currentImage === undefined || 
                                            override.currentImage === null) &&
                                            productMode === "multiple";
                        return null;
                    }
                    case "preview": {
                        action.icon = <IconZoom
                                        className="icon icon-svg-fill icon-zoom product-card-icon-zoom"/>;
                        if (_zoomed) {
                            action.cls = "product-card-action product-card-action-close"
                            action.icon = 
                                <IconClose
                                    className="icon icon-svg-fill icon-close product-card-icon-close"/>;
                        }
                        break;
                    }
                    case "close": {
                        action.icon = 
                            <IconClose
                                className="icon icon-svg-fill icon-close product-card-icon-close"/>;
                        break;
                    }
                    case "save": {
                        if (product.saved) {
                            action.cls += ` product-card-action-${ action.type }-active`;
                        }
                        action.icon = 
                            _saving ? 
                                <Loader className="product-card-icon-heart-loading"/> :
                                product.saved ? 
                                    <IconHeartActive
                                        className="icon icon-svg-fill icon-heart-active product-card-icon-heart-active"/> : 
                                    <IconHeart
                                        className="icon icon-svg-fill icon-heart product-card-icon-heart"/>;
                        break;
                    }
                }

                return action;
            })
            .filter(a => !!a);

            if (enableImageSlider && images.length > 1) {

                actions.push({
                    type: "left",
                    cls: "product-card-action product-card-action-left",
                    onClick: onAction,
                    icon: <IconBack className="icon icon-svg-fill icon-back product-card-icon-left"/>
                });
                actions.push({
                    type: "right",
                    cls: "product-card-action product-card-action-right",
                    onClick: onAction,
                    icon: <IconBack className="icon icon-svg-fill icon-back product-card-icon-right"/>
                });
            }

            return actions;
        },
        [ props.look?.id, props.actionState?.zoomed, props.actionState?.saving, 
            saving, zoomed, props.actions, props.override?.currentImage, onAction ]
    );


    const layers = useMemo(
        () => {
            const l = props.layers || [];
            const images = product.cdnImages || product.images;
            const { override = {}, look = {} } = props;
            const { productMode = "multiple" } = look;

            const layers = l.map(l => {
                switch (l) {
                    case "sizes": {
                        return <LookProductCardSizes
                                    product={ product } 
                                    className={ props.className }
                                    showEmpty={ override?.emptySizes }/>
                    }
                    case "retailer": {
                        const retailer = getAffiliateName(product);
                        if (retailer) {
                            let cls = [ "product-card-retailer" ];
                            if (props.className) {
                                cls.push(props.className + "-retailer");
                            }
                            return (
                                <Container className={ cls.join(" ") }>{ retailer }</Container>
                            )
                        }
                        break;
                    }
                    case "images": {
                        if (images.length > 1 && 
                            productMode === "multiple" &&
                            (override.currentImage === undefined || override.currentImage === null)) {
                            return <LookProductCardImageSlider 
                                        product={ product } 
                                        currentImage={ currentImage }/>
                        }
                        break;
                    }
                    default: {
                        if (typeof l === "function") {
                            return l(product);
                        }
                        else if (l) {
                            return l;
                        }
                        break;
                    }
                }
                return null;
            })
            .filter(l => !!l)

            return layers;
        },
        [ currentImage, product.cdnImages, product.images, product.id, 
            props.override?.currentImage, props.layers,
            props.className ]
    );


    const blockCls = useMemo(
        () => {

            if (!look || slotIndex === undefined) {
                return "";
            }

            const { layouts = {}, productMode = "multiple" } = ( look || {} ),
                    template = layouts['template'] || [],
                    order = layouts['order'] || [],
                    inx = order.findIndex(id => id === product.id),
                    templateBlock = productMode === "multiple" ? 
                                        template[ inx ] :
                                        template[ slotIndex ];

            return getLayoutClass(template, templateBlock);
        },
        [ !!look, look, slotIndex, product.id ]
    );

    const slotClasses = useMemo(
        () => {
            const { layouts = {} } = look || {},
            template = layouts['template'],
            slotClass = getSlotClass(slotIndex, template);
            const base = !look ? "product-card" : "look-product-card";
            const slotCls1 = slotClass.map(c => base +"-"+ c).join(" ");
            const slotCls2 = props.className ? 
                                slotClass.map(c => props.className +"-" + c).join(" ") :
                                "";
            return [ slotCls1, slotCls2 ].join(" ");
        },
        [ !!look, props.className ]
    );

    const cls = useMemo(
        () => {
            const base = !look ? "product-card" : "look-product-card";

            return [ 
                base,
                product.saved ? "product-saved" : "",
                slotIndex !== undefined ? blockCls : "",
                props.className,
                slotClasses,
                Array.isArray(props.cls) ? props.cls.join(" ") : props.cls
            ].join(" ")
        },
        [ !!look, product.saved, slotIndex, props.cls, props.className, slotClasses ]
    );

    const footer = useMemo(
        () => {
            if (props.showFooter === false) {
                return null;
            }
            if (props.footer) {
                return props.footer;
            }
            return (
                <LookProductCardFooter 
                    className={ props.className }
                    product={ product } 
                    look={ look }
                    biggestSlot={ props.biggestSlot }/>
            )
        },
        [ props.showFooter, props.footer, product ]
    );

    const { slotStyle, imgProps } = useMemo(
        () =>  {

            if (!look) {

                return { slotStyle: null, imgProps: null };
            }

            const { override = {}, is1x1 } = props,
                  { layouts = {}, productMode = "multiple" } = look,
                    template = layouts['template'],
                    order = layouts['order'] || [],
                    inx = order.findIndex(id => id === product.id),
                    images = product.cdnImages || product.images,
                    templateBlock = productMode === "multiple" || is1x1 ? 
                                        template[ inx ] :
                                        template[ slotIndex ],
                    productImageStyles = layouts[ product.id ],
                    productImageStyle = productMode === "multiple" || is1x1 ?
                                            productImageStyles[ currentImage ] :
                                            productImageStyles[ slotIndex ],
                    bigSlot = is1x1 || props.bigSlot,

                    layoutConfig = Object.assign({}, templateBlock, override.layout || productImageStyle),
                    layoutOptions = { 
                        img: productMode === "multiple" || is1x1 ? 
                                images[ currentImage ] :
                                images[ slotIndex ], 
                        template,
                        imgSize: props.imageSize || {},
                        bigSlot,
                        slotIndex,
                        width, 
                        height
                    },

                    imgProps = getImageProps(layoutConfig, layoutOptions),
                    slotStyle = getGridStyle(layoutConfig, layoutOptions);

            //console.log("override", override.layout)
            //console.log("style", productImageStyle)
            return { slotStyle, imgProps };
        },
        [ !!look, currentImage, slotIndex, props.override, props.is1x1, look, 
            props.bigSlot, props.imageSize, width, height,
            product, product.images, product.cdnImages ]
    );

    const standaloneStyle = useMemo(
        () => {

            if (look) {
                return null;
            }

            const   images = product.cdnImages || product.images,
                    image = images[ currentImage ],
                    imageSize = props.imageSize || {},
                    size = imageSize.big,
                    src = image ? 
                            (image.bgPreview ? 
                                `data:image/png;base64,${ image.bgPreview }` :
                                s3url( image.key || image.src, size )) : 
                            null,
                    style = {
                        backgroundImage: `url(${ src })`,
                        ...imContainerSize
                    };

            return style;
        },
        [ !!look, currentImage, product.images, imContainerSize ]
    );

    const attrs = useMemo(
        () => {
            const attrs = { 
                "data-cy": "product-card",
                "data-product-id": product.id, 
                ...props.attrs 
            };
            if (!!look && slotIndex !== undefined) {
                attrs.style = { ...slotStyle, ...attrs.style };
            }
            else if (!!look && slotIndex === undefined) {
                attrs.style = standaloneStyle;
            }
            return attrs;
        },
        [ !!look, slotIndex, product.id, props.attrs, slotStyle, standaloneStyle ]
    );

    const cardProps = useMemo(
        () => {
            const cardProps = {
                ...props,
                cls, 
                attrs,
                actions, 
                layers, 
                footer,
                onNativeLayoutChange,
                footerCls: [
                        "product-card-footer", 
                        look ? "look-card-product-footer" : "",
                        props.className ? props.className + "-product-card-footer" : "",
                        props.footerCls || "" ].join(" ")
                
            };

            if (children) {
                cardProps.children = children;
            }
            else if (!look) {
                cardProps.children = (
                    <Container className="product-card-image" 
                            nativeProps={{ resizeMode: "contain", objectFit: "contain" }} 
                            style={ standaloneStyle }/>
                )
            }
            // this is native only
            else if (imgProps && imgProps.source) {
                const imgContainerStyle = { 
                    overflow: "hidden", 
                    resizeMode: "contain", 
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: -1,
                    ...imContainerSize
                };
                cardProps.children = (
                    <Container style={ imgContainerStyle } className={ slotClasses }>
                        <Image { ...imgProps }/>
                    </Container>
                );
            }

            if (props.link === true) {
                cardProps.url = product.clickUrl;
                cardProps.linkProps = {
                    target: "_blank",
                    rel: "noreferrer noopener"
                }
            }

            return cardProps;
        },
        [ product.id, !!look, cls, actions, layers, footer, 
            attrs, standaloneStyle, props.link, props.onClick,
            imgProps, slotClasses, imContainerSize, children ]
    );

    useEffect(
        () => setCurrentImage(props.override?.currentImage || 0),
        [ props.override?.currentImage ]
    );

    return (
        <Card { ...cardProps }/>
    )
}




export default LookProductCard