import moment from "moment"
import df from "common/src/lib/date/formats"
import User from "common/src/components/user/User"
import retailers from "common/src/lib/catalogue/retailers"
import getClickDomain from "common/src/lib/url/clickDomain"
import formatNumber from "common/src/lib/format/number"


const dateFormats = {
    "year": "YYYY",
    "month": "MMMM YYYY",
    "day": "MMM Do YY"
};

export const list = [
    { 
        id: "createdAt", 
        name: "Date", 
        modes: ["grid"],
        render: row => moment(row.createdAt).format(df.full) 
    },

    { 
        id: "createdDate", 
        name: "Date", 
        modes: ["csv"],
        render: row => moment(row.createdAt).format(df.date) 
    },
    { 
        id: "createdTime", 
        name: "Time", 
        modes: ["csv"],
        render: row => moment(row.createdAt).format(df.time) 
    },

    { 
        id: "on", 
        name: "On", 
        render: row => {
            if (row.moodboardId) {
                return "Moodboard"
            }
            if (row.product) {
                return "Styling session"
                //return row.product?.look?.consultationId ? "Styling session look" : "Look";
            }
            return row.catalogueProductId ? "Catalogue" : "" 
        }
    },
    { 
        id: "user", 
        name: "Clicked by", 
        render: row => row.user ? 
                        <User user={ row.user } passthruClick/> : 
                        row.isBot ? "BOT" : null,
        renderCsv: row => row.user ?
                            row.user.givenName + " " +
                            row.user.familyName :
                        row.isBot ? "BOT" : ""
    },
    { 
        id: "fri", 
        name: "Look created by", 
        render: row => row.product?.look?.fri ? 
                        <User user={ row.product.look.fri } passthruClick/> : null,
        renderCsv: row => row.product?.look?.fri ?
                            row.product.look.fri.givenName + " " +
                            row.product.look.fri.familyName :
                        ""
    },
    { 
        id: "contributor", 
        name: "Link created by", 
        render: row => row.referenceUser ? 
                        <User user={ row.referenceUser } passthruClick/> : null,
        renderCsv: row => row.referenceUser ?
                        row.referenceUser.givenName + " " +
                        row.referenceUser.familyName :
                    ""
    },
    { 
        id: "campaignId", 
        name: "Campaign", 
        render: row => row.campaignId || ""
    },

    {
        id: "sid",
        name: "SID",
        modes: ["details", "csv"],
        render: row => row.sid
    },

    {
        id: "targetUrl",
        name: "Target url",
        modes: ["details", "csv"],
        render: row => row.usedUrl
    },

    {
        id: "contributorId",
        name: "Link created by",
        modes: ["details", "csv"],
        render: row => row.referenceUser?.id
    },

    {
        id: "productId",
        name: "Product ID",
        modes: ["details", "csv"],
        render: row => row.link?.sku || row.catalogueProductId || row.product?.id || ""
    },

    {
        id: "product",
        name: "Product",
        modes: ["details", "csv"],
        render: row => row.product?.name || row.link?.name || ""
    },

    {
        id: "productUrl",
        name: "Product url",
        modes: ["details", "csv"],
        render: row => {
            const plink = row.link?.targetUrl;
            if (plink) {
                return <a href={ plink } children={ plink } target="_blank" rel="noreferrer noopener"/>
            }
            return "";
        },
        renderCsv: row => row.link?.targetUrl || null
    },

    {
        id: "trackableLink",
        name: "Trackable link",
        modes: ["grid", "details", "csv"],
        renderGrid: row => row.link?.hash,
        renderDetails: row => {
            if (row.link) {
                const clickDomain = getClickDomain({ retailer: row.link.retailer });
                const tlink = `https://${ clickDomain }/s/${ row.link.hash }`;
                return <a href={ tlink } children={ tlink } target="_blank" rel="noreferrer noopener"/>
            }
            return "";
        },
        renderCsv: row => {
            if (row.link) {
                const clickDomain = getClickDomain({ retailer: row.link.retailer });
                return `https://${ clickDomain }/s/${ row.link.hash }`;
            }
            return null;
        }
    },

    {
        id: "retailer",
        name: "Retailer",
        modes: ["details"],
        render: row => {
            if (row.link) {
                const retailer = retailers.find(r => r.id === row.link.retailer);
                return retailer ? retailer.name : row.link.retailer;
            }
            return "";
        }
    },

    {
        id: "brand",
        name: "Brand",
        modes: ["details", "csv"],
        render: row => row.link?.brand || ""
    },

    {
        id: "userAgent",
        name: "User agent",
        modes: ["details", "csv"]
    },

    {
        id: "userCountry",
        name: "User country",
        modes: ["details", "csv"]
    }
]


export const date = [
    { 
        id: "period", 
        name: "Period", 
        render: (row, _, ds) => {
            const { dateGroup } = ds.getCustomData();
            return moment(row.date_group).format(dateFormats[ dateGroup[0].id ]) 
        }
    },
    { 
        id: "cnt", 
        name: "Clicks", 
        render: row => formatNumber(row.cnt) 
    }
]

export const fri = [
    { 
        id: "fri", 
        name: "FRI", 
        render: row => <User user={ row.fri } passthruClick/>,
        renderCsv: row => row.fri.givenName + " " + row.fri.familyName
    },
    { 
        id: "cnt", 
        name: "Clicks", 
        render: row => formatNumber(row.cnt) 
    }
]


export const pse = [
    { 
        id: "pse", 
        name: "PSE", 
        render: row => row.pse ? <User user={ row.pse } passthruClick/> : null,
        renderCsv: row => row.pse ? row.pse.givenName + " " + row.pse.familyName : ""
    },
    { 
        id: "cnt", 
        name: "Clicks", 
        render: row => formatNumber(row.cnt) 
    }
]


export const contributor = [
    { 
        id: "contributor", 
        name: "Contributor", 
        render: row => <User user={ row.contributor } passthruClick/>,
        renderCsv: row => row.contributor.givenName + " " + row.contributor.familyName
    },
    { 
        id: "cnt", 
        name: "Clicks", 
        render: row => formatNumber(row.cnt) 
    }
]