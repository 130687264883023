import React from "react"
import moment from "moment"
import DateRangeDialog from "common/src/components/material/DateRange"
import { ReactComponent as IconExpand } from "common/src/svg/expand.svg"
import { ReactComponent as IconClear } from "common/src/svg/clear.svg"

import addListener from "common/src/lib/dom/addListener"
import removeListener from "common/src/lib/dom/removeListener"

import async from "common/src/lib/js/async"


class DateRangeSelector extends React.Component {

    state = {
        expanded: false,
        selection: [],
        loading: false,
        showDialog: false
    }

    _selectionHash = ""
    _isMounted = false

    constructor(props) {
        super(props);
        this.onSelectorOpen = this.onSelectorOpen.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.group) {
            hub.listen("tag-selector", "open", this.onSelectorOpen);
        }

        if (this.props.value) {
            let selection = Array.from(this.props.value || []);
            let selhash = selection.map(t => t ? (new Date(t)).getTime() : "" );
            selhash.sort();
            this._selectionHash = selhash.join(",");
            this.setState({ selection });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.props.group) {
            hub.remove("tag-selector", "open", this.onSelectorOpen);
        }
    }

    componentDidUpdate(prev) {
        
        const selection = Array.from(this.props.value || []);
        const vs = selection.map(t => t ? (new Date(t)).getTime() : "" );
        vs.sort();
        const selHash = vs.join(",");

        if (this._selectionHash !== selHash) {
            this._selectionHash = selHash;
            async(() => this.setState({ selection }));
        }
    }


    onSelectorOpen({ group, type }) {
        if (this.props.group === group &&
            this.props.type !== type &&
            this.state.expanded) {
            this.setState({ expanded: false })
        }
    }

    getSelectionState() {
        const selection = this.state.selection;
        const format = this.props.format || "MMM Do YYYY";
        if (selection.length === 0) {
            return this.props.textAll || "All time"
        }
        const names = selection.map(t => moment(t).format(format));
        return names.join(" - ");
    }

    onToggleClick(e) {
        e.preventDefault();
        this.toggle();
    }

    onClearClick(e) {
        e.preventDefault();
        this.clear();
    }

    toggle() {
        const expanded = this.state.expanded;
        this.setState({ expanded: !expanded });

        if (expanded) {
            addListener(document.body, "click", this.onBodyClick);
        }
        else {
            removeListener(document.body, "click", this.onBodyClick);
        }

        if (!expanded && this.props.group) {
            hub.dispatch("tag-selector", "open", {
                group: this.props.group,
                type: this.props.type
            })
        }
    }

    clear() {
        this.setState({ selection: [] });
        this.props.onChange && this.props.onChange([]);
    }

    setDateRange(selection) {

        if (selection[0] === null) {
            selection = [];
        }

        this.setState({ selection });
        this.props.onChange && this.props.onChange(selection);
        this.toggle();
    }

    render() {

        const { expanded, selection } = this.state;
        const { name = "", minDate, maxDate } = this.props;
        const cls = ["public-selector", 
                        "tag-selector-v2",
                        "public-selector-date",
                        this.props.className, 
                        expanded ? "expanded" : "",
                        selection.length ? "selection" : ""].concat(this.props.cls || []);
        const selectionState = this.getSelectionState();

        return (
            <div className={ cls.join(" ") }>
                <div className="tag-selector-header">
                    <a href="/#"
                        className="tag-selector-toggle"
                        onClick={ e => this.onToggleClick(e) }>
                        <label>{ name }</label>
                        <span>{ selectionState }</span>
                        <IconExpand />
                    </a>
                    <a href="/#" 
                        className="tag-selector-clear"
                        onClick={ e => this.onClearClick(e) }
                        children={ <IconClear/> }/>
                </div>
                { expanded && 
                    <DateRangeDialog 
                        onChange={ r => this.setDateRange(r) }
                        onCancel={ () => this.toggle() }
                        minDate={ minDate }
                        maxDate={ maxDate }
                        value={ selection }/> }
            </div>
        )
    }
}

export default DateRangeSelector
