import React from "react"
import { connect } from "react-redux"

import Button from "@mui/material/Button"
import Dialog from "common/src/components/dialog/Dialog"
import Loader from "common/src/components/Loader"
import Checkbox from "common/src/components/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

import { ui as ddUi } from "common/src/store/dialogs"
import api from "app/api"


class ExportTags extends React.Component {

    state = {
        exporting: false,
        types: ["Style", "Season", "Occasion", "Location", "Designer", "ProductStyle"],
        withCounts: false
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onShowHide(state) {
        if (state) {
            
        }
    }

    setExported(type, exported) {
        const types = this.state.types;
        const inx = types.indexOf(type);
        const ex = inx !== -1;
        if (exported && !ex) {
            types.push(type);
        }
        else if (!exported && ex) {
            types.splice(inx, 1);
        }
        this.setState({ types });
    }

    async export() {
        this.setState({ exporting: true })
        const types = this.state.types.join(",");
        const withCounts = this.state.withCounts;
        const qs = { types };

        if (withCounts) {
            qs.withCounts = 1;
        }

        const csv = await api.backend.get("tag/export", { queryStringParameters: qs })
                          .then(response => response.csv);

        const blob = new Blob([csv], { type: 'plain/text' })
        const burl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = burl;
        a.download = "tags.csv";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again   

        this.setState({ exporting: false })
    }

    render() {

        const { types, exporting, withCounts } = this.state;

        return (
            <Dialog name="tag-export" 
                    className="dialog-tag-export"
                    triggerMode="click"
                    closeOnBody={ false }
                    closeOnOverlay={ false }
                    onChange={ state => this.onShowHide(state) }>
                <h3>Export tags</h3>

                <FormControlLabel 
                    control={ <Checkbox color="primary" 
                                name="Style"
                                value="1"
                                checked={ types.indexOf("Style") !== -1 }
                                onChange={ (e) => this.setExported("Style", e.target.checked) }/>}
                    label="Outfit Styles"/>
                <FormControlLabel 
                    control={ <Checkbox color="primary" 
                                name="Season"
                                value="1"
                                checked={ types.indexOf("Season") !== -1 }
                                onChange={ (e) => this.setExported("Season", e.target.checked) }/>}
                    label="Style Forecast"/>
                <FormControlLabel 
                    control={ <Checkbox color="primary" 
                                name="Location"
                                value="1"
                                checked={ types.indexOf("Location") !== -1 }
                                onChange={ (e) => this.setExported("Location", e.target.checked) }/>}
                    label="Where to wear"/>
                <FormControlLabel 
                    control={ <Checkbox color="primary" 
                                name="Occasion"
                                value="1"
                                checked={ types.indexOf("Occasion") !== -1 }
                                onChange={ (e) => this.setExported("Occasion", e.target.checked) }/>}
                    label="Occasions"/>
                <FormControlLabel 
                    control={ <Checkbox color="primary" 
                                name="Designer"
                                value="1"
                                checked={ types.indexOf("Designer") !== -1 }
                                onChange={ (e) => this.setExported("Designer", e.target.checked) }/>}
                    label="Designers"/>
                <FormControlLabel 
                    control={ <Checkbox color="primary" 
                                name="ProductStyle"
                                value="1"
                                checked={ types.indexOf("ProductStyle") !== -1 }
                                onChange={ (e) => this.setExported("ProductStyle", e.target.checked) }/>}
                    label="Product Styles"/>

                <hr/>

                <FormControlLabel 
                    control={ <Checkbox color="primary" 
                                name="withCounts"
                                value="1"
                                checked={ withCounts }
                                onChange={ (e) => this.setState({ withCounts: e.target.checked }) }/>}
                    label="With counts"/>
                
                <Button
                    disabled={ types.length === 0 || exporting }
                    variant="contained"
                    children="Export"
                    startIcon={ exporting ? <Loader inline/> : null }
                    onClick={ () => this.export() }/>
                <Button
                    variant="outlined"
                    children="Cancel"
                    disabled={ exporting }
                    onClick={ () => this.props.dispatch(ddUi.hide("tag-export")) }/>
            </Dialog>
        )
    }
}

export default connect(state => state.tagsPage)(ExportTags)