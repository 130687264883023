

import api from "app/api"
import { lookLoader } from "common/src/actions/looks"
import downloadUrl from "common/src/lib/downloadUrl"


export const PER_PAGE = 50;

const idmap = (list) => {
    return list.map(item => {
        return typeof item === "string" ? item : item.id;
    });
}

const dateToTimestamp = (d, time) => {
    if (d.indexOf("T") === -1) {
        d += ' ' + time;
    }
    return d;
}

const setBodyDates = (body, dateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
        body.start = dateToTimestamp(dateRange.startDate, '00:00:00');
        body.end = dateToTimestamp(dateRange.endDate, '23:59:59');
    }
}

const setBodyIds = (body, key, list) => {
    if (list && list.length > 0) {
        body[key] = idmap(list);
    }
}

const getRequestBody = (filters) => {
    const body = {};
    
    setBodyDates(body, filters);
    setBodyIds(body, "campaigns", filters.campaigns);
    setBodyIds(body, "fris", filters.fris);
    setBodyIds(body, "contributors", filters.contributors);
    setBodyIds(body, "links", filters.links);
    setBodyIds(body, "friOrContributors", filters.pses);
    
    if (filters.pses && filters.pses.length > 0) {
        body.withFriOrContributor = true;
    }
    if (filters.withFri !== undefined && filters.withFri !== null) {
        body.withFri = filters.withFri;
    }
    if (filters.withContributor !== undefined && filters.withContributor !== null) {
        body.withContributor = filters.withContributor;
    }
    if (filters.withUser !== undefined && filters.withUser !== null) {
        body.withUser = filters.withUser;
    }
    if (filters.query) {
        body.query = filters.query;
    }
    if (filters.group && filters.group.length > 0) {
        body.group = filters.group[0].id;
    }

    return body;
}


export async function loadClickLog(filters, page) {

    const limit = PER_PAGE;
    const offset = page * PER_PAGE;
    const order = { createdAt: "desc" };
    const body = getRequestBody(filters);

    body.limit = limit;
    body.offset = offset;

    const { rows, data } = await api.reporting.post("/report/clicks/list", { body });

    const where = { id: { _in: rows }};
    const { count } = data;

    if (rows && rows.length > 0) {
        const clicks = await api.logProductClick.list({ where, order });
        const lookIds = clicks.map(c => c.product ? c.product.lookId : null).filter(id => !!id);
        
        if (lookIds.length > 0) {
            const friFields = "id avatar handle givenName familyName";
            const looks = await lookLoader({ friFields, limit, where: { id: { _in: lookIds }}});
            clicks.forEach(c => {
                if (c.product) {
                    c.product.look = looks.find(l => l.id === c.product.lookId);
                }
            })
        }

        return { clicks, count };
    }

    return { clicks: [], count: 0 };
}



export const loadClicksByFri = async (filters, csv = false) => {

    const body = getRequestBody(filters);
    body.csv = csv;

    const usrGraph = "id givenName familyName avatar handle"
    const response = await api.reporting.post("/report/clicks/byfri", { body });

    if (csv) {
        downloadUrl(response.url, "byfri.csv");
        return;
    }

    const { rows } = response;
    const friIds = rows.map(c => c.friId).filter((id, inx, self) => self.indexOf(id) === inx);
    const fris = await api.user.list({ where: { id: { _in: friIds }}}, usrGraph);
    
    return rows.map(row => {
        row.fri = fris.find(f => f.id === row.friId);
        return row;
    });
}


export const loadClicksByContributor = async (filters, csv = false) => {

    const body = getRequestBody(filters);
    body.csv = csv;


    const usrGraph = "id givenName familyName avatar handle"
    const response = await api.reporting.post("/report/clicks/bycontributor", { body });

    if (csv) {
        downloadUrl(response.url, "bycontributor.csv");
        return;
    }

    const { rows } = response;
    const infIds = rows.map(c => c.referenceUserId).filter((id, inx, self) => self.indexOf(id) === inx);
    const infs = await api.user.list({ where: { id: { _in: infIds }}}, usrGraph);
    
    return rows.map(row => {
        row.contributor = infs.find(f => f.id === row.referenceUserId);
        return row;
    });
}


export const loadClicksByPse = async (filters, csv = false) => {

    const body = getRequestBody(filters);
    body.csv = csv;

    const usrGraph = "id givenName familyName avatar handle"
    const response = await api.reporting.post("/report/clicks/byreceiver", { body });

    if (csv) {
        downloadUrl(response.url, "byfri.csv");
        return;
    }

    const { rows } = response;
    const userIds = rows.map(c => c.userId).filter((id, inx, self) => self.indexOf(id) === inx);
    const users = await api.user.list({ where: { id: { _in: userIds }}}, usrGraph);
    
    return rows.map(row => {
        row.pse = users.find(f => f.id === row.userId);
        return row;
    });
}



export const loadClicksByDate = async (filters, csv = false) => {
    const body = getRequestBody(filters);
    body.csv = csv;

    const response = await api.reporting.post("/report/clicks/bydate", { body });

    if (csv) {
        downloadUrl(response.url, "bydate.csv");
        return;
    }

    const { rows } = response;
    return rows;
}


export const loadClicksByLook = async (filters) => {

    const body = getRequestBody(filters);
    const { rows } = await api.reporting.post("/report/clicks/bylook", { body });
    const lookIds = rows.map(c => c.lookId).filter((id, inx, self) => self.indexOf(id) === inx);
    const looks = await lookLoader({ where: { id: { _in: lookIds }}});
    
    return rows.map(row => {
        const look = looks.find(l => l.id === row.lookId);
        if (look) {
            look.clicks = row.cnt;
            return look;
        }
        return null;
    })
    .filter(l => !!l);
}