import React from "react"
import { connect } from "react-redux"
import Selector from "../material/Autocomplete"

class CharacteristicSelector extends React.Component {

    state = {
        characteristics: []
    }

    onChange(characteristics) {
        this.setState({ characteristics });
        this.props.onChange && this.props.onChange(characteristics);
    }

    render() {
        const { characteristics } = this.state;
        return (
            <Selector 
                options={ this.props.characteristics }
                selection={ characteristics }
                onChange={ characteristics => this.onChange(characteristics) }/>
        )
    }
}

export default connect(state => ({ characteristics: state.catalogue.data.characteristics }))
                        (CharacteristicSelector)