import React, { useMemo, useContext } from "react"
import ProductCard from "common/src/components/look/ProductCard"
import normalizeCatalogueProduct from "common/src/lib/catalogue/normalizeCatalogueProduct";
import formatCurrency from "common/src/lib/format/currency"
import { CrossPlatformContext } from "common/src/cross-platform/Provider"


let xcmp = null;

function getXcmp(context) {
    if (!xcmp) {
        xcmp = context.provideComponent([ "text", "container" ]);
    }
    return xcmp;
}

function CatalogueProductCardFooter({ product }) {

    const salePrice = product.salePrice || 0;
    const price = product.price || 0;
    const currency = product.currency;
    const isSale = !!salePrice && salePrice !== price;
    const context = useContext(CrossPlatformContext);
    const { Container, Text } = useMemo(
        () => {
            const xcmp = getXcmp(context);
            const Text = xcmp["text"];
            const Container = xcmp["container"];
            return { Container, Text };
        },
        []
    );

    const brand = useMemo(
        () => product.designers ? 
                product.designers
                    .map(d => typeof d === "string" ? d : d.name)
                    .join(" ") :
                "",
        [ product.designers, product.uid ]
    );

    const salePriceStr = useMemo(
        () => formatCurrency(salePrice, currency), 
        [ salePrice, currency ]
    );
    const priceStr = useMemo(
        () => formatCurrency(price, currency), 
        [ price, currency ]
    );

    return (
        <>
        <Text cmp="p" 
            className="brand catalogue-product-card-brand" 
            nativeProps={{ numberOfLines: 1 }}>
            { brand }
        </Text>
        <Container cmp="p" 
            className="price catalogue-product-card-price" 
            nativeProps={{ numberOfLines: 1 }}>
            { isSale ?
                <>
                <Text cmp="span" className="catalogue-product-price-sale">
                    { salePriceStr }
                </Text>
                <Text cmp="span" className="catalogue-product-price-previous">
                    { priceStr }
                </Text>
                </>  :
                <Text cmp="span" className="catalogue-product-price-current">
                    { priceStr }
                </Text>
            }
            
        </Container>
        </>
    )
}

function CatalogueProductCard(props) {

    const cls = useMemo(
        () => [ "catalogue-product-card", props.cls, props.className ].join(" "), 
        [ props.cls, props.className ]
    );

    const p = useMemo(
        () => props.normalized ? props.product : normalizeCatalogueProduct(props.product),
        [ props.product, props.normalized ]
    );

    const footer = useMemo(
        () => props.footer || <CatalogueProductCardFooter product={ p }/>,
        [ p, props.footer ]
    );

    return (
        <ProductCard 
            { ...props }
            cls={ cls }
            product={ p }
            footerCls="catalogue-product-card-footer"
            footer={ footer }/>
    )
}


export default CatalogueProductCard