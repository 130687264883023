
const isMobileOrTablet = () => {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};


export default function(provider, url) {
    let surl;
    switch (provider) {
        case "facebook": {
            surl = "https://www.facebook.com/sharer/sharer.php?u=" +
                        encodeURIComponent(url);
            break;
        }
        case "twitter": {
            surl = "https://twitter.com/share?url=" + encodeURIComponent(url);
            break;
        }
        case "whatsapp": {
            surl = 'https://' +
                    (isMobileOrTablet() ? 'api' : 'web') +
                    '.whatsapp.com/send?text=' + encodeURIComponent(url);
            break;
        }
        default: {
            return null;
        }
    }
    return surl;
};