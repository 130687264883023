
import formatCurrency from "common/src/lib/format/currency"
import moment from "moment"
import df from "common/src/lib/date/formats"
import { getOrderItemStatusName } from "app/lib/orderStatus";


export function getProductRows(order) {

    const details = JSON.parse(order.details);

    return details.conversion_items.map(item => {

        return {
            order,
            sku: item.sku,
            name: item.meta_data.product || item.meta_data.name,
            brand: item.meta_data.item_product_group || item.meta_data.brand,
            quantity: item.quantity,
            // grossCount: item.grossItemsCount,
            // count: item.itemsCount,
            // cancelledCount: item.cancelledItemsCount,
            // details: details,
            saleAmount: item.item_status === "rejected" ? 0 : item.item_value,
            commissionAmount: item.item_status === "rejected" ? 0 : item.item_publisher_commission,
            currency: order.currency,
            status: item.item_status,
            item_status: item.item_status,
            rejectReason: item.reject_reason
        }
    })
}

export async function getProducts(comm) {
    const details = JSON.parse(comm.details);
    const currency = details.currency.toLowerCase();
    const cols = [
        { id: "name", name: "Product" },
        { id: "brand", name: "Brand" },
        { id: "sale", name: "Sale", render: i => formatCurrency(i.sale, currency, 2) },
        { id: "commission", name: "TF Commission", render: i => formatCurrency(i.commission, currency, 2) },
        { id: "item_status", name: "Status" },
        { id: "status", name: "TF Order status", render: i => getOrderItemStatusName(comm, i) },
        { id: "reject_reason", name: "Reject reason" }
    ];
    const rows = details.conversion_items.map(i => {
        return {
            name: i.meta_data.product || i.meta_data.name,
            brand: i.meta_data.item_product_group || i.meta_data.brand,
            sale: i.item_value,
            commission: i.item_publisher_commission,
            item_status: i.item_status,
            status: i.item_status,
            reject_reason: i.reject_reason
        };
    });

    return { cols, rows };
}


export function getStatusClass(comm) {
    switch (comm.status) {
        case "approved": {
            return "ok";
        }
        case "mixed": {
            return "okeish"; 
        }
        case "rejected": {
            return "notok";
        }
        case "pending": {
            return "pending";
        }
        default: {
            return "";
        }
    } 
}

export function hasHistory(order) {
    return order.snapshots && order.snapshots.length > 0;
}

export function getHistory(order) {
    const cols = [
        { id: "importedAt", name: "Import date",
            render: c => moment(c.importedAt).format(df.full) },
        { id: "status", name: "Status" }
    ]
    const rows = order.snapshots;

    return { cols, rows };
}

export function hasCorrections(order) {
    return false;
}