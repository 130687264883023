import React, { useCallback, useState, useEffect, useMemo } from "react"
import moment from "moment"

import DateRangeDialog from "common/src/components/material/DateRange"
import Loader from "common/src/components/Loader"
import Menu from "common/src/components/Menu"
import Table from "common/src/components/table/Table"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"

import useStateWithGetter from "common/src/hooks/useStateWithGetter"

import api from "app/api"
import downloadUrl from "common/src/lib/downloadUrl"
import async from "common/src/lib/js/async"


const dateToTimestamp = (d, time) => {
    if (d instanceof Date) {
        d = d.toISOString();
        d = d.split("T")[0];
    }
    if (d.indexOf("T") === -1) {
        d += ' ' + time;
    }
    return d;
}



function RawAnalytics() {

    const [ dateSelectorShown, setShowDateSelector ] = useState(false);
    const [ dateRange, setDateRange, getDateRange ] = useStateWithGetter([]);
    const [ loading, setLoading ] = useState(true);
    const [ generating, setGenerating ] = useState(false);
    const [ columns, setColumns ] = useState([]);
    const [ rows, setRows ] = useState([]);
    
    const loadReport = useCallback(
        () => {
            const body = {};
            const dateRange = getDateRange();
            if (dateRange[0] && dateRange[1]) {
                body.start = dateToTimestamp(dateRange[0], '00:00:00');
                body.end = dateToTimestamp(dateRange[1], '23:59:59');
            }
            setLoading(true);
            api.reporting.post("/report/raw/data", { body })
                        .then(resp => {
                            setColumns(resp.columns);
                            setRows(resp.rows);
                            setLoading(false);
                        });
        },
        [ getDateRange ]
    );

    const minDate = useMemo(() => new Date("2019-01-01"), []);

    const dateRangeLabel = useMemo(
        () => {
            if (!dateRange || !dateRange[0]) {
                return "Set date range";
            }
            const names = dateRange.map(t => moment(t).format("MMM Do YYYY"));
            return names.join(" - ");
        },
        [ dateRange ]
    );

    const hideDateSelector = useCallback(() => setShowDateSelector(false), []);
    const showDateSelector = useCallback(
        () => {
            setShowDateSelector(!dateSelectorShown)
        }, 
        [ dateSelectorShown ]
    );

    const applyDateRange = useCallback(
        (dateRange) => {
            setDateRange(dateRange);
            setShowDateSelector(false);
            async(() => loadReport());
        },
        [ loadReport, setDateRange ]
    );

    const onGenerateClick = useCallback(
        async () => {
            const body = {};
            const dateRange = getDateRange();
            if (dateRange[0] && dateRange[1]) {
                body.start = dateToTimestamp(dateRange[0], '00:00:00');
                body.end = dateToTimestamp(dateRange[1], '23:59:59');
            }
            setGenerating(true);
            const res = await api.reporting.post("/report/raw/csv", { body });
            setGenerating(false);
            downloadUrl(res.url, "raw-report.csv");
        },
        [ getDateRange ]
    );

    const menu = useMemo(
        () => {
            return [
                {
                    name: dateRangeLabel,
                    onClick: showDateSelector,
                    extraChildren: 
                        dateSelectorShown ? 
                            <DateRangeDialog
                                onChange={ applyDateRange }
                                onCancel={ hideDateSelector }
                                minDate={ minDate }
                                value={ dateRange }/> :
                            null
                },
                generating ? 
                    {
                        icon: <Loader/>
                    } :
                    {
                        className: "menu-download",
                        name: "Generate csv",
                        icon: <IconDownload className="icon icon-svg-fill icon-download"/>,
                        onClick: onGenerateClick
                    }
            ]
        },
        [ generating, onGenerateClick, dateRangeLabel, applyDateRange, 
            dateRange, minDate, hideDateSelector, showDateSelector, dateSelectorShown ]
    );


    useEffect(
        () => { loadReport() },
        [loadReport]
    );

    return (
        <div className="page page-raw-report">
            <div className="toolbar">
                <div className="toolbar-title">
                    Raw analytics
                </div>
                <Menu menu={menu} className="right" />
            </div>
            <div className="grid-container">
                <Table 
                    variant="slim"
                    cols={ columns } 
                    rows={ rows }
                    className={ loading ? "grid-loading" : "" }/>
            </div>
            { loading && <Loader/> }
        </div>
    )
}

export default RawAnalytics