import React from "react"
import { search, createTemporaryTag } from "common/src/actions/tags"
import Autocomplete from "common/src/components/material/Autocomplete"
import user from "common/src/user"
import throttle from "lodash/throttle"
import api from "app/api"


class TagSelector extends React.Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.searchTime = null;
        this.state = {
            tags: [],
            loading: false,
            lastQuery: ''
        }
        this.search = throttle(this.search.bind(this), 1000, { trailing: true });
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSearchChange(value) {
        if (value && value.length > 1) {
            this.setState({ loading: true, lastQuery: value });
            this.searchTime = (new Date()).getTime();
            this.search(value, this.searchTime);
        }
        else {
            this.setState({     tags: [], 
                                loading: false, 
                                lastQuery: '' });
        }
    }

    search(value, time) {
    
        this.setState({loading: true});
        search(this.props.entity, value)
            .then(tags => {
                if (this._isMounted && 
                    time === this.searchTime && 
                    this.state.lastQuery) {
                    this.setState({ tags, loading: false });
                }
            })

    }

    onEnterKey(e) {
        const name = e.target.value.toLowerCase().trim();

        // this one needed when freeForm is disabled
        if (!user.is("Admin")) {
            const tag = this.state.tags.find(t => t.name.toLowerCase() === name);
            if (tag) {
                const value = this.props.value || [];
                if (!value.find(t => t.name.toLowerCase() === name)) {
                    const onChange = this.props.onChange;
                    onChange && onChange([].concat(value, [tag]));
                }
            }
        }
    }

    onCreate(name) {
        name = name.trim();

        const entity = this.props.entity;
        const tags = this.props.value || [];
        const lc = name.toLowerCase();
        const tag = tags.find(t => t.name.toLowerCase() === lc);

        if (tag) {
            return null;
        }

        return createTemporaryTag(entity, name)
    }

    onChange(value) {
        const onChange = this.props.onChange;

        value = value.map(item => {

            if (!item || item.length < 3) {
                return null;
            }

            // this one happens when freeForm is enabled
            if (typeof item === "string") {
                return this.onCreate(item);
            }
            return item;
        });
    
        value = value.filter(item => item !== null);

        onChange && onChange(value);
    }  

    async onOpen() {
        
        if (this.state.tags.length === 0 && 
            !this.state.lastQuery) {
            this.setState({ loading: true });
            const tags = await api[this.props.entity].list({ order: { name: "asc" }});
            this.setState({ tags, loading: false });
        }

        this.props.onOpen && this.props.onOpen();
    }

    onInputClick(e) {
        this.props.onInputClick && this.props.onInputClick(e);
    } 

    render() {

        const { entity, label, placeholder, value, disabled } = this.props;
        const { loading, tags } = this.state;

        return (
            <Autocomplete
                multiple={ true }
                freeSolo={ user.is("Admin") || entity === "location" }
                selection={ value }
                options={ tags }
                loading={ loading }
                disabled={ disabled }
                label={ label }
                placeholder={ placeholder }
                onChange={ (selected) => this.onChange(selected) }
                onCreate={ name => this.onCreate(name) }
                onOpen={ () => this.onOpen() }
                onInputClick={ (e) => this.onInputClick(e) }
                onSearchChange={ query => this.onSearchChange(query) }/>
        )
    }
}

export default TagSelector


/*

<Autocomplete
                className="tag-selector"
                autoComplete={ true }
                multiple={ true }
                freeSolo={ user.is("Admin") || entity === "location" }
                value={ this.props.value }
                options={ this.state.tags }
                loading={ loading }
                getOptionLabel={ opt => typeof opt === "string" ? opt : opt.name }
                onChange={ (e, selected) => this.onChange(selected) }
                onOpen={ () => this.onOpen() }
                closeIcon={ null }
                popupIcon={ <IconDown/> }
                forcePopupIcon
                renderTags={ (value, getTagProps) =>
                    value.map((tag, index) => (
                        <Chip variant="outlined" 
                            size="small"
                            label={ tag.name } 
                            deleteIcon={ <IconDelete className="icon icon-svg-fill"/> }
                            {...getTagProps({ index })} />
                    ))
                }
                renderInput={ params => {
                    if (!params.InputProps.startAdornment) {
                        params.InputProps.startAdornment = (
                            <InputAdornment position="start">
                                { loading ? <Loader/> : <IconSearch /> }
                            </InputAdornment>
                        )
                    }
                    return <TextField 
                        autoComplete="off"
                        {...params} 
                        label={ this.props.label }
                        variant="outlined" 
                        onClick={ (e) => this.onInputClick() }
                        placeholder={ this.props.placeholder }
                        onKeyDown={ e => e.key === "Enter" && this.onEnterKey(e) }
                        onChange={ e => this.onSearchChange(e.target.value) }
                        size="small"
                        fullWidth />
                }}>
            </Autocomplete>
            */