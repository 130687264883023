
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { ReactComponent as Logo } from "common/src/svg/logo.svg"


function Footer({ children }) {

    const theme = useSelector(s => s.app.ui.theme);
    const active = useMemo(() => theme === "light", [ theme ]);
    const year = useMemo(() => (new Date()).getFullYear(), []);
    const navCls = useMemo(
        () => [ "footer-nav", active ? "active" : "" ].join(" "),
        [ active ]
    );
    const logoCls = useMemo(
        () => [ "footer-logo", active ? "active" : "" ].join(" "),
        [ active ]
    );

    return (
        <footer className="footer">
            <section className={ navCls }>
                { children }
            </section>
            <section className={ logoCls }>
                <Logo/>
            </section>
            <section className="footer-copy">
                Copyright &copy; { year } The Floorr. The Floorr Ltd is registered in England and Wales
            </section>
        </footer>
    )
}

export default Footer
