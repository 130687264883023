

async function isAscii(value) {
    if (!value) {
        return true;
    }
    if (value.match(/[^\x00-\x7F]/)) {
        return false;
    }
    return true;
}

export default isAscii