import React from "react"
import Loader from "common/src/components/Loader"
import { Button, TextField } from "@mui/material"
import Pagination from "common/src/components/Pagination"
import Checkbox from "common/src/components/material/Checkbox"
import CategorySelector from "common/src/components/catalogue/CategorySelector"
import CharacteristicSelector from "common/src/components/catalogue/CharacteristicSelector"
import ProductDetails from "common/src/components/catalogue/ProductDialog"
import TagList from "common/src/components/tag/List"
import Products from "common/src/components/catalogue/products/PaginatedProducts"
import { ReactComponent as IconAdd } from "common/src/svg/plus.svg"
import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"
import { confirm } from "common/src/components/dialog/Alert"
import api from "app/api"
import hub from "common/src/hub"
import { loadCategories, loadCharacteristics } from "common/src/actions/catalogue"
import store from "app/store"
import { ui as ddUi } from "common/src/store/dialogs"
import NullForm from "common/src/components/NullForm"


const PER_PAGE = 50;

const getAssignDefaults = () => {
    return {
        assignToId: null,
        characteristics: [],
        showCharSelector: false
    }
}

const getAssignData = (data, leaf) => {
    return leaf ? data[leaf] : data;
}

class CatalogueCategoryQueuePage extends React.Component {

    state = {
        openId: null,
        loading: false,
        editId: null,
        assignId: null,
        createId: null,
        creating: false,
        name: null,
        synonyms: "",
        useLeafs: false,
        categories: [],
        selection: [],
        banning: [],
        assigning: [],
        ignoring: [],
        search: "",
        count: 0,
        page: 0,
        product: null,

        assign: {
            assignToId: null,
            showCharSelector: false,
            characteristics: []
        }
    }

    componentDidMount() {
        this.loadList();
        loadCategories();
        loadCharacteristics();
    }

    async loadList() {
        const { search, page } = this.state;
        this.setState({ loading: true });
        const order = { name: "asc" };
        const where = {};
        const limit = PER_PAGE;
        const offset = page * PER_PAGE;

        if (search) {
            where.name = { _ilike: `%${ search }%` }
        }

        const categories = await api.catalogueCategoryQueue
                    .list({ where, order, limit, offset })
                    .then(categories => categories.map(c => {
                        const leafReg = /\[([^\]]+)\]$/i;
                        const match = c.name.match(leafReg);
                        c.leafs = match ? match[1].split("|") : [];
                        return c;
                    }));

        const count = await api.catalogueCategoryQueue.count({ where }).then(r => r.count);
                
        this.setState({ categories, loading: false, count });
        
    }

    onPageChange(page) {
        this.setState({ page }, () => this.loadList());
    }

    search() {
        this.loadList();
    }


    toggleAllSelected(selected) {
        const { categories } = this.state;
        if (selected) {
            const selection = categories.map(c => c.id);
            this.setState({ selection });
        }
        else {
            this.setState({ selection: [] });
        }
    }

    toggleSelected(id, selected) {
        const { selection } = this.state;
        const inx = selection.indexOf(id);
        if (selected) {
            if (inx === -1) {
                selection.push(id);
                this.setState({ selection });
            }
        }
        else {
            if (inx !== -1) {
                selection.splice(inx, 1);
                this.setState({ selection });
            }
        }
    }

    toggleCharSelector(leaf, state) {
        const { assign } = this.state;
        if (leaf) {
            assign[leaf].showCharSelector = state;
        }
        else {
            assign.showCharSelector = state;
        }
        this.setState({ assign });
    }

    addCharacteristic(c, leaf) {
        const { assign, useLeafs } = this.state;
        const { characteristics = [] } = useLeafs && leaf ? assign[leaf] || {} : assign;
        const inx = characteristics.findIndex(c1 => c1.id === c.id);
        if (inx === -1) {
            characteristics.push(c);
            if (useLeafs && leaf) {
                assign[leaf].characteristics = characteristics;
                assign[leaf].showCharSelector = false;
            }
            else {
                assign.characteristics = characteristics;
                assign.showCharSelector = false;
            }
            this.setState({ assign });
        }
    }

    removeCharacteristic(c, leaf) {
        const { assign, useLeafs } = this.state;
        const { characteristics = [] } = useLeafs && leaf ? assign[leaf] || {} : assign;
        const inx = characteristics.findIndex(c1 => c1.id === c.id);
        if (inx !== -1) {
            characteristics.splice(inx, 1);
            if (useLeafs && leaf) {
                assign[leaf].characteristics = characteristics;
            }
            else {
                assign.characteristics = characteristics;
            }
            this.setState({ assign });
        }
    }

    setAssignTo(assignToId, leaf) {
        const { assign, useLeafs } = this.state;
        if (useLeafs && leaf) {
            assign[leaf].assignToId = assignToId;
        }
        else {
            assign.assignToId = assignToId;
        }
        this.setState({ assign });
    }

    showAssignForm(c, useLeafs = true) {
        let assign = {};
        if (c && useLeafs && c.leafs.length > 0) {
            c.leafs.forEach(l => {
                assign[l] = getAssignDefaults();
            });
        }
        else {
            assign = getAssignDefaults();
        }
        this.setState({ assign, assignId: c ? c.id : "selection", useLeafs });
    }

    async ban(queueId) {

        try {
            await confirm({
                title: "Ban feed category",
                message: "Are you sure?"
            });
        }
        catch (err) {
            return;
        }

        this.setState({ banning: Array.isArray(queueId) ? queueId: [ queueId ] });

        const categories = this.state.categories;
        const fcategories = Array.isArray(queueId) ?
                                queueId.map(id => categories.find(c => c.id === id)) :
                                [ categories.find(c => c.id === queueId) ];
        let i, l;

        for (i = 0, l = fcategories.length; i < l; i++) {
            await api.catalogueCategoryFeed.create({ 
                categoryId: null, 
                feedCategoryId: fcategories[i].feedCategoryId, 
                name: fcategories[i].name,
                ban: true
            });
            await api.catalogueCategoryQueue.remove(fcategories[i].id);
        }

        hub.dispatch("catalogue", "category-change");
        this.setState({ creating: false, createId: null, assignId: null, banning: [], selection: [] });
        this.loadList();
    }


    async ignore(queueId) {

        try {
            await confirm({
                title: "Ignore feed category",
                message: "Are you sure?"
            });
        }
        catch (err) {
            return;
        }

        this.setState({ ignoring: Array.isArray(queueId) ? queueId: [ queueId ] });

        const categories = this.state.categories;
        const fcategories = Array.isArray(queueId) ?
                                queueId.map(id => categories.find(c => c.id === id)) :
                                [ categories.find(c => c.id === queueId) ];
        let i, l;

        for (i = 0, l = fcategories.length; i < l; i++) {
            await api.catalogueCategoryFeed.create({ 
                categoryId: null, 
                feedCategoryId: fcategories[i].feedCategoryId, 
                name: fcategories[i].name,
                ignore: true
            });
            await api.catalogueCategoryQueue.remove(fcategories[i].id);
        }

        hub.dispatch("catalogue", "category-change");
        this.setState({ creating: false, createId: null, assignId: null, ignoring: [], selection: [] });
        this.loadList();
    }

    async assign() {
        const queueId = this.state.assignId === "selection" ? this.state.selection : this.state.assignId;
        const { categories, useLeafs, assign } = this.state;
        const queue = Array.isArray(queueId) ?
                                queueId.map(id => categories.find(c => c.id === id)) :
                                [ categories.find(c => c.id === queueId) ];

        this.setState({ assigning: queueId });

        if (!Array.isArray(queueId) && queue[0].leafs.length > 0 && useLeafs) {
            const c = queue[0];
            let i, l, j, jl;

            for (i = 0, l = c.leafs.length; i < l; i++) {
                let leaf = c.leafs[i];
                let characteristics = assign[leaf].characteristics;
                let categoryId = assign[leaf].assignToId;

                let feedId = await api.catalogueCategoryFeed.create({ 
                    categoryId, 
                    feedCategoryId: c.feedCategoryId, 
                    name: c.name,
                    leafCategory: leaf
                }).then(r => r.id);

                await api.catalogueCategoryQueue.remove(c.id);

                for (j = 0, jl = characteristics.length; j < jl; j++) {
                    await api.catalogueCategoryFeedCharacteristic.create({
                        categoryFeedId: feedId,
                        characteristicId: characteristics[j].id
                    }, "affected_rows");
                }
            }
        }
        else {

            let i, l, j, jl;
            let characteristics = assign.characteristics;
            let categoryId = assign.assignToId;

            for (i = 0, l = queue.length; i < l; i++) {

                let feedId = await api.catalogueCategoryFeed.create({ 
                    categoryId, 
                    feedCategoryId: queue[i].feedCategoryId, 
                    name: queue[i].name,
                    leafCategory: null
                }).then(r => r.id);

                await api.catalogueCategoryQueue.remove(queue[i].id);

                for (j = 0, jl = characteristics.length; j < jl; j++) {
                    await api.catalogueCategoryFeedCharacteristic.create({
                        categoryFeedId: feedId,
                        characteristicId: characteristics[j].id
                    }, "affected_rows");
                }
            }
        }

        hub.dispatch("catalogue", "category-change");
        this.setState({ assigning: [], creating: false, createId: null, assignId: null });
        this.loadList();
    }

    onProductClick(product) {
        store.dispatch(ddUi.show("product-details"));
        this.setState({ product });
    }

    openCategory(e, c) {
        const openId = this.state.openId;
        e.preventDefault();
        e.stopPropagation();

        if (openId === c.id) {
            this.setState({ openId: null });    
        }
        else {
            this.setState({ openId: c.id });
        }
    }

    renderInfo(c) {
        const { selection, assignId, banning, ignoring, openId } = this.state;
        const isBanning = banning.length === 1 && banning[0] === c.id;
        const isIgnoring = ignoring.length === 1 && ignoring[0] === c.id;
        const cls = ["page-catalogue-color"];

        openId === c.id && cls.push("active");

        return (
            <div className={ cls.join(" ") } key={ c.id }>
                
                <Checkbox 
                    onChange={ e => this.toggleSelected(c.id, e.target.checked) }
                    checked={ selection.indexOf(c.id) !== -1 }/>
                <div>
                    <h4>
                        { c.name }
                        <a href="/#" onClick={ e => this.openCategory(e, c) }>
                            <IconDown/>
                        </a> 
                    </h4>
                </div>

                { assignId === c.id && this.renderAssignForm(c) }

                <div className="actions">
                    <Button 
                        variant="contained" 
                        children="Assign"
                        disabled={ isBanning }
                        onClick={ () => this.showAssignForm(c) }/>
                    <Button 
                        variant="text" 
                        children="Ban"
                        disabled={ isBanning }
                        startIcon={ isBanning ? <Loader inline/> : null }
                        onClick={ () => this.ban(c.id) }/>
                    <Button 
                        variant="text" 
                        children="Ignore"
                        disabled={ isIgnoring }
                        startIcon={ isIgnoring ? <Loader inline/> : null }
                        onClick={ () => this.ignore(c.id) }/>
                </div>  

                { (c.id === openId) && 
                    <Products 
                        autoload
                        setName={ c.feedCategoryId }
                        perPage={ 16 }
                        feed_category_id={ c.feedCategoryId }
                        product={ p => ({ onClick: () => this.onProductClick(p) })}
                        emptyText="Currently, no products found"/>}
            </div> 
        )
    }

    renderAssignButtons(c, leaf) {
        let disableSave = false;
        const { useLeafs, assign, assigning } = this.state;
        const showSplit = c.leafs.length > 0;
        const isAssigning = assigning.indexOf(c.id) !== -1 ||
                            (Array.isArray(c.id) && c.id.length > 0 && c.id.length === assigning.length);

        if (!useLeafs || c.leafs.length === 0) {
            disableSave = !assign.assignToId;
        }
        else if (leaf) {
            disableSave = !getAssignData(assign, leaf).assignToId;
        }
        else if (c.leafs.length > 0) {
            let i, l;
            for (i = 0, l = c.leafs.length; i < l; i++) {
                if (!assign[c.leafs[i]].assignToId) {
                    disableSave = true;
                    break;
                }
            }
        }   

        return (
            <>
            <Button 
                variant="contained" 
                children="Save"
                disabled={ disableSave || assigning.length > 0 }
                startIcon={ isAssigning ? <Loader inline/> : null }
                onClick={ () => this.assign() }/>
            <Button 
                variant="text" 
                children="Cancel"
                onClick={ () => this.setState({ assignId: null }) }/>
            { showSplit && <Button 
                variant="text" 
                children={ useLeafs ? "Do not split" : "Split" }
                onClick={ () => this.showAssignForm(c, !useLeafs) }/> }
            </>
        )
    }

    renderSingleAssign(qc, leaf) {
        const { assign } = this.state;
        const { characteristics, showCharSelector } = getAssignData(assign, leaf);

        return (
            <div className="page-catalogue-category-leaf-assign" key={ leaf }>
                <div className="page-catalogue-color-assign">
                    { leaf && <h4>{ leaf }</h4> }
                    <CategorySelector 
                        onChange={ c => c && this.setAssignTo(c.id, leaf) }/>
                    { !leaf && this.renderAssignButtons(qc, leaf) }
                </div>
                <div className="page-catalogue-category-char-select">
                    { (showCharSelector === leaf || showCharSelector === true) ?
                        <>
                            <CharacteristicSelector 
                                onChange={ c => c && this.addCharacteristic(c, leaf) }/>
                            <Button 
                                variant="text" 
                                children="Cancel"
                                onClick={ () => this.toggleCharSelector(leaf, false) }/>
                        </> :
                        <a href="/#" onClick={ e => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.toggleCharSelector(leaf, true) }}> 
                            <span><IconAdd/></span> add characteristic
                        </a>
                    }
                </div>
                { characteristics.length > 0 && 
                        <TagList 
                            tags={ characteristics }
                            onDelete={ c => this.removeCharacteristic(c, leaf) }/>}
            </div>
        )
    }

    renderAssignForm(c) {
        const { useLeafs } = this.state;

        if (useLeafs && c.leafs.length > 0) {
            return (
                <div className="page-catalogue-category-leafs">
                    { c.leafs.map(l => this.renderSingleAssign(c, l))}
                    <div className="page-catalogue-category-leaf-buttons">
                        { this.renderAssignButtons(c) }
                    </div>
                </div>
            )
        }
        else {
            return this.renderSingleAssign(c, null);
        }
    }

    render() {
        const { loading, categories, selection, banning, ignoring,
                search, assignId, count, page, product } = this.state;

        const banningMultiple = banning.length > 1;
        const ignoringMultiple = ignoring.length > 1;
        const allSelected = categories.length === selection.length && categories.length > 0;
        const hasLeafs = !!selection.find(id => categories.find(c => c.id === id && c.leafs.length > 0));
            
        return (
            <>
            <div className="page page-catalogue-color-queue">
                { loading && <Loader size={ 64 }/> }
                <h2>Category queue</h2>
                <NullForm className="toolbar">
                    <TextField 
                        autoComplete="off"
                        fullWidth 
                        placeholder={ `Search (total: ${ count })`  }
                        variant="outlined"
                        value={ search }
                        onKeyDown={ e => e.key === "Enter" && this.search() }
                        onChange={ e => this.setState({ search: e.target.value }) }/>
                    <Button 
                        variant="contained" 
                        children="Search"
                        onClick={ () => this.search() }/>
                </NullForm>
                { selection.length > 0 &&
                    <div className="toolbar page-catalogue-color-queue-create">
                        <Checkbox 
                            onChange={ e => this.toggleAllSelected(e.target.checked) }
                            checked={ allSelected }/>
                        <label>Select all</label>
                        <Button 
                            variant="contained" 
                            children="Assign selection"
                            disabled={ hasLeafs || banningMultiple }
                            onClick={ () => this.showAssignForm() }/>
                        <Button 
                            variant="text" 
                            children="Ban"
                            disabled={ banningMultiple }
                            startIcon={ banningMultiple ? <Loader inline/> : null }
                            onClick={ () => this.ban(selection) }/>
                        <Button 
                            variant="text" 
                            children="Ignore"
                            disabled={ ignoringMultiple }
                            startIcon={ ignoringMultiple ? <Loader inline/> : null }
                            onClick={ () => this.ignore(selection) }/>

                        { assignId === "selection" && this.renderAssignForm({ 
                            name: '', 
                            id: selection,
                            leafs: [] }) }
                    </div> }
                { selection.length === 0 && 
                    <div className="toolbar page-catalogue-color-queue-create">
                        <Checkbox 
                            onChange={ e => this.toggleAllSelected(e.target.checked) }
                            checked={ allSelected }/>
                        <label>Select all</label>
                    </div> }
                { categories.map(c => this.renderInfo(c)) }

                <Pagination
                    page={ page }
                    count={ count }
                    perPage={ PER_PAGE }
                    onChange={ no => this.onPageChange(no) }/>

            </div>
            <ProductDetails 
                product={ product }/>
            </>
        )
    }
}

export default CatalogueCategoryQueuePage