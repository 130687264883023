import { useCallback, useEffect, useState, useMemo } from "react";
import { Button, TextField, Checkbox, FormControlLabel } from "@mui/material"
import moment from "moment";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import NullForm from "common/src/components/NullForm";
import useDictRef from "common/src/hooks/useDictRef";

import api from "app/api"
import Loader from "common/src/components/Loader";
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import { confirm } from "common/src/components/dialog/Alert"
import df from "common/src/lib/date/formats"


import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"

function BannerPreview({ banner }) {

    const calculateMaxWidth = () => {
        const windowWidth = window.innerWidth;
        const padding = windowWidth > 960 ? 40 : 20;
        return windowWidth - (padding * 2);
    };


    const [ width, setWidth ] = useState(calculateMaxWidth());
    const [ value, setValue ] = useState(calculateMaxWidth());

    useEffect(() => {
        const handleResize = () => {
            const newMaxWidth = calculateMaxWidth();
            setWidth(newMaxWidth);
            
            setWidth(prevWidth => {
                if (prevWidth > newMaxWidth) {
                    setValue(newMaxWidth);
                    return newMaxWidth;
                }
                return prevWidth;
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onWidthChange = useCallback(
        (e) => {
            setValue(e.target.value);
        },
        [ ]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                if (e.target.value) {
                    const maxWidth = calculateMaxWidth();
                    const newWidth = parseInt(e.target.value);
                    
                    if (newWidth > maxWidth) {
                        setWidth(maxWidth);
                        setValue(maxWidth);
                    } else {
                        setWidth(newWidth);
                    }
                } else {
                    setWidth(calculateMaxWidth());
                    setValue(calculateMaxWidth());
                }
                e.target.blur();
            }
        },
        []
    );

    return (
        <>
            <TextField onKeyDown={ onKeyDown } value={ value } onChange={ onWidthChange } label="Preview width" className="page-marketing-banner-preview-width" />
            <div className="page-marketing-banner-preview" style={{ width: width }}>
                <p>
                    { banner.text }
                </p>
                <IconClose/>
            </div>
        </>

    )
}


function Banner({ banner, onEdit, onRemoved }) {
    const [ open, setOpen ] = useState(false);
    const [ showForm, setShowForm ] = useState(false);

    const cls = useMemo(
        () => [ "page-catalogue-color page-marketing-banner", open ? "active" : "" ].join(" "),
        [ open ]
    );

    const onEditClick = useSwallowEventCallback(
        () => setShowForm(true),
        []
    );

    const onCancel = useCallback(
        () => setShowForm(false),
        []
    );

    const openBanner = useCallback(
        (e) => {
            e.preventDefault();
            setOpen(!open)
        },
        [ open ]
    );

    const onRemoveClick = useSwallowEventCallback(
        async () => {
            const res = await confirm({ message: "Are you sure?" });
            if (res) {
                await api.banner.remove(banner.id);
                onRemoved();
            }
        },
        // eslint-disable-next-line
        [ banner ]
    );

    if (showForm) {
        return (
            <BannerForm
                banner={ banner }
                onCreate={ onEdit }
                onClose={ onCancel }
            />
        );
    }

    return (
        <>
        <div className={ cls }>
            <div className="page-marketing-banner-content">
                <h4>
                    <span className={`page-marketing-banner-status ${ banner.active ? "active" : "" }`}></span>
                    { banner.text }
                    <a href="/#" onClick={ openBanner }>
                        <IconDown/>
                    </a> 
                </h4>
                <p>From { moment(banner.startDate).format(df.date)} to { moment(banner.endDate).format(df.date) }</p>
            </div>


            <div className="actions">
                <a href="/#" onClick={ onEditClick }>
                    <IconEdit/>
                </a>
                <a href="/#" onClick={ onRemoveClick }>
                    <IconDelete/>
                </a>
            </div>  
        </div>
        { (open) &&
            <BannerPreview banner={ banner }/> }
        </>
    )
}

function BannerForm({ banner, onClose, onCreate }) {
    const [ text, setText ] = useState(banner?.text || "");
    const [ isActive, setIsActive ] = useState(banner?.active || false);
    const [ startDate, setStartDate ] = useState(banner?.startDate || null);
    const [ endDate, setEndDate ] = useState(banner?.endDate || null);
    const [ creating, setCreating ] = useState(false);
    const ref = useDictRef({ banner, text, startDate, endDate, isActive });

    const create = useCallback(
        async () => {
            setCreating(true);

            if (ref.banner) {
                await api.banner.update(banner.id, { text: ref.text, active: ref.isActive, startDate: ref.startDate, endDate: ref.endDate });

                await api.userBanner.update({ bannerId: banner.id }, { countClosed: 0 });
            }
            else {
                await api.banner.create({ text: ref.text, active: ref.isActive, startDate: ref.startDate, endDate: ref.endDate });
            }

            setCreating(false);
            onCreate();
            onClose();
        },
        // eslint-disable-next-line
        []
    );

    const onTextChange = useCallback(
        (e) => setText(e.target.value),
        []
    );

    const onActiveChange = useCallback(
        (e) => setIsActive(e.target.checked),
        []
    );

    const onStartDateChange = useCallback(
        (e) => setStartDate(e),
        []
    );

    const onEndDateChange = useCallback(
        (e) => setEndDate(e),
        []
    );

    const onCancel = useCallback(
        () => { onClose() },
        [ onClose ]
    );

    return (
        <NullForm className="page-catalogue-color-form page-marketing-banner-form">
            <TextField
                autoComplete="off"
                variant="outlined"
                placeholder="Banner text"
                value={ text }
                onChange={ onTextChange }/>
            <LocalizationProvider dateAdapter={ AdapterDateFns }>
                <DatePicker
                    label="Start date"
                    value={ startDate }
                    onChange={ onStartDateChange }
                    renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                    label="End date"
                    value={ endDate }
                    onChange={ onEndDateChange }
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <FormControlLabel
                control={
                    <Checkbox checked={ isActive } onChange={ onActiveChange }/>
                }
                label="Active"
            />
            <Button
                variant="contained"
                children={ banner ? "Save" : "Create" }
                startIcon={ creating ? <Loader inline/> : null }
                disabled={ creating || !text || !startDate || !endDate }
                onClick={ create } />
            <Button 
                disabled={ creating }
                variant="text" 
                children="Cancel"
                onClick={ onCancel }/>
        </NullForm>
    )
}

function Banners() {

    const [ showNew, setShowNew ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ query, setQuery ] = useState("");
    const [ banners, setBanners ] = useState([]);

    const ref = useDictRef({ query, });

    const loadList = useCallback(
        async () => {
            setLoading(true);

            const where = {};
            const order = { startDate: "asc" };

            if (ref.query) {
                where.text = { _ilike: `%${ref.query}%` };
            }
            const banners = await api.banner.list({ where, order });

            setLoading(false);
            setBanners(banners);
        },
        // eslint-disable-next-line
        []
    )

    const onCreateNew = useCallback(
        () => { setShowNew(true) },
        []
    );

    const onNewClose = useCallback(
        () => setShowNew(false),
        []
    );

    const onSearchChange = useCallback(
        (e) => setQuery(e.target.value),
        []
    );

    const onSearchKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                loadList();
            }
        },
        // eslint-disable-next-line
        []
    );

    const reloadList = useCallback(
        () => {
            loadList();
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => { 
            loadList();
            try {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
            catch (err) {}
        },
        // eslint-disable-next-line
        [ ]
    );

    return (
        <>
            <div className="page page-catalogue-colors">
            { loading && <Loader size={ 64 }/> }
            <h2>Banners</h2>
            <NullForm className="toolbar">
                <Button
                    variant="contained"
                    children="Add banner"
                    onClick={ onCreateNew } />

                <div className="spacer-full"/>
                <TextField 
                    autoComplete="off"
                    placeholder="Search"
                    variant="outlined"
                    value={ query }
                    onKeyDown={ onSearchKeyDown }
                    onChange={ onSearchChange } />
                <Button 
                    variant="contained" 
                    children="Search"
                    onClick={ loadList } />
            </NullForm>
            { showNew && <BannerForm onClose={ onNewClose } onCreate={ reloadList } /> }
            { banners.map(banner => (
                <Banner key={ banner.id } banner={ banner } onEdit={ reloadList } onRemoved={ reloadList } />
            )) }
            </div>
        </>
    );
}

export default Banners;
