import React from "react"
import { Button } from "@mui/material"
import Contact from "./Contact"
import Loader from "common/src/components/Loader"
import { ReactComponent as IconEmail } from "common/src/svg/email.svg"
import { ReactComponent as IconWhatsapp } from "common/src/svg/whatsapp.svg"
import { ReactComponent as IconSms } from "common/src/svg/sms.svg"
import { saveContacts, removeContactType, reload, getUserContacts } from "common/src/actions/contacts"
import async from "common/src/lib/js/async"

const type2icon = {
    "email": IconEmail,
    "phone": IconSms,
    "whatsapp": IconWhatsapp
};

const type2add = {
    "phone": "Add sms number", 
    "whatsapp": "Add Whatsapp", 
    "email": "Add email"
}

class UserContacts extends React.Component {

    state = {
        loading: false,
        changedContacts: {},
        removedContacts: {},
        newContacts: {}
    }

    componentDidMount() {
        this.props.exportSave &&
            this.props.exportSave(() => this.save());
    }

    getContactList() {

        let contacts = getUserContacts(this.props.user);
        contacts = contacts.filter(c => !this.state.removedContacts[ c.type ]);
        Object.values(this.state.newContacts).forEach(c => contacts.push(c));

        return contacts;
    }

    onContactChange(c) {
        const { removedContacts, changedContacts } = this.state;
        if (removedContacts[ c.type ]) {
            removedContacts[ c.type ];
        }
        changedContacts[ c.type ] = c;
        this.setState({ changedContacts, removedContacts });
    }

    onContactRemove(c) {
        const { removedContacts, changedContacts, newContacts } = this.state;
        if (changedContacts[ c.type ]) {
            delete changedContacts[ c.type ];
        }
        if (newContacts[ c.type ]) {
            delete newContacts[ c.type ];
        }
        removedContacts[ c.type ] = c;
        this.setState({ changedContacts, removedContacts, newContacts });
    }

    onContactClose() {
        if (this.props.autoSave) {
            async(() => this.save());
        }
    }

    createContact(type) {
        const { newContacts } = this.state;
        newContacts[type] = {
            id: null,
            type,
            value: "",
            normalized: "",
            notifications: true
        }
        this.setState({ newContacts });
    }

    async getSaveData(cb) {
        const remove = Object.keys(this.state.removedContacts);
        const save = Object.values(this.state.changedContacts);
        
        await cb({ save, remove });
        this.setState({ 
            loading: false, 
            removedContacts: {}, 
            changedContacts: {},
            newContacts: {} 
        });
    }

    async save() {
        this.setState({ loading: true });

        const userId = this.props.userId || null;
        const toRemove = Object.keys(this.state.removedContacts);
        const toSave = Object.values(this.state.changedContacts);

        let i, l;

        for (i = 0, l = toRemove.length; i < l; i++) {
            await removeContactType(toRemove[i], userId);
        }

        if (toSave.length > 0) {
            await saveContacts(toSave, userId);
        }

        if (userId === null && (toSave.length > 0 || toRemove.length > 0)) {
            await reload();
        }

        this.setState({ 
            loading: false, 
            removedContacts: {}, 
            changedContacts: {},
            newContacts: {} 
        });
    }

    render() {

        const { showSave = true, autoSave = false } = this.props;
        const contacts = this.getContactList();
        const { loading } = this.state;
        const add = Object.keys(type2add)
                        .filter(t => !contacts.find(c => c.type === t))
                        .map(type => ({
                            type,
                            name: type2add[type],
                            icon: type2icon[type]
                        }));

        return (
            <div className="user-contacts">
                { contacts.map(c => 
                    <Contact 
                        key={ (c.id || "") + c.type }
                        disabled={ loading }
                        closeText={ autoSave ? "Save" : "Close" }
                        cancelText={ autoSave ? "Cancel" : null }
                        onChange={ c => this.onContactChange(c) }
                        onRemove={ c => this.onContactRemove(c) }
                        onClose={ c => this.onContactClose(c) }
                        contact={ c }/>)}

                { add.map(a => 
                    <a href="/#" key={ "add-" + a.type } 
                        className="user-contact-add"
                        onClick={ e => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.createContact(a.type);
                        }}>
                        <span className="user-contact-collapsed-tick" children={ React.createElement(a.icon) }/>
                        { a.name }
                    </a> )}

                { showSave && <Button 
                    variant="contained" 
                    children="Save"
                    disabled={ loading }
                    startIcon={ loading ? <Loader inline/> : null }
                    onClick={ () => this.save() }/> }
            </div>
        )
    }
}

export default UserContacts