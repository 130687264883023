import React from "react"
import s3url from "common/src/lib/image/s3url"


function Avatar({ user, imageSize = 80, renderAs }) {
    let Cmp = "span",
        style = {},
        rest = {};

    if (user) {

        if (user.avatar) {
            let url = s3url(user.avatar, imageSize);
            style.backgroundImage = "url(" + url + ")";
            rest.dangerouslySetInnerHTML = {
                __html: "&nbsp;"
            }
        }
        else {
            if (user.givenName && user.familyName) {
                rest.children = user.givenName[0].toUpperCase() + user.familyName[0].toUpperCase();
            }
            else {
                rest.dangerouslySetInnerHTML = {
                    __html: "&nbsp;"
                }
            }
        }
    }

    if (renderAs) {
        Cmp = renderAs;
    }

    return (
        <Cmp className="avatar" style={ style } { ...rest }/>
    )
}

export default Avatar