import React, { useMemo, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Loader from "common/src/components/Loader"
import ProductCard from "common/src/components/catalogue/ProductCard"

import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useProductFilters from "common/src/components/catalogue/products/useProductFilters"
import useDictRef from "common/src/hooks/useDictRef"
import { sets } from "common/src/store/catalogue"
import { catalogueLoader, getProductsBySet } from "common/src/actions/catalogue"


function ProductsListItem({ product, propsGetter }) {

    const props = useMemo(
        () => propsGetter ?
                (typeof propsGetter === "function") ? propsGetter(product) : { ...propsGetter } :
                {},
        [ propsGetter, product ]
    );

    return (
        <div className="catalogue-products-item">
            <ProductCard product={ product } { ...props }/>
        </div>
    )
}

function CatalogueProducts(props) {

    const { setName, 
            emptyText = "", 
            showLoading = true, 
            manual = false,
            autoload = false, 
            append = false,
            product } = props;

    const dispatch = useDispatch();
    const puids = useSelector(s => s.catalogue.sets.products[ setName ] || []);
    const loading = useSelector(
        s => s.catalogue.ui.products.misc[ setName ] ? 
                s.catalogue.ui.products.misc[ setName ].loading || false :
                true
    );
    const [ products, setProducts ] = useState([]);
    const { filters, hasResettingChanges } = useProductFilters(props);
    const cls = useMemo(
        () => ["catalogue-products", (loading && !append) ? "catalogue-products-loading" : "" ].join(" "),
        [ loading, append ]
    );

    const ref = useDictRef({ hasResettingChanges });

    async function loadProducts() {
        await catalogueLoader(filters);
        const products = getProductsBySet(setName);
        cbRef.onLoad && cbRef.onLoad(products);
    };

    const cbRef = useDictRef({
        onLoad: props.onLoad,
        onResettingChange: props.onResettingChange,
        loadProducts
    });

    useEffect(
        () => {
            if (!loading) {
                setProducts(getProductsBySet(setName));
            }
        },
        [ puids, loading, setName ]
    );

    useUpdateEffect(
        () => {
            if (!manual && autoload) {
                cbRef.loadProducts();
            }
        },
        [ manual, autoload ]
    );

    useEffect(
        () => {
            let reload = autoload && filters;

            if (ref.hasResettingChanges && cbRef.onResettingChange) {
                if (cbRef.onResettingChange() === false) {
                    reload = false;
                }
            }

            reload && cbRef.loadProducts();
        },
        [ filters ]
    );

    useEffect(
        () => {
            return () => {
                dispatch(sets.products.unset(setName));
            }
        },
        []
    );

    return (
        <div className={ cls }>
            { (loading && showLoading && products.length === 0) && <Loader/> }
            { products.map((p) => 
                <ProductsListItem key={ p.uid } product={ p } propsGetter={ product }/>
            )}
            { (products.length === 0 && emptyText && !loading) && 
                <div className="catalogue-products-empty">{ emptyText }</div> }
        </div>
    )
}

export default CatalogueProducts
