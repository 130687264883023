import moment from "moment"
import formatNumber from "common/src/lib/format/number"
import formatCurrency from "common/src/lib/format/currency"
import df from "common/src/lib/date/formats"
import User from "common/src/components/user/User"
import retailers from "common/src/lib/catalogue/retailers"


const dateFormats = {
    "year": "YYYY",
    "month": "MMMM YYYY",
    "day": "MMM Do YY"
};



const saleCols = [
    {
        id: "grossSaleAmount",
        name: "Gross Sales",
        modes: ["grid"],
        render: row => formatCurrency(row.grossSaleAmount || 0, "gbp", 2)
    },

    {
        id: "saleAmount",
        name: "Sales",
        modes: ["grid"],
        render: row => formatCurrency(row.saleAmount || 0, "gbp", 2)
    },

    {
        id: "grossCommissionAmount",
        name: "Gross commission",
        modes: ["grid"],
        render: row => formatCurrency(row.grossCommissionAmount || 0, "gbp", 2)
    },

    {
        id: "commissionAmount",
        name: "Commission",
        modes: ["grid"],
        render: row => formatCurrency(row.commissionAmount || 0, "gbp", 2)
    }
]


export const list = [
    { 
        id: "createdAt", 
        name: "Created", 
        modes: ["grid"],
        render: row => moment(row.createdAt).format(df.full) 
    },

    /*{ 
        id: "createdDate", 
        name: "Date", 
        modes: ["csv"],
        render: row => moment(row.createdAt).format(df.date) 
    },
    { 
        id: "createdTime", 
        name: "Time", 
        modes: ["csv"],
        render: row => moment(row.createdAt).format(df.time) 
    },*/

    { 
        id: "contributor", 
        name: "User", 
        render: row => row.contributor ? <User user={ row.contributor } passthruClick/> : null,
        renderCsv: row => row.contributor ? row.contributor.givenName + " " +
                                            row.contributor.familyName :
                                            ""
    },
    { 
        id: "product", 
        name: "Product", 
        render: row => row.lookProduct?.name || row.name || ""
    },
    { 
        id: "clicks", 
        name: "Clicks",
        render: row => formatNumber(row.clicks)
    },

    ...saleCols,

    {
        id: "productUrl",
        name: "Product url",
        modes: ["details", "csv"],
        render: row => <a href={ row.targetUrl } 
                            target="_blank" 
                            rel="noreferrer noopener"
                            children={ row.targetUrl }/>,
        renderCsv: row => row.targetUrl
    },

    {
        id: "hash",
        name: "Hash",
        modes: ["details", "csv"]
    },

    {
        id: "contributorId",
        name: "Contributor ID",
        modes: ["details", "csv"],
        render: row => row.contributor.id
    },

    {
        id: "productId",
        name: "Product ID",
        modes: ["details", "csv"],
        render: row => row.sku || row.catalogueProductId || row.lookProductId
    },

    {
        id: "retailer",
        name: "Retailer",
        modes: ["details", "csv"],
        render: row => {
            const retailer = retailers.find(r => r.id === row.retailer);
            return retailer?.name || row.retailer || "";
        }
    },

    {
        id: "brand",
        name: "Brand",
        modes: ["details", "csv"]
    },

    {
        id: "firstClick",
        name: "First click date",
        modes: ["details", "csv"],
        render: row => row.firstClickDate ? moment(row.firstClickDate).format(df.full) : ""
    },

    {
        id: "lastClick",
        name: "Last click date",
        modes: ["details", "csv"],
        render: row => row.lastClickDate ? moment(row.lastClickDate).format(df.full) : ""
    }
]


export const contributor = [
    {
        className: "grid-section-end grid-nowrap",
        id: "inf", 
        name: "Contributor", 
        render: row => row.contributor ? <User user={ row.contributor } passthruClick/> : null,
        renderCsv: row => row.contributor ?
                            row.contributor.givenName + " " + row.contributor.familyName :
                            ""
    },
    { 
        id: "links", 
        name: "Links",
        render: row => formatNumber(row.links)
    },
    { 
        id: "clicks", 
        name: "Clicks",
        render: row => formatNumber(row.clicks)
    },

    ...saleCols
]


export const date = [
    {
        id: "period", 
        name: "Period", 
        className: "grid-nowrap",
        render: (row, _, ds) => {
            const { dateGroup } = ds.getCustomData();
            return moment(row.date_group).format(dateFormats[ dateGroup[0].id ])
        }
    },
    { 
        id: "links", 
        name: "Links",
        render: row => formatNumber(row.links)
    },
    { 
        id: "clicks", 
        name: "Clicks",
        render: row => formatNumber(row.clicks)
    },

    ...saleCols
]