
import people from "./page/people"
import tags from "./page/tags"
import invitations from "./page/invitations"
import notifications from "common/src/listeners/notifications"
import webAuth from "common/src/listeners/web/auth"
import auth from "./auth"

const listeners = {
    init: function() {
        
        webAuth.init();
        people.init();
        tags.init();
        invitations.init();
        notifications.init();
        auth.init();
    }
}

export default listeners