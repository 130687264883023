import React, { useMemo } from "react"

import cls from "common/src/lib/className"

function Cell(props) {

    const { col, row, index, variant, dataStore,
            onCellClick, isCellClickable } = props;

    const clickable = useMemo(
        () => !!onCellClick && (!isCellClickable || isCellClickable(col, row)),
        [ onCellClick, isCellClickable, col, row ]
    );

    const cellCls = useMemo(
        () => cls([ col.className, clickable ? "clickable" : null, `grid-var-${ variant }` ]),
        [ col.className, variant, clickable ]
    );

    const content = useMemo(
        () => col.render ? col.render(row, index, dataStore) : row[col.dataKey || col.id],
        [ col, row ]
    );

    const handleClickEvent = useMemo(
        () => {
            return !clickable ? 
                    null : 
                    (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onCellClick && onCellClick(col, row);
                    }
        },
        [ clickable ]
    );

    return (
        <td className={ cellCls } onClick={ handleClickEvent }>
            { content }
        </td>
    )
}

export default Cell