
import singlePromise from "common/src/lib/js/singlePromise"
import prepareSearchQuery from "common/src/lib/prepareSearchQuery"

import { data, ui } from "app/store/page/tags"
import store from "app/store"
import api from "app/api"
import hub from "common/src/hub"
import settings from "app/settings"
import fuc from "common/src/lib/fuc"

const PER_PAGE = settings.tagsPerPage;


function loadTags(type, search = "", filter = "all", page = 0) {
    const   q = prepareSearchQuery(search),
            where = {},
            state = store.getState(),
            sortBy = state.tagsPage.ui[type+"s"].sortBy || "name",
            sortDir = state.tagsPage.ui[type+"s"].sortDir || "asc",
            order = {};

    order[sortBy] = sortDir;

    if (q) {
        where.name = { _ilike: "%" + q + "%" };
    }
    if (filter === "featured") {
        where.picked = { _eq: true };
    }
    else if (filter === "orphans") {
        if (["occasion", "location", "season", "style"].indexOf(type) !== -1) {
            where._not = { looks: {}};
        }
        if (type === "designer" || type === "productStyle") {
            where._not = { products: {}};
        }
    }

    let graph = `
        id 
        name
        path
        description
        picked
        lastUsed
    `

    if (type === "hiddenTag") {
        graph = `
            id name path description
            looks_aggregate { aggregate { count }}
        `
    }
    if (["style", "occasion", "location", "season"].indexOf(type) !== -1) {
        graph += "\nlooks_aggregate { aggregate { count }}"
    }
    if (["productStyle", "designer"].indexOf(type) !== -1) {
        graph += "\nproducts_aggregate { aggregate { count }}"
    }

    return api[type].list({
        where, 
        order, 
        offset: page * PER_PAGE,
        limit: PER_PAGE
    }, graph, true)
}

export let reloadTagList = function(type) {
    let state = store.getState(),
        page = state.tagsPage.ui[type+"s"].page,
        search = state.tagsPage.ui[type+"s"].search,
        filter = state.tagsPage.ui[type+"s"].filter;

    return loadTagList(type, search, filter, page, true);
}

export let loadTagList = singlePromise({
    mode: singlePromise.KEY_CANCEL,
    pre: (type, search = "", filter = "all", page = 0, force) => {
        let state = store.getState(),
            prevS = state.tagsPage.ui[type+"s"].search,
            prevP = state.tagsPage.ui[type+"s"].page,
            prevF = state.tagsPage.ui[type+"s"].filter,
            setup = {
                key: type + "-" + prepareSearchQuery(search),
                search,
                type,
                filter, 
                page
            };

        if (state.tagsPage.data[type+"s"].length > 0 && 
            prevP === page &&
            prevS === search &&
            prevF === filter) {
            if (!force) {
                setup.value = {
                    items: state.tagsPage.data[type+"s"],
                    count: state.tagsPage.ui[type+"s"].count,
                };
                setup.skip = true;
            }
        }

        return setup;
    },
    fn: (type, search, filter, page) => {
        store.dispatch(ui[type + "s"].loading(true));
        return loadTags(type, search, filter, page);
    },
    resolve: (response, setup, args) => {
        const type = setup.type;
        const items = response.items.map(t => {
            if (t.looks_aggregate) {
                t.looks = t.looks_aggregate.aggregate.count;
            }
            if (t.products_aggregate) {
                t.products = t.products_aggregate.aggregate.count;
            }
            return t;
        })
        store.dispatch(data[type+"s"].set(items));
        store.dispatch(ui[type + "s"].count(response.count));
        store.dispatch(ui[type + "s"].search(setup.search));
        store.dispatch(ui[type + "s"].page(setup.page));
        store.dispatch(ui[type + "s"].filter(setup.filter));
    },
    reject: (err, setup) => {
        let type = setup.type;
        hub.dispatch("error", type + "s-load-list", err);
    },
    always: (response, setup) => {
        let type = setup.type;
        store.dispatch(ui[type + "s"].loading(false));
    }
})




export function createTag(type, name) {

    const body = {};
    body[fuc(type)] = typeof name === "string" ? 
                        [{ name }] :
                        name.map(n => ({ name: n }));

    return api.backend.post("/tag/prepare", { body });
/*
    return api[type].list({
        where: {
            name: {
                _ilike: name
            }
        }
    })
    .then(tags => {
        if (tags.length === 1) {
            return tags[0];
        }
        return api[type].create({name}, 'returning { id name picked }')
    })
    .then(resp => {
        hub.dispatch(type, "created", resp.id);
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", type + "-create", err);
    });*/
}


export function deleteTag(type, id) {
    return api[type].remove(id)
    .then(resp => {
        hub.dispatch(type, "deleted", id);
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", type + "-delete", err);
    })
}

export function deleteTags(type, ids) {

    return api[type].remove({
        id: { _in: ids }
    })
    .then(() => {
        hub.dispatch(type, "deleted", ids);
        return ids;
    })
    .catch(err => {
        hub.dispatch("error", type + "-delete", err);
    });
}


export function update(type, id, prop, value) {

    const set = {};
    set[prop] = value;

    return api[type].update(id, set)
        .then(resp => {
            hub.dispatch(type, "updated", id);
            return resp;
        })
        .catch(err => {
            hub.dispatch("error", type + "-update", err);
        });
}

export function updateName(type, id, name) {

    return api[type].update(id, { name })
    .then(resp => {
        hub.dispatch(type, "updated", id);
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", type + "-update", err);
    })
}


export function merge(type, from, to) {

    return api.backend.post("/tag/merge", {
        body: {
            type: fuc(type),
            from,
            to
        }
    })
    .then(resp => {
        if (resp.success) {
            hub.dispatch(type, "merged", from);
        }
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", type + "-merge", err);
    });
}

export function setPicked(type, id, picked) {

    return api[type].update(
        {id: {_eq: id}},
        { picked }
    )
    .then(resp => {
        hub.dispatch(type, "picked", { id, picked });
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", type + "-set-picked", err);
    });
}