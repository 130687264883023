import React from "react"
import { connect } from "react-redux"

import Button from "@mui/material/Button"
import SideDialog from "common/src/components/dialog/SideDialog"
import Loader from "common/src/components/Loader"
import Dropzone from "common/src/components/Dropzone"

import addListener from "common/src/lib/dom/addListener"
import removeListener from "common/src/lib/dom/removeListener"
import getOuterHeight from "common/src/lib/dom/getOuterHeight"
import getOuterWidth from "common/src/lib/dom/getOuterWidth"

import { alert } from "common/src/components/dialog/Alert"
import { ui } from "common/src/store/user"
import * as actions from "common/src/actions/user"
import readInputFile from "common/src/lib/dom/readInputFile"
import api from "app/api"


class AvatarEditor extends React.Component {

    state = {}

    constructor(props) {
        super(props)
        
        this.startX = null;
        this.startY = null;
        this.offsetX = 0;
        this.offsetY = 0;
        this.lastPosition = null;
        this.imgVertical = false;
        this.imgData = null;
        this.imgMime = null;
        this.imgRatio = 1;
        this.imgRef = React.createRef();
        this.fullImg = null;
        if (props.current) {
            this.state = {
                showUpload: false,
                uploading: false
            }
        }
        
        this.state.imgStyle = {
            transform: "translate(-50%, -50%)"
        }

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    componentDidUpdate(prevProps) {
        let prevCurrent = prevProps.current || {},
            current = this.props.current || {};
        if (current.avatar !== prevCurrent.avatar) {
            this.setState({
                avatar: current.avatar,
                showUpload: false
            })
        }
    }

    componentWillUnmount() {
        if (this.fullImg) {
            document.body.removeChild(this.fullImg);
            this.imgRatio = 1;
        }
    }

    onClose() {
        this.props.dispatch(ui.form(null));
        this.setState({showUpload: false});
        this.imgData = null;
        this.imgMime = null;
        this.lastPosition = null;
        this.imgRatio = 1;
    }

    isImgSquare() {
        return Math.abs(1 - this.imgRatio) < 0.1;
    }

    isImgVertical() {
        return this.imgRatio < 1;
    }

    onInputChange(file) {

        if (this.fullImg) {
            document.body.removeChild(this.fullImg);
        }

        readInputFile(file)
        .then(({mime, data}) => {
            this.lastPosition = null;
            this.imgData = data;
            this.imgMime = mime;
            let img = this.fullImg = new Image();
            img.style.position = "absolute";
            img.style.right = "150%";
            img.style.top = 0;
            img.style.visibility = "hidden";
            img.style.transform = "translateX(-100%)";
            img.src = "data:" + mime + ";base64," + data;
            img.onload = () => {
                this.imgRatio = parseInt(img.width) / parseInt(img.height);
                this.setState({showUpload: true});
            }
            document.body.appendChild(img);
        });
    }

    onRemoveClick() {
        if (this.imgData) {
            this.lastPosition = null;
            this.imgData = null;
            this.imgMime = null;
            this.setState({showUpload: false});
        }
    }

    onSaveClick() {
        let s3key,
            vertical = this.isImgVertical(),
            square = this.isImgSquare(),
            { x, y } = this.getImgCoords(),
            ix, iy,
            img = this.imgRef.current,
            fullImg = this.fullImg,
            w = parseInt(img.width),
            h = parseInt(img.height),
            fw = parseInt(fullImg.width),
            fh = parseInt(fullImg.height),
            canvas = document.createElement('canvas'),
            context = canvas.getContext('2d'),
            imgData,
            size,
            csize = 1000;

        if (square) {
            size = fw;
            iy = 0;
            ix = 0;
        }
        else if (vertical) {
            size = fw;
            iy = Math.abs(y) - w/2; // position on visible image
            iy = Math.max(0, iy);
            iy = (fh * iy) / h; // position on full image
            iy = Math.min(iy, fh - size)
            ix = 0;
        }
        else {
            size = fh;
            ix = Math.abs(x) - h/2;
            ix = Math.max(0, ix);
            ix = (fw * ix) / w;
            ix = Math.min(ix, fw - size);
            iy = 0;
        }

        csize = Math.min(csize, size);

        canvas.width = csize;
        canvas.height = csize;
        context.fillStyle = "white";
        context.fillRect(0, 0, csize, csize);
        context.drawImage(fullImg, ix, iy, size, size, 0, 0, csize, csize);
        imgData = canvas.toDataURL('image/jpeg')
                        .replace(/^data:image\/.+;base64,/, '');


        this.setState({uploading: true});
        api.backend.post("/upload/avatar", {
            body: {
                contentType: "image/jpeg",
                data: imgData
            }
        })
        .then(resp => {
            if (resp.error) {
                return Promise.reject(resp.error);
            }
            return resp;
        })
        .then(resp => s3key = resp.key)
        .then(() => actions.removeAvatar())
        .then(() => actions.update({ avatar: s3key }))
        .then(() => {
            alert({
                title: "Your details have been updated.",
                message: "Thanks for keeping your details on The Floorr up to date."
            })
            this.onClose();
        })
        .finally(() => {
            this.setState({ uploading: false });
        })
        .catch(err => alert({
            title: "User update failed",
            message: err.message
        }))
    }

    getImgCoords() {
        let img = this.imgRef.current,
            dz = img.parentNode,
            dw = getOuterWidth(dz),
            dh = getOuterHeight(dz),
            w = parseInt(img.width),
            h = parseInt(img.height),
            vertical = this.isImgVertical(),
            square = this.isImgSquare(),
            x, y, center, extra,
            minX, minY,
            maxX, maxY;

        if (square) {
            x = "-50%";
            y = "-50%";
        }
        else if (vertical) {
            center = -h/2;
            extra = (h - dh) / 2;
            minY = center - extra;
            maxY = center + extra;
            y = this.lastPosition + this.offsetY;
            y = Math.max(y, minY);
            y = Math.min(y, maxY);
            x = "-50%";
        }
        else {
            center = -w/2;
            extra = (w - dw) / 2;
            minX = center - extra;
            maxX = center + extra;
            x = this.lastPosition + this.offsetX;
            x = Math.max(x, minX);
            x = Math.min(x, maxX);
            y = "-50%";
        }

        return {x, y}
    }

    setImgPosition() {
        let { x, y } = this.getImgCoords();
        typeof (x) === "number" && (x += "px");
        typeof (y) === "number" && (y += "px");
        let transform = `translate(${x}, ${y})`;
        this.setState({imgStyle: { transform }})
    }

    setInitialPosition() {
        this.lastPosition = this.getInitialPosition();
        this.setImgPosition();
    }

    getInitialPosition() {
        let img = this.imgRef.current,
            w = parseInt(img.width),
            h = parseInt(img.height),
            vertical = this.isImgVertical();
        return vertical ? -h/2 : -w/2;
    }

    onMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.isImgSquare()) {
            return;
        }

        this.startX = e.clientX;
        this.startY = e.clientY;
        this.offsetY = 0;
        this.offsetX = 0;

        if (this.lastPosition === null) {
            this.lastPosition = this.getInitialPosition();
        }

        addListener(document.body, "mousemove", this.onMouseMove);
        addListener(document.body, "mouseup", this.onMouseUp);
    }

    onMouseUp(e) {
        e.preventDefault();
        e.stopPropagation();

        let { x, y } = this.getImgCoords(),
            vertical = this.isImgVertical();
        
        this.lastPosition = vertical ? y : x;
        this.offsetX = 0;
        this.offsetY = 0;

        removeListener(document.body, "mousemove", this.onMouseMove);
        removeListener(document.body, "mouseup", this.onMouseUp);
    }

    onMouseMove(e) {
        this.offsetX = e.clientX - this.startX;
        this.offsetY = e.clientY - this.startY;
        this.setImgPosition();
    }

    render() {
        let show = this.props.ui.form === "avatar",
            saving = this.props.ui.saving,
            uploading = this.state.uploading,
            dataUrl,
            cls = this.isImgSquare() ? "square" : 
                    this.isImgVertical() ? "vertical" : "horizontal";

        if (this.imgData) {
            dataUrl = "data:" + this.imgMime + ";base64," + this.imgData;
        }

        return (
            <SideDialog title="Photo" 
                        show={ show }
                        className="dialog-user-details"
                        onClose={ () => this.onClose() }>
                <div className="dz-wrapper">
                    <Dropzone 
                        onChange={ file => this.onInputChange(file) }
                        useInputField={ !this.imgData }>
                        { this.state.showUpload ?
                            <img src={ dataUrl } 
                                ref={ this.imgRef }
                                className={ cls }
                                onLoad={ () => this.setInitialPosition() }
                                style={ this.state.imgStyle }
                                onMouseDown={ this.onMouseDown }
                                alt=""/> :
                            <p>Click or drop image file</p> }
                    </Dropzone>
                </div>
                <Button 
                    className="submit"
                    variant="contained" 
                    disabled={ saving || uploading || !this.imgData }
                    startIcon={ saving || uploading ? 
                                    <Loader inline/> : 
                                    null }
                    onClick={ () => this.onSaveClick() }
                    color="primary">Use photo</Button>
                <Button 
                    className="submit"
                    variant="outlined" 
                    disabled={ saving || uploading || !this.imgData }
                    onClick={ () => this.onRemoveClick() }
                    color="primary">Remove</Button>
            </SideDialog>
        )
    }
}

export default connect(state => state.user)(AvatarEditor)