import React from "react"
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from "common/src/components/material/Checkbox"
import { Button } from "@mui/material"
import Loader from "common/src/components/Loader"
import accessTree from "app/accessTree"


const hasInvalidKeys = (access) => {
    const found = [];

    Object.keys(access).forEach(key => {
        const parts = key.split("/");
        let tree = accessTree;
        parts.forEach(part => {
            if (part === "*" || !tree) {
                return;
            }
            let accessKey = tree.find(item => item.id === part);
            if (!accessKey) {
                found.push(key);
                return;
            }
            tree = accessKey.children;
        });
    })

    return found;
}

const checkFilter = (node, prefix, filters) => {

    if (!filters) {
        return true;
    }

    const path = [ prefix, node.id ].filter(i => !!i).join("/");
    for (let i = 0, l = filters.length; i < l; i++) {
        let filter = filters[i];

        if (filter === path || path.indexOf(filter + '/') === 0) {
            return true;
        }
        
        if (filter.indexOf(path) === 0) {
            return null;
        }
    }

    return false;
};

const filterByRole = (rootKey, roles) => {

    switch (rootKey) {
        case "admin": {
            return roles.indexOf("Admin") !== -1;
        }
        case "tools": {
            return roles.indexOf("Admin") !== -1 ||
                    roles.indexOf("FRI") !== -1 ||
                    roles.indexOf("GPS") !== -1 ||
                    roles.indexOf("Contributor") !== -1;
        }
        default: {
            return true;
        }
    }
};

const hasEnabledChildren = (children, prefix, access) => {

    let i, l;

    for (i = 0, l = children.length; i < l; i++) {
        let child = children[i];
        let key = child.id ? 
                        prefix ? 
                            `${ prefix }/${ child.id }` : 
                            child.id :
                        prefix;
        if (access[key] === true) {
            return true;
        }
        if (child.children && hasEnabledChildren(child.children, prefix, access)) {
            return true;
        }
    }

    return false;
};

class AccessTree extends React.Component {

    toggleKey(key) {
        const access = this.props.access || {};
        this.props.onChange && 
            this.props.onChange(key, !access[ key ]);
    }

    removeOldPermissions() {
        const access = this.props.access || {};
        const invalidKeys = hasInvalidKeys(access);
        this.props.onRemoveOldPermissions &&
            this.props.onRemoveOldPermissions(invalidKeys);
    }

    renderNode(node, prefix, parentEnabled = false, showDisabled = false) {

        const roles = this.props.roles;
        const key = node.id ? 
                        prefix ? 
                            `${ prefix }/${ node.id }` : 
                            node.id :
                        prefix;

        if (roles && !prefix && !filterByRole(key, roles)) {
            return null;
        }

        const access = this.props.access || {};
        const disabled = this.props.disabled || false;
        const loading = this.props.loading;
        const childrenEnabled = node.children && hasEnabledChildren(node.children, key, access);
        const checked = access[ key ] === true || parentEnabled;
        const indeterminate = !checked && childrenEnabled;

        return (
            <>
            { node.name && 
                <div className="access-tree-node-header">
                    <FormControlLabel
                            control={ 
                                loading === key ?
                                <Loader/> :
                                <Checkbox 
                                    color="primary" 
                                    indeterminate={ indeterminate }
                                    disabled={ disabled || showDisabled || parentEnabled }
                                    checked={ checked } 
                                    onChange={ e => this.toggleKey(key) }/> }
                            label={ node.name }/>
                </div>
            }
            { (node.children && node.children.length > 0) && 
                this.renderChildren(node.children, key, parentEnabled || access[ key ] === true) }
            </>
        )
    }

    renderChildren(children, prefix, parentEnabled) {
        const { filter } = this.props;
        return (
            <ul>
                { children.map((c, inx) => {
                    const show = checkFilter(c, prefix, filter);

                    if (show === false) {
                        return null;
                    }
                    
                    return (
                        <li key={ inx }>
                            { this.renderNode(c, prefix, parentEnabled, show === null) }
                        </li> 
                    )
                })}
            </ul>
        )
    }

    render() {

        const loading = this.props.loading;
        const access = this.props.access || {};
        const invalidKeys = hasInvalidKeys(access);

        if (invalidKeys.length > 0) {
            console.log("old permission keys", invalidKeys);
        }

        return (
            <div className="access-tree">
                { this.renderNode({ children: accessTree }, '', false) }

                { invalidKeys.length > 0 && 
                    <div className="access-tree-invalid">
                        <Button 
                            disabled={ loading === "old-permissions" }
                            startIcon={ loading === "old-permissions" ? <Loader inline/> : null }
                            variant="outlined" 
                            onClick={ () => this.removeOldPermissions() }
                            children="Remove old permissions"/>
                    </div>}
            </div>
        )
    }
}

export default AccessTree