import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Auth from "@aws-amplify/auth"

import PasswordField from "common/src/components/material/PasswordField"
import Button from "@mui/material/Button"
import SideDialog from "common/src/components/dialog/SideDialog"
import Loader from "common/src/components/Loader"

import { alert } from "common/src/components/dialog/Alert"
import { ui } from "common/src/store/user"
//import * as actions from "common/src/actions/user"

import useFormData from "common/src/lib/form/useFormData"
import required from "common/src/lib/form/validator/required"
import validatePassword from "common/src/lib/form/validator/password"
import createValidator from "common/src/lib/form/createValidator"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useDictRef from "common/src/hooks/useDictRef"


async function validatePasswordMatch(value, _1, _2, values) {
    return value === values["password"];
}

const fields = [
    {
        name: "oldPassword",
        validator: createValidator(required, "Please enter your old password")
    },
    {
        name: "password",
        validator: [
            createValidator(required, "Please enter your new password"),
            validatePassword
        ]
    },
    {
        name: "password2",
        validator: [
            createValidator(required, "Please confirm your password"),
            createValidator(validatePasswordMatch, "Passwords don't match"),
        ]
    }
]

function UserPassword() {

    const dispatch = useDispatch();
    const show = useSelector(s => s.user.ui.form === "password");
    const [ saving, setSaving ] = useState(false);

    const {
        setErrorMode, validateAll, resetAll,
        oldPassword, oldPasswordError, oldPasswordChange, oldPasswordSetError,
        password, passwordError, passwordChange,
        password2, password2Error, password2Change
    } = useFormData(fields);

    const ref = useDictRef({ password, password2, oldPassword });

    const onSubmit = useCallback(
        async () => {
            const valid = await validateAll();

            if (!valid) {
                setErrorMode(true);
                return;
            }

            setSaving(true);

            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, ref.oldPassword, ref.password);
                })
                .then(() => {
                    alert({
                        closeable: true,
                        message: "Your password was changed. Please keep it secure, don't write it down, and don't tell it to anyone."
                    });
                    resetAll();
                    setSaving(false);
                    onClose();
                })
                .catch(err => {
                    setSaving(false);
                    if (err.message === "Incorrect username or password.") {
                        oldPasswordSetError("Old password doesn't match");
                    }
                    else {
                        oldPasswordSetError(err.message);
                    }
                });
        },
        []
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSubmit();
            }
        },
        []
    );

    const onClose = useCallback(
        () => dispatch(ui.form(null)),
        []
    );

    useUpdateEffect(
        () => {
            if (!show) {
                setErrorMode(false);
                resetAll();
            }
        },
        [ show ]
    )

    return (
        <SideDialog title="Your password" 
            show={ show }
            className="dialog-user-details"
            onClose={ onClose }>
            <PasswordField 
                fullWidth
                autoFocus
                variant="outlined"
                size="small"
                label="Old password" 
                error={ !!oldPasswordError }
                helperText={ oldPasswordError }
                value={ oldPassword }
                onKeyDown={ onKeyDown }
                onChange={ oldPasswordChange }/>
            <PasswordField 
                fullWidth
                variant="outlined"
                size="small"
                label="Your new password" 
                error={ !!passwordError }
                helperText={ passwordError }
                value={ password }
                onKeyDown={ onKeyDown }
                onChange={ passwordChange }/>
            <PasswordField 
                fullWidth
                variant="outlined"
                size="small"
                label="Confirm new password" 
                error={ !!password2Error }
                helperText={ password2Error }
                value={ password2 }
                onKeyDown={ onKeyDown }
                onChange={ password2Change }/>
            <Button 
                className="submit"
                variant="contained" 
                disabled={ saving }
                startIcon={ saving ? <Loader inline/> : null }
                onClick={ onSubmit }
                color="primary">Change password</Button>
        </SideDialog>
    )
}

export default UserPassword
