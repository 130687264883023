
import React, { useMemo, useCallback, useRef } from "react"
import { Link } from "react-router-dom"

import swallow from "common/src/lib/dom/swallowEvent"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"

const MAX_DISPLAY_PAGES = 10;
const HALF = Math.ceil(MAX_DISPLAY_PAGES / 2);

function getPageNums(pages, page, lastPage) {
    let nums = [], i, start, end;

    if (lastPage < MAX_DISPLAY_PAGES) {
        return [ ...Array(pages).keys() ];
    }
    else {
        if (page > HALF) {
            nums.push(0);
            nums.push(null);
            start = page - (HALF - 2);
        }
        else {
            start = 0;
        }

        if (page < lastPage - 2) {
            end = start + MAX_DISPLAY_PAGES - (page > HALF ? 5 : 3);
            if (end > lastPage) {
                end = lastPage;
            }
        }
        else {
            end = lastPage;
        }

        for (i = start; i <= end; i++) {
            nums.push(i);
        }

        if (end < lastPage) {
            if (end < lastPage - 1) {
                nums.push(null);
            }
            nums.push(lastPage);
        }
    }

    return nums;
}

function Page({ no, onChange, currentPage, pageUrl }) {

    const url = useMemo(() => pageUrl ? pageUrl(no) : "/#", [ no ]);
    const cls = useMemo(() => no === currentPage ? "current" : "", [ no, currentPage ]);
    const onClick = useMemo(
        () => pageUrl ? null : (e) => swallow(e) && onChange(no),
        [ no ]
    );

    if (no !== null) {
        return (
            <Link to={ url } 
                className={ cls }
                onClick={ onClick }
                children={ no + 1 }/>
        )
    }
    else {
        return (
            <span>...</span>
        )
    }
}

function Pagination(props) {

    const { count, page, perPage, pageSizes, showCount, pageUrl, 
            onChange, onSizeChange } = props;

    const changeCb = useRef(onChange);
    const sizeCb = useRef(onSizeChange);
    const prevPage = useRef(null);
    const nextPage = useRef(null);
    const pages = useMemo(() => Math.ceil(count / perPage), [ count, perPage ]);
    const lastPage = useMemo(() => Math.max(0, pages - 1), [ pages ]);
    prevPage.current = useMemo(() => page < 1 ? 0 : page - 1, [ page ]);
    nextPage.current = useMemo(() => page < lastPage ? page + 1 : lastPage, [ page, lastPage ]);
    const nums = useMemo(() => getPageNums(pages, page, lastPage), [ pages, page, lastPage ]);
    const prevUrl = useMemo(() => pageUrl ? pageUrl(prevPage.current) : "/#", [ prevPage.current ]);
    const nextUrl = useMemo(() => pageUrl ? pageUrl(nextPage.current) : "/#", [ nextPage.current ]);
    const prevCls = useMemo(() => [ "prev", page < 1 ? "disabled" : "" ].join(" "), [ page ]);
    const nextCls = useMemo(
        () => [ "next", page === lastPage ? "disabled" : "" ].join(" "),
        [ page, lastPage ]
    );
    
    const onPrevClick = useSwallowEventCallback(() => changeCb.current(prevPage.current), []);
    const onNextClick = useSwallowEventCallback(() => changeCb.current(nextPage.current), []);
    const onSizeSelect = useCallback((e) => sizeCb.current(parseInt(e.target.value)));


    if (pages < 2) {
        if (showCount) {
            return (
                <div className="pagination">
                    <span>Total: { count }</span>
                </div>
            )
        }
        return <></>
    }

    return (
        <div className="pagination">
            <Link to={ prevUrl } children="Prev" className={ prevCls } onClick={ onPrevClick }/>
            { nums.map((no, inx) => 
                <Page 
                    key={ inx } 
                    no={ no }
                    pageUrl={ pageUrl }
                    currentPage={ page }
                    onChange={ changeCb.current } />)}
            <Link to={ nextUrl } children="Next" className={ nextCls } onClick={ onNextClick }/>
            { !!pageSizes && 
                <select 
                    value={ perPage }
                    onChange={ onSizeSelect }>
                    { pageSizes.map(s => (
                        <option key={ s } value={ s }>{ s } per page</option>
                    ))}
                </select> }
            { showCount && <span>Total: { count }</span> }
        </div>
    )
}


export default Pagination
