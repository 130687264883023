import React from "react"
import Table from "common/src/components/table/Table"
import User from "common/src/components/user/User"
import Loader from "common/src/components/Loader"
import { TextField } from "@mui/material"

import api from "app/api"
import userPaymentSettings from "common/src/lambdalib/userPaymentSettings"
import { saveUserCommissionRate } from "app/actions/page/accounting"
import formatNumber from "common/src/lib/format/number"
import NullForm from "common/src/components/NullForm"

const userGraph = "id givenName familyName avatar " +
                    "paymentSettings { friCommission contributorCommission pseCommission }";

class PagePaymentSettings extends React.Component {

    state = {
        loading: false,
        users: [],

        editingUserId: null,
        editingCol: null,

        savingUserId: null,
        savingCol: null
    }

    componentDidMount() {
        this.loadUsers();
    }

    async loadUsers() {
        const where = {
            _or: [
                { groups: { _like: "%FRI%" }},
                { groups: { _like: "%GPS%" }},
                { groups: { _like: "%Contributor%" }}
            ]
        }
        const order = { familyName: "asc" };
        const users = await api.user.list({ where, order }, userGraph);
        this.setState({ users });
    }

    async savePseRate() {
        const { editingUserId, editingCol, editingValue } = this.state;
        this.setState({ savingUserId: editingUserId, savingCol: editingCol });
        await saveUserCommissionRate(editingUserId, "pseCommission", editingValue);
        await this.loadUsers();
        this.setState({ 
            editingUserId: null, editingCol: null,
            savingUserId: null, savingCol: null,
            editingValue: ""
        });
    }

    async saveConRate() {
        const { editingUserId, editingCol, editingValue } = this.state;
        this.setState({ savingUserId: editingUserId, savingCol: editingCol });
        await saveUserCommissionRate(editingUserId, "contributorCommission", editingValue);
        await this.loadUsers();
        this.setState({ 
            editingUserId: null, editingCol: null,
            savingUserId: null, savingCol: null,
            editingValue: ""
        });
    }

    onCellClick(col, row) {
        this.setState({ 
            editingCol: col.id, 
            editingUserId: row.id,
            editingValue: row.paymentSettings?.pseCommission || ""
        });
    }

    renderPseRateCol(user) {
        const { editingUserId, editingCol, editingValue, 
                savingUserId, savingCol } = this.state;
        
        if (savingUserId === user.id && savingCol === "rate-pse") {
            return <Loader/>;
        }
        else if (editingUserId === user.id && editingCol === "rate-pse") {
            return (
                <NullForm>
                <TextField
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    fullWidth
                    autoFocus
                    value={ editingValue }
                    onChange={ e => this.setState({ editingValue: e.target.value })}
                    onKeyDown={ e => e.key === "Enter" && this.savePseRate() }
                    onBlur={ e => this.savePseRate() }/>
                </NullForm>
            )
        }
        else {
            if (user?.paymentSettings?.pseCommission && 
                user?.paymentSettings?.pseCommission !== userPaymentSettings.defaultRates.pse) {
                return (
                    <b>
                    { formatNumber(user.paymentSettings.pseCommission, 2) }
                    </b>
                )
            }
            else {
                return "Default: " + formatNumber(userPaymentSettings.defaultRates.pse, 2);
            }
        }
    }

    renderConRateCol(user) {
        const { editingUserId, editingCol, editingValue, 
                savingUserId, savingCol } = this.state;

        if (savingUserId === user.id && savingCol === "rate-con") {
            return <Loader/>;
        }
        else if (editingUserId === user.id && editingCol === "rate-con") {
            return (
                <NullForm>
                <TextField
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    fullWidth
                    autoFocus
                    value={ editingValue }
                    onChange={ e => this.setState({ editingValue: e.target.value })}
                    onKeyDown={ e => e.key === "Enter" && this.saveConRate() }
                    onBlur={ e => this.saveConRate() }/>
                </NullForm>
            )
        }
        else {
            if (user?.paymentSettings?.contributorCommission &&
                user?.paymentSettings?.contributorCommission !== userPaymentSettings.defaultRates.contributor) {
                return (
                    <b>{ formatNumber(user.paymentSettings.contributorCommission, 2) }</b>
                )
            }
            else {
                return "Default: " + formatNumber(userPaymentSettings.defaultRates.contributor, 2);
            }
        }
    }

    render() {

        const { loading, users } = this.state;
        const cols = [
            { id: "user", name: "User", render: user => <User user={ user }/> },
            { id: "rate-pse", name: "PSE commission rate",
                render: user => this.renderPseRateCol(user) },
        ];

        return (
            <div className="page page-accounting-settings">
                <div className="toolbar">
                    <div className="toolbar-title">
                        User commission rates
                    </div>
                </div>
                { loading && <Loader size={ 64 }/> }
                { users.length > 0 && 
                    <Table 
                        cols={ cols } 
                        rows={ users }
                        isCellClickable={ c => c.id === "rate-pse" || c.id === "rate-con" }
                        onCellClick={ (col, row) => this.onCellClick(col, row) }/> }
            </div>
        )
    }
}

export default PagePaymentSettings