
const getStrValue = v => {

    if (v === undefined) {
        return undefined;
    }
    if (v === null) {
        return null;
    }
    if (Array.isArray(v)) {
        return v.map(getStrValue).toString();
    }
    if (typeof v === "object" && v.constructor === Object) {
        return getStrValue( Object.keys(v).map(k => v[k]) );
    }

    return v.toString();
}

export default (s1, s2, keys) => {

    if (!keys) {
        keys = Object.keys(s1);
    }

    for (let i = 0, l = keys.length; i < l; i++) {
        let k = keys[i];
        let v1 = getStrValue(s1[k]);
        let v2 = getStrValue(s2[k]);

        if (v1 !== v2) {
            return false;
        }
    }

    return true;
}   