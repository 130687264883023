import { useCallback, useMemo, useState } from "react"

import { Button } from "@mui/material"

import api from "app/api"

import Table from "common/src/components/table/Table"
import DesignerSelector from "common/src/components/catalogue/DesignerSelector"
import RetailerSelector from "common/src/components/catalogue/RetailerSelector"

import useQuery from "common/src/refactor/hooks/useQuery"

function DesignerExclusion() {

    const [ designerId, setDesignerId ] = useState(null);
    const [ retailerId, setRetailerId ] = useState(null);


    const loadDesigners = useCallback(
        async () => {
            const order = {
                designer: {
                    name: "asc"
                }
            };
            return api.catalogueDesignerExclusion.list({ order })
                    .then(list => list.map(row => {
                        row.name = row.designer.name;
                        return row;
                    }))
        },
        []
    );

    const { data: designers, refetch } = useQuery(
        loadDesigners,
        []
    );

    const handleDesignerChange = useCallback(
        (row) => setDesignerId(row.id),
        []
    );

    const handleRetailerChange = useCallback(
        (row) => setRetailerId(row.id),
        []
    );

    const onAddDesigner = useCallback(
        async () => {
            if (designerId && retailerId && 
                !designers.find(d => d.designerId === designerId && d.retailer === retailerId)) {
                await api.catalogueDesignerExclusion.create({
                    designerId: designerId,
                    retailer: retailerId
                });
                refetch();
            }
        },
        [ refetch, designerId, retailerId, designers ]
    );

    const onDeleteDesigner = useCallback(
        async (row) => {
            await api.catalogueDesignerExclusion.remove(row.id);
            refetch();
        },
        [ refetch ]
    );

    const columns = useMemo(
        () => [
            {
                id: "name",
                name: "Designer"
            },
            {
                id: "retailer",
                name: "Retailer"
            },
            {
                id: "action",
                className: "min-width",
                render: (row) => {
                    return (
                        <Button
                            variant="text"
                            children="Delete"
                            onClick={ () => onDeleteDesigner(row) }/>
                    )
                }
            }
        ],
        [ onDeleteDesigner ]
    );

    return (
        <div className="page page-designer-exclusion">
            <div className="toolbar">
                <div className="toolbar-title">Designer commission exclusion</div>
                <div className="spacer-full"/>
                <DesignerSelector 
                    stage={ process.env.REACT_APP_ENV }
                    size="normal"
                    onChange={ handleDesignerChange }/>
                <RetailerSelector
                    size="normal"
                    onChange={ handleRetailerChange }/>
                <Button 
                    variant="contained" 
                    children="Add pair"
                    onClick={ onAddDesigner }/>
            </div>
            <Table cols={ columns } rows={ designers }/>
        </div>
    )
}

export default DesignerExclusion
