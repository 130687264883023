import React from "react"
import { ReactComponent as IconExpand } from "common/src/svg/expand.svg"
import { ReactComponent as IconClear } from "common/src/svg/clear.svg"

import async from "common/src/lib/js/async"
import hub from "common/src/hub"


class GenericExpandSelector extends React.Component {

    state = {
        expanded: false
    }

    _isMounted = false

    constructor(props) {
        super(props);
        this.onSelectorOpen = this.onSelectorOpen.bind(this);
    }


    componentDidMount() {
        this._isMounted = true;

        if (this.props.group) {
            hub.listen("tag-selector", "open", this.onSelectorOpen);
        }

        if (this.props.expanded) {
            async(() => this.toggle());
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.props.group) {
            hub.remove("tag-selector", "open", this.onSelectorOpen);
        }
    }

    onSelectorOpen({ group, type }) {
        if (this.props.group === group &&
            this.props.type !== type &&
            this.state.expanded) {
            this.setState({ expanded: false })
        }
    }

    onToggleClick(e) {
        e.preventDefault();
        this.toggle();
    }

    onClearClick(e) {
        e.preventDefault();
        this.clear();
    }

    clear() {
        if (this.props.onClear) {
            this.props.onClear();
        }
        else {
            this.setState({ selection: [] });
            this.props.onChange && this.props.onChange([]);
        }
    }

    getSelectionState() {
        return this.props.getSelectionState ? this.props.getSelectionState() : "All selected";
    }
    
    hasSelection() {
        return this.props.hasSelection ? this.props.hasSelection() : false;
    }

    toggle() {
        const expanded = !this.state.expanded;
        this.setState({ expanded });

        if (expanded && this.props.group) {
            hub.dispatch("tag-selector", "open", {
                group: this.props.group,
                type: this.props.type
            })
        }
    }

    render() {

        const { expanded } = this.state;
        const { name = "", bodyCls = "" } = this.props;
        const cls = ["public-selector", "tag-selector-v2",
                        this.props.className, 
                        expanded ? "expanded" : ""];
        const selectionState = this.getSelectionState();
        
        if (this.hasSelection()) {
            cls.push("selection");
        }

        return (
            <div className={ cls.join(" ") }>
                <div className="tag-selector-header">
                    <a href="/#"
                        className="tag-selector-toggle"
                        onClick={ e => this.onToggleClick(e) }>
                        <label>{ name }</label>
                        <span>{ selectionState }</span>
                        <IconExpand />
                    </a>
                    <a href="/#" 
                        className="tag-selector-clear"
                        onClick={ e => this.onClearClick(e) }
                        children={ <IconClear/> }/>
                </div>
                
                <div className={ "tag-selector-body " + bodyCls }
                    onWheel={ e => e.stopPropagation() }>
                    { this.props.children }
                </div>
            </div>
        )
    }
}

export default GenericExpandSelector
