import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { Link } from "react-router-dom"

import Dialog from "common/src/components/dialog/Dialog"
import Loader from "common/src/components/Loader"
import Avatar from "common/src/components/user/Avatar"

import { ReactComponent as IconGo } from "common/src/svg/aright.svg"

import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import { setRead, loadMoreNotifications, loadNotifications } from "common/src/actions/notifications"
import { ScrollWatcher } from "common/src/lib/js/scrollWatcher"
import async from "common/src/lib/js/async"
import settings from "app/settings"
import commonSettings from "common/src/settings"
import user from "common/src/user"


const getOutsideUrl = (domain, redir, params) => {

    let url = commonSettings.domains[ process.env.REACT_APP_ENV ][ domain ];
    url += "?redir=" + encodeURIComponent(redir);
    if (params) {
        url += "&params=" + encodeURIComponent(JSON.stringify(params));
    }

    return url;
}

const getToolsChatUrl = (contactId) => {
    let url, crossApp;
    if (settings.app === "tools") {
        url = getUrl(routes.chat, { contactId });
    }
    else {
        url = getOutsideUrl("tools", "chat", { contactId });
        crossApp = true;
    }
    return { url, crossApp };
}

const getWebChatUrl = (contactId) => {
    let url, crossApp;
    if (settings.app === "web") {
        url = getUrl(routes.chat, { contactId });
    }
    else {
        url = getOutsideUrl("web", "chat", { contactId });
        crossApp = true;
    }
    return { url, crossApp };
}




const getToolsConsultationUrl = (id) => {
    let url, crossApp;
    if (settings.app === "tools") {
        url = getUrl(routes.consultation, { id });
    }
    else {
        url = getOutsideUrl("tools", "consultation", { id });
        crossApp = true;
    }
    return { url, crossApp };
}


const getWebConsultationUrl = (id) => {
    let url, crossApp;
    if (settings.app === "web") {
        url = getUrl(routes.consultation, { id });
    }
    else {
        url = getOutsideUrl("web", "consultation", { id });
        crossApp = true;
    }
    return { url, crossApp };
}


const isForShopper = (participants) => {
    const currUserId = user.id();
    const p = participants.find(p => p.userId === currUserId);
    const role = p ? p.role : null;
    if (role) {
        return role === "shopper";
    }
    return user.is(["Admin", "FRI", "GPS"]);
}


class Notifications extends React.Component {

    moreEl = null
    dialogEl = null
    scrollWatcher = null

    componentDidMount() {
        this.toggleScrollWatcher(true);  
    }

    componentWillUnmount() {
        this.toggleScrollWatcher(false);  
    }

    componentDidUpdate(prev) {
        if (this.props.dd['notifications-dialog'] && !prev.dd['notifications-dialog']) {
            if (this.props.ntfs.data.list.length === 0) {
                loadNotifications();
            }
            else {
                this.scrollWatcher && async(() => this.scrollWatcher.check());
            }
        }
    }

    toggleScrollWatcher(enable) {
        if (enable) {
            if (this.moreEl) {
                this.scrollWatcher = new ScrollWatcher();
                this.scrollWatcher.scrollEl = this.dialogEl;
                this.scrollWatcher.watch(this.moreEl, "inside", (el, state) => {
                    this.loadNextPage();
                });
            }
        }
        else {
            if (this.moreEl) {
                this.scrollWatcher.release(this.moreEl);
                this.scrollWatcher.stop();
                this.scrollWatcher = null;
            }
        }
    }

    loadNextPage() {
        loadMoreNotifications();
    }

    toggleNotificationRead(e, type, id, read) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setRead(type, id, read);
    }

    onActionClick(e, n) {
        if (n.chatMessageNotification) {
            setRead("chat-message-notification", n.chatMessageNotificationId, true);
        }
    }

    chatNotification(n) {
        const message = n.chatMessageNotification.message;
        const user = message.user;
        const from = user.givenName;
        const fromFull = `${ user.givenName } ${ user.familyName }`;
        const read = n.chatMessageNotification.read;

        let text = "";
        let url = "";
        let crossApp = false;
        let productAction = null;
        let subtext = "";

        if (["product-like", "product-dislike", 
            "product-replace", "product-shop"].indexOf(message.action) !== -1) {
            if (!message.product && !message.product?.look &&
                (!message.catalogueProducts || message.catalogueProducts.length === 0)) {
                //console.log(message)
                return {};
            }
            subtext = message.product?.name || 
                        message.catalogueProducts[0]?.catalogueProduct.name;
            if (settings.app === "tools") {
                if (message.product && message.product.look) {
                    productAction = getUrl(
                        routes.consultation, 
                        {id: message.product.look.consultationId}
                    )
                }
                else if (message.catalogueProducts && message.catalogueProducts.length > 0) {
                    const cp = message.catalogueProducts[0].catalogueProduct;
                    productAction = getUrl(routes.catalogue, { cpid: cp.id });
                }
            }
        }

        switch (message.action) {

            // for customer, on main website or gps website
            case "look-published": {
                text = "Looks are ready for review";
                if (!message.look) {
                    return {};
                }
                ({ url, crossApp } = getWebConsultationUrl(message.look.consultationId));
                //url = getUrl(routes.consultation, { id: message.look.consultationId });
                break;
            }

            // for shopper, in tools
            case "product-like": {
                text = `${ from } liked a product`;
                ({ url, crossApp } = getToolsChatUrl(user.id));
                
                break;
            }
            // for shopper, in tools
            case "product-dislike": {
                text = `${ from } disliked a product`;
                ({ url, crossApp } = getToolsChatUrl(user.id));
                break;
            }
            // for shopper, in tools
            case "product-replace": {
                text = `${ from } wants a different product`;
                ({ url, crossApp } = getToolsChatUrl(user.id));
                break;
            }
            // for shopper, in tools
            case "product-shop": {
                text = `${ from } wants to buy a product`;
                ({ url, crossApp } = getToolsChatUrl(user.id));
                break;
            }
            // for shopper, in tools
            case "register": {
                text = `${ fromFull } has joined THE FLOORR.`;
                ({ url, crossApp } = getToolsChatUrl(user.id));
                break;
            }
            case "":
            case null: {
                if (!message.chat) {
                    return {};
                }
                text = `You have a message from ${ from }`;
                const isShopper = isForShopper(message.chat.participants);
                // for shopper, in tools
                if (isShopper) {
                    ({ url, crossApp } = getToolsChatUrl(user.id));
                }
                // for customer, on main website or gps website
                else {
                    ({ url, crossApp } = getWebChatUrl(user.id));
                }
                break;
            }
            default: {
                return {}
            }
        }

        return { read, url, text, user, crossApp, productAction, subtext };
    }

    userNotification(n) {

        let text = "", url = "", user, crossApp = false;
        const read = n.userNotification.read;
    
        
        switch (n.userNotification.type) {
            // for customer on main website or gps
            case "consultation-request-submitted": {
                if (!n.userNotification.consultationId) {
                    console.log(n)
                    return <></>
                };
                text = "Consultation request submitted. Your Personal Shopper will be in touch soon.";
                if (settings.app === "admin") {
                    ({ url, crossApp } = getToolsConsultationUrl(n.userNotification.consultationId));
                }
                else {
                    url = getUrl(routes.consultation, { id: n.userNotification.consultationId });
                }
                user = n.userNotification.consultation?.fri;
                break;
            }
            // for shopper in tools
            case "consultation-assigned": {
                if (!n.userNotification.consultationId) {
                    console.log(n)
                    return <></>
                };
                text = "You have a new styling session assigned to you.";
                ({ url, crossApp } = getToolsConsultationUrl(n.userNotification.consultationId));
                user = n.userNotification.consultation?.customer;
                break;
            }
            // for shopper in tools
            case "consultation-request-received": {
                if (!n.userNotification.consultationId) {
                    console.log(n)
                    return <></>
                };
                text = "You have a new Personal Shopping Styling session request.";
                ({ url, crossApp } = getToolsConsultationUrl(n.userNotification.consultationId));
                user = n.userNotification.consultation?.customer;
                break;
            }
            case "message-reaction": {
                user = n.userNotification.fromUser;
                const { reaction } = JSON.parse(n.userNotification.details);
                text = `${ user.givenName } reacted ${ reaction } to your message`;
                if (settings.app === "admin") {
                    ({ url, crossApp } = getToolsChatUrl(user.id));
                }
                else {
                    url = getUrl(routes.chat, { contactId: user.id, mid: n.userNotification.messageId });
                }
                break;
            }
            default: {
                return <></>
            }
        }

        return { read, url, text, user, crossApp };
    }

    renderNotification(n) {

        let type, fn;

        if (n.chatMessageNotificationId) {
            type = "chat-message-notification";
            fn = "chatNotification";
        }
        else if (n.userNotificationId) {
            type = "user-notification";
            fn = "userNotification";
        }
        else {
            console.log(n);
            return null;
        }

        const date = moment(n.createdAt).fromNow();
        const { read, text, url, user, crossApp, productAction, subtext } = this[fn](n);

        if (!text) {
            return null;
        }

        return (
            <div className="notification" key={ n.id }>
                <a href="/#" 
                    title={ read ? "Mark as unread" : "Mark as read" }
                    className={ read ? "notification-read" : "notification-unread" }
                    onClick={ e => this.toggleNotificationRead(e, 
                        type, 
                        n.group, 
                        !read) }/>
                { user && 
                    <Avatar user={ user }/> }
                <div>
                    { crossApp ?
                        <a href={ url }
                            className="notification-text"
                            target="_blank"
                            rel="noreferrer noopener"
                            children={ text }
                            onClick={ e => !read && this.toggleNotificationRead(
                                null, 
                                type, 
                                n.group, 
                                true) }/> :
                        <Link 
                            className="notification-text"
                            to={ url } 
                            children={ text }
                            onClick={ e => !read && this.toggleNotificationRead(
                                null, 
                                type, 
                                n.group, 
                                true) }/>
                    }
                    { subtext && <span className="notification-subtext">{ subtext }</span> }
                    <span className="notification-date">{ date }</span>
                </div>
                { n.group.length > 1 && 
                    <span className="notification-group">{ n.group.length }</span> }
                { productAction && 
                    <Link to={ productAction } 
                        className="notification-action"
                        onClick={ (e) => this.onActionClick(e, n ) }><IconGo/></Link>}
            </div>
        )
    }

    render() {

        const { list } = this.props.ntfs.data;
        const { loading } = this.props.ntfs.ui.list;

        return (
            <Dialog 
                name="notifications-dialog"
                className="notifications-dialog"
                triggerMode="click"
                trigger=".app-header-bell"
                onRef={ el => this.dialogEl = el }
                noOverlay>
                { list.length === 0 && <p>You don't have notifications yet</p> }
                { list.map(n => this.renderNotification(n)) }
                { loading && <Loader/> }
                <div className="notifications-more"
                    ref={ el => this.moreEl = el }/>
                
            </Dialog>
        )
    }
}

export default connect(state => ({ ntfs: state.notifications, 
                                    dd: state.dialogs }))(Notifications)
