import React, { useCallback, useState, useEffect, useMemo } from "react"
import { Select, MenuItem, FormControl } from "@mui/material"

import Loader from "common/src/components/Loader"
import Menu from "common/src/components/Menu"
import Table from "common/src/components/table/Table"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"


import api from "app/api"
import downloadUrl from "common/src/lib/downloadUrl"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"



function PSEAnalytics() {

    const [ loading, setLoading ] = useState(true);
    const [ generating, setGenerating ] = useState(false);
    const [ columns, setColumns ] = useState([]);
    const [ rows, setRows ] = useState([]);
    const [ adopted, setAdopted ] = useState("all");
    const [ active, setActive ] = useState("all");
    
    const loadReport = useCallback(
        () => {
            const body = { adopted, active };
            setLoading(true);
            api.reporting.post("/report/pse/data", { body })
                        .then(resp => {
                            setColumns(resp.columns);
                            setRows(resp.rows);
                            setLoading(false);
                        });
        },
        [ adopted, active ]
    );

    const onGenerateClick = useCallback(
        async () => {
            const body = { adopted, active };
            setGenerating(true);
            const res = await api.reporting.post("/report/pse/csv", { body });
            setGenerating(false);
            downloadUrl(res.url, "pse-report.csv");
        },
        [ adopted, active ]
    );

    const menu = useMemo(
        () => {
            return [
                generating ? 
                    {
                        icon: <Loader/>
                    } :
                    {
                        className: "menu-download",
                        name: "Generate csv",
                        icon: <IconDownload className="icon icon-svg-fill icon-download"/>,
                        onClick: onGenerateClick
                    }
            ]
        },
        [ generating, onGenerateClick ]
    );

    const onAdoptedChange = useCallback(
        (e) => {
            setAdopted(e.target.value)
        },
        []
    );

    const onActiveChange = useCallback(
        (e) => {
            setActive(e.target.value)
        },
        []
    );


    useEffect(
        () => void loadReport(),
        // eslint-disable-next-line
        []
    );

    useUpdateEffect(
        () => void loadReport(),
        [ adopted, active ]
    );

    return (
        <div className="page page-raw-report">
            <div className="toolbar">
                <div className="toolbar-title">
                    PSE analytics
                </div>
                <FormControl variant="standard" style={{ marginLeft: 'auto', marginRight: '1rem'}}>
                    <Select size="small" value={ active } onChange={ onActiveChange }>
                        <MenuItem value="all">All (active and not)</MenuItem>
                        <MenuItem value={ true }>Active</MenuItem>
                        <MenuItem value={ false }>Inactive</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" style={{ marginRight: '1rem'}}>
                    <Select size="small" value={ adopted } onChange={ onAdoptedChange }>
                        <MenuItem value="all">All (adopted and not)</MenuItem>
                        <MenuItem value="adopted">Adopted</MenuItem>
                        <MenuItem value="partially">Partially adopted</MenuItem>
                        <MenuItem value="not">None</MenuItem>
                    </Select>
                </FormControl>
                <Menu menu={menu} />
            </div>
            <div className="grid-container">
                <Table 
                    variant="slim"
                    cols={ columns } 
                    rows={ rows }
                    className={ loading ? "grid-loading" : "" }/>
            </div>
            { loading && <Loader/> }
        </div>
    )
}

export default PSEAnalytics