import hub from "common/src/hub"
import store from "app/store"
import * as actions from "app/actions/page/people"


function onUserUpdated(id) {
    let state = store.getState();
    if (state.peoplePage.data.people.find(l => l.id === id)) {
        actions.reloadPeopleList();
    }
}

function onUserCreated() {
    actions.reloadPeopleList();
}

const listeners = {
    init: function() {
        hub.listen("users", "updated", onUserUpdated);
        hub.listen("users", "created", onUserCreated);
        hub.listen("users", "deleted", onUserUpdated);
    }
}

export default listeners