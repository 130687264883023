import { useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "@mui/material/Button"

import Loader from "common/src/components/Loader"
import Avatar from "common/src/components/user/Avatar"

import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import { ui } from "common/src/store/user"
import userSelector from "common/src/selectors/user/current"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { removeAvatar } from "common/src/actions/user"
import s3url from "common/src/lib/image/s3url"


function ProfileAvatar() {

    const dispatch = useDispatch();
    const current = useSelector(userSelector);
    const [ removingAvatar, setRemovingAvatar ] = useState(false);
    const avatarUrl = current.avatar ? s3url(current.avatar) : null;

    const onRemoveAvatarClick = useSwallowEventCallback(
        async () => {
            setRemovingAvatar(true);
            removeAvatar();
            setRemovingAvatar(false);
        },
        []
    );

    const onShowAvatarClick = useCallback(
        () => dispatch(ui.form("avatar")),
        []
    );

    return (
        <div className="profile-card card-avatar">
            <div>
                <label>Photo</label>
                <div className="card-avatar-image">
                    <div>
                    {removingAvatar ? <Loader size={ 100 }/> :
                        avatarUrl ? 
                        <>
                            <img src={ avatarUrl } alt=""/>
                            <a href="/#" 
                                onClick={ onRemoveAvatarClick }>
                                <IconClose/>
                            </a>
                        </> :
                        <Avatar user={ current }/> }
                    </div>
                </div>
                <div className="footer-actions">
                    <Button variant="outlined" 
                        onClick={ onShowAvatarClick }
                        color="primary">Change</Button>
                </div>
            </div>
        </div>
    )
}

export default ProfileAvatar