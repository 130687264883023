

export default function(items, ids) {

    return items.sort((a, b) => {
        const   inxA = ids.indexOf(a.id),
                inxB = ids.indexOf(b.id);
        return inxA < inxB ? -1 : 
                inxA === inxB ? 0 : 1;
    });
}