import React from "react"
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/close.svg"
import { ReactComponent as IconEmail } from "common/src/svg/email.svg"
import { ReactComponent as IconWhatsapp } from "common/src/svg/whatsapp.svg"
import { ReactComponent as IconSms } from "common/src/svg/sms.svg"
import { TextField, FormControlLabel } from "@mui/material"
import Checkbox from "common/src/components/material/Checkbox"
import async from "common/src/lib/js/async"
import DialCodeSelector from "common/src/components/form/DialCodeSelector"


const type2icon = {
    "email": IconEmail,
    "phone": IconSms,
    "whatsapp": IconWhatsapp
};

const type2header = {
    "email": "Email",
    "phone": "SMS",
    "whatsapp": "Whatsapp",
    "custom": "Custom"
};

class Contact extends React.Component {

    state = {
        expanded: false,
        allowDelete: true,

        id: null,
        type: null,
        value: null,
        code: null,
        normalized: null
    }

    componentDidMount() {
        async(() => this.importContactState(this.props.contact));
    }

    componentDidUpdate(prevProps) {
        const current = this.props.contact;
        const prev = prevProps.contact;

        if (!!current !== !!prev) {
            if (current) {
                async(() => this.importContactState(current));
            }
            else {
                this.setState({ type: null, value: null });
            }
        }
        else if (current.value !== prev.value || 
                current.notifications !== prev.notifications ||
                current.id !== prev.id) {
            async(() => this.importContactState(current));
        }
    }

    importContactState(contact) {
        const state = { ...contact };
        state.expanded = false;
        state.allowDelete = typeof contact.allowDelete !== "undefined" ? contact.allowDelete : true;

        if (contact.type === "phone" || contact.type === "whatsapp") {
            state.code = "+44";
            if (contact.value) {
                const parts = contact.value.split(" ", 2);
                state.code = parts[0];
                state.value = parts[1];
            }
        }
        if (!contact.value) {
            state.expanded = true;
        }

        this.setState(state, () => {
            if (!state.normalized) {
                this.normalize();
            }   
        });
    }

    getContact() {

        let { id, type, value, code, normalized, notifications } = this.state;

        if (type === "phone" || type === "whatsapp") {
            value = (code || "") + ' ' + (value || "");
        }

        return {
            id, type, value, normalized, notifications
        }
    }

    normalize(cb) {

        const { type, value, code } = this.state;
        let normalized;

        if (type === "phone" || type === "whatsapp") {
            normalized = (code + value).replace(/[^0-9+]/, '');
            this.setState({ normalized }, cb);
        }
        else if (type === "email") {
            normalized = value.toLowerCase();
            this.setState({ normalized }, cb);
        }
        else {
            cb && cb();
        }
    }

    onRemoveClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onRemove && 
            this.props.onRemove(this.getContact());
    }

    onEditClick(e) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ expanded: true });
    }

    onCloseClick(e) {
        e && e.stopPropagation();
        e && e.preventDefault();
        const remove = !this.state.value;
        if (remove) {
            this.props.onRemove && 
                this.props.onRemove(this.getContact());
        }
        this.setState({ expanded: false }, () => {
            this.props.onClose && 
                this.props.onClose(!remove ? this.getContact() : null);
        });
    }
    
    onCancelClick(e) {
        e && e.stopPropagation();
        e && e.preventDefault();

        if (this.props.contact && this.props.contact.id) {
            this.importContactState(this.props.contact);
            this.setState({ expanded: false });
        }
        else {
            this.props.onRemove && 
                this.props.onRemove(this.getContact());
            this.setState({ expanded: false });
        }
    }

    onValueChange(value) {
        this.setState({ value }, () => {
            this.normalize(() => {
                this.props.onChange &&
                    this.props.onChange(this.getContact());
            });
        });
    }

    onNotificationsChange(notifications) {
        this.setState({ notifications }, () => {
            this.props.onChange &&
                this.props.onChange(this.getContact());
        });
    }

    onCountryCodeChange(code) {
        this.setState({ code }, () => {
            this.normalize(() => {
                this.props.onChange &&
                    this.props.onChange(this.getContact());
            });
        });
    }

    renderCollapsed() {

        let { allowEdit = true, allowDelete = true, disabled = false } = this.props;
        const { type, value, code, notifications } = this.state;
        let displayValue = value;
        const cls = ["user-contact-collapsed"];
        disabled && cls.push("disabled");
        const Icon = type2icon[type];
        const header = type2header[type];

        if (this.state.allowDelete === false) {
            allowDelete = false;
        }

        if (type === "phone" || type === "whatsapp") {
            displayValue = code + ' ' + value;
        }

        return (
            <div className={ cls.join(" ") }>
                <div className="user-contact-collapsed-tick">
                    <Icon/>
                </div>
                <div className="user-contact-collapsed-value">
                    <p>
                        <b>{ header }:</b>
                        { displayValue }{/*<br/>
                        <span>Notifications: { notifications ? "on" : "off" }</span>*/}
                    </p>
                </div>
                <div className="actions">
                    { allowEdit && <a href="/#" onClick={ e => this.onEditClick(e) }><IconEdit/></a> }
                    { allowDelete && <a href="/#" onClick={ e => this.onRemoveClick(e) }><IconDelete/></a> }
                </div>
            </div>
        )
    }

    renderPhone() {
        const { dialCodes, type, code, value, notifications } = this.state;
        const { disabled, closeText = "Close", cancelText = "Cancel" } = this.props;
        const headers = {
            "phone": "SMS",
            "whatsapp": "Whatsapp"
        };
        const cls = ["user-contact"];
        disabled && cls.push("disabled");

        return (
            <div className={ cls.join(" ") }>
                <h4>
                    <span>{ headers[type] }</span>
                    <a href="/#" onClick={ e => this.onCloseClick(e) }>{ closeText }</a>
                    { cancelText && 
                        <a href="/#" onClick={ e => this.onCancelClick(e) }>{ cancelText }</a> }
                </h4>
                <div className="user-contact-phone">
                    <DialCodeSelector 
                        value={ code }
                        onChange={ code => this.onCountryCodeChange(code) }/>
                    <TextField
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        value={ value || "" }
                        placeholder="1234567"
                        onKeyDown={ e => e.key === "Enter" && this.onCloseClick() }
                        onChange={ e => this.onValueChange(e.target.value) }/>
                </div>
                {/*<FormControlLabel
                    control={ 
                        <Checkbox 
                            onChange={ e => this.onNotificationsChange(e.target.checked) }
                            checked={ notifications }/> 
                    }
                    label="Enable notifications"/>*/}
            </div>
        )
    }


    renderEmail() {
        const { value, notifications } = this.state;
        const { closeText = "Close", cancelText = "Cancel" } = this.props;
        return (
            <div className="user-contact">
                <h4>
                    <div className="user-contact-collapsed-tick">
                        <IconEmail/>
                    </div>
                    <span>Email</span>
                    <a href="/#" onClick={ e => this.onCloseClick(e) }>{ closeText }</a>
                    { cancelText && 
                        <a href="/#" onClick={ e => this.onCancelClick(e) }>{ cancelText }</a> }
                </h4>
                <TextField
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    value={ value || "" }
                    placeholder="my@email.tld"
                    onKeyDown={ e => e.key === "Enter" && this.onCloseClick() }
                    onChange={ e => this.onValueChange(e.target.value) }/>
                {/*<FormControlLabel
                    control={ 
                        <Checkbox 
                            onChange={ e => this.onNotificationsChange(e.target.checked) }
                            checked={ notifications }/> 
                    }
                    label="Enable notifications"/>*/}
            </div>
        )
    }

    renderCustom() {
        const { contact } = this.props;
        return (
            <div className="user-contact">
                <h4>
                    <span></span>
                    <a href="/#" onClick={ e => this.onCloseClick(e) }>Close</a>
                </h4>
                <TextField
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    value={ contact.value || "" }
                    placeholder="other means of contact"
                    onChange={ e => this.onCustomChange(e.target.value) }/>
            </div>
        )
    }

    render() {
        
        const { type, value, expanded } = this.state;

        if (value && !expanded) {
            return this.renderCollapsed();
        }
    
        switch (type) {
            case "phone":
            case "whatsapp": {
                return this.renderPhone();
            }
            case "email": {
                return this.renderEmail();
            }
            default: {
                return <></>
            }
        }
        
    }
}

export default Contact
