import React from "react"
import { connect } from "react-redux"
import Loader from "common/src/components/Loader"
import { Button, TextField } from "@mui/material"
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"
import { confirm } from "common/src/components/dialog/Alert"
import TagList from "common/src/components/tag/List"
import Products from "common/src/components/catalogue/products/PaginatedProducts"
import ProductDetails from "common/src/components/catalogue/ProductDialog"
import api from "app/api"
import hub from "common/src/hub"
import { loadColors } from "common/src/actions/catalogue"
import { ui as ddUi } from "common/src/store/dialogs"
import NullForm from "common/src/components/NullForm"


class CatalogueColorsPage extends React.Component {

    state = {
        loading: false,
        editId: null,
        creating: false,
        name: "",
        synonyms: "",
        feedColors: [],
        openId: null,
        product: null
    }

    componentDidMount() {
        this.loadList();
    }

    async loadList() {
        this.setState({ loading: true });
        await loadColors();
        this.setState({ loading: false });
    }

    async loadFeedColors(colorId) {
        const order = { name: "asc" };
        const where = { colorId: { _eq: colorId }};
        const feedColors = await api.catalogueColorFeed.list({ where, order });
        this.setState({ feedColors });
    }

    async removeFeedColor(feedColorId) {
        const { feedColors } = this.state;

        const inx = feedColors.findIndex(c => c.id === feedColorId);
        feedColors.splice(inx, 1);
        this.setState({ feedColors });

        await api.catalogueColorFeed.remove(feedColorId);
        hub.dispatch("catalogue", "color-change");
    }


    async create() {
        this.setState({ creating: true });

        const { name, synonyms, editId } = this.state;

        if (editId === "new") {
            const newColor = { name, synonyms };
            await api.catalogueColor.create(newColor);
        }
        else {
            const payload = { name, synonyms };
            await api.catalogueColor.update(editId, payload);
        }

        this.setState({ creating: false, editId: null });
        //this.loadList();
        hub.dispatch("catalogue", "color-change");
    }

    async remove(id) {
        try {
            await confirm({ title: "Delete color", message: "Are you sure?" });
        }
        catch (err) {
            return;
        }

        await api.catalogueColor.remove(id);
        hub.dispatch("catalogue", "color-change");
    }


    openColor(e, c) {
        const openId = this.state.openId;
        e.preventDefault();
        e.stopPropagation();

        if (openId === c.id) {
            this.setState({ openId: null });    
        }
        else {
            this.setState({ openId: c.id, feedColors: [] });
            this.loadFeedColors(c.id);
        }
    }

    onProductClick(product) {
        this.props.dispatch(ddUi.show("product-details"));
        this.setState({ product });
    }

    renderForm() {
        const { editId, creating, name, synonyms } = this.state;

        return (
            <NullForm className="page-catalogue-color-form"  key={ editId }>
                <TextField 
                    autoComplete="off"
                    variant="outlined" 
                    placeholder="Color name"
                    value={ name }
                    disabled={ creating }
                    onKeyDown={ e => (e.key === "Enter" && name) && this.create() }
                    onChange={ e => this.setState({ name: e.target.value }) }/>
                <TextField 
                    autoComplete="off"
                    variant="outlined" 
                    placeholder="Synonyms (comma separated)"
                    value={ synonyms }
                    disabled={ creating }
                    onKeyDown={ e => (e.key === "Enter" && name) && this.create() }
                    onChange={ e => this.setState({ synonyms: e.target.value }) }/>
                <Button
                    variant="contained"
                    children={ editId === "new" ? "Create" : "Save" }
                    startIcon={ creating ? <Loader inline/> : null }
                    disabled={ creating || !name }
                    onClick={ () => this.create() }/>
                <Button 
                    variant="text" 
                    children="Cancel"
                    onClick={ () => this.setState({ editId: null }) }/>
            </NullForm>
        )
    }

    renderInfo(c) {
        const { openId, feedColors } = this.state; 
        const cls = [ "page-catalogue-color" ];

        if (c.id === openId) {
            cls.push("active");
        }

        return (
            <div className={ cls.join(" ") } key={ c.id }>

                <div>
                    <h4>
                        { c.name }
                        { c.feedColorsCount > 0 && 
                            <a href="/#" onClick={ e => this.openColor(e, c) }>
                                <IconDown/>
                            </a> 
                        }
                    </h4>
                    <p>{ c.synonyms }</p>
                </div>

                <div className="actions">
                    <a href="/#" onClick={ (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                                editId: c.id,
                                name: c.name,
                                synonyms: c.synonyms
                            });
                        }}>
                        <IconEdit/>
                    </a>
                    <a href="/#" onClick={ (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.remove(c.id);
                        }}>
                        <IconDelete/>
                    </a>
                </div>  

                { (c.feedColorsCount > 0 && c.id === openId) && 
                    <>
                        <div className="page-catalogue-feed-colors">
                            <TagList tags={ feedColors } onDelete={ fc => this.removeFeedColor(fc.id) }/>
                        </div>
                        <Products 
                            autoload
                            setName={ c.id }
                            color_id={ c.id }
                            perPage={ 16 }
                            product={ p => ({ onClick: () => this.onProductClick(p) })}
                            emptyText="Currently, no products found"/>
                    </> }
            </div> 
        )
    }

    render() {
        const { loading, editId, product } = this.state;
        const { colors } = this.props;
        return (
            <>
            <div className="page page-catalogue-colors">
                { loading && <Loader size={ 64 }/> }
                <div className="toolbar">
                    <Button 
                        variant="contained" 
                        children="Add color"
                        onClick={ () => this.setState({ 
                            editId: "new", 
                            name: "", 
                            synonyms: "" }) }/>
                </div>

                { editId === "new" && this.renderForm() }
                { colors.map(c => editId === c.id ? this.renderForm() : this.renderInfo(c)) }
            </div>
            <ProductDetails 
                product={ product }/>
            </>
        )
    }
}

export default connect(state => ({ colors: state.catalogue.data.colors }))(CatalogueColorsPage)