import hub from "common/src/hub"
import user from "common/src/user"
import { subscribeToUserData, unsubscribeFromUserData } from "common/src/actions/user"

const initUserDataListener = async (authState) => {
    if (authState) {
        subscribeToUserData(user.id());
    }
    else {
        unsubscribeFromUserData();
    }
}

const listeners = {
    init: () => {
        !hub.hasListener("app-auth", "stateChange", initUserDataListener) && 
            hub.listen("app-auth", "stateChange", initUserDataListener);
    }
}

export default listeners