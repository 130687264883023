import React from "react"
import moment from "moment"

import { Button, Select, MenuItem } from "@mui/material"
import { Link } from "react-router-dom"
import Menu from "common/src/components/Menu"
import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"
import Loader from "common/src/components/Loader"
import Table from "common/src/components/table/Table"
import { alert, confirm } from "common/src/components/dialog/Alert"
import RevolutDraftDialog from "./RevolutDraftDialog"

import UserSelector from "common/src/components/user/UserSelector"
import UserGroupsSelector from "../../filter/UserGroupsSelector"

import DataStore from "common/src/lib/DataStore"

import async from "common/src/lib/js/async"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"

import * as columns from "./datastore/payments"
import { downloadCsv } from "common/src/lib/csv"

import state2query from "common/src/lib/url/state2query"
import { default as url2state, list2param } from "common/src/lib/url/url2state"
import statesAreEqual from "common/src/lib/statesAreEqual"

import { loadPayments, loadPaymentsByUser, cancelPayment, 
        setNotPaid, setPaid, createRevolutDraft, removeRevolutDraft,
        resetPaymentDraftConnection } from "app/actions/page/payments"
import api from "app/api"

const DEFAULT_SECTION = "awaiting";

const urlParams = [
    { name: "section", defaultValue: DEFAULT_SECTION },
    { name: "page", type: "int", defaultValue: 1, 
        restore: page => Math.max(1, page) - 1,
        store: page => page + 1 },
    { name: "start", stateName: "startDate" },
    { name: "end", stateName: "endDate" },
    { name: "m", stateName: "months", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "f", stateName: "fris", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "p", stateName: "pses", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "c", stateName: "contributors", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "ug", stateName: "userGroups", type: "array", defaultValue: [],
        store: v => list2param(v) },
];

const getUrlState = (props) => {
    return url2state(urlParams, props);
}

const getFilterUrl = (state) => {
    return getUrl(routes.accountingPayments, state2query(state, urlParams));
}


class PagePayments extends React.Component {

    state = {
        section: DEFAULT_SECTION,
        startDate: null,
        endDate: null,
        page: 0,
        loading: false,
        data: null,
        cancellingId: null,
        returningId: null,
        settingPaidId: null,
        draftData: null,
        deletingId: null,
        resettingId: null,
        changingStatusId: null,

        draftUserId: null,

        expandedUser: null,
        userPayments: [],
        loadingUserPayments: false,

        months: [],
        users: [],
        fris: [],
        pses: [],
        contributors: [],
        userGroups: []
    }

    _isMounted = false
    userDataStore = null
    paymentsDataStore = null

    componentDidMount() {
        this._isMounted = true;
        this.userDataStore = new DataStore();
        this.paymentsDataStore = new DataStore();
        const state = getUrlState(this.props);
        this.setState(state, () => this.load());
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.userDataStore = null;
        this.paymentsDataStore = null;
    }

    componentDidUpdate() {
        const state = getUrlState(this.props);
        const keys = urlParams.map(p => p.stateName || p.name);
        if (!statesAreEqual(state, this.state, keys)) {
            async(() => this.setState({ ...state, data: null }, () => this.load()));
        }
    }


    getFilters() {
        const { startDate, endDate, pses, fris, contributors, months, userGroups } = this.state;
        const filters = { startDate, endDate, pses, fris, contributors, months, userGroups };
        return filters;
    }

    async load() {
        const { section } = this.state;
        let data;
        this.setState({ loading: true });
        switch (section) {
            case "awaiting": {
                data = await loadPaymentsByUser({ ...this.getFilters(), paid: false, draft: false });
                break;
            }
            case "draft": {
                data = await loadPaymentsByUser({ ...this.getFilters(), paid: false, draft: true });
                break;
            }
            case "paid": {
                data = await loadPaymentsByUser({ ...this.getFilters(), paid: true });
                break;
            }
            default: {}
        }
        
        if (this.state.section === section) {
            this.setState({ loading: false, data });
        }
    }

    reload() {
        this.load();
    }

    download(what) {

        switch (what) {
            case "user": {
                downloadCsv(this.userDataStore.getCsv(), "payments.csv");
                break;
            }
            case "commissions": {
                const { expandedUser } = this.state;
                const user = this.state.data.find(r => r.user.id === expandedUser).user;
                const name = user.givenName + "_" + user.familyName + "_commissions.csv";
                downloadCsv(this.paymentsDataStore.getCsv(), name);
                break;
            }
            default: {}
        }
    }

    async createRevolutDraft(userId, e) {
        if (e) {
            e.stopPropagation();
        }
        const p = this.state.data.find(p => p.userId === userId);
        if (!p) {
            return;
        }

        this.setState({ creatingDraftId: userId });
   
        const user = p.user;
        const reference = `Payment to ${ user.givenName } ${ user.familyName }`;
        const amount = p.amount;
        const currency = "gbp";

        const where = {
            userId: { _eq: userId },
            paid: { _eq: false },
            revolutDraftId: { _is_null: true }
        };
        const graph = "id";
        const comms = await api.userApprovedPayment.list({ where }, graph);
        const pids = comms.map(c => c.id);

        const draftData = {
            userId,
            reference,
            amount,
            currency,
            pids
        }
        this.setState({ draftData }, () => {
            RevolutDraftDialog.show();
        })

        this.setState({ creatingDraftId: null });
    }

    async onRevolutDraftSubmit({ userId, reference, accountId, amount, currency, pids }) {
        this.setState({ creatingDraftId: userId });
        const res = await createRevolutDraft(userId, reference, accountId, amount, currency, pids);
        this.setState({ creatingDraftId: null });
        this.reload();

        if (res !== true) {
            alert({ message: res });
        }
    }

    async onRevolutDraftCancel() {
        
    }

    async cancelPayment(p, e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ cancellingId: p.id });
        await cancelPayment(p.id);
        this.setState({ cancellingId: null });
        this.reloadUserPayments();
    }

    async returnToAwaiting(p, e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ returningId: p.id });
        await setNotPaid(p.id);
        this.setState({ returningId: null });
        this.reloadUserPayments();
    }

    async setPaid(p, e, withDraft = false) {
        if (e) {
            e.stopPropagation();
        }

        if (withDraft) {
            try {
                const res = await confirm({
                    message: "Revolut drafts should be marked as paid automatically. "+
                            "Use this function only if something went wrong as a manual override. " +
                            "Mark this draft as paid?"
                });
                if (!res) {
                    return;
                }
            }
            catch (err) { return };
        }

        this.setState({ settingPaidId: p.id });
        await setPaid(p.id);
        this.setState({ settingPaidId: null });
        this.reloadUserPayments();
    }

    async deleteDraft(p, e) {
        if (e) {
            e.stopPropagation();
        }
        try {
            const ans = await confirm({
                message: "Are you sure you want to delete this draft?"
            });
            if (!ans) {
                return;
            }
        }
        catch (err) { return };

        this.setState({ deletingId: p.id });
        const res = await removeRevolutDraft(p.revolutDraftId);
        this.setState({ deletingId: null });

        if (res !== true) {
            alert({ message: res || "Failed to delete draft payment" });
        }

        this.reloadUserPayments();
    }

    async resetDraft(p, e) {
        if (e) {
            e.stopPropagation();
        }

        this.setState({ resettingId: p.id });
        await resetPaymentDraftConnection(p.id);
        this.setState({ resettingId: null });
        this.reloadUserPayments();
    }

    async reloadUserPayments() {
        const userId = this.state.expandedUser;
        const section = this.state.section;
        const { rows } = await loadPayments({ 
            users: [ userId ], 
            paid: section === "paid",
            draft: section === "draft" ? true : section === "paid" ? null : false
        });
        this.setState({ userPayments: rows });
    }

    async changeRevolutStatusId(paymentId, transactionId, action) {
        if (process.env.REACT_APP_ENV !== "devtwo") {
            return;
        }

        this.setState({ changingStatusId: paymentId });

        const resp = await api.revolut.post("/payments/set-status", { body: { transactionId, action }});

        if (resp.success === false) {
            alert({ message: resp.error });
        }
        else {
            alert({ message: "Payment status will update once Revolut calls our api. Refresh in a minute" });
        }

        this.setState({ changingStatusId: null });
    }

    async onUserExpand(userId) {
        if (userId) {
            const section = this.state.section;
            this.setState({ expandedUser: userId, userPayments: [], loadingUserPayments: true });
            const { rows } = await loadPayments({ 
                users: [ userId ], 
                paid: section === "paid",
                draft: section === "draft" ? true : section === "paid" ? null : false
            });
            this.setState({ loadingUserPayments: false, userPayments: rows });
        }
        else {
            this.setState({ expandedUser: null, userPayments: [], loadingUserPayments: false });
        }
    }


    getNav() {
        const { loading, section } = this.state;
        const refreshCls = [ 'icon', 'icon-svg-fill', loading ? 'spinning' : ''];

        return [
            {
                name: "Awaiting",
                onClick: () => this.setSection("awaiting"),
                active: section === "awaiting"
            },
            {
                name: "Pending",
                onClick: () => this.setSection("draft"),
                active: section === "draft"
            },
            {
                name: "Paid",
                onClick: () => this.setSection("paid"),
                active: section === "paid"
            },
            {
                icon: <IconDownload/>,
                onClick: () => this.download("user")
            },
            {
                icon: <IconRefresh className={ refreshCls.join(" ") }/>,
                onClick: () => this.reload()
            }
        ]
    }

    setFilter(state) {
        const newState = Object.assign({}, this.state, state, { page: 0 });
        const url = getFilterUrl(newState);
        this.props.history.push(url);
    }

    setDateRange(range) {
        const state = Object.assign({}, this.state, {
            startDate: range[0] ? moment(range[0]).utc(true).format("YYYY-MM-DD") : null,
            endDate: range[1] ? moment(range[1]).utc(true).format("YYYY-MM-DD") : null
        });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    setSection(section) {
        const state = Object.assign({}, { section, page: 0 });
        const url = getFilterUrl(state);
        this.setState({ data: null });
        this.props.history.push(url);
    }

    setPage(page) {
        const state = Object.assign({}, this.state, { page });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    renderFilters(filters) {
        const { pses, fris, contributors, userGroups } = this.state;
        return (
            <>
                { filters.indexOf("pse") !== -1 && 
                    <UserSelector
                        name="PSE"
                        group="FRI"
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ pses }
                        onChange={ pses => this.setFilter({ pses }) }/> },
                { filters.indexOf("fri") !== -1 && 
                    <UserSelector
                        name="Look by"
                        group="FRI"
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ fris }
                        onChange={ fris => this.setFilter({ fris }) }/> }
                { filters.indexOf("contributor") !== -1 &&
                    <UserSelector
                        name="Link by"
                        group="Contributor"
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ contributors }
                        onChange={ contributors => this.setFilter({ contributors }) }/> }
                { filters.indexOf("usergroup") !== -1 && 
                    <UserGroupsSelector
                        name="User group"
                        value={ userGroups }
                        onChange={ userGroups => this.setFilter({ userGroups }) }/> }
            </>
        )
    }

    renderPaymentDetails(id) {
        return (
            <div className="page-commission-details-toolbar">
                <Link 
                    children="View commission"
                    to={ getUrl(routes.reportCommissions, { q: id }) }/>
            </div>
        )
    }

    renderUserPayments(row) {
        const loading = this.state.loadingUserPayments;
        const rows = this.state.userPayments;
        const { section, cancellingId, returningId, 
                changingStatusId,
                settingPaidId, deletingId, resettingId } = this.state;
        
        if (loading) {
            return <Loader/>;
        }

        this.paymentsDataStore.setColumns(section === "draft" ? columns.drafts : columns.payments);
        this.paymentsDataStore.setRows(rows);
        
        if (section === "awaiting") {
            this.paymentsDataStore.addColumn({
                id: "action",
                name: "",
                className: "min-width grid-nowrap",
                modes: ["grid"],
                render: p => {
                    if (!p.revolutDraftId) {
                        return (
                            <>
                            <Button 
                                disabled={ cancellingId !== null }
                                variant="text" 
                                size="small" 
                                children="Cancel"
                                onClick={ (e) => this.cancelPayment(p, e) }/>
                            <Button 
                                disabled={ settingPaidId !== null }
                                variant="text" 
                                size="small" 
                                children="Set paid"
                                onClick={ (e) => this.setPaid(p, e) }/>
                            </>
                        )
                    }
                }
            });
        }
        else if (section === "paid") {
            this.paymentsDataStore.addColumn({
                id: "action",
                name: "",
                className: "min-width grid-nowrap",
                modes: ["grid"],
                render: (row) => {
                    if (!row.revolutDraftId) {
                        return (
                            <>
                            <Button 
                                disabled={ cancellingId !== null }
                                variant="text" 
                                size="small" 
                                children="Cancel"
                                onClick={ (e) => this.cancelPayment(row, e) }/>
                            <Button 
                                disabled={ returningId !== null }
                                variant="text" 
                                size="small" 
                                children="Back to awaiting"
                                onClick={ (e) => this.returnToAwaiting(row, e) }/>
                            </>
                        )
                    }
                }
            })
        }
        else if (section === "draft") {
            if (process.env.REACT_APP_ENV === "devtwo") {
                this.paymentsDataStore.addColumn({
                    id: "revolut-action",
                    name: "",
                    className: "min-width grid-nowrap",
                    modes: ["grid"],
                    render: (row) => {
                        if (changingStatusId === row.id) {
                            return <Loader/>
                        }
                        if (row.revolutDraftId && row.revolutDraft?.transactionId) {
                            const status = row.revolutDraft?.status;
                            if (status === "PENDING") {
                                return (
                                    <Select 
                                        size="small" 
                                        variant="standard"
                                        value=""
                                        onChange={ e => this.changeRevolutStatusId(
                                            row.id, 
                                            row.revolutDraft.transactionId, 
                                            e.target.value
                                        ) }>
                                        <MenuItem value="complete">Revolut: set completed</MenuItem>
                                        <MenuItem value="fail">Revolut: set failed</MenuItem>
                                        <MenuItem value="decline">Revolut: set declined</MenuItem>
                                        <MenuItem value="revert">Revolut: set reverted</MenuItem>
                                    </Select>
                                )
                            }
                            return null;
                        }
                    }
                });
            }
            this.paymentsDataStore.addColumn({
                id: "action",
                name: "",
                className: "min-width grid-nowrap",
                modes: ["grid"],
                render: (row) => {
                    if (row.revolutDraftId) {
                        const status = row.revolutDraft?.status;
                        return (
                            <>
                            { status === "CREATED" && 
                            <Button 
                                disabled={ deletingId !== null }
                                startIcon={ deletingId === row.id ? <Loader inline/> : null }
                                variant="text" 
                                size="small" 
                                children="Delete draft"
                                onClick={ (e) => this.deleteDraft(row, e) }/> }
                            { (status !== "CREATED" && status !== "PENDING" && status !== "COMPLETED") && 
                                <Button 
                                    disabled={ resettingId !== null }
                                    startIcon={ resettingId === row.id ? <Loader inline/> : null }
                                    variant="text" 
                                    size="small" 
                                    children="Back to awaiting"
                                    onClick={ (e) => this.resetDraft(row, e) }/> }
                            <Button 
                                disabled={ settingPaidId !== null }
                                variant="text" 
                                size="small" 
                                children="Set paid"
                                onClick={ (e) => this.setPaid(row, e, true) }/>
                            </>
                        )
                    }
                }
            })
        }

        this.paymentsDataStore.trigger("change");

        return (
            <>
                <Table
                    dataStore={ this.paymentsDataStore }
                    expandable 
                    expandKey="id"
                    variant="slim"
                    isRowExpandable={ row => !!row.networkOrderId }
                    renderDetails={ row => this.renderPaymentDetails(row.networkOrderId) }/>
                <div className="page-commission-details-toolbar">
                    <span className="spacer"/>
                    <Button 
                        size="small" 
                        variant="text" 
                        children="Download commissions"
                        onClick={ () => this.download("commissions") }/>
                </div>
            </>
        )
    }

    renderPaymentsByUser(type) {
        const rows = this.state.data;
        const creatingDraftId = this.state.creatingDraftId;

        if (!rows) {
            return null;
        }

        this.userDataStore.setColumns(columns.user);
        this.userDataStore.setRows(rows);

        if (type === "awaiting") {
            this.userDataStore.addColumn({
                id: "action",
                name: "",
                className: "min-width grid-nowrap",
                modes: ["grid"],
                render: (row) => {
                    if (row.user.revolutCounterparty && row.user.revolutCounterparty.length > 0) {
                        return (
                            <Button 
                                disabled={ !!creatingDraftId }
                                startIcon={ creatingDraftId === row.userId ? <Loader inline/> : null }
                                variant="outlined" 
                                size="small" 
                                children="Create draft"
                                onClick={ e => this.createRevolutDraft(row.userId, e) }/>
                        )
                    }
                    else {
                        return "No banking details"
                    }
                }
            })
        }

        this.userDataStore.trigger("change");

        return (
            <Table
                dataStore={ this.userDataStore }
                expandable
                expandKey="userId"
                onRowExpand={ userId => this.onUserExpand(userId) }
                renderDetails={ row => this.renderUserPayments(row) }/>
        )
    }

    render() {
        const menu = this.getNav();
        const { data, loading, section, draftData } = this.state;

        return (
            <>
            <div className="page page-accounting-payments">
                <div className="toolbar">
                    <div className="toolbar-title">
                        Payments
                    </div>
                    <Menu menu={ menu } className="right"/>
                </div>
                { loading && <Loader size={ 64 }/> }

                <div className="page-commissions-layout">
                    <div className="left">
                        { this.renderFilters([ "pse", "fri", "contributor", /*"usergroup"*/ ]) }
                    </div>
                    <div className="right">
                        { (data && section === "awaiting") && this.renderPaymentsByUser("awaiting") }
                        { (data && section === "paid") && this.renderPaymentsByUser("paid") }
                        { (data && section === "draft") && this.renderPaymentsByUser("draft") }
                    </div>
                </div>
            </div>
            <RevolutDraftDialog 
                draftData={ draftData }
                onSubmit={ data => this.onRevolutDraftSubmit(data) }
                onCancel={ () => this.onRevolutDraftCancel() }/>
            </>
        )
    }
}

export default PagePayments