import React from "react"
import { connect } from "react-redux"

import { TextareaAutosize } from "@mui/material"
import Button from "@mui/material/Button"
import SideDialog from "common/src/components/dialog/SideDialog"
import Loader from "common/src/components/Loader"

import { alert } from "common/src/components/dialog/Alert"
import { ui } from "common/src/store/user"
import * as actions from "common/src/actions/user"


class UserDetails extends React.Component {

    state = {}

    constructor(props) {
        super(props)

        if (props.current) {
            this.state = {
                bio: props.current.bio || ""
            }
        }
    }

    componentDidUpdate(prevProps) {
        let prevCurrent = prevProps.current || {},
            current = this.props.current || {};
        if (current.bio !== prevCurrent.bio) {
            this.setState({
                bio: current.bio
            })
        }
    }

    onClose() {
        this.props.dispatch(ui.form(null));
    }

    onFieldChange(field, value) {
        this.setState({[field]: value})
    }

    onSaveClick() {
        let { bio = "" } = this.state;

        actions.update({ bio })
        .then(() => {
            alert({
                title: "Your details have been updated.",
                message: "Thanks for keeping your details on The Floorr up to date."
            })
            this.onClose();
        })
        .catch(err => alert({
            title: "User update failed",
            message: err.message
        }))
    }

    render() {
        let show = this.props.ui.form === "bio",
            saving = this.props.ui.saving,
            { bio = "" } = this.state;

        return (
            <SideDialog title="Your bio" 
                        show={ show }
                        className="dialog-user-details"
                        onClose={ () => this.onClose() }>
                <TextareaAutosize 
                    autoFocus
                    rows={ 5 }
                    minRows={ 15 }
                    maxRows={ 25 }
                    variant="outlined"
                    size="small"
                    label="Bio" 
                    value={ bio }
                    onChange={ e => this.onFieldChange("bio", e.target.value) }/>
                <Button 
                    className="submit"
                    variant="contained" 
                    disabled={ saving }
                    startIcon={ saving ? <Loader inline/> : null }
                    onClick={ () => this.onSaveClick() }
                    color="primary">Update</Button>
            </SideDialog>
        )
    }
}

export default connect(state => state.user)(UserDetails)