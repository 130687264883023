
const url = "https://assets.thefloorr.com/content/dial-codes-no-cache.txt";
let codesCache = null;
let promise = null;

function loadCodes() {

    promise = new Promise(async (resolve, reject) => {
        const csv = await fetch(url).then(r => r.text());
        const lines = csv.split("\r\n");
        const headers = lines.shift().split(";");
        const parsedCodes = lines.map(l => {
                            const row = l.split(";");
                            const obj = {};
                    
                            for (let i = 0, l = row.length; i < l; i++) {
                                obj[headers[i].trim()] = row[i];
                            }

                            obj.key = obj.code;
                            return obj;
                        })
                        .sort((a, b) => {
                            return a.name < b.name ? -1 : a.name === b.name ? 0 : 1;
                        });
        const codes = [];

        parsedCodes.forEach(c => {
            if (c.dial.indexOf(',') !== -1) {
                const dials = c.dial.split(',');
                dials.forEach((dial, inx) => {
                    const entry = { ...c };
                    entry.dial = dial;
                    entry.key = c.code + '-' + inx;
                    codes.push(entry);
                });
            }
            else {
                codes.push(c);
            }
        })

        codesCache = codes;
        promise = null;
        resolve(codesCache);
    });

    return promise;
}

export default async function() {

    if (codesCache) {
        return codesCache;
    }

    if (promise) {
        return promise;
    }

    return loadCodes();
}