import React from "react"
import { connect } from "react-redux"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Pagination from "common/src/components/Pagination"
import Loader from "common/src/components/Loader"

import productUrlById from "common/src/lib/url/productUrlById"
import api from "app/api"
import unaccent from "common/src/lib/unaccent"
import NullForm from "common/src/components/NullForm"

const PER_PAGE = 50;

class CatalogueSearch extends React.Component {

    _isMounted = false

    state = {
        query: "",
        list: [],
        count: 0,
        page: 0,
        loading: false
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSearchChange(query) {
        this.setState({ query });
    }

    onPageChange(no) {
        this.search(no);
    }

    async search(page = 0) {

        this.setState({ loading: true, list: [], count: 0 })

        const query = unaccent(this.state.query);
        const body = { 
            query, 
            limit: PER_PAGE, 
            region: "GB,UK,US",
            stage: "live",
            availability: "in-stock",
            start: page * PER_PAGE 
        }
        const { products, count } = await api.backend.post("/catalogue/search", { body });

        if (this._isMounted) {
            this.setState({ list: products, count, page, loading: false });
        }
    }


    render() {

        const { list, query, count, page, loading } = this.state;

        return (
            <>
            <div className="page page-catalogue-search page-w-loading">
                { loading && <Loader size={ 64 }/> }
                <NullForm className="toolbar">
                    <div>
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            value={ query }
                            label={ query ? "" : "Search" }
                            onKeyDown={ e => e.key === "Enter" && this.search(0) }
                            onChange={ e => this.onSearchChange(e.target.value) }/>
                        <Button 
                            variant="contained"
                            children="Search"
                            disabled={ query.length < 2 }
                            onClick={ e => this.search(0) }/>
                    </div>
                </NullForm>
                <div className="grid-wrapper">
                    <table className="grid">
                        <thead>
                        <tr>
                            <th>Image</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                            { list.map((p, inx) => (
                                <tr key={ p.uid }>
                                    <td>
                                        <img src={ p.images[0] } alt=""/>
                                    </td>
                                    <td>
                                        { p.uid } | { p.product_web_id }<br/>
                                        { p.catalogue_source } | { p.catalogue_file }<br/>
                                        { p.name }<br/>
                                        { p.description }<br/>
                                        <b>{ p.retailer }</b><br/>
                                        <b>{ p.brand }</b><br/>
                                        { p.sale_price > 0 && <b>{ p.sale_price } / </b> }
                                        { p.price } { p.currency } { p.availability }<br/>
                                        { p.details.sizes
                                            .filter(s => s.availability === "in-stock")
                                            .map(s => s.size).join(', ') } 
                                        <s>{ p.details.sizes
                                            .filter(s => s.availability !== "in-stock")
                                            .map(s => s.size).join(', ') }</s>
                                        { p.details.size_system && `(${p.details.size_system})` }<br/>
                                        <a href={ productUrlById(p.retailer, p.product_web_id) }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            children="Product page"/> &nbsp;&nbsp;
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                        page={ page }
                        count={ count }
                        perPage={ PER_PAGE }
                        onChange={ no => this.onPageChange(no) }/>
            </div>
            </>
        )
    }
}

export default connect()(CatalogueSearch)

