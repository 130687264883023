
export default function (qs) {
    let params = {};
    let d = decodeURIComponent;
    let i, j, p;
    // remove preceding non-querystring, correct spaces, and split
    qs = qs.substring(qs.indexOf('?')+1).replace(/\+/g,' ').split('&');
    // march and parse
    for (i = qs.length; i > 0;) {
        p = qs[--i];
        // allow equals in value
        j = p.indexOf('=');
        // what if no val?
        if (j === -1) {
            params[d(p)] = undefined;
        }
        else {
            params[d(p.substring(0,j))] = d(p.substring(j+1));
        }
    }

    return params;
};