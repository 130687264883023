import React, { useCallback, useState  } from "react"
import { useDispatch, useSelector } from "react-redux"
import parsePhoneNumber from 'libphonenumber-js'

import { TextField, Button } from "@mui/material"
import SideDialog from "common/src/components/dialog/SideDialog"
import Loader from "common/src/components/Loader"

import user from "common/src/user"
import { alert } from "common/src/components/dialog/Alert"
import { ui } from "common/src/store/user"
import * as actions from "common/src/actions/user"
import useFormData from "common/src/lib/form/useFormData"
import createValidator from "common/src/lib/form/createValidator"
import required from "common/src/lib/form/validator/required"
import isEmail from "common/src/lib/form/validator/isEmail"
import isPhoneNumber from "common/src/lib/form/validator/isPhoneNumber"
import userSelector from "common/src/selectors/user/current"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useDictRef from "common/src/hooks/useDictRef"
import { getDefaultEmailContact, getDefaultPhoneContact, syncContacts } from "common/src/actions/contacts"

async function validateUniqueEmail(email) {
    const res = await actions.checkEmail(email);
    return !res;
}

async function isValidPhoneNumber(phone) {
    if (phone) {
        return await isPhoneNumber(phone);
    }
    return true;
}

const fields = [
    {
        name: "givenName",
        validator: createValidator(required, "Please enter your name")
    },
    {
        name: "familyName",
        validator: createValidator(required, "Please enter your surname")
    },
    {
        name: "email",
        validator: [
            createValidator(required, "Please enter your email address"),
            createValidator(isEmail, "Please enter a valid email address"),
            createValidator(validateUniqueEmail, "Sorry, you can't use this email")
        ]
    },
    {
        name: "phone",
        defaultValue: "",
        validator: createValidator(isValidPhoneNumber, "Please use a valid phone number")
    }
]



function UserDetailsDialog() {

    const dispatch = useDispatch();
    const current = useSelector(userSelector);
    const show = useSelector(s => s.user.ui.form === "details");
    const saving = useSelector(s => s.user.ui.saving);
    const [ updating, setUpdating ] = useState(false);
    const contacts = current.contacts;
    const currentPhone = contacts.find(c => c.type === "phone") || getDefaultPhoneContact(current);
    const currentEmail = contacts.find(c => c.type === "email") || getDefaultEmailContact(current);
    const {
        validateAll, setErrorMode, resetAll,
        givenName, givenNameChange, givenNameError,
        familyName, familyNameChange, familyNameError,
        email, emailChange, emailError,
        phone, phoneChange, phoneError
    } = useFormData(fields, { ...current, email: currentEmail?.value || "", 
                                            phone: currentPhone?.value || "" });

    const ref = useDictRef({ givenName, familyName, email, phone, currentPhone, currentEmail, updating });

    const onClose = useCallback(
        () => dispatch(ui.form(null)),
        []
    );

    const onSaveClick = useCallback(
        async () => {

            setUpdating(true);
            const valid = await validateAll();
        
            if (!valid) {
                setUpdating(false);
                setErrorMode(true);
                return;
            }

            const emailContact = {
                ...currentEmail,
                value: ref.email.trim(),
                normalized: ref.email.trim().toLowerCase()
            };

            const contacts = [ emailContact ];

            const payload = { 
                givenName: ref.givenName, 
                familyName: ref.familyName, 
                email: ref.email, 
                phone: ""
            };

            if (ref.phone) {
                const parsedPhone = parsePhoneNumber(ref.phone);
                if (parsedPhone) {
                    const dialCode = parsedPhone.countryCallingCode;
                    const phoneNumber = parsedPhone.nationalNumber;
                    const phoneContact = {
                        ...currentPhone,
                        value: `+${ dialCode } ${ phoneNumber }`,
                        normalized: `+${ dialCode }${ phoneNumber.replace(/[^0-9]/g, "") }`
                    };
                    contacts.push(phoneContact);
                    payload.phone = phoneContact.normalized;
                }
            }

            await syncContacts(contacts, user.id());

            await actions.update(payload)
                .then(() => {
                    setUpdating(false);
                    alert({
                        title: "Your details have been updated.",
                        message: "Thanks for keeping your details on THE FLOORR up to date."
                    });
                    //actions.reloadCurrent();
                    onClose();
                })
                .catch(err => alert({
                    title: "User update failed",
                    message: err.message
                }))
                .finally(() => {
                    setUpdating(false);
                });

        },
        []
    );

    useUpdateEffect(
        () => {
            if (!ref.updating) {
                resetAll({ ...current, email: currentEmail?.value || "", 
                                    phone: currentPhone?.value || "" });
            }
        },
        [ current ]
    );

    useUpdateEffect(
        () => {
            if (!show) {
                resetAll({ ...current, email: currentEmail?.value || "", 
                                        phone: currentPhone?.value || "" });
            }
        },
        [ show ]
    );

    return (
        <SideDialog title="Your details" 
                    show={ show }
                    className="dialog-user-details"
                    onClose={ onClose }>
            <TextField 
                fullWidth
                autoFocus
                autoComplete="off"
                variant="outlined"
                size="small"
                label="Given name" 
                error={ !!givenNameError }
                helperText={ givenNameError }
                value={ givenName }
                onChange={ givenNameChange }/>
            <TextField 
                fullWidth
                autoComplete="off"
                variant="outlined"
                size="small"
                label="Last name" 
                error={ !!familyNameError }
                helperText={ familyNameError }
                value={ familyName }
                onChange={ familyNameChange }/>
            
            <TextField 
                fullWidth
                autoFocus
                autoComplete="off"
                variant="outlined"
                size="small"
                label="Email" 
                error={ !!emailError }
                value={ email }
                onChange={ emailChange }
                helperText={ emailError }/>
            <TextField 
                fullWidth
                autoFocus
                autoComplete="off"
                variant="outlined"
                size="small"
                label="Phone number (+1234567890)" 
                value={ phone }
                onChange={ phoneChange }
                error={ !!phoneError }
                helperText={ phoneError }/>

            <Button 
                className="submit"
                variant="contained" 
                disabled={ saving || updating }
                startIcon={ saving || updating ? <Loader inline/> : null }
                onClick={ onSaveClick }
                color="primary">Update</Button>
        </SideDialog>
    )
};

export default UserDetailsDialog

/*
{ user.is(["Admin", "FRI", "GPS"]) && 
                <>
                    <TextField 
                    error={ !!handleError }
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Handle" 
                    value={ handle }
                    onChange={ e => this.onFieldChange("handle", e.target.value) }
                    helperText={ handleError || "" }/>
                </> }
    async validateEmail(email) {
        return actions.checkEmail(email).then(res => !res);
    }

    validate() {

        let valid = true;
        const { email, handle } = this.state;

        if (!email || !isEmail(email)) {
            valid = false;
            this.setState({ emailError: "Please enter a valid email" });
        }
        else {
            this.setState({ emailError: null });
        }

        if (handle) {
            if (handle.length < 4) {
                valid = false;
                this.setState({ handleError: "Please enter at least 4 characters" });
            }
            else if (handle.length > 12) {
                valid = false;
                this.setState({ handleError: "Please enter maximum 12 characters" });
            }
            else if (handle.match(/[\?\#\/]/)) {
                valid = false;
                this.setState({ handleError: "Handle cannot contain ?#/ symbols" });
            }
            else {
                this.setState({ handleError: null });
            }
        }

        return valid;
    }*/