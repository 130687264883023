import Observable from "@kuindji/observable"
import React, { useState, useEffect, useMemo, useCallback } from "react"

import Header from "./Header"
import SummaryRow from "./SummaryRow"
import Row from "./Row"

import cls from "common/src/lib/className"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"


function Table(props) {

    const { dataStore, className, variant = "normal", 
            noHeader, before, after } = props;
    const [ rows, setRows ] = useState(() => (props.rows || []).filter(r => !!r));
    const [ cols, setCols ] = useState(() => (props.cols || []).filter(c => !!c));
    const [ expandedKey, setExpandedKey ] = useState(null);
    const table = useMemo(() => new Observable(), []);

    const tableCls = useMemo(
        () => cls([ "grid", className, `grid-var-${ variant }` ]), 
        [ variant, className ]
    );

    const summary = useMemo(
        () => props.summary === true ? "bottom" : props.summary, 
        [ props.summary ]
    );

    const renderedSummary = useMemo(
        () => summary ? <SummaryRow { ...{ ...props, rows, cols, table }}/> : null,
        [ summary, cols, rows ]
    );

    const onDataStoreUpdate = useCallback(
        () => {
            const { rows, cols } = dataStore.getGrid();
            setRows(rows);
            setCols(cols);
        },
        []
    );

    useUpdateEffect(
        () => {
            setRows((props.rows || []).filter(r => !!r));
            setCols((props.cols || []).filter(c => !!c));
        },
        [ props.rows, props.cols ]
    );

    useEffect(
        () => setExpandedKey(null), 
        [ rows?.length, !!rows ]
    );

    useEffect(
        () => {
            table.on("toggleRow", setExpandedKey);
            if (dataStore) {
                dataStore.on("change", onDataStoreUpdate);
                const { rows, cols } = dataStore.getGrid();
                setRows(rows);
                setCols(cols);
            }
            return () => {
                table.un("toggleRow", setExpandedKey);
                dataStore && dataStore.un("change", onDataStoreUpdate);
            }
        },
        []
    );

    return (
        <table className={ tableCls }>
            { !noHeader && <Header { ...{ ...props, cols }}/> }
            <tbody>
                { before }
                { (summary === "top" || summary === "both") && renderedSummary }
                { rows.map((row, index) => 
                    <Row { ...{ ...props, key: index, row, cols, rows, index, expandedKey, table }}/>)}
                { (summary === "bottom" || summary === "both") && renderedSummary }
                { after }
            </tbody>
        </table>
    )

}

export default Table