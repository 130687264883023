import React from "react"
import PublicSelector from "common/src/components/PublicSelector"
//import async from "common/src/lib/js/async"
import api from "app/api"


class AdvertiserSelector extends React.Component {

    state = {
        search: "",
        advertisers: [],
        loading: false
    }


    onSearchChange(search) {
        this.setState({ search }, () => this.load());
    }

    async load() {

        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const query = `
            query AdvertiserListQuery($where: Network_Order_bool_exp) {
                Network_Order_aggregate(distinct_on: advertiser, 
                                            order_by: {advertiser: asc},
                                            where: $where) {
                    nodes {
                        advertiser
                    }
                }
            }
        `;

        const where = {};
        const { search } = this.state;
        if (search) {
            where.advertiser = { _ilike: `%${ search }%` }
        }

        const advertisers = await api.customGqlQuery(query, { where })
                                .then(r => r.data.Network_Order_aggregate.nodes)
                                .then(list => list.map(node => {
                                    const a = { id: node.advertiser, name: node.advertiser };
                                    return a;
                                }));
        this.setState({ advertisers, loading: false });
    }

    render() {
        const { advertisers, loading } = this.state;
        const props = Object.assign({}, this.props);
        props.onSearchChange = s => this.onSearchChange(s);
        props.onLoadItems = () => this.load();
        props.name = props.name || "Advertiser";
        props.cls = [ "tag-selector-v2", "advertiser-selector" ];
        props.items = advertisers;
        props.filtered = advertisers;
        props.loading = loading;

        return (
            <PublicSelector { ...props }/>
        )
    }
}

export default AdvertiserSelector