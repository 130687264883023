import Link from "common/src/components/Link"
import Wrapper from "./components/Wrapper"
import Loader from "common/src/cross-platform/Loader"

import Button from "@mui/material/Button"
import SharePanel from "common/src/components/look/SharePanel"
import User from "common/src/components/user/User"

import { ReactComponent as IconTick } from "common/src/svg/tick.svg"
import { ReactComponent as IconZoom } from "common/src/svg/zoom-in.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import { ReactComponent as IconHeart } from "common/src/svg/heart.svg"
import { ReactComponent as IconHeartActive } from "common/src/svg/heart-active.svg"
import { ReactComponent as IconBack } from "common/src/svg/back.svg"
import { ReactComponent as IconBasket } from "common/src/svg/basket.svg"
import { ReactComponent as IconBasketActive } from "common/src/svg/basket-active.svg"

import { ReactComponent as IconFav } from "common/src/svg/favorite.svg"
import { ReactComponent as IconFavActive } from "common/src/svg/favorite-active.svg"
import { ReactComponent as IconShare } from "common/src/svg/share.svg"
import { ReactComponent as IconMove } from "common/src/svg/move.svg"


const wrap = (Cmp) => {
    return props => {
        const newProps = { ...props };
        newProps.cmp = Cmp;
        return <Wrapper { ...newProps }/>;
    }
}

const components = {
    "navigation/link": Link,
    "link": Wrapper,
    "container": Wrapper,
    "text": Wrapper,
    "image": "img",

    "loader": Loader,

    "icon/tick": wrap(IconTick),
    "icon/zoom": wrap(IconZoom),
    "icon/close": wrap(IconClose),
    "icon/heart": wrap(IconHeart),
    "icon/heart-active": wrap(IconHeartActive),
    "icon/back": wrap(IconBack),
    "icon/basket": wrap(IconBasket),
    "icon/basket-active": wrap(IconBasketActive),

    "icon/fav": wrap(IconFav),
    "icon/fav-active": wrap(IconFavActive),
    "icon/share": wrap(IconShare),
    "icon/move": wrap(IconMove),

    "component/button": wrap(Button),
    "component/share": wrap(SharePanel),
    "component/user": wrap(User)
}

export default function provideComponent(what) {
    if (Array.isArray(what)) {
        const cmps = {};
        what.forEach(name => cmps[name] = components[name] || null)
        return cmps;
    }
    return components[ what ] || null;
}