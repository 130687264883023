import React from "react"
import PublicSelector from "common/src/components/PublicSelector"
import { distinctValues } from "common/src/api/hasura/add"

class StatusSelector extends React.Component {

    state = {
        search: "",
        statuses: [],
        loading: false
    }

    onSearchChange(search) {
        this.setState({ search }, () => this.load());
    }

    async load() {

        this.setState({ loading: true });

        const where = {};
        const { search } = this.state;
        const order = { status: "asc" };

        if (search) {
            where.status = { _ilike: `%${ search }%` };
        }

        const statuses = await distinctValues("Network_Order", "status", { where, order })
                                .then(list => {
                                    return list.map(s => s ? { id: s, name: s } : null)
                                                .filter(s => !!s);
                                });


        this.setState({ statuses, loading: false });
    }

    render() {
        const { statuses, loading } = this.state;
        const props = Object.assign({}, this.props);
        props.onSearchChange = s => this.onSearchChange(s);
        props.onLoadItems = () => this.load();
        props.name = props.name || "Status";
        props.cls = [ "tag-selector-v2", "status-selector" ];
        props.items = statuses;
        props.filtered = statuses;
        props.loading = loading;

        return (
            <PublicSelector { ...props }/>
        )
    }
}

export default StatusSelector