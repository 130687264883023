
import React, { useMemo, useEffect } from "react"
import { useSelector } from "react-redux"
import TopMenuContainer from "common/src/components/admin-layout/Header"
import { ReactComponent as Logo } from "common/src/svg/tf-admin-logo.svg"
import routes from "app/routes"
import getUrl from "common/src/lib/url/get"
import AppMenu from "common/src/components/Menu"
//import async from "common/src/lib/js/async"
import { loadUserGroups } from "app/actions/page/people"
import user from "common/src/user"

const getMenu = () => [
    {
        accessKey: "admin/dashboard",
        name: "Dashboard",
        routes: [ routes.home ],
        exact: true
    },
    {
        id: "people",
        accessKey: "admin/people",
        name: "People",
        routes: [ routes.people, routes.invitations ],
        url: getUrl(routes.people, { role: "all", page: "" }),
        children: [
            {
                name: "All",
                isActive: l => l.pathname.indexOf("/all") !== -1 && l.search === "",
                url: getUrl(routes.people, { role: "all", page: "" }),
            },
            {
                name: "Customers",
                isActive: null,
                url: getUrl(routes.people, { role: "user", page: "" }),
            },
            {
                name: "PSEs",
                isActive: null,
                url: getUrl(routes.people, { role: "pse", page: "" }),
            },
            /*{
                name: "FRIs",
                isActive: null,
                url: getUrl(routes.people, { role: "fri", page: "" }),
            },*/
            {
                name: "Admins",
                isActive: null,
                url: getUrl(routes.people, { role: "admin", page: "" }),
            },
            /*{
                name: "Contributors",
                isActive: null,
                url: getUrl(routes.people, { role: "contributor", page: "" }),
            },*/
            '|',
            {
                accessKey: "admin/debug",
                accessExact: true,
                name: "Invitations",
                isActive: null,
                url: getUrl(routes.invitations, { page: "" })
            },
            {
                name: "PSE Applications",
                isActive: null,
                url: getUrl(routes.pseapplications, { page: "" })
            },
            {
                name: "Registered Interest",
                isActive: null,
                url: getUrl(routes.visitorapplications, { page: "" })
            },
        ]
    },
    /*{
        accessKey: "admin/search",
        name: "Search",
        routes: [ routes.search, routes.searchHistory, routes.searchSynonyms ],
        children: [
            {
                name: "Search Test",
                routes: [ routes.search ],
                isActive: null,
            },
            {
                name: "Search History",
                routes: [ routes.searchHistory ],
                isActive: null,
            },
            {
                name: "Synonyms",
                routes: [ routes.searchSynonyms ],
                isActive: null,
            }
        ]
    },*/
    
    /*{
        accessKey: "admin/tags",
        name: "Tags",
        routes: [ routes.tags ],
        url: getUrl(routes.tags, { listName: "styles", page: "" }),
        children: [
            {
                name: "Outfit styles",
                isActive: null,
                url: getUrl(routes.tags, { listName: "styles", page: "" })
            },
            {
                name: "Where to wear",
                isActive: null,
                url: getUrl(routes.tags, { listName: "locations", page: "" })
            },
            {
                name: "Style Forecast",
                isActive: null,
                url: getUrl(routes.tags, { listName: "seasons", page: "" })
            },
            {
                name: "Occasions",
                isActive: null,
                url: getUrl(routes.tags, { listName: "occasions", page: "" })
            },
            {
                name: "Designers",
                isActive: null,
                url: getUrl(routes.tags, { listName: "designers", page: "" })
            },
            {
                name: "Product styles",
                isActive: null,
                url: getUrl(routes.tags, { listName: "productStyles", page: "" })
            },
            {
                name: "Hidden tags",
                isActive: null,
                url: getUrl(routes.tags, { listName: "hiddenTags", page: "" })
            }
        ]
    },*/

    {
        accessKey: "admin/analytics",
        name: "Analytics",
        routes: [ routes.reportCommissions, routes.reportClicks, routes.reportLinks, routes.reportFris ],
        children: [
            {
                name: "Commissions",
                //exact: true,
                routes: [ routes.reportCommissions ],
                isActive: null
            },
            {
                name: "Clicks",
                //exact: true,
                routes: [ routes.reportClicks ],
                isActive: null
            },
            {
                name: "Links",
                //exact: true,
                routes: [ routes.reportLinks ],
                isActive: null
            },
            /*{
                name: "Looks",
                exact: true,
                routes: [ routes.reportFris ],
                isActive: null
            },*/
            {
                name: "Raw report",
                //exact: true,
                routes: [ routes.reportRaw ],
                isActive: null
            },
            {
                name: "PSE report",
                //exact: true,
                routes: [ routes.reportPse ],
                isActive: null
            },
            {
                name: "PSE Aggregation report",
                //exact: true,
                routes: [ routes.reportPseAgg ],
                isActive: null
            }
        ]
    },
    {
        accessKey: "admin/accounting",
        name: "Accounting",
        routes: [ routes.accountingCommissions ],
        children: [
            {
                name: "Commissions",
                exact: true,
                routes: [ routes.accountingCommissions ],
                isActive: null
            },
            {
                name: "Payments",
                exact: true,
                routes: [ routes.accountingPayments ],
                isActive: null
            },
            {
                name: "User rates",
                exact: true,
                routes: [ routes.accountingSettings ],
                isActive: null
            },
        ]
    },
    {
        accessKey: "admin/catalogue",
        name: "Catalogue",
        routes: [ routes.catalogueSearch, routes.catalogueFiles, routes.products,
                    routes.catalogueCategories, routes.catalogueCategoryQueue, 
                    routes.catalogueCharacteristics, routes.catalogueCharacteristicQueue,
                    routes.catalogueColors, routes.catalogueColorQueue,
                    routes.catalogueMaterials, routes.catalogueMaterialQueue,
                    routes.catalogueIgnored, routes.catalogueDesignerQueue ],
        children: [
            {
                accessKey: "admin/debug",
                accessExact: true,
                exact: true,
                name: "Search",
                routes: [ routes.catalogueSearch ],
                isActive: null
            },
            {
                accessKey: "admin/debug",
                accessExact: true,
                exact: true,
                name: "Files",
                routes: [ routes.catalogueFiles ],
                isActive: null
            },
            {
                accessKey: "admin/debug",
                accessExact: true,
                exact: true,
                name: "Products",
                routes: [ routes.products ],
                isActive: null
            },
            user.hasAccessTo("admin/debug", true) ? "|" : null,
            {
                exact: true,
                name: "Designers",
                routes: [ routes.catalogueDesigners ],
                isActive: null
            },
            {
                exact: true,
                name: "Top Designers",
                routes: [ routes.catalogueDesignersTop ],
                isActive: null
            },
            {
                exact: true,
                name: "Designers exclusion",
                routes: [ routes.catalogueDesignersExclusion ],
                isActive: null
            },
            {
                exact: true,
                name: "Categories",
                routes: [ routes.catalogueCategories ],
                isActive: null
            },
            {
                exact: true,
                name: "Colors",
                routes: [ routes.catalogueColors ],
                isActive: null
            },
            {
                exact: true,
                name: "Materials",
                routes: [ routes.catalogueMaterials ],
                isActive: null
            },
            {
                exact: true,
                name: "Characteristics",
                routes: [ routes.catalogueCharacteristics ],
                isActive: null
            },
            "|",
            {
                exact: true,
                name: "Designer queue",
                routes: [ routes.catalogueDesignerQueue ],
                isActive: null
            },
            {
                exact: true,
                name: "Category queue",
                routes: [ routes.catalogueCategoryQueue ],
                isActive: null
            },
            {
                exact: true,
                name: "Color queue",
                routes: [ routes.catalogueColorQueue ],
                isActive: null
            },
            {
                exact: true,
                name: "Material queue",
                routes: [ routes.catalogueMaterialQueue ],
                isActive: null
            },
            /*{
                exact: true,
                name: "Characteristic queue",
                routes: [ routes.catalogueCharacteristicQueue ],
                isActive: null
            },*/
            "|",
            {
                exact: true,
                name: "Ignored items",
                routes: [ routes.catalogueIgnored ],
                isActive: null
            }
        ]
    },
    {
        name: "Marketing",
        routes: [ routes.marketingBanners ],
        children: [
            {
                exact: true,
                name: "Banners",
                routes: [ routes.marketingBanners ],
                isActive: null
            },
        ]
    },
    {
        accessKey: "admin/tools",
        accessExact: true,
        name: "Tools",
        routes: [ routes.toolsChats ],
        children: [
            {
                accessKey: "admin/tools/chats",
                name: "Chats",
                exact: true,
                routes: [ routes.toolsChats ],
                isActive: null
            },
            {
                accessKey: "admin/tools/connections",
                name: "Connections",
                exact: true,
                routes: [ routes.toolsConnections ],
                isActive: null
            },
            {
                accessKey: "admin/tools/revolut",
                name: "Revolut",
                exact: true,
                routes: [ routes.toolsRevolut ],
                isActive: null
            },
            {
                accessKey: "admin/tools/timing",
                name: "Performance timing",
                exact: true,
                routes: [ routes.toolsTiming ],
                isActive: null
            },
            {
                accessKey: "admin/tools/removebg",
                name: "Removebg balance",
                exact: true,
                routes: [ routes.toolsRemovebg ],
                isActive: null
            }
        ]
    }
];

function TopMenu() {

    const userGroups = useSelector(s => s.peoplePage.data.userGroups);
    const menu = useMemo(
        () => {
            const menu = getMenu();
            const menuItems = userGroups.map(g => {
                return {
                    exact: true,
                    name: g.name,
                    isActive: (l) => l.search.indexOf(g.id) !== -1,
                    url: getUrl(routes.people, { role: "all", g: g.id, page: "" }),
                }
            });
    
            if (menuItems.length > 0) {
                menuItems.unshift("|");
                const people = menu.find(m => m.id === "people");
                people.children = people.children.concat(menuItems);
            }

            return menu;
        },
        [ userGroups ]
    );

    useEffect(() => { loadUserGroups() }, []);

    return (
        <TopMenuContainer logo={ <Logo/> }>
            <AppMenu menu={ menu } className="header-nav"/>
        </TopMenuContainer>
    )
}

export default TopMenu

