import React from "react"
import moment from "moment"
import Button from "@mui/material/Button"
import InputMask from "react-input-mask"
import TextField from "@mui/material/TextField"
import { LocalizationProvider, StaticDatePicker, PickersDay } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ReactComponent as IconExpand } from "common/src/svg/expand.svg"
import NullForm from "common/src/components/NullForm"

const extractValue = (value, inx) => {
    return value[inx] ?
            typeof value[inx] === "string" ?
                new Date(value[inx]) :
                value[inx]:
            null;
}

class DateRangeSelector extends React.Component {

    state = {
        start: null,
        end: null,
        hover: null,

        startInput: null,
        endInput: null,
        
        showTo: false
    }

    componentDidMount() {
        const value = this.props.value || [];
        const start = extractValue(value, 0);
        const end = extractValue(value, 1);

        if (start && end) {
            this.setState({ start, end });
        }
    }

    componentDidUpdate(prev) {
        const value = this.props.value || [];
        const pvalue = prev.value || [];
        const start = extractValue(value, 0);
        const end = extractValue(value, 1);
        const pstart = extractValue(pvalue, 0);
        const pend = extractValue(pvalue, 1);

        if (moment(start).format("YYYY-MM-DD") !== moment(pstart).format("YYYY-MM-DD") ||
            moment(end).format("YYYY-MM-DD") !== moment(pend).format("YYYY-MM-DD")) {
            this.setState({ start, end });
        }
    }

    onStartChange(value) {
        const { start, end } = this.state;
        if (!start || (start && end) || value.getTime() < start.getTime()) {
            this.setState({ start: value, end: null });
        }
        else {
            this.setState({ end: value });
        }
    }

    onEndChange(value) {
        const { start, end } = this.state;
        if (start && value.getTime() > start.getTime()) {
            this.setState({ end: value });
        }
        if (!start) {
            this.setState({ start: value, end: null });
        }
        if (start && value.getTime() < start.getTime()) {
            return;
        }
    }

    onStartFieldChange(e) {
        const startInput = e.target.value;
        const start = moment.utc(startInput);
        if (start.format() === "Invalid date") {
            this.setState({ startInput, start: null });
        }
        else {
            this.setState({ start, startInput: null });
        }
    }

    onEndFieldChange(e) {
        const endInput = e.target.value;
        const end = moment.utc(endInput);
        if (end.format() === "Invalid date") {
            this.setState({ endInput, end: null });
        }
        else {
            this.setState({ end, endInput: null });
        }
    }

    setPeriod(e, period) {
        e.preventDefault();
        e.stopPropagation();

        switch (period) {
            case "today": {
                this.setState({
                    start: moment().toDate(),
                    end: moment().toDate()
                });
                break;
            }
            case "last-7": {
                this.setState({
                    start: moment().subtract(7, "days").toDate(),
                    end: moment().toDate()
                });
                break;
            }
            case "last-30": {
                this.setState({
                    start: moment().subtract(30, "days").toDate(),
                    end: moment().toDate()
                });
                break;
            }
            case "this-week": {
                this.setState({
                    start: moment().startOf("week").toDate(),
                    end: moment().endOf("week").toDate()
                });
                break;
            }
            case "this-month": {
                this.setState({
                    start: moment().startOf("month").toDate(),
                    end: moment().endOf("month").toDate()
                });
                break;
            }
            case "prev-month": {
                this.setState({
                    start: moment().subtract(1, 'month').startOf("month").toDate(),
                    end: moment().subtract(1, 'month').endOf("month").toDate()
                });
                break;
            }
            case "this-year": {
                this.setState({
                    start: moment().startOf("year").toDate(),
                    end: moment().endOf("year").toDate()
                });
                break;
            }
            case "prev-year": {
                this.setState({
                    start: moment().subtract(1, 'year').startOf("year").toDate(),
                    end: moment().subtract(1, 'year').endOf("year").toDate()
                });
                break;
            }
        }
    }

    renderDate(day, selectedDates, cmpProps, cal) {

        const { start, end, hover, showTo } = this.state;

        let cls = '';

        if (start && (end || hover)) {
            const d = moment(day).startOf("day").toDate().getTime()
            const s = start ? moment(start).startOf("day").toDate().getTime() : null;
            const e = end ? moment(end).startOf("day").toDate().getTime() : null;
            const h = hover ? moment(hover).startOf("day").toDate().getTime() : null;
    
            if (d >= s) {
                if (d === s || d === e) {
                    cls = 'date-range-selector-selected';
                    if (d === s) {
                        cls += ' date-range-selector-start'
                        if (cal === 'end') {
                            cls += ' date-range-selector-shadow'
                        }
                    }
                    if (d === e) {
                        cls += ' date-range-selector-end'
                        if (cal === 'start' && showTo) {
                            cls += ' date-range-selector-shadow'
                        }
                    }
                }
                else if (s && e && s < d && d < e) {
                    cls = 'date-range-selector-range';
                }
                else if (s && !e && h && s < d && d <= h) {
                    cls = 'date-range-selector-range';
                }
            }
        }

        // cls = cmpProps.className ? cmpProps.className + " " + cls : cls;
        // cmpProps.className = cls;
        // cmpProps.onMouseOver = e => this.setState({ hover: day });

        //return <PickersDay { ...cmpProps }/>

        return (
            <div className={ cls } 
                    key={ day.toISOString() }
                    onMouseOver={ e => this.setState({ hover: day }) }>
                { <PickersDay { ...cmpProps }/> }
            </div>
        )    
    }

    getValue() {
        const { value = [] } = this.props;
        const start = extractValue(value, 0);
        const end = extractValue(value, 1);
        return { start, end };
    }

    onApply() {
        this.props.onChange([ this.state.start, this.state.end ]);
    }

    onCancel() {
        this.props.onCancel();
    }

    onClear() {
        this.props.onChange([ null, null ]);
    }

    toggleTo(e) {
        e.preventDefault();
        e.stopPropagation();
        let showTo = this.state.showTo;
        this.setState({ showTo: !showTo });
    }

    render() {

        const { disabled = false, minDate, maxDate } = this.props;
        const { start, end, startInput, endInput, showTo } = this.state;

        const startStr = start ? moment(start).format("YYYY-MM-DD") : "";
        const endStr = end ? moment(end).format("YYYY-MM-DD") : "";
        const cals = [
            <StaticDatePicker 
                key="1"
                displayStaticWrapperAs="desktop"
                openTo="day"
                disableToolbar
                disableHighlightToday={ true }
                disabled={ disabled }
                value={ start }
                minDate={ minDate }
                maxDate={ maxDate }
                onChange={ value => this.onStartChange(value) }
                renderDay={ (d, s, ps) => this.renderDate(d, s, ps, 'start') }
                renderInput={(params) => <TextField autoComplete="off" {...params} />}/>
        ];

        if (showTo) {
            cals.push(
                <StaticDatePicker     
                    key="2"
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    disableToolbar
                    disableHighlightToday={ true }
                    disabled={ disabled || !start }
                    minDate={ start }
                    maxDate={ maxDate }
                    value={ end }
                    onChange={ value => this.onEndChange(value) }
                    renderDay={ (d, s, ps) => this.renderDate(d, s, ps, 'end') }
                    renderInput={(params) => <TextField autoComplete="off" {...params} />}/>
            )
        }

        return (
            <div className="date-range-selector">
                <div className="date-range-selector-templates">
                    <a href="/#" onClick={ e => this.setPeriod(e, "today") }>Today</a>
                    <a href="/#" onClick={ e => this.setPeriod(e, "last-7") }>Last 7 days</a>
                    <a href="/#" onClick={ e => this.setPeriod(e, "last-30") }>Last 30 days</a>
                    <a href="/#" onClick={ e => this.setPeriod(e, "this-week") }>This week</a>
                    <a href="/#" onClick={ e => this.setPeriod(e, "this-month") }>This month</a>
                    <a href="/#" onClick={ e => this.setPeriod(e, "this-year") }>This year</a>
                    <a href="/#" onClick={ e => this.setPeriod(e, "prev-month") }>Previous month</a>
                    <a href="/#" onClick={ e => this.setPeriod(e, "prev-year") }>Previous year</a>
                    <div className="date-range-selector-actions">
                        <Button 
                            variant="contained" 
                            children="Apply"
                            onClick={ () => this.onApply() }/>
                        <Button 
                            variant="text" 
                            children="Clear"
                            onClick={ () => this.onClear() }/>
                    </div>
                </div>
                <div className="date-range-selector-form">
                    <NullForm className="date-range-selector-inputs">
                        <InputMask 
                            value={ startInput || startStr } 
                            disabled={ disabled }
                            mask="9999-99-99"
                            maskPlaceholder="yyyy-mm-dd"
                            alwaysShowMask
                            onChange={ v => this.onStartFieldChange(v) }>
                            { () => <TextField variant="outlined" fullWidth autoComplete="off"/> }
                        </InputMask>
                        <label>&mdash; to &mdash;</label>
                        <InputMask 
                            value={ endInput || endStr } 
                            disabled={ disabled || !start }
                            mask="9999-99-99"
                            maskPlaceholder="yyyy-mm-dd"
                            alwaysShowMask
                            onChange={ v => this.onEndFieldChange(v) }>
                            { () => <TextField variant="outlined" fullWidth autoComplete="off"/> }
                        </InputMask>
                    </NullForm>
                    <div className="date-range-selector-calendars">
                        <LocalizationProvider 
                            dateAdapter={ AdapterDateFns }
                            children={ cals }/>
                    </div>
                </div>
                <div className={ "date-range-selector-toggle " + (showTo ? "active" : "") }>
                    <a href="/#" onClick={ e => this.toggleTo(e) }>
                        <IconExpand/>
                    </a>
                </div>
            </div>
        )
    }
}

export default DateRangeSelector
