import hub from "common/src/hub"
import store from "app/store"
import * as actions from "app/actions/page/tags"
import listUpdater from "common/src/listeners/listUpdater"
import { data } from "app/store/page/tags"

function onTagCreated(type) {
    actions.reloadTagList(type);
}

function onTagDeleted(type, ids) {

    if (typeof ids === "string") {
        return;
    }

    let state = store.getState().tagsPage.data,
        list = state[type + "s"];

    if (list.length > 0) {
        if (list.find(t => ids.indexOf(t.id) !== -1)) {
            actions.reloadTagList(type);
        }
    }
}

function onTagsMerged(type, ids) {
    let state = store.getState().tagsPage.data,
        list = state[type + "s"];

    if (list.length > 0) {
        if (list.find(t => ids.indexOf(t.id) !== -1)) {
            actions.reloadTagList(type);
        }
    }
}


const listeners = {
    init: function() {
        let stylesUpdater = listUpdater.create("tagsPage", "styles", data);
        let seasonsUpdater = listUpdater.create("tagsPage", "seasons", data);
        let designersUpdater = listUpdater.create("tagsPage", "designers", data);
        let locationsUpdater = listUpdater.create("tagsPage", "locations", data);
        let occasionsUpdater = listUpdater.create("tagsPage", "occasions", data);
        let pStylesUpdater = listUpdater.create("tagsPage", "productStyles", data);
        //let hiddenTagUpdater = listUpdater.create("tagsPage", "hiddenTags", data);


        ["style", "location", "season", "designer", 
            "occasion", "productStyle", "hiddenTag"].forEach(type => {
            hub.listen(type, "created", id => onTagCreated(type, id));
            hub.listen(type, "deleted", ids => onTagDeleted(type, ids));
            hub.listen(type, "merged", ids => onTagsMerged(type, ids));
        })

        hub.listen("style", "picked", t => stylesUpdater(t));
        hub.listen("location", "picked", t => locationsUpdater(t));
        hub.listen("season", "picked", t => seasonsUpdater(t));
        hub.listen("designer", "picked", t => designersUpdater(t));
        hub.listen("occasion", "picked", t => occasionsUpdater(t));
        hub.listen("productStyle", "picked", t => pStylesUpdater(t));
    }
}

export default listeners