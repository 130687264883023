import { useCallback, useState, useMemo, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Button, TextField } from "@mui/material"

import Loader from "common/src/components/Loader"
import ProductDialog from "common/src/components/catalogue/ProductDialog"
import Pagination from "common/src/components/Pagination"
import Products from "common/src/components/catalogue/products/PaginatedProducts"
import TagList from "common/src/components/tag/List"

import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"

import { ui as ddUi } from "common/src/store/dialogs"

import { confirm } from "common/src/components/dialog/Alert"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useDictRef from "common/src/hooks/useDictRef"

import api from "app/api"
import NullForm from "common/src/components/NullForm"


const PER_PAGE = 50;

async function removeDesignerFeedRecord(designerId, feedDesignerId) {
    const where = {
        designerId: { _eq: designerId },
        feedDesignerId: { _eq: feedDesignerId }
    }
    await api.catalogueDesignerFeed.remove(where);
}


function Designer({ designer, onOpenProduct, onRemoved, onEdited }) {

    const [ open, setOpen ] = useState(false);
    const [ showForm, setShowForm ] = useState(false);
    const [ feed, setFeed ] = useState([]);
    const ref = useDictRef({ designer, onEdited, onRemoved });

    const cls = useMemo(
        () => [ "page-catalogue-color", open ? "active" : "" ].join(" "),
        [ open ]
    );

    const loadFeedDesigners = useCallback(
        async () => {
            const where = {
                designerId: { _eq: ref.designer.id }
            };
            const order = { feedDesignerId: "asc" };
            const feed = await api.catalogueDesignerFeed.list({ where, order });
            setFeed(feed.map(f => {
                f.name = f.feedDesignerName;
                return f;
            }));
        },
        // eslint-disable-next-line
        []
    );

    const onEditClick = useSwallowEventCallback(
        () => setShowForm(true),
        []
    );

    const onCancel = useCallback(
        () => setShowForm(false),
        []
    );

    const onRemoveClick = useSwallowEventCallback(
        async () => {
            const res = await confirm({ message: "Are you sure?" });
            if (res) {
                await api.catalogueDesigner.remove(designer.id);
                ref.onRemoved();
            }
        },
        // eslint-disable-next-line
        [ designer ]
    );

    const onRemoveFeedId = useCallback(
        async (fr) => {
            await removeDesignerFeedRecord(ref.designer.id, fr.feedDesignerId);
            loadFeedDesigners();
            //ref.onEdited();
        },
        // eslint-disable-next-line
        []
    );

    const openDesigner = useSwallowEventCallback(
        () => {
            setOpen(!open);
            loadFeedDesigners();
        },
        // eslint-disable-next-line
        [ open ]
    );

    if (showForm) {
        return (
            <DesignerForm 
                designer={ designer }
                onCreate={ onEdited }
                onClose={ onCancel }/>
        )
    }

    return (
        <div className={ cls }>
            <div>
                <h4>
                    { designer.name }
                    <a href="/#" onClick={ openDesigner }>
                        <IconDown/>
                    </a> 
                </h4>
            </div>


            <div className="actions">
                <a href="/#" onClick={ onEditClick }>
                    <IconEdit/>
                </a>
                <a href="/#" onClick={ onRemoveClick }>
                    <IconDelete/>
                </a>
            </div>  

            { (open && feed.length > 0) && 
                <div className="page-catalogue-feed-colors">
                    <TagList tags={ feed } onDelete={ onRemoveFeedId }/>
                </div> }
            { (open && feed.length > 0) && 
                <Products 
                    autoload
                    setName={ designer.id }
                    perPage={ 16 }
                    brand={ feed.map(f => f.feedDesignerName) }
                    product={ p => ({ onClick: () => onOpenProduct(p) })}
                    emptyText="Currently, no products found"/>}
        </div>
    )
}

function DesignerForm({ designer, onClose, onCreate }) {

    const [ name, setName] = useState(designer?.name || "");
    const [ creating, setCreating ] = useState(false);
    const ref = useDictRef({ name, designer });

    const create = useCallback(
        async () => {
            setCreating(true);

            if (ref.designer) {
                await api.catalogueDesigner.update(designer.id, { name: ref.name });
            }
            else {
                await api.catalogueDesigner.create({ name: ref.name });
            }

            setCreating(false);
            onCreate();
            onClose();
        },
        // eslint-disable-next-line
        []
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                create();
            }
        },
        // eslint-disable-next-line
        []
    );

    const onCancel = useCallback(
        () => { onClose() },
        [ onClose ]
    );

    const onNameChange = useCallback(
        (e) => setName(e.target.value),
        []
    );


    return (
        <NullForm className="page-catalogue-color-form">
            <TextField 
                autoComplete="off"
                variant="outlined" 
                placeholder="Designer name"
                value={ name }
                disabled={ creating }
                onKeyDown={ onKeyDown }
                onChange={ onNameChange }/>
            <Button
                variant="contained"
                children={ designer ? "Save" : "Create" }
                startIcon={ creating ? <Loader inline/> : null }
                disabled={ creating || !name }
                onClick={ create }/>
            <Button 
                disabled={ creating }
                variant="text" 
                children="Cancel"
                onClick={ onCancel }/>
        </NullForm>
    )
}


function CatalogueDesigners() {

    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState(false);
    const [ query, setQuery ] = useState("");
    const [ count, setCount ] = useState(0);
    const [ designers, setDesigners ] = useState([]);
    const [ page, setPage ] = useState(0);
    const [ product, setProduct ] = useState(null);
    const [ showNew, setShowNew ] = useState(false);
    const ref = useDictRef({ query, page });

    const loadList = useCallback(
        async () => {
            setLoading(true);

            const where = {};
            const limit = PER_PAGE;
            const offset = ref.page * PER_PAGE;
            const order = { name: "asc" };

            if (ref.query) {
                where.name = { _ilike: `%${ ref.query }%` };
            }

            const designers = await api.catalogueDesigner.list({ where, order, offset, limit });
            const count = await api.catalogueDesigner.count({ where }).then(r => r.count);

            setDesigners(designers);
            setCount(count);
            setLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const onSearchKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                loadList();
            }
        },
        // eslint-disable-next-line
        []
    );

    const onSearchChange = useCallback(
        (e) => setQuery(e.target.value),
        []
    );

    const onOpenProduct = useCallback(
        (p) => {
            setProduct(p);
            dispatch(ddUi.show("product-details"));
        },
        // eslint-disable-next-line
        []
    );

    const onCreateNew = useCallback(
        () => setShowNew(true),
        []
    );

    const onNewDesignerClose = useCallback(
        () => setShowNew(false),
        []
    );

    const onDesignerCreated = useCallback(
        () => { loadList() },
        // eslint-disable-next-line
        []
    );

    const onDesignerRemoved = useCallback(
        () => { loadList() },
        // eslint-disable-next-line
        []
    );

    const onDesignerEdited = useCallback(
        () => { loadList() },
        // eslint-disable-next-line
        []
    );


    useEffect(
        () => { 
            loadList();
            try {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
            catch (err) {}
        },
        // eslint-disable-next-line
        [ page ]
    );
    

    return (
        <>
        <div className="page page-catalogue-colors">
            { loading && <Loader size={ 64 }/> }
            <h2>Designers</h2>
            <NullForm className="toolbar">
                <Button 
                    variant="contained" 
                    children="Add designer"
                    onClick={ onCreateNew }/>

                <div className="spacer-full"/>
                <TextField 
                    autoComplete="off"
                    placeholder={ `Search (total: ${ count })`  }
                    variant="outlined"
                    value={ query }
                    onKeyDown={ onSearchKeyDown }
                    onChange={ onSearchChange }/>
                <Button 
                    variant="contained" 
                    children="Search"
                    onClick={ loadList }/>
            </NullForm>
            { showNew && <DesignerForm onClose={ onNewDesignerClose } onCreate={ onDesignerCreated }/> }
            { designers.map(d => 
                <Designer 
                    designer={ d } 
                    key={ d.id }
                    onOpenProduct={ onOpenProduct }
                    onRemoved={ onDesignerRemoved }
                    onEdited={ onDesignerEdited }/>)}

            <Pagination
                page={ page }
                count={ count }
                perPage={ PER_PAGE }
                onChange={ setPage }/>
        </div>
        <ProductDialog product={ product }/>
        </>
    )
}

export default CatalogueDesigners