import React, { useState, useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import Pagination from "common/src/components/Pagination"
import Products from "./Products"

import { PER_PAGE } from "common/src/actions/catalogue"
import useDictRef from "common/src/hooks/useDictRef"

function PaginatedProducts(props) {

    const cbRef = useDictRef({ 
        onPageChange: props.onPageChange, 
        onResettingChange: props.onResettingChange 
    });
    const pageRef = useRef(null);
    const count = useSelector(s => s.catalogue.ui.products.misc[ props.setName ]?.count || 0);
    const [ statePage, setStatePage ] = useState(0);
    const { perPage = PER_PAGE, page = statePage } = props;
    const start = useMemo(() => page * perPage, [ page, perPage ]);
    const onPageChange = useCallback(
        (page) => cbRef.onPageChange ? cbRef.onPageChange(page) : setStatePage(page),
        []
    );

    pageRef.current = page;

    const onResettingChange = useCallback(
        () => { 
            if (pageRef.current !== 0) {
                setStatePage(0);
                cbRef.onPageChange && cbRef.onPageChange(0);
            } 
            return cbRef.onResettingChange ? 
                    cbRef.onResettingChange(pageRef.current) : 
                    /* return false if products list should not udpate in this iteration;
                        it will update on page change */
                    (pageRef.current === 0);
        }, 
        []
    );

    return (
        <>
        <Products 
            { ...props } 
            start={ start } 
            limit={ perPage }
            onResettingChange={ onResettingChange }/>
        <Pagination
            page={ page }
            count={ count }
            perPage={ perPage }
            onChange={ onPageChange }/>
        </>
    )

}


export default PaginatedProducts
