
import React from "react"
import Button from "@mui/material/Button"
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

const LinkButton = props => {
    const { history, location, match,
        staticContext, onClick,
        target, ...rest } = props;

    const to = rest.to;

    if (rest.component === 'a' && to) {
        delete rest.to;
        rest.href = to;
    }

    return (
        <Button {...rest}
            onClick={e => {
                onClick && onClick(e);
                if (!e.isDefaultPrevented()) {
                    setTimeout(() => {
                        if (target === "_blank") {
                            window.open(to, target);
                        }
                        else {
                            if (to.indexOf("http") !== 0) {
                                history.push(to)
                            }
                            else {
                                window.location = to;
                            }
                        }
                    }, 200);
                }
            }} />
    )
}

LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

export default withRouter(LinkButton)
