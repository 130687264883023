
export const STATUS_PENDING = 0;
export const STATUS_APPROVED = 1;
export const STATUS_REJECTED = 2;
export const STATUS_MIXED = 3;
export const STATUS_APPROVED_AUTO = 11;
export const STATUS_APPROVED_MANUAL = 12;
export const STATUS_REJECTED_MANUAL = 22;

export const statusNames = {
    [STATUS_PENDING]: "Pending",
    [STATUS_APPROVED]: "Approved",
    [STATUS_REJECTED]: "Rejected",
    [STATUS_APPROVED_AUTO]: "Auto approved",
    [STATUS_APPROVED_MANUAL]: "Manually approved",
    [STATUS_REJECTED_MANUAL]: "Manually rejected",
    [STATUS_MIXED]: "Mixed",
};

export function getOrderItemStatus(order, item) {
    if (order.networkId === "rakuten") {

        if (item.manualStatus === "approved") {
            return STATUS_APPROVED_MANUAL;
        }
        else if (item.manualStatus === "rejected") {
            return STATUS_REJECTED_MANUAL;
        }
        else if (item.saleAmount <= 0) {
            return STATUS_REJECTED;
        }
        else if (order.autoApprovedAt !== null) {
            return STATUS_APPROVED_AUTO;
        }
        return STATUS_PENDING;
    }
    else if (order.networkId === "partnerize") {
        if (item.item_status === "approved") {
            return STATUS_APPROVED;
        }
        else if (item.item_status === "pending") {
            return STATUS_PENDING;
        }
        else if (item.item_status === "rejected") {
            return STATUS_REJECTED;
        }
        else if (item.item_status === "declined") {
            return STATUS_REJECTED;
        }
        // const s = item.item_status;// || item.status;
        // console.log(s, item)
        // return s[0].toUpperCase() + s.substring(1);
    }
    else if (order.networkId === "cj") {

        if (item.quantity === 0) {
            return STATUS_REJECTED;
        }
        else if (order.status === "new") {
            return STATUS_PENDING;
        }
        else if (order.status === "locked") {
            return STATUS_PENDING;
        }
        else if (order.status === "closed") {
            return item.quantity > 0 ? STATUS_APPROVED : STATUS_REJECTED;
        }
        return null;
    }
    return "";
}

export function getOrderItemStatusName(order, item) {
    const s = getOrderItemStatus(order, item);
    return statusNames[s];
}

export function getOrderItemStatuses(order) {

    let statuses = [];

    if (order.networkId === "rakuten") {
        statuses = order.rakutenItems.map(i => {
            return getOrderItemStatus(order, i);
        })
    }
    else if (order.networkId === "partnerize") {
        const details = JSON.parse(order.details);
        statuses = details.conversion_items.map(i => {
            return getOrderItemStatus(order, i);
        });
    }
    else if (order.networkId === "cj") {
        const details = JSON.parse(order.details);
        statuses = details.items.map(i => {
            return getOrderItemStatus(order, i);
        });
    }

    statuses = statuses.filter(s => s !== null && s !== undefined);
    statuses = statuses.filter((s, inx, self) => self.indexOf(s) === inx);

    return statuses;
}

export function getOrderStatusName(order) {
    const status = getOrderStatus(order);
    if (status === STATUS_MIXED) {
        const ss = getOrderItemStatuses(order).map(s => statusNames[s]);
        return `Mixed (${ss.join("/")})`;
    }
    else return statusNames[status];
}

export function getOrderStatus(order) {

    const itemStatuses = getOrderItemStatuses(order);

    if (itemStatuses.length === 1) {
        return itemStatuses[0];
    }
    else if (itemStatuses.length > 1) {
        return STATUS_MIXED;
        //return `Mixed (${ itemStatuses.join("/") })`;
    }

    if (!order.status) {
        if (order.saleAmount <= 0) {
            return STATUS_REJECTED;
        }
        if (order.autoApprovedAt !== null) {
            return STATUS_APPROVED_AUTO;
        }
        return STATUS_PENDING;
    }

    if (order.status === "new") {
        return STATUS_PENDING;
    }

    if (order.status === "closed") {
        return STATUS_APPROVED;
    }

    if (order.status === "declined") {
        return STATUS_REJECTED;
    }

    //return order.status[0].toUpperCase() + order.status.substring(1);
    return null;
}

export function getItemStatusClass(order, item) {
    const s = getOrderItemStatus(order, item);
    if (s === STATUS_APPROVED ||
        s === STATUS_APPROVED_AUTO ||
        s === STATUS_APPROVED_MANUAL) {
        return "ok";
    }
    if (s === STATUS_REJECTED || s === STATUS_REJECTED_MANUAL) {
        return "notok";
    }
    if (s === STATUS_PENDING) {
        return "pending";
    }
    return "";
}

export function getStatusClass(comm) {
    const s = getOrderStatus(comm);
    if (s === STATUS_APPROVED ||
        s === STATUS_APPROVED_AUTO ||
        s === STATUS_APPROVED_MANUAL) {
        return "ok";
    }
    if (s === STATUS_REJECTED || s === STATUS_REJECTED_MANUAL) {
        return "notok";
    }
    if (s === STATUS_PENDING) {
        return "pending";
    }

    if (s === STATUS_MIXED) {
        const ss = getOrderItemStatuses(comm);
        if (ss.length === 1) {
            return ss[0];
        }
        if (ss.indexOf(STATUS_PENDING) !== -1) {
            return "pending";
        }
        return "ok";
    }

    return "";
}