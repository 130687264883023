
import getQueryParam from "./getQueryParam"

export default (params, props) => {

    const state = {};
    let hash = "";
    const matchParams = props && props.match ?
                            props.match.params || {} : {};

    params.forEach(p => {

        const name = p.name;
        let value = matchParams[ name ] ||
                    getQueryParam(name) ||
                    p.defaultValue ||
                    null;

        hash += value || "";

        if (value && p.type) {
            switch (p.type) {
                case "float": {
                    value = parseFloat(value);
                    break;
                }
                case "int": {
                    value = parseInt(value);
                    break;
                }
                case "bool":
                case "boolean": {
                    if (value === "none") {
                        value = null;
                    }
                    else if (value === 1 || value === "1" || value === "on" || value === "true") {
                        value = true;
                    }
                    else {
                        value = false;
                    }
                    break;
                }
                case "array": {
                    if (!Array.isArray(value)) {
                        value = value.split(",");
                    }
                }
            }
        }

        if (p.restore) {
            value = p.restore(value, name, state);
        }

        state[ p.stateName || name ] = value;
    });

    state.__hash = hash;

    return state;
}


export const list2param = (list) => {
    if (!list) {
        return null;
    }
    return list.map(v => typeof v === "string" ? v : v.id);
}