import { useEffect } from "react"

function useKeySetter(key, ctx, value, namespace) {

    useEffect(
        () => void ctx?.set(key, value, namespace),
        [ value, ctx, key, namespace ]
    );
}

export default useKeySetter