import { useEffect, useCallback, useState, useMemo } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"

import Selector from "common/src/components/material/Autocomplete"

import api from "app/api"
import useDictRef from "common/src/hooks/useDictRef"

const PER_PAGE = 50;

function DesignerSelector({ allowEmpty = true, size, onChange,
    multiple = false, checkboxMode = false }) {

    const [selection, setSelection] = useState([]);
    const [designers, setDesigners] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [start, setStart] = useState(0);
    const [hasNext, setHasNext] = useState(true);
    const [query, setQuery] = useState("");

    const ref = useDictRef({ count, start, designers, query });

    const loadDesigners = useCallback(
        async (append = false) => {
            setLoading(true);
            let { designers, count } = await api.unauth.post("/catalogue/designers", {
                body: {
                    start: ref.start,
                    perPage: PER_PAGE,
                    withCount: true,
                    query: ref.query,
                    // stage
                }
            });

            if (append) {
                designers = [...ref.designers, ...designers];
            }

            setCount(count);
            setDesigners(designers);
            setLoading(false);
            setHasNext(ref.start + PER_PAGE < count);
        },
        []
    );

    const loadNextPage = useCallback(
        () => {
            setStart(ref.start + PER_PAGE);
            ref.start = ref.start + PER_PAGE;
            loadDesigners(true);
        },
        []
    );

    const options = useMemo(
        () => {
            if (!hasNext || ref.count === 0) {
                return ref.designers;
            }
            return [...ref.designers, { id: "", name: "", isLoadingMore: true }];
        },
        [hasNext, designers]
    );

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage: hasNext,
        onLoadMore: loadNextPage,
        rootMargin: '0px 0px 50px 0px',
    });

    const optionLabel = useCallback(
        (o) => o.name || "",
        []
    );

    const onSearchChange = useCallback(
        (query) => {
            setQuery(query);
            setStart(0);
            ref.start = 0;
            ref.query = query;
            loadDesigners();
        },
        []
    );

    const onSelectorClose = useCallback(
        () => {
            if (ref.query !== "") {
                onSearchChange("");
            }
        },
        []
    );

    const onDesignerChange = useCallback(
        (ds) => {
            setSelection(ds);
            onChange && onChange(ds);
        },
        [onChange]
    );

    useEffect(
        () => {
            loadDesigners();
        },
        []
    );

    return (
        <Selector
            checkboxMode={checkboxMode}
            multiple={multiple}
            options={options}
            selection={selection}
            loading={loading}
            placeholder="Designer"
            allowEmpty={allowEmpty}
            size={size}
            getOptionLabel={optionLabel}
            onChange={onDesignerChange}
            onClose={onSelectorClose}
            onSearchChange={onSearchChange}
            loadingMoreRef={sentryRef}
            query={query}
            extra={{
                ListboxProps: { ref: rootRef }
            }} />
    )
}

export default DesignerSelector