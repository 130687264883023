import React from "react"
import { ReactComponent as ToolsLogo } from "common/src/svg/tf-tools-logo.svg"
import { ReactComponent as AdminLogo } from "common/src/svg/tf-admin-logo.svg"
import settings from "app/settings"

const SigninHeader = () => {

    return (
        <div className="signin-header">
            { settings.app === "admin" ? 
                <AdminLogo/> :
                <ToolsLogo/> }
        </div>
    )

};

export default SigninHeader;