import { useCallback, useMemo, useState } from "react"

import { Button } from "@mui/material"

import api from "app/api"

import Table from "common/src/components/table/Table"
import DesignerSelector from "common/src/components/catalogue/DesignerSelector"

import findParent from "common/src/lib/dom/findParent"
import useQuery from "common/src/refactor/hooks/useQuery"


function DesignerTop() {

    const [ selectedId, setSelectedId ] = useState(null);

    const loadDesigners = useCallback(
        async () => {
            const order = {
                position: "asc"
            }
            const where = {
            };
            return api.catalogueDesignerTop.list({ where, order })
                    .then(list => list.map(row => {
                        const designer = { ...row.designer };
                        designer.position = row.position;
                        designer.topId = row.id;
                        return designer;
                    }))
        },
        []
    );

    const { data: designers, refetch } = useQuery(
        loadDesigners,
        []
    );

    const handleSelectedChange = useCallback(
        (row) => {
            setSelectedId(row.id);
            //console.log(row);
        },
        []
    );

    const moveDesigner = useCallback(
        async (moveId, targetId) => {
            
            let list = [ ...designers ];
            const moveInx = designers.findIndex(d => d.id === moveId);
            const targetInx = designers.findIndex(d => d.id === targetId);
            const designer = designers.find(d => d.id === moveId);

            if (moveInx === targetInx) {
                return;
            }
            if (moveInx > targetInx) {
                list.splice(moveInx, 1);
                list.splice(targetInx, 0, designer);
            }
            else {
                list.splice(targetInx, 0, designer);
                list.splice(moveInx, 1);
            }

            for (let i = 0, l = list.length; i < l; i++) {
                const row = list[i];
                await api.catalogueDesignerTop.update(
                    { id: { _eq: row.topId }},
                    { position: i }
                );
            }

            refetch();
        },
        [ designers, refetch ]
    );

    const onAddDesigner = useCallback(
        async () => {
            if (selectedId && !designers.find(d => d.id === selectedId)) {
                await api.catalogueDesignerTop.create({
                    designerId: selectedId,
                    position: designers.length
                });
                refetch();
            }
        },
        [ selectedId, designers, refetch ]
    );

    const onDeleteDesigner = useCallback(
        async (d) => {
            await api.catalogueDesignerTop.remove({ designerId: { _eq: d.id }});
            refetch();
        },
        [ refetch ]
    );

    const columns = useMemo(
        () => [
            {
                id: "position",
                name: "Position",
                render: (row, index) => index + 1,
                className: "min-width"
            },
            {
                id: "name",
                name: "Designer"
            },
            {
                id: "action",
                className: "min-width",
                render: (row) => {
                    return (
                        <Button
                            variant="text"
                            children="Delete"
                            onClick={ () => onDeleteDesigner(row) }/>
                    )
                }
            }
        ],
        [ onDeleteDesigner ]
    );

    const rowAttrs = useCallback(
        (row) => {
            return {
                onDragEnter: e => {
                    e.preventDefault();
                    e.stopPropagation();
                },
                onDragOver: e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const el = findParent(e.target, "tr");
                    el && el.classList.add("droppable-active");
                },
                onDragLeave: e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const el = findParent(e.target, "tr");
                    el && el.classList.remove("droppable-active");
                },
                onDrop: e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const data = JSON.parse(e.dataTransfer.getData("application/json"));
                    const el = findParent(e.target, "tr");
                    el && el.classList.remove("droppable-active");
                    moveDesigner(data, row.id);
                },

                draggable: true,
                onDragStart: e => {
                    e.dataTransfer.setData(
                        "application/json", 
                        JSON.stringify(row.id)
                    );
                },
                onDragEnd: e => {
                }
            }
        },
        [ moveDesigner ]
    );


    return (
        <div className="page page-designer-top">
            <div className="toolbar">
                <div className="toolbar-title">Top Designers</div>
                <div className="spacer-full"/>
                <DesignerSelector 
                    stage={ process.env.REACT_APP_ENV }
                    size="normal"
                    onChange={ handleSelectedChange }/>
                <Button 
                    variant="contained" 
                    children="Add designer"
                    onClick={ onAddDesigner }/>
            </div>
            <Table cols={ columns } rows={ designers } rowAttrs={ rowAttrs }/>
        </div>
    )
}

export default DesignerTop