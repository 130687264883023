import React from "react"
import { connect } from "react-redux"
import SideDialog from "common/src/components/dialog/SideDialog"
import LinkButton from "common/src/components/material/LinkButton"
import { ReactComponent as IconExport } from "common/src/svg/export.svg"
import Loader from "common/src/components/Loader"
import LookCard from "common/src/components/look/Card"
import ProductCard from "common/src/components/look/ProductCard"
import { ui as ddUi } from "common/src/store/dialogs"
import { lookLoader } from "common/src/actions/looks"
import async from "common/src/lib/js/async"
import getUrl from "common/src/lib/url/get"
import api from "app/api"
import normalizeCatalogueProduct from "common/src/lib/catalogue/normalizeCatalogueProduct"


const dialogName = "click-info";


class ClickProductDialog extends React.Component {

    state = {
        loading: false,
        look: null,
        catalogueProduct: null
    }

    _isMounted = false 

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prev) {
        const   click = this.props.click,
                prevClick = prev.click,
                lookId = click && click.product ? click.product.lookId : null,
                prevLookId = prevClick && prevClick.product ? prevClick.product.lookId : null,
                catProdId = click ? click.catalogueProductId : null,
                prevCatProdId = prevClick ? prevClick.catalogueProductId : null;

        if (lookId && lookId !== prevLookId) {
            async(() => this.loadLook());
        }
        else if (!lookId && prevLookId) {
            this.setState({ look: null });
        }

        if (catProdId && catProdId !== prevCatProdId) {
            async(() => this.loadCatalogueProduct());
        }
        else if (!catProdId && prevCatProdId) {
            this.setState({ catalogueProduct: null });
        }
    }

    async loadLook() {
        this.setState({ loading: true, look: null });
        const lookId = this.props.click.product.lookId;
        const options = {
            where: {
                id: { _eq: lookId }
            }
        };
        const look = await lookLoader(options)
                            .then(list => list.length > 0 ? list[0] : null);
        
        if (this._isMounted) {
            this.setState({ loading: false, look });
        }
    }

    async loadCatalogueProduct() {

        this.setState({ loading: true });
        const id = this.props.click.catalogueProductId;
        const retailer = this.props.click.retailer;
        const res = await api.backend.post("/catalogue/search", { body: {
            product_web_id: id,
            stage: "live",
            retailer
        }});

        if (res.products && res.products.length > 0) {
            this.setState({ 
                loading: false, 
                catalogueProduct: normalizeCatalogueProduct(res.products[0]) 
            });
        }
        else {
            this.setState({ loading: false, catalogueProduct: null });
        }
    }

    onDialogClose() {
        this.props.dispatch(ddUi.hide(dialogName));
    }

    renderLookInfo() {
        const { look } = this.state;
        const { click } = this.props;
        const productId = click.product.id;
        const productUrl = click.product.url;
        const lookUrl = getUrl("/look/aaa/:id", { id: look.id }, "web");

        return (
            <>
            <LookCard 
                look={ look }
                product={ p => ({
                    attrs: {
                        style: {
                            opacity: p.id === productId ? 1 : 0.3
                        }
                    }
                })}/>
            <LinkButton 
                variant="outlined"
                to={ productUrl }
                target="_blank"
                rel="noreferrer noopener"
                endIcon={ <IconExport/> }
                children="Go to product"/>
            <LinkButton 
                variant="outlined"
                to={ lookUrl }
                target="_blank"
                rel="noreferrer noopener"
                endIcon={ <IconExport/> }
                children="Go to look"/>
            </>
        )
    }

    renderGenericInfo() {
        const { catalogueProduct } = this.state;
        return (
            <>

            { catalogueProduct && 
                <ProductCard product={ catalogueProduct }/> }
            </>
        )
    }

    render() {

        const { loading, look } = this.state,
              { click } = this.props,
                show = click && this.props.dd[dialogName];
                
        return (
            <SideDialog 
                className="dialog-click-info" 
                title="Click information" 
                onClose={ () => this.onDialogClose() }
                show={ show }>

                { loading && <Loader/> }
                { (!loading && !!look && click && click.product) && this.renderLookInfo() }
                { (!loading && !look && click && click.catalogueProductId) && this.renderGenericInfo() }

            </SideDialog>
        )
    }
}


export default connect(state => ({ dd: state.dialogs }))(ClickProductDialog)