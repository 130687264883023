import { useCallback, useState } from "react"
import Selector from "../material/Autocomplete"

const networks = [
    {
        id: "all",
        name: "All Networks"
    },
    {
        id: "cj",
        name: "CJ"
    },
    /*{
        id: "impact",
        name: "Impact"
    },*/
    {
        id: "partnerize",
        name: "Partnerize"
    },
    {
        id: "productsup",
        name: "Productsup"
    },
    {
        id: "rakuten",
        name: "Rakuten"
    }
    
    /*{
        id: "awin",
        name: "Awin"
    },*/
    
];


function AffiliateSelector({ size, multiple, allowEmpty, defaultValue, onChange }) {

    const [ selection, setSelection ] = useState([]);

    const onNetworkChange = useCallback(
        (selection) => {
            if (selection.find(n => n.id === "all")) {
                selection = [];
            }
            setSelection(selection);
            onChange && onChange(selection);
        },
        [ onChange ]
    );

    return (
        <Selector 
            options={ networks }
            multiple={ multiple }
            allowEmpty={ allowEmpty }
            defaultValue={ defaultValue }
            size={ size }
            selection={ selection }
            placeholder="All Networks"
            onChange={ onNetworkChange }/>
    )
}



export default AffiliateSelector