const routes = {
    "home": "/",
    "signin": "/signin",
    "register": "/register/:inv?",
    "profile": "/profile",
    "profileContacts": "/profile/contacts",
    "content": "/content",
    "terms": "/terms",
    "privacy": "/privacy",
    "faq": "/faq",

    "indexV2": "/indexV2",
    "about": "/about",
    "founder": "/about/founder",
    "contact": "/contact",
    "friTerms": "/fri-terms",
    "cookies": "/cookies",
    "thirdparty": "/third-party",
    "request": "/request",
    "consultations": "/consultations/:page?",
    "recommendations": "/recommendations/:page?",
    "consultation": "/c/:id",
    "recommendation": "/r/:id",
    "oldlook": "/l/:name?/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
    "look": "/luxury-looks/:name?/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
    "looks": "/latest/looks",
    "looks2": "/latest/looks-v2",
    "fri": "/fri/:handle?/:id?",
    "friRegister": "/fri-register",
    "verifyEmail": "/verifyEmail/:vid/:code",
    "favourites": "/favourites/:mode?",
    "search": "/search",
    "searchShort": "/q/:query",
    "picked": "/picked",
    "popular": "/latest-fashion/trending/:when?",
    "designers": "/designers/:d?",
    "looking": "/looking-for",
    "blog": "/blog",
    "concierge": "/concierge",
    "shopfloorcommunity": "/shopfloorcommunity",
    "sale": "/sale",
    "gpslanding": "/freelancepersonalshopping",
    "chat": "/chat/:contactId?/:consultationId?",
    "catalogue": "/catalogue",
    "out": "/out",
}

export default routes
