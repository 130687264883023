import hub from "common/src/hub"
import {    loadColors, loadMaterials, 
            loadCharacteristics, loadCategories } from "common/src/actions/catalogue"

const listeners = {
    init: () => {
        hub.listen("catalogue", "color-change", () => loadColors(true));
        hub.listen("catalogue", "material-change", () => loadMaterials(true));
        hub.listen("catalogue", "characteristic-change", () => loadCharacteristics(true));
        hub.listen("catalogue", "category-change", () => loadCategories(true));
    }
};

export default listeners;