import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { Link } from "react-router-dom"

import Menu from "common/src/components/Menu"
import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"
import Loader from "common/src/components/Loader"
import Table from "common/src/components/table/Table"
import Pagination from "common/src/components/Pagination"
import CopyField from "common/src/components/form/CopyField"

import UserSelector from "common/src/components/user/UserSelector"
import PublicSelector from "common/src/components/PublicSelector"
import DateRangeSelector from "common/src/components/filter/DateRange"
import SearchFilter from "common/src/components/filter/Search"
import UserGroupsSelector from "../../filter/UserGroupsSelector"
import RetailerSelector from "../../filter/RetailerSelector"

import DataStore from "common/src/lib/DataStore"

import getClickDomain from "common/src/lib/url/clickDomain"
import async from "common/src/lib/js/async"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import state2query from "common/src/lib/url/state2query"
import { default as url2state, list2param } from "common/src/lib/url/url2state"
import statesAreEqual from "common/src/lib/statesAreEqual"
import { dateGroupNames, dateGroupItems } from "./common"

import * as columns from "./datastore/links"

import { PER_PAGE, loadLinks, loadLinksByDate, loadLinksByContributor } from "app/actions/page/links"

const urlParams = [
    { name: "section", defaultValue: "all" },
    { name: "page", type: "int", defaultValue: 1, 
        restore: page => Math.max(1, page) - 1,
        store: page => (page || 0) + 1 },
    { name: "start", stateName: "startDate" },
    { name: "end", stateName: "endDate" },
    { name: "q", stateName: "query" },
    { name: "ifs", stateName: "contributors", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "rs", stateName: "retailers", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "ugs", stateName: "userGroups", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "g", stateName: "dateGroup", defaultValue: "month",
        restore: v => [{ id: v, name: dateGroupNames[v] }],
        store: v => v ? v[0].id : null },
];
            

const getUrlState = (props) => {
    return url2state(urlParams, props);
}

const getFilterUrl = (state) => {
    return getUrl(routes.reportLinks, state2query(state, urlParams));
}

const filterSetup = {
    all: {
        filters: [ "date", "search", "contributor", /*"usergroup",*/ "retailer" ],
        options: {}
    },
    bycontributor: {
        filters: ["date", /*"usergroup",*/ "contributor", "retailer"],
        options: {}
    },
    bydate: {
        filters: ["dategroup", "date", "contributor", /*"usergroup",*/ "retailer"],
        options: { groupItems: dateGroupItems }
    }
};



class PageLinks extends React.Component {

    state = {
        section: "all",
        startDate: null,
        endDate: null,
        page: 0,
        loading: false,
        downloading: false,
        data: null,
        
        contributors: [],
        userGroups: [],
        retailers: [],
        query: "",
        dateGroup: [{ id: "month", name: "Month" }],

        expandedDate: null,
        dateByContributors: [],
        dateByContributorsLoading: false,

        expandedContributor: null,
        contributorsByDate: [],
        contributorsByDateLoading: false
    }

    _isMounted = false
    dataStore = null

    componentDidMount() {
        this.dataStore = new DataStore();
        const state = getUrlState(this.props);
        this.setState(state, () => this.load());
        this._isMounted = true;
    }

    componentWillUnmount() {
        this.dataStore = null;
        this._isMounted = false;
    }

    componentDidUpdate() {
        const state = getUrlState(this.props);
        const keys = urlParams.map(p => p.stateName || p.name);
        if (!statesAreEqual(state, this.state, keys)) {
            async(() => this.setState({ ...state, data: null }, () => this.load()));
        }
    }

    setFilter(state) {
        const newState = Object.assign({}, this.state, state, { page: 0 });
        const url = getFilterUrl(newState);
        this.props.history.push(url);
    }

    getFilters() {
        const { startDate, endDate, dateGroup, query, retailers,
                contributors, userGroups } = this.state;
        return {
            query,
            startDate, endDate, group: dateGroup,
            contributors, userGroups, retailers
        }
    }

    prepareDs(data) {
        const { section } = this.state;

        switch (section) {
            case "all": {
                this.dataStore.setRows(data.links);
                this.dataStore.setColumns(columns.list);
                this.dataStore.trigger("change");
                break;
            }
            case "bycontributor": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.contributor);
                this.dataStore.trigger("change");
                break;
            }
            case "bydate": {
                const { dateGroup } = this.state;
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.date);
                this.dataStore.setCustomData({ dateGroup });
                this.dataStore.trigger("change");
                break;
            }
            default: {}
        }
    }

    async load(csv = false) {
        const { section, page } = this.state;
        const filters = this.getFilters();
        let data;
        this.setState({ loading: !csv, downloading: csv });

        switch (section) {
            case "all": {
                data = await loadLinks(filters, page);
                break;
            }
            case "bycontributor": {
                data = await loadLinksByContributor(filters, csv);
                break;
            }
            case "bydate": {
                data = await loadLinksByDate(filters, csv);
                break;
            }
            default: {
                data = null;
                break;
            }
        }

        if (!csv && this.state.section === section) {
            this.prepareDs(data);
            this.setState({ loading: false, data });
        }
        else if (csv) {
            this.setState({ loading: false, downloading: false });
        }
    }

    async loadByDateByContributor(dateGroup) {

        const filters = this.getFilters();
        filters.months = [ dateGroup ];

        this.setState({ dateByContributors: [], 
                        dateByContributorsLoading: true, 
                        expandedDate: dateGroup });

        const rows = await loadLinksByContributor(filters);

        this.setState({ dateByContributors: rows, dateByContributorsLoading: false });
    }

    async loadByContributorByDate(userId) {

        const filters = this.getFilters();
        filters.contributors = [ userId ];
        filters.group = "month";

        this.setState({ contributorsByDate: [], 
                        contributorsByDateLoading: true,
                        expandedContributor: userId });

        const rows = await loadLinksByDate(filters);

        this.setState({ contributorsByDate: rows, contributorsByDateLoading: false });
    }


    reload() {
        this.load();
    }

    async download() {
        this.load(true);
    }

    setDateRange(range) {
        const state = Object.assign({}, this.state, {
            startDate: range[0] ? moment(range[0]).format("YYYY-MM-DD") : null,
            endDate: range[1] ? moment(range[1]).format("YYYY-MM-DD") : null,
            page: 0
        });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    setSection(section) {
        const state = Object.assign({}, { section, page: 0 });
        const url = getFilterUrl(state);
        this.setState({ data: null });
        this.props.history.push(url);
    }

    setPage(page) {
        const state = Object.assign({}, this.state, { page });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    getNav() {
        const { loading, section, downloading } = this.state;
        const refreshCls = [ 'icon', 'icon-svg-fill', loading ? 'spinning' : ''];
        const downloadCls = [ 'icon', 'icon-svg-fill' ];

        return [
            {
                name: "All",
                onClick: () => this.setSection("all"),
                active: section === "all"
            },
            {
                name: "By date",
                onClick: () => this.setSection("bydate"),
                active: section === "bydate"
            },
            {
                name: "By User",
                onClick: () => this.setSection("bycontributor"),
                active: section === "bycontributor"
            },
            section !== "all" ? {
                icon: downloading ? 
                        <Loader/> : 
                        <IconDownload className={ downloadCls.join(" ") }/>,
                onClick: () => !downloading && this.download()
            } : null,
            {
                icon: <IconRefresh className={ refreshCls.join(" ") }/>,
                onClick: () => this.reload()
            }
        ]
    }

    renderFilters(filters, options = {})  {
        const { contributors, retailers,
                dateGroup, startDate, endDate, query, userGroups } = this.state;

        return (
            <>
                { filters.indexOf("dategroup") !== -1 && 
                    <PublicSelector
                        name="Group by"
                        cls="tag-selector-v2"
                        noSearch
                        single
                        mustHaveSelection
                        value={ dateGroup }
                        items={ options.groupItems }
                        filtered={ options.groupItems }
                        onChange={ dateGroup => this.setFilter({ dateGroup }) }/> }
                { filters.indexOf("date") !== -1 && 
                    <DateRangeSelector
                        name="Link created"
                        minDate={ new Date("2020-01-01T00:00:00") }
                        maxDate={ new Date() }
                        value={ startDate ? [ startDate, endDate ] : [] }
                        onChange={ r => this.setDateRange(r) }/> }
                { filters.indexOf("search") !== -1 && 
                    <SearchFilter
                        value={ query }
                        onChange={ query => this.setFilter({ query }) }/> }
                { filters.indexOf("retailer") !== -1 && 
                    <RetailerSelector
                        name="Retailer"
                        value={ retailers }
                        onChange={ retailers => this.setFilter({ retailers }) }/> }
                { filters.indexOf("contributor") !== -1 && 
                    <UserSelector
                        className="multiline"
                        name="User"
                        group="Contributor"
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ contributors }
                        onChange={ contributors => this.setFilter({ contributors }) }/> }
                { filters.indexOf("usergroup") !== -1 && 
                    <UserGroupsSelector
                        name="User group" 
                        value={ userGroups }
                        onChange={ userGroups => this.setFilter({ userGroups }) }/> }
            </>
        )
    }


    renderLinkDetails(link) {

        const state = this.state;
        const domain = getClickDomain({ retailer: link.retailer });
        const url = `https://${ domain }/s/${ link.hash }`;
        const pid = link.sku || link.catalogueProductId || link.lookProductId;
        
        return (
            <>
                <CopyField value={ url }/>
                <div className="page-commission-details-toolbar">
                    <span className="spacer"/>
                    <Link
                        children="Filter by this contributor"
                        to={ getFilterUrl({ ...state, contributors: [{ id: link.contributor.id }]}) }/>
                    <Link
                        children="Filter by this retailer"
                        to={ getFilterUrl({ ...state, retailers: [{ id: link.retailer }]}) }/>
                    <Link
                        children="Filter by this product"
                        to={ getFilterUrl({ ...state, retailers: [{ id: link.retailer }], query: pid }) }/>
                    { link.clicks > 0 && <Link
                        children="Show clicks"
                        to={ getUrl(routes.reportClicks, { ls: [ link.id ]}) }/> }
                </div>
            </>
        )
    }

    renderLinks() {

        const { links, count } = this.state.data;
        const { page } = this.state;

        return (
            <>
            <Table 
                variant="slim"
                dataStore={ this.dataStore }
                expandKey="id"
                expandable
                detailsNameClass="page-clicks-details-name"
                detailsValueClass="page-clicks-details-value"
                renderDetails={ link => this.renderLinkDetails(link) }/>
            { links.length === 0 && <div className="empty">No links found</div> }
            <Pagination 
                page={ page } 
                perPage={ PER_PAGE } 
                count={ count } 
                showCount
                onChange={ page => this.setPage(page) }/>
            </>
        )
    }

    renderBreakdownDetails(row) {

        let linksUrl, clicksUrl;
        const { expandedDate, expandedContributor } = this.state;

        const cid = row.contributor ? row.contributor.id : expandedContributor;
        const dt = row.date_group ? row.date_group : expandedDate;
        const start = moment(dt).utc().startOf("month").toDate().toISOString();
        const end = moment(dt).utc().endOf("month").toDate().toISOString();
        
        linksUrl = getUrl(routes.reportLinks, { 
            section: "all", 
            ifs: cid,
            start,
            end
        });
        clicksUrl = getUrl(routes.reportClicks, { 
            section: "log", 
            ifs: cid,
            start, 
            end 
        });

        return (
            <div className="page-commission-details-toolbar">
                <span className="spacer"/>
                { row.links > 0 && 
                    <Link 
                        children="Show links" 
                        onClick={ () => this.setState({ data: null }) }
                        to={ linksUrl }/> }
                { row.clicks > 0 && 
                    <Link children="Show clicks" to={ clicksUrl }/> }
            </div>
        )
    }

    renderLinkByDateByContributors() {

        const { dateByContributors, dateByContributorsLoading } = this.state;
        const dataStore = new DataStore(columns.contributor, dateByContributors || []);

        if (dateByContributorsLoading) {
            return (
                <Loader/>
            )
        }

        return (
            <Table
                dataStore={ dataStore }
                expandable
                expandKey="referenceUserId"
                renderDetails={ (row) => this.renderBreakdownDetails(row) }/>
        )
    }

    renderLinksByDate() {
        const rows = this.state.data;

        return (
            <>
            <Table 
                dataStore={ this.dataStore }
                expandable
                expandKey="date_group"
                onRowExpand={ dg => this.loadByDateByContributor(dg) }
                renderDetails={ row => this.renderLinkByDateByContributors(row) }/>
            { rows.length === 0 && <div className="empty">No links found</div> }
            </>
        )
    }


    renderLinkByContributorByDate() {

        const { contributorsByDate, contributorsByDateLoading, dateGroup } = this.state;
        const dataStore = new DataStore(columns.date, contributorsByDate || []);
        dataStore.setCustomData({ dateGroup });

        if (contributorsByDateLoading) {
            return (
                <Loader/>
            )
        }

        return (
            <Table
                dataStore={ dataStore }
                expandable
                expandKey="date_group"
                renderDetails={ (row) => this.renderBreakdownDetails(row) }/>
        )
    }



    renderLinksByContributor() {
        const rows = this.state.data;
        
        return (
            <>
            <Table 
                dataStore={ this.dataStore }
                expandKey="referenceUserId"
                expandable
                onRowExpand={ userId => this.loadByContributorByDate(userId) }
                renderDetails={ row => this.renderLinkByContributorByDate(row) }/>
            { rows.length === 0 && <div className="empty">No links found</div> }
            </>
        )
    }



    render() {

        const menu = this.getNav();
        const { section, data, loading } = this.state;

        return (
            <>
            <div className="page page-commissions">
                <div className="toolbar">
                    <div className="toolbar-title">
                        Trackable links
                    </div>
                    <Menu menu={ menu } className="right"/>
                </div>

                { loading && <Loader size={ 64 }/> }

                <div className="page-commissions-layout">
                    <div className="left">
                        { this.renderFilters(
                            filterSetup[section].filters,
                            filterSetup[section].options
                        )}
                    </div>
                    <div className="right">
                        { (section === "all" && data) && this.renderLinks() }
                        { (section === "bycontributor" && data) && this.renderLinksByContributor() }
                        { (section === "bydate" && data) && this.renderLinksByDate() }
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default connect()(PageLinks)
