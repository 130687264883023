
export default function(retailer, id) {

    switch (retailer) {
        case "net-a-porter": {
            return `https://www.net-a-porter.com/en-gb/shop/product/${id}`;
        }

        case "ssense": {
            return `https://www.ssense.com/en-gb/women/product/a/b/${id}`;
        }

        case "sturm": {
            return `https://www.drsturm.com/${id}/`;
        }

        case "mytheresa": {
            return `https://www.mytheresa.com/eu_en/${id}.html`;
        }

        case "matchesfashion": {
            return `https://www.matchesfashion.com/intl/products/${id}`;
        }

        case "harveynichols": {
            return `https://www.harveynichols.com/int/brand/abc/${id}`;
        }

        case "neous": {
            return `https://www.neous.co.uk/products/${id}`;
        }

        case "chloe": {
            return `https://www.chloe.com/gb/${id}.html`;
        }

        case "selfridges": {
            return `https://www.selfridges.com/GB/en/cat/aaa_${id}/`
        }

        case "luisaviaroma": {
            id = id.substring(0, 3) + '-' + id.substring(3);
            return `https://www.luisaviaroma.com/en-gb/p/${id}`;
        }

        case "modaoperandi": {
            if (id.match(/^\d+$/)) {
                return `https://www.modaoperandi.com/women/p/a/b/${id}`;
            }
            else {
                return `https://www.modaoperandi.com/${id}`;
            }
        }

        case 'intermix': {
            id = id.replace(/\+/g, ' ');
            return `https://www.intermixonline.com/a/b/${id}.html`;
        }

        case 'farfetch': {
            //return `https://www.farfetch.com/uk/shopping/women/${id}.aspx`;
            if ((""+id).match(/^\d+$/)) {
                return `https://www.farfetch.com/item-${id}.aspx`;    
            }
            return `https://www.farfetch.com/${id}.aspx`;
        }

        case 'rl': {
            return `https://www.ralphlauren.co.uk/${id}.html`;
        }

        case 'browns': {
            return `https://www.brownsfashion.com/uk/shopping/aaa-${id}`;
        }

        case 'jimmychoo': {
            return `https://row.jimmychoo.com/en/a/b/ab-${id}.html`;
        }

        case 'etro': {
            return `https://www.etro.com/${id}.html`;
        }

        case 'ferragamo': {
            return `https://www.ferragamo.com/shop/gb/en/${id}`;
        }

        case 'rogervivier': {
            return `https://www.rogervivier.com/ww-en/p/${ id }/`
        }

        case 'shopbop': {
            return `https://www.shopbop.com/aaa/vp/v=1/${ id }.htm`
        }

        case 'webster': {
            return `https://thewebster.us/shop/${ id }.html`
        }

        case 'neiman': {
            return `https://www.neimanmarcus.com/en-gb/p/${ id }`
        }

        case 'bergdorf': {
            return `https://www.bergdorfgoodman.com/p/${ id }`
        }

        case 'forward': {
            return `https://www.fwrd.com/product-aaa/${ id }/`
        }

        case 'tods': {
            return `https://www.tods.com/gb-en/p/${ id }/`
        }

        case 'saks': {
            return `https://www.saksfifthavenue.com/product/${ id }.html`
        }

        case 'gucci': {
            return `https://www.gucci.com/uk/en_gb/abc-p-${ id }`
        }

        default: {
            return null;
        }
    }
}