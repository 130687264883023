
import React from "react"
import { connect } from "react-redux"
import Loader from "common/src/components/Loader"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { ReactComponent as IconDelete } from "common/src/svg/tag-delete.svg"
import async from "common/src/lib/js/async"
import api from "app/api"
import localDownload from "common/src/lib/localDownload.js"


class Synonyms extends React.Component {

    state = {
        page: "synonyms",
        search: "",
        loading: false,
        requiresIndexing: false,
        processing: false,
        synonyms: [],
        aliases: {},
        stemming: {}
    }

    componentDidMount() {
        this.setState({ loading: true });
        const promises = [];

        promises.push(api.backend.get("/search/scheme")
        .then(resp => {
            const data = JSON.parse(resp.AnalysisOptions.Synonyms);
            this.setState({ 
                synonyms: data.groups, 
                aliases: data.aliases,
                stemming: JSON.parse(resp.AnalysisOptions.StemmingDictionary)
            });
        }));

        promises.push(this.loadDomain());

        Promise.all(promises).then(() => {
            this.setState({ loading: false });
        });
    }

    loadDomain() {
        return api.backend.get("/search/domain")
        .then(resp => {
            this.setState({ 
                requiresIndexing: resp.RequiresIndexDocuments,
                processing: resp.Processing
            })
        })
    }

    save() {
        const synonyms = [].concat(this.state.synonyms);
        const aliases = Object.assign({}, this.state.aliases);
        const stemming = Object.assign({}, this.state.stemming);
        this.setState({ loading: true });
        api.backend.post("/search/scheme", {
            body: { synonyms, aliases, stemming }
        })
        .then(() => this.loadDomain())
        .then(resp => {
            this.setState({ loading: false });
        })
    }

    rebuild() {
        this.setState({ loading: true });
        api.backend.post("/search/reindex")
            .then(() => this.loadDomain())
            .then(() => {
                this.setState({ loading: false });
            })
    }

    focusField(rowInx) {
        async(() => {
            document.getElementById("new-syn-" + rowInx).focus();
        })
    }



    deleteSyn(e, rowInx, synInx) {
        e && e.preventDefault();
        rowInx = this.getUnfilteredSynInx(rowInx);
        const synonyms = [].concat(this.state.synonyms);
        if (synInx !== undefined) {
            synonyms[rowInx].splice(synInx, 1);
            if (synonyms[rowInx].length === 0) {
                synonyms.splice(rowInx, 1);    
            }
        }
        else {
            synonyms.splice(rowInx, 1);
        }
        this.setState({ synonyms });
    }

    createSynGroup(e) {
        const syn = e.target.value.trim();
        e.target.value = "";
        const synonyms = [].concat(this.state.synonyms);
        synonyms.unshift([syn]);
        this.setState({ synonyms, search: "" });
        this.focusField(0);
    }

    createSynEntry(rowInx, e) {
        rowInx = this.getUnfilteredSynInx(rowInx);
        const syn = e.target.value.trim();
        e.target.value = "";
        const synonyms = [].concat(this.state.synonyms);
        synonyms[rowInx].push(syn);
        this.setState({ synonyms });
    }

    getUnfilteredSynInx(fInx) {
        const search = this.state.search;
        const q = search ? search.toLowerCase() : "";
        if (!q) {
            return fInx;
        }
        const synonyms = [].concat(this.state.synonyms);
        let found = 0;
        return synonyms.findIndex((row, inx) => {
            if (row.find(s => s.toLowerCase().indexOf(q) !== -1)) {
                if (fInx === found) {
                    return true;
                }
                found++;
            }
            return false;
        });
    }

    exportSynCsv(e) {
        e.preventDefault();
        const synonyms = [].concat(this.state.synonyms);
        let csv = [];
        synonyms.forEach(row => {
            csv.push(row.map(s => `"${s}"`).join(","));
        });

        localDownload(csv.join("\n"), "text/csv", "synonyms.csv");
    }





    deleteAls(e, key, synInx) {
        e && e.preventDefault();
        const aliases = Object.assign({}, this.state.aliases);
        if (synInx !== undefined) {
            aliases[key].splice(synInx, 1);
            if (aliases[key].length === 0) {
                delete aliases[key];
            }
        }
        else {
            delete aliases[key];
        }
        this.setState({ aliases });
    }

    createAlsGroup(e) {
        const name = e.target.value.trim();
        e.target.value = "";
        const aliases = Object.assign({}, this.state.aliases);
        
        if (!aliases[name]) {
            aliases[name] = [];
            this.setState({ aliases, search: "" });
            this.focusField(0);
        }
    }

    createAlsEntry(key, e) {
        const syn = e.target.value.trim();
        e.target.value = "";
        const aliases = Object.assign({}, this.state.aliases);
        aliases[key].push(syn);
        this.setState({ aliases });
    }

    exportAlsCsv(e) {
        e.preventDefault();
        const aliases = Object.assign({}, this.state.aliases);
        let csv = [];
        Object.entries(aliases).forEach(([key, row]) => {
            row.unshift(key);
            csv.push(row.map(s => `"${s}"`).join(","));
        });

        localDownload(csv.join("\n"), "text/csv", "aliases.csv");
    }



    createStemGroup() {
        const a = document.getElementById("new-stem-a");
        const b = document.getElementById("new-stem-b");

        if (!a || !b) {
            return;
        }

        const w1 = a.value.trim();
        const w2 = b.value.trim();

        if (!w1 || !w2) {
            return;
        }

        a.value = "";
        b.value = "";

        const stemming = Object.assign({}, this.state.stemming);
        if (!stemming[w1]) {
            stemming[w1] = w2;
            this.setState({ stemming, search: "" });
        }
    }

    deleteStem(e, w1) {
        e && e.preventDefault();
        const stemming = Object.assign({}, this.state.stemming);
        if (stemming[w1]) {
            delete stemming[w1];
            this.setState({ stemming, search: "" });
        }
    }

    exportStemCsv(e) {
        e.preventDefault();
        const stemming = Object.assign({}, this.state.stemming);
        let csv = [];
        Object.entries(stemming).forEach((row) => {
            csv.push(row.map(s => `"${s}"`).join(","));
        });

        localDownload(csv.join("\n"), "text/csv", "stemming.csv");
    }


    search(query) {
        this.setState({ search: query });
    }

    setPage(e, page) {
        e.preventDefault();
        this.setState({ page });
    }

    download(e) {
        e.preventDefault();
        const { synonyms, aliases, stemming } = this.state;
        const json = JSON.stringify({ synonyms, aliases, stemming });

        localDownload(json, "application/json", "scheme.json");
    }

    renderSynonyms() {

        const { synonyms, search } = this.state;
        const q = search ? search.toLowerCase() : "";
        const list = q ? 
            synonyms.filter(row => 
                row.find(s => 
                    s.toLowerCase().indexOf(q) !== -1)) :
            synonyms;

        return (
        <div className="grid-wrapper">
            <table className="grid">
                <thead>
                    <tr>
                        <th>
                            <span>Create new group</span>
                            <TextField 
                                autoComplete="off"
                                id="new-syn--1"
                                onKeyDown={ e => e.key === "Enter" && 
                                                    this.createSynGroup(e) }
                                variant="outlined"/>
                            <a href="/#" 
                                onClick={ e => this.exportSynCsv(e) }
                                children="Export csv"/>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    
                    { list.map((ss, inx) => (
                    <tr key={ inx }>
                        <td>
                            { ss.map((s, sinx) => (
                                <div className="tag" key={ sinx }>
                                    { s }
                                    <a href="/#"
                                        onClick={ e => this.deleteSyn(e, inx, sinx) }>
                                        <IconDelete/>
                                    </a>
                                </div>
                            )) }
                            <TextField 
                                autoComplete="off"
                                id={ "new-syn-" + inx }
                                onKeyDown={ e => e.key === "Enter" && 
                                                this.createSynEntry(inx, e) }
                                variant="outlined"/>
                        </td>
                        <td>
                        <a href="/#" 
                            onClick={ e => this.deleteSyn(e, inx) }
                            children="Delete"/>
                    </td>
                    </tr>
                    )) }
                </tbody>
            </table>    
        </div>
        )
    }

    renderAliases() {
        const { aliases, search } = this.state;
        const q = search ? search.toLowerCase() : "";
        const list = q ? 
            Object.entries(aliases).filter(([key, row]) => 
                key.toLowerCase().indexOf(q) !== -1 ||
                row.find(s => 
                    s.toLowerCase().indexOf(q) !== -1)) :
            Object.entries(aliases);

        return (
        <div className="grid-wrapper">
            <table className="grid">
                <thead>
                    <tr>
                        <th>
                            <span>Create new group</span>
                            <TextField 
                                autoComplete="off"
                                id="new-syn--1"
                                onKeyDown={ e => e.key === "Enter" && 
                                                    this.createAlsGroup(e) }
                                variant="outlined"/>
                            <a href="/#" 
                                onClick={ e => this.exportAlsCsv(e) }
                                children="Export csv"/>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    
                    { list.map(([key, ss], inx) => (
                    <tr key={ inx }>
                        <td>
                            <div className="tag">{ key }</div>
                            { ss.map((s, sinx) => (
                                <div className="tag" key={ sinx }>
                                    { s }
                                    <a href="/#"
                                        onClick={ e => this.deleteAls(e, key, sinx) }>
                                        <IconDelete/>
                                    </a>
                                </div>
                            )) }
                            <TextField 
                                autoComplete="off"
                                id={ "new-syn-" + inx }
                                onKeyDown={ e => e.key === "Enter" && 
                                                this.createAlsEntry(key, e) }
                                variant="outlined"/>
                        </td>
                        <td>
                        <a href="/#" 
                            onClick={ e => this.deleteAls(e, key) }
                            children="Delete"/>
                    </td>
                    </tr>
                    )) }
                </tbody>
            </table>    
        </div>
        )
    }

    renderStemming() {
        const { stemming, search } = this.state;
        const q = search ? search.toLowerCase() : "";
        const list = q ? 
            Object.entries(stemming).filter(([w1, w2]) => 
                w1.toLowerCase().indexOf(q) !== -1 ||
                w2.toLowerCase().indexOf(q) !== -1) :
            Object.entries(stemming);

        return (
        <div className="grid-wrapper">
            <table className="grid">
                <thead>
                    <tr>
                        <th>
                            <span>Create new entry</span>
                            <TextField 
                                autoComplete="off"
                                id="new-stem-a"
                                onKeyDown={ e => e.key === "Enter" && 
                                                    this.createStemGroup() }
                                variant="outlined"/>
                            <TextField 
                                autoComplete="off"
                                id="new-stem-b"
                                onKeyDown={ e => e.key === "Enter" && 
                                                    this.createStemGroup() }
                                variant="outlined"/>
                            <a href="/#" 
                                onClick={ e => this.exportStemCsv(e) }
                                children="Export csv"/>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    { list.map(([w1, w2], inx) => (
                    <tr key={ inx }>
                        <td>
                            <div className="tag">{ w1 }</div>
                            <div className="tag">{ w2 }</div>
                        </td>
                        <td>
                        <a href="/#" 
                            onClick={ e => this.deleteStem(e, w1) }
                            children="Delete"/>
                    </td>
                    </tr>
                    )) }
                </tbody>
            </table>    
        </div>
        )
    }

    render() {

        const { loading, search, page,
                requiresIndexing, processing } = this.state;

        return (
        <>
        <div className="page page-analysis-scheme page-w-loading">
            { loading && <Loader size={ 64 }/> }

            <div className="toolbar">
                <Button 
                    disabled={ loading }
                    variant="contained" 
                    children="Save"
                    onClick={ () => this.save() }/>
                <Button 
                    disabled={ loading || !requiresIndexing || processing }
                    variant="outlined" 
                    children={ processing ? "Re-building..." : "Re-build index" }
                    onClick={ () => this.rebuild() }/>
                <nav>
                    <a href="/#" 
                        className={ page === "synonyms" ? "active" : "" }
                        onClick={ e => this.setPage(e, "synonyms") }
                        children="Synonyms"/>
                    <a href="/#" 
                        className={ page === "aliases" ? "active" : "" }
                        onClick={ e => this.setPage(e, "aliases") }
                        children="Aliases"/>
                    <a href="/#" 
                        className={ page === "stemming" ? "active" : "" }
                        onClick={ e => this.setPage(e, "stemming") }
                        children="Stemming"/>
                    <TextField 
                        autoComplete="off"
                        value={ search }
                        label={ search ? "" : "Search" }
                        onChange={ e => this.search(e.target.value) }
                        variant="outlined"/>
                </nav>
            </div>

            { page === "synonyms" && this.renderSynonyms() }
            { page === "aliases" && this.renderAliases() }
            { page === "stemming" && this.renderStemming() }

            <div className="download-all">
                <a href="/#" 
                    onClick={ e => this.download(e) }
                    children="Download all as json"/>
            </div>
        </div>
        </>
        )
    }
}

export default connect()(Synonyms)