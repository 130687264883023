import React from "react"
import PublicSelector from "common/src/components/PublicSelector"
//import async from "common/src/lib/js/async"
//import api from "app/api"
import { getRetailers } from "common/src/lib/catalogue/retailers"


class UserGroupsSelector extends React.Component {

    state = {
        search: "",
        loading: false,
        retailers: []
    }


    onSearchChange(search) {
        this.setState({ search }, () => this.load());
    }

    async load() {
        getRetailers().then((list) => this.setState({ retailers: list }))
    }

    render() {
        const { loading, retailers } = this.state;
        const props = Object.assign({}, this.props);
        props.onSearchChange = s => this.onSearchChange(s);
        props.onLoadItems = () => this.load();
        props.name = props.name || "Retailer";
        props.cls = [ "tag-selector-v2", "retailer-selector" ];
        props.items = retailers;
        props.filtered = retailers;
        props.loading = loading;
        props.noSearch = true;

        return (
            <PublicSelector { ...props }/>
        )
    }
}

export default UserGroupsSelector