import React, { useMemo, useCallback, useEffect, useRef } from "react"
import {  useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import Context from "common/src/refactor/lib/Context"
import animation from "common/src/lib/animation"
import hub from "common/src/hub"
import { ui } from "common/src/store/dialogs"
import addListener from "common/src/lib/dom/addListener"
import removeListener from "common/src/lib/dom/removeListener"
import async from "common/src/lib/js/async"
import getWidth from "common/src/lib/dom/getWidth"
import { isActive as isAlertActive } from "common/src/components/dialog/Alert"
import swallowEvent from "common/src/lib/dom/swallowEvent"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useClassEffect from "common/src/hooks/useClassEffect"
import store from "app/store"
import useKeySetter from "common/src/refactor/hooks/useKeySetter"
import { useImperativeHandle } from "react"
import { forwardRef } from "react"

class DialogApi extends Context {

    //isActive = false

    constructor(data) {
        super({ ...data, isActive: false })
        this.dialogId = uuidv4();
        this.onShowEvent = this.onShowEvent.bind(this);
        this.onHideEvent = this.onHideEvent.bind(this);
        this.onToggleEvent = this.onToggleEvent.bind(this);
        this.onSelfShowEvent = this.onSelfShowEvent.bind(this);
        this.onSelfHideEvent = this.onSelfHideEvent.bind(this);
        this.onTriggerShowEvent = this.onTriggerShowEvent.bind(this);
        this.onTriggerHideEvent = this.onTriggerHideEvent.bind(this);
        this.onBodyHideEvent = this.onBodyHideEvent.bind(this);
        this.onKeepVisibleEvent = this.onKeepVisibleEvent.bind(this);
        this.onOtherDialogShow = this.onOtherDialogShow.bind(this);
        this.onRenewTriggers = this.onRenewTriggers.bind(this);
    }

    checkTriggerBreakpoint() {
        const tbp = this.get("triggerBreakpoint");
        return tbp && getWidth(window) < tbp ? false : true;
    }

    onToggleEvent(e) {
        this.get("isActive") ? this.onTriggerHideEvent(e) : this.onTriggerShowEvent(e);
    }

    onTriggerShowEvent(e)  {
        if (this.checkTriggerBreakpoint()) {
            swallowEvent(e);
            this.onShowEvent(e);
        }
    }

    onTriggerHideEvent(e) {
        swallowEvent(e);
        this.onHideEvent(e);
    }

    onSelfHideEvent() {
        this.onHideEvent();
    }

    onBodyHideEvent(e) {
        if (isAlertActive()) {
            return;
        }
        if (this.get("keepVisibleOnce")) {
            this.set("keepVisibleOnce", false);
            return;
        }
        this.onHideEvent(e)
    }

    onOtherDialogShow(name) {
        if (!name || name === this.get("name")) {
            return;
        }
        if (this.get("keepVisibleOnce")) {
            this.set("keepVisibleOnce", false);
            return;
        }
        this.onHideEvent();
    }

    onSelfShowEvent(e) {
        this.set("keepVisibleOnce", true);
        this.onShowEvent(e);
    }

    onKeepVisibleEvent() {
        this.get("show") && (this.set("keepVisibleOnce", true));
    }

    onShowEvent(e) {
        if (!this.checkTriggerBreakpoint(e)) {
            return; 
        }
        if (this.get("onBeforeShow") && this.get("onBeforeShow")() === false) {
            return;
        }
        this.set("hiding", false);
        store.dispatch(ui.show(this.get("name")));
    }

    onHideEvent(e) {
        if (!this.checkTriggerBreakpoint(e)) {
            return; 
        }
        if (this.get("keepOpen") === true || this.get("hiding")) {
            return;
        }
        if (!this.get("show")) {
            return;
        }
        if (this.get("hideDelay")) {
            this.set("hiding", true);
            async(this.delayedHide, this.get("hideDelay"), this);
        }
        else {
            this.set("hiding", true);
            this.delayedHide();
        }
    }

    delayedHide() {
        if (this.get("hiding")) {
            this.set("hiding", false);
            store.dispatch(ui.hide(this.get("name")));
        }
    }

    setTriggerEvents(state, mode) {
        const fn = state ? addListener : removeListener,
                    trEls = this.triggerEl,
                    el = this.get("mainRef")?.current;

        if (this.get("keepVisibleEvent")) {
            hub[ state ? "listen" : "remove" ](
                "dialog", 
                this.get("keepVisibleEvent"), 
                this.onKeepVisibleEvent
            );
        }

        if (this.get("closeOnOther")) {
            hub[ state ? "listen" : "remove" ](
                "app", 
                "dialog-show", 
                this.onOtherDialogShow
            );
        }

        if (mode === "hover") {
            el && fn(el, "mouseover", this.onSelfShowEvent);
            el && fn(el, "mouseout", this.onSelfHideEvent);
            trEls.forEach(trEl => {
                fn(trEl, "mouseover", this.onTriggerShowEvent);
                fn(trEl, "mouseout", this.onTriggerHideEvent);
            });
        }
        else if (mode === "click") {
            trEls && trEls.forEach(trEl => {
                fn(trEl, "click", this.onToggleEvent);
            });
            if (this.get("closeOnBody") !== false) {
                fn(document.body, "click", this.onBodyHideEvent);
            }
            el && fn(el, "click", this.onSelfShowEvent);
        }
    }

    toggleTrigger(state) {
        const mode = this.get("triggerMode") || "hover";
        if (state) {
            const trigger = this.get("trigger"),
                el = trigger ? document.querySelectorAll(trigger) : null;
            if (el) {
                this.triggerEl = Array.from(el);
                this.setTriggerEvents(true, mode);
            }
            else if (this.data.closeOnBody === true) {
                this.setTriggerEvents(true, "click");
            }
        }
        else {
            if (this.triggerEl) {
                this.setTriggerEvents(false, mode);
                this.triggerEl = null;
            }
            else if (this.get("closeOnBody") === true) {
                this.setTriggerEvents(false, "click");
            }
        }
    }

    onRenewTriggers() {
        async(() => {
            this.toggleTrigger(false);
            this.toggleTrigger(true);
        });
    }


    triggerHubEvents(show) {
        const noOverlay = this.get("noOverlay") || false;

        if (!noOverlay) {
            hub.dispatch("dialog", show ? "show" : "hide", this.get("name"));
        }
        hub.dispatch("app", show ? "dialog-show" : "dialog-hide", this.get("name"));
        
        show && this.get("onShow")?.();
        !show && this.get("onHide")?.();

        if (this.get("group")) {
            hub.dispatch(
                "dialog", 
                show ? "group-show" : "group-hide", 
                { name: this.get("name"), group: this.get("group"), id: this.dialogId }
            );
        }

        this.get("onChange")?.(show);
    }

    animate(show) {
        const anim = this.get("anim") || ["a-fadein", "a-fadeout"];
        return animation(this.get("mainRef").current, show ? anim[0] : anim[1], {
            start: () => {
                if (show !== this.get("isActive")) {
                    return;
                }
                show && this.get("mainRef").current && this.get("mainRef").current.classList.add("active");
                show && this.get("htmlCls") && document.documentElement.classList.add(this.get("htmlCls"));
            },
            end: () => {
                if (show !== this.get("isActive")) {
                    return;
                }
                !show && this.get("mainRef").current && this.get("mainRef").current.classList.remove("active");
                !show && this.get("htmlCls") && document.documentElement.classList.remove(this.get("htmlCls"));
            }
        });
    }


    afterDelay(show, immediately = false) {
        const   tcls = this.get("triggerCls"),
                htmlCls = this.get("htmlCls"),
                noAnimation = this.get("noAnimation") || false;

        // still active
        if (show !== this.get("show")) {
            return;
        }

        if (tcls) {
            this.triggerEl.forEach(el => {
                el.classList[show ? "add" : "remove"](tcls)
            })
        }

        this.set("isActive", show);

        if (immediately || noAnimation) {
            show ? 
                this.get("mainRef").current.classList.add("active") :
                this.get("mainRef").current.classList.remove("active");

            if (htmlCls) {
                show ? 
                    document.documentElement.classList.add(this.get("htmlCls")) :
                    document.documentElement.classList.remove(this.get("htmlCls"));
            }
        }
        else {
            this.animate(show);
        }

        this.triggerHubEvents(show);
    }

    onShowChange() {
        if (this.get("show")) {
            this.get("showDelay") ?
                (async(this.afterDelay, this.get("showDelay"), this, [true])) : 
                (this.afterDelay(true));   
        }
        else {
            if (!this.get("trigger")) {
                this.get("hideDelay") ?
                    (async(this.afterDelay, this.get("hideDelay"), this, [false])) :
                    (this.afterDelay(false));    
            }
            else {
                this.afterDelay(false, /*immediately*/this.get("hiding"));
            }
        }
    }
}


function Dialog(props, ref) {

    const { id, attrs, name, children, renewTriggers: renew } = props;

    const dispatch = useDispatch();
    const show = useSelector(s => s.dialogs[name]);
    const mainRef = useRef(null);

    const self = useMemo(() => new DialogApi({ ...props, renew, show }), []);

    useKeySetter("onChange", self, props.onChange);
    useKeySetter("onShow", self, props.onShow);
    useKeySetter("onHide", self, props.onHide);
    useKeySetter("onBeforeShow", self, props.onBeforeShow);
    useKeySetter("onRef", self, props.onRef);
    useKeySetter("mainRef", self, mainRef);

    useImperativeHandle(ref, () => self, [ self ]);

    const onRouteChanged = useCallback(
        () => self.get("show") && dispatch(ui.hide(self.get("name"))),
        []
    );

    const onDialogRef = useCallback(
        el => {
            mainRef.current = el;
            const onRef = self.get("onRef");
            onRef && onRef(el);
        },
        []
    );

    const onOverlayClick = useCallback(
        () => {
            if (isAlertActive()) {
                return;
            }
            if (self.get("closeOnOverlay") === true && self.get("show")) {
                dispatch(ui.hide(self.get("name")));
            }
        },
        []
    );

    useClassEffect(mainRef, [ "dialog", props.className ]);

    // update
    useUpdateEffect(
        () => {
            if (show !== self.get("show")) {
                self.set({ show });    
                if (show !== self.get("isActive")) {
                    self.onShowChange();
                }
            }
    
            if (renew !== self.get("renew")) {
                self.set({ renew });
                self.onRenewTriggers();
            }
        },
        [ show, renew ]
    );

    // mount/unmount
    useEffect(
        () => {
            
            hub.listen("dialog", "overlayClick", onOverlayClick);
            hub.listen("app", "locationChange", onRouteChanged);
            hub.listen("app-auth", "stateChange", self.onRenewTriggers);
            async(() => self.toggleTrigger(true));

            if (show && !self.get("isActive")) {
                self.onShowChange();
            }
            return () => {
                hub.remove("dialog", "overlayClick", onOverlayClick);
                hub.remove("app", "locationChange", onRouteChanged);
                hub.remove("app-auth", "stateChange", self.onRenewTriggers);
                self.toggleTrigger(false);

                self.$destroy();
        
                if (self.get("isActive")) {
                    self.triggerHubEvents(false);
                }
            }
        },
        []
    );

    return (
        <div id={ id } ref={ onDialogRef } children={ children } { ...attrs } />
    )
}

const DialogWithRef = forwardRef(Dialog);

export default DialogWithRef

