import React from "react"
import cls from "common/src/lib/className"

function Header({ cols, expandable, variant }) {

    return (
        <thead>
            <tr>
                { expandable && <th className="expandable-th"/> }
                { cols.map(c => 
                    <th key={ c.dataKey || c.id } 
                        className={ cls([`grid-var-${ variant }`, c.className]) }
                        title={ c.title || "" }>
                        { c.name }
                    </th>
                )}
            </tr>
        </thead>
    )
}

export default Header