import React from "react";
import { Auth } from "@aws-amplify/auth"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"

import async from "common/src/lib/js/async"
import isEmptyObject from "common/src/lib/js/isEmptyObject"



function objectWithProperties(obj, keys) {
	const target = {};
	for (const key in obj) {
		if (keys.indexOf(key) === -1) {
			continue;
		}
		if (!Object.prototype.hasOwnProperty.call(obj, key)) {
			continue;
		}
		target[key] = obj[key];
	}
	return target;
}


class TFRequireNewPassword extends React.Component {

    state = {
        password: '',
        password2: '',
        errors: {}
    }

    submitClicked = false


	checkContact(user) {
		
		Auth.verifiedContact(user).then(data => {
			if (!isEmptyObject(data.verified)) {
				this.props.onAuthStateChange('signedIn', user);
			} 
            else {
                console.error("Contact verification is not implemented")
				//user = Object.assign(user, data);
                //this.props.onAuthStateChange('verifyContact', user);
			}
		});
	}

	change() {
		const user = this.props.authData;
		const { password } = this.state;
		const { requiredAttributes } = user.challengeParam;
		const attrs = objectWithProperties(this.state, requiredAttributes);

		Auth.completeNewPassword(user, password, attrs)
			.then(user => {
				//logger.debug('complete new password', user);
				if (user.challengeName === 'SMS_MFA') {
					//this.changeState('confirmSignIn', user);
                    console.error("MFA not implemented");
				} 
                else if (user.challengeName === 'MFA_SETUP') {
					///logger.debug('TOTP setup', user.challengeParam);
					//this.changeState('TOTPSetup', user);
                    console.error("MFA not implemented");
				} 
                else {
					this.checkContact(user);
				}
			})
			.catch(err => this.props.onError(err));
	}


    validate() {
        let errors = {},
            valid = true,
            messages = {
                "password": "Please enter the new password",
                "password2": "Please confirm your new password",
                "match": "Passwords don't match"
            };

        ["password", "password2"].forEach(name => {
            if (!this.state[name]) {
                valid = false;
                errors[name] = messages[name];
            }
        });

        if (this.state.password !== this.state.password2 &&
            !errors.password2) {
            errors['password2'] = messages['match'];
            valid = false;
        }

        this.setState({ errors });

        return valid;
    }

    setInput(field, value) {

        this.setState({ [field]: value }, () => {
            if (this.submitClicked) {
                async(() => this.validate());
            }
        });
    }

    onSubmitClick() {
        this.submitClicked = true;
        if (this.validate()) {
            this.change();
        }
    }

    onReturnClick(e) {
        e.preventDefault();
        this.props.onAuthStateChange('signIn')
    }

    render() {
        
        const { password, password2, errors = {} } = this.state;

        return (
            <div className="form-require-new-password">

                <h1>Change password</h1>

                <TextField label="New password" 
                            fullWidth
                            required
                            key="password"
						    name="password"
						    type="password"
                            value={ password }
                            onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                            onChange={ e => this.setInput("password", e.target.value) }
                            variant="outlined"
                            error={ !!errors.password }
                            helperText={ errors.password }/>

                <TextField label="Confirm" 
                            fullWidth
                            required
                            key="password2"
						    name="password2"
						    type="password"
                            value={ password2 }
                            onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                            onChange={ e => this.setInput("password2", e.target.value) }
                            variant="outlined"
                            error={ !!errors.password2 }
                            helperText={ errors.password2 }/>

                <Button variant="contained" 
                        size="large"
                        onClick={ () => this.onSubmitClick() }
                        color="primary">Change</Button>

                <p className="secondary-action">
                    <a href="/#" 
                        onClick={ e => this.onReturnClick(e) }>
                        Back to Log in
                    </a>
                </p>    
            </div>
        )
    }
}

export default TFRequireNewPassword
