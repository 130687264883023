import React, { useMemo } from "react"

function SummaryCol({ col, rows, dataStore, className }) {
    const sum = useMemo(
        () => col.renderSummary ? col.renderSummary(dataStore, rows) : "", 
        [ rows, col ]
    );

    return (
        <td className={ className }>{ sum }</td>
    )
}

export default SummaryCol