
import prepare from "common/src/lib/store/prepare"

import * as user from "common/src/store/user"
import * as dialogs from "common/src/store/dialogs"
import * as app from "common/src/store/app"

import * as dashboard from "./page/dashboard"
import * as peoplePage from "./page/people"
import * as tagsPage from "./page/tags"
import * as invitationsPage from "./page/invitations"
import * as historyPage from "./page/searchHistory"
import * as searchPage from "./page/search"
import * as clicksPage from "./page/clicks"
import * as peopleStatsPage from "./page/peopleStats"

import * as catalogue from "common/src/store/catalogue"
import * as notifications from "common/src/store/notifications"

export default prepare({
    app,
    user, 
    dialogs,
    
    dashboard,
    peoplePage,
    tagsPage,
    invitationsPage,
    historyPage,
    searchPage,
    clicksPage,
    peopleStatsPage,

    catalogue,
    notifications
});
