import React, { useMemo } from "react"

import Chip from "@mui/material/Chip"
import { ReactComponent as IconDelete } from "common/src/svg/tag-delete.svg"
import Loader from "common/src/components/Loader"

import cls from "common/src/lib/className"

const defaultLabel = tag => tag.name;

function Tag(props) {

    const { 
        tag,
        variant = "default",
        onDelete,
        onClick,
        chip,
        icon,
        deleteIcon = <IconDelete className="icon icon-svg-fill"/>,
        getLabel = defaultLabel
    } = props;

    const chipVariant = useMemo(
        () => typeof variant === "function" ? variant(tag) : variant,
        [ tag, variant ]
    );

    const chipLabel = useMemo(
        () => getLabel(tag),
        [ getLabel, tag ]
    );

    const chipIcon = useMemo(
        () => typeof icon === "function" ? icon(tag) : icon,
        [ icon, tag ]
    );

    const chipDeleteIcon = useMemo(
        () => typeof deleteIcon === "function" ? deleteIcon(tag) : deleteIcon,
        [ deleteIcon, tag ]
    );

    const chipOnClick = useMemo(
        () => onClick ? () => onClick(tag) : null,
        [ onClick, tag ]
    );

    const chipOnDelete = useMemo(
        () => onDelete ? () => onDelete(tag) : null,
        [ onDelete, tag ]
    );

    return (
        <Chip 
            size="small"
            variant={ chipVariant }
            label={ chipLabel }
            icon={ chipIcon }
            deleteIcon={ chipDeleteIcon }
            onClick={ chipOnClick }
            onDelete={ chipOnDelete }
            { ...chip }/>
    )
}

function TagList(props) {

    const { margin = "dense", label, loading = false, tags = [] } = props;
    const className = useMemo(() => cls(["tag-list", "tag-list-" + margin]), [ margin ]);

    return (
        <div className={ className }>
            { label && <label>{ label }</label> }
            { loading && <Loader/> }
            { tags.map(tag => <Tag { ...props } key={ tag.id || tag.name } tag={ tag }/>)}
        </div>
    )
}

export default TagList
