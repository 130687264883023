import React from "react"
import { connect } from "react-redux"
import isEmail from "validator/es/lib/isEmail"

import { TextField, Button, FormControlLabel } from "@mui/material"
import Checkbox from "common/src/components/material/Checkbox"
import SideDialog from "common/src/components/dialog/SideDialog"
import UserBlock from "common/src/components/user/User"
import AccessTree from "app/components/AccessTree"
import CopyField from "common/src/components/form/CopyField"

import { ui as ddUi } from "common/src/store/dialogs"
import Loader from "common/src/components/Loader"
import { confirm, alert } from "common/src/components/dialog/Alert"
import api from "app/api"
//import hub from "common/src/hub"
import retailers from "common/src/lib/catalogue/retailers"
import getUrl from "common/src/lib/url/get"
import async from "common/src/lib/js/async"
import NullForm from "common/src/components/NullForm"


const getInitialState = () => {
    return {
        saving: false,
        closing: false,
        deleting: false,
        email: "",
        name: "",
        type: "",
        invitation: null,
        user: null,
        retailers: [ "all" ],
        userGroups: [],
        groups: [],
        access: {},
        errs: {}
    }
}


class InvitationDialog extends React.Component {

    state = getInitialState()
    _isMounted = false
    _submitClicked = false
    
    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prev) {
        const editId = this.props.editId,
                prevEditId = prev.editId;

        if (editId && editId !== prevEditId) {
            async(() => this.loadInvitationById(editId));
        }
        else if (!editId && prevEditId) {
            async(() => this.setState(getInitialState()));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDialogClose() {
        this.setState(getInitialState());
        this._submitClicked = false;
        this.props.dispatch(ddUi.hide("dialog-invitation"));
        this.props.onClose && this.props.onClose();
    }

    setGroup(group, enabled) {
        let groups = this.state.groups;
        let inx = groups.indexOf(group);
        if (inx === -1) {
            groups.push(group);
        }
        else {
            groups.splice(inx, 1);
        }
        this.setState({ groups });
    }

    setAccessKey(key, enabled) {
        const { access } = this.state;

        if (enabled) {
            access[key] = true;
        }
        else {
            delete access[key];
        }

        this.setState({ access });
    }

    setRetailerAccess(retailer, enabled) {
        let { retailers } = this.state;
        const inx = retailers.indexOf(retailer);

        if (inx === -1 && enabled) {
            if (retailer === "all") {
                retailers = [ "all" ];
            }
            else {
                retailers.push(retailer);
            }
        }
        else if (inx !== -1 && !enabled) {
            retailers.splice(inx, 1);
        }

        this.setState({ retailers });
    }

    setUserGroup(gid, enabled) {
        const { userGroups } = this.state;
        const inx = userGroups.indexOf(gid);

        if (inx === -1 && enabled) {
            userGroups.push(gid);
        }
        else if (inx !== -1 && !enabled) {
            userGroups.splice(inx, 1);
        }

        this.setState({ userGroups });
    }

    onChangeEmail(email) {
        this.setState({ email }, () => {
            if (this._submitClicked) {
                this.validate();
            }
        });

        if (isEmail(email)) {
            this.loadInvitation(email);
            this.loadUser(email);
        }
    }

    onChangeName(name) {
        this.setState({ name });
    }

    async loadInvitationById(id) {
        const where = { id: { _eq: id }};
        const invitation = await api.invitation.list({ where })
                                .then(list => list.length > 0 ? list[0] : null);
        let settings = {};

        if (invitation && invitation.settings) {
            settings = JSON.parse(invitation.settings);
        }

        this.setState({ 
            email: invitation.email || "", 
            name: invitation.name || "", 
            type: invitation.email ? "personal" : "reusable",
            invitation, ...settings });
    }

    async loadInvitation(email) {

        const where = { email: { _eq: email.toLowerCase() }};
        const invitation = await api.invitation.list({ where })
                                .then(list => list.length > 0 ? list[0] : null);
        let settings = {};

        if (invitation && invitation.settings) {
            settings = JSON.parse(invitation.settings);
        }

        this.setState({ invitation, ...settings });
    }

    async loadUser(email) {
        const where = { email: { _ilike: email }};
        const user = await api.user.list({ where })
                                .then(list => list.length > 0 ? list[0] : null);
        this.setState({ user });
    }

    onSubmitClick() {

        if (!this.validate()) {
            return;
        }

        this.saveInvitation();
    }

    async onDeleteClick() {

        try {
            await confirm({ message: "Delete invitation?" });
        }
        catch (err) {
            return;
        }

        const { invitation } = this.state;
        this.setState({ deleting: true });

        await api.invitation.remove(invitation.id);

        this.setState({ deleting: false });
        this.props.onInvitationDeleted && this.props.onInvitationDeleted(invitation.id);

        this.onDialogClose();
    }

    async onCloseClick() {

        const { invitation } = this.state;
        this.setState({ closing: true });

        await api.invitation.update(invitation.id, { disabled: true });
        await this.loadInvitationById(invitation.id);

        this.setState({ closing: false });
        this.props.onInvitationUpdated && this.props.onInvitationUpdated(invitation.id);
    }

    async saveInvitation() {

        const { groups, access, email, name, type, 
                retailers, invitation, userGroups } = this.state;
        const settings = JSON.stringify({ groups, access, retailers, userGroups });

        this.setState({ saving: true });

        if (invitation) {
            const update = { settings };
            if (type === "reusable") {
                update.name = name;
            }
            await api.invitation.update(invitation.id, update);
            await this.loadInvitationById(invitation.id);

            this.props.onInvitationUpdated && this.props.onInvitationUpdated(invitation.id);
            alert({ message: "Invitation updated. Old registration link still works." });
        }
        else {
            const insert = { settings };
            if (type === "personal") {
                insert.email = email.toLowerCase();
                insert.name = null;
            }
            else {
                insert.name = name;
                insert.email = null;
            }
            const newInv = await api.invitation.create(insert);
            this.loadInvitation(email);
            this.props.onInvitationCreated && this.props.onInvitationCreated(newInv);
            alert({ message: "Invitation created. You can now copy the registation link." });
        }

        this.setState({ saving: false });
    }

    validate() {

        const { email, type, name } = this.state;
        const errs = {};
        let valid = true;

        if (type === "personal") {
            if (!email || !isEmail(email)) {
                errs.email = "Please enter a working email address";
                valid = false;
            }
        }
        else if (type === "reusable") {
            if (!name) {
                errs.name = "Please name this invitation";
                valid = false;
            }
        }

        this.setState({ errs });
        return valid;
    }

    renderForm() {
        const roleTags = [
            { name: "FRI" },
            { name: "GPS", label: "PSE" },
            { name: "Admin" },
            { name: "Contributor" }
        ];

        const userGroupsList = this.props.userGroups;
        const { groups, access, invitation, saving, closing } = this.state;
        const userRetailers = this.state.retailers;
        const userGroups = this.state.userGroups;
        const allRetailers = userRetailers.indexOf("all") !== -1;
        const isContributor = groups.length === 1 && groups.indexOf("Contributor") !== -1;

        return (
            <>
            <h4>Roles</h4>
            <div className="dialog-invitation-groups">
                <FormControlLabel
                    control={ <Checkbox color="primary" disabled checked/> }
                    label="User"/>
                { roleTags.map(g => 
                    <div key={ g.name }>
                    <FormControlLabel
                        className=""
                        control={ 
                            <Checkbox 
                                color="primary" 
                                checked={ groups.indexOf(g.name) !== -1 } 
                                onChange={ e => this.setGroup(g.name, e.target.checked) }/> }
                        label={ g.label || g.name }/>
                    </div> )}
            </div>

            <h4>Partial access</h4>
            <AccessTree 
                roles={ groups }
                access={ access }
                onChange={ (key, value) => this.setAccessKey(key, value) }/>

            { isContributor && 
                <>   
                <h4>Retailers</h4>
                <div className="user-details-retailers">
                <FormControlLabel
                    key="all"
                    className="user-details-retailer-all"
                    control={ 
                        <Checkbox 
                            color="primary" 
                            checked={ allRetailers } 
                            onChange={ e => this.setRetailerAccess("all", e.target.checked) }/> }
                    label="All"/>
                { !allRetailers && 
                    retailers.map(r => 
                        <FormControlLabel
                            key={ r.id }
                            className="user-details-retailer"
                            control={ 
                                <Checkbox 
                                    color="primary" 
                                    disabled={ false }
                                    checked={ userRetailers.indexOf(r.id) !== -1 } 
                                    onChange={ e => 
                                        this.setRetailerAccess(r.id, e.target.checked) }/> }
                            label={ r.name }/> )}
                </div>
                </>}

            { userGroupsList.length > 0 && 
                <>
                <h4>Groups</h4>
                <div className="user-details-retailers">
                    {userGroupsList.map(g => 
                        <FormControlLabel
                            key={ g.id }
                            className="user-details-retailer"
                            control={ 
                                <Checkbox 
                                    color="primary" 
                                    disabled={ false }
                                    checked={ userGroups.indexOf(g.id) !== -1 } 
                                    onChange={ e => 
                                        this.setUserGroup(g.id, e.target.checked) }/> }
                            label={ g.name }/> )}
                </div>
                </>}
            
            <div className="dialog-invitation-actions">
                <Button variant="contained"
                    disabled={ saving || closing }
                    startIcon={ saving ? <Loader inline/> : null }
                    onClick={ () => this.onSubmitClick() } 
                    children={ invitation ? "Update invitation" : "Create invitation" }/>

                { (invitation && (!invitation.disabled && !invitation.accepted)) && 
                    <Button variant="outlined"
                        disabled={ saving || closing }
                        startIcon={ closing ? <Loader inline/> : null }
                        onClick={ () => this.onCloseClick() } 
                        children="Disable invitation"/> } 
            </div>
            </>
        )
    }

    renderDelete() {
        const { invitation, deleting } = this.state;
        if (invitation.user || invitation.users.length > 0) {
            return <></>
        }
        return (
            <div className="dialog-invitation-actions">
                <Button variant="text"
                        disabled={ deleting }
                        startIcon={ deleting ? <Loader inline/> : null }
                        onClick={ () => this.onDeleteClick() } 
                        children="Delete invitation"/>
            </div>
        )
    }

    renderLink() {

        const { invitation, groups } = this.state;
        const tools = groups.indexOf("FRI") !== -1 ||
                        groups.indexOf("GPS") !== -1 ||
                        groups.indexOf("Contributor") !== -1;
        const admin = groups.indexOf("Admin") !== -1;

        return (
            <>
            <h4>Registration link</h4>
            <label>Web</label>
            <CopyField value={ getUrl("/register", { iid: invitation.id }, "web") }/>

            { tools && 
            <>
            <label>Tools</label>
            <CopyField value={ getUrl("/register", { iid: invitation.id }, "tools") }/>
            </> }

            { admin && 
            <>
            <label>Admin</label>
            <CopyField value={ getUrl("/register", { iid: invitation.id }, "admin") }/>
            </> }
            </>
        )
    }

    render() {
        const show = this.props.dd['dialog-invitation'];
        const { email, errs, invitation, user, type, name } = this.state;
        const exUser = user || 
                        (invitation ? invitation.user : null);

        return (
            <SideDialog 
                className="dialog-invitation" 
                title="User invitation" 
                onClose={ () => this.onDialogClose() }
                show={ show }>
                <NullForm>
                { !invitation && 
                    <select 
                        value={ type } 
                        onChange={ e => this.setState({ type: e.target.value })}>
                        { !type && <option>Invitation type</option> }
                        <option value="personal">Personal</option>
                        <option value="reusable">Reusable</option>
                    </select> }
                    
                { type === "personal" && 
                    <TextField
                        fullWidth
                        autoComplete="off"
                        label="Email"
                        variant="outlined"
                        size="small"
                        name="email"
                        value={ email }
                        error={ !!errs.email }
                        helperText={ errs.email || "Must be a working email address" }
                        onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                        onChange={ e => this.onChangeEmail(e.target.value) }/> }

                { type === "reusable" && 
                    <TextField
                        fullWidth
                        autoComplete="off"
                        label="Name"
                        variant="outlined"
                        size="small"
                        name="email"
                        value={ name }
                        error={ !!errs.name }
                        helperText={ errs.name || "Please name this invitation" }
                        onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                        onChange={ e => this.onChangeName(e.target.value) }/> }
                </NullForm>
                { (!exUser && type) && this.renderForm() }
                { (!exUser && invitation) && this.renderLink() }
                { exUser && <UserBlock user={ exUser }/>}    
                { invitation && this.renderDelete() }

            </SideDialog>
        )
    }
}

export default connect(state => ({
    dd: state.dialogs,
    userGroups: state.peoplePage.data.userGroups
}))(InvitationDialog)


/* 
<LinkButton
                            children="View stats"
                            variant="outlined"
                            to={ getUrl(routes['people-stats'], { id: user.id }) }/>*/