import React from "react"
import GenericExpandSelector from "./GenericExpandSelector"
import { ReactComponent as IconExpand } from "common/src/svg/expand.svg"
import TextField from '@mui/material/TextField';
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import InputAdornment from '@mui/material/InputAdornment'
import async from "common/src/lib/js/async"
import hub from "common/src/hub"
import NullForm from "common/src/components/NullForm";


class SearchFilter extends React.Component {

    _isMounted = false

    state = {
        value: ""
    }

    /*constructor(props) {
        super(props);
        this._onSelectorOpen = this._onSelectorOpen.bind(this);
    }*/

    componentDidMount() {
        const { value = "" } = this.props;
        this.setState({ value });

        this._isMounted = true;

        if (this.props.group) {
            hub.listen("tag-selector", "open", this._onSelectorOpen);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.props.group) {
            hub.remove("tag-selector", "open", this._onSelectorOpen);
        }
    }


    componentDidUpdate(prev) {
        const state = {};
        const props = this.props;

        /*if (props.expanded !== prev.expanded) {
            state.expanded = props.expanded;
        }*/
        if (props.value !== prev.value) {
            state.value = props.value;
        }

        async(() => {
            if (this._isMounted) {
                this.setState(state);
            }
        });
    }

    /*
    _onSelectorOpen({ group, type }) {

        if (this.props.group === group &&
            "query" !== type &&
            this.state.expanded) {
            this.setState({ expanded: false })
        }

    }

    onToggleClick(e) {
        e.preventDefault();
        this.toggle();
    }

    toggle() {
        const expanded = !this.state.expanded;
        this.setState({ expanded });

        if (expanded && this.props.group) {
            hub.dispatch("tag-selector", "open", {
                group: this.props.group,
                type: "query"
            })
        }
    }*/

    onSearchChange(query) {
        this.setState({ value: query });
    }

    onEnter() {
        if (this.state.value.length !== 1) {
            this.props.onChange && this.props.onChange(this.state.value);
        }
    }

    onClear() {
        if (this.props.onChange) {
            this.props.onChange("");
        }
        else {
            this.setState({ value: "" });
        }
    }

    render() {

        const placeholder = "Search";
        const { value } = this.state;
        const appliedValue = this.props.value;

        return (
            <GenericExpandSelector
                className="query-filter"
                name="Search"
                bodyCls="tag-selector-search"
                onClear={ () => this.onClear() }
                getSelectionState={ () => appliedValue || "All" }
                hasSelection={ () => !!appliedValue }>
                <NullForm>
                <TextField
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconSearch/>
                            </InputAdornment>
                        )
                    }}
                    onKeyDown={ e => e.key === "Enter" && this.onEnter() }
                    onChange={ e => this.onSearchChange(e.target.value) }
                    value={ value || "" }
                    placeholder={ value ? "" : placeholder }/>
                </NullForm>
            </GenericExpandSelector>
        )
    }
}

export default SearchFilter