import React from "react"
import Radio from "@mui/material/Radio"
import { ReactComponent as RadioChecked } from "common/src/svg/radio-checked.svg"

class TFRadio extends React.Component {

    render() {
        return <Radio 
            { ...this.props }
            checkedIcon={ <RadioChecked/> }
            icon={ <span className="radio-blank"/> }/>
    }
}

export default TFRadio