import React from "react"

const Wrapper = (props) => {
    const newProps = { ...props, ...props.webProps };
    const Cmp = newProps.cmp || "div";
    delete newProps.nativeProps;
    delete newProps.webProps;
    delete newProps.cmp;
    return <Cmp { ...newProps }/>;
}

export default Wrapper;