import React from "react"
import moment from "moment"
import { connect } from "react-redux"

import { TextField, Button } from "@mui/material"

import Loader from "common/src/components/Loader"
import Table from "common/src/components/table/Table"
import Pagination from "common/src/components/Pagination"
import User from "common/src/components/user/User"
import InvitationDialog from "app/components/dialog/Invitation"

import { ui as ddUi } from "common/src/store/dialogs"
import api from "app/api"
import df from "common/src/lib/date/formats"
import NullForm from "common/src/components/NullForm"

const PER_PAGE = 30;

class PageInvitations extends React.Component {

    state = {
        invitations: [],
        loading: false,
        search: "",
        editId: null,
        page: 0,
        count: 0
    }

    componentDidMount() {
        this.loadInvitations();
    }

    async loadInvitations() {

        this.setState({ loading: true });

        const { search, page } = this.state;
        const where = {};
        const order = { createdAt: "desc" };
        const offset = page * PER_PAGE;
        const limit = PER_PAGE;

        if (search) {
            where._or = [
                { email: { _ilike: `%${ search }%` }},
                { name: { _ilike: `%${ search }%` }}
            ]
        }

        const { items, count } = await api.invitation.list({ where, order, offset, limit }, null, true);

        this.setState({ loading: false, invitations: items, count });
    }

    showInviteForm() {
        this.props.dispatch(ddUi.show("dialog-invitation"));
    }

    setPage(page) {
        this.setState({ page }, () => this.loadInvitations());
    }

    search() {
        this.setState({ page: 0 }, () => this.loadInvitations());
    }

    editInvitation(editId) {
        this.setState({ editId });
        this.showInviteForm();
    }

    renderUserList(users) {
        return (
            <>
                { users.map(u => <User key={ u.id } user={ u }/> ) }
            </>
        )
    }

    render() {

        const { invitations, loading, search, editId, page, count } = this.state;
        const cols = [
            { id: "createdByUser", name: "Created by", 
                render: (i) => i.createdByUser ? <User user={ i.createdByUser }/> : null},
            { id: "name", name: "Recepient", render: i => i.email || i.name },
            { id: "created", name: "Created", render: i => moment(i.createdAt).format(df.full) },
            { id: "accepted", name: "Accepted", render: i => {
                return i.user ? 
                        <User user={ i.user }/> : 
                        i.users && i.users.length > 0 ?
                            this.renderUserList(i.users) :
                            null
            }},
            { id: "disabled", name: "Disabled", className: "min-width",
                render: i => i.disabled || i.accepted ? "Yes" : "" },
            { id: "action", name: "", className: "min-width", render: i => {
                if (!i.user) {
                    return (
                        <Button 
                            variant="text" 
                            size="small" 
                            children="Edit"
                            onClick={ () => this.editInvitation(i.id) }/>
                    )
                }
                return <></>
            }}
        ]

        return (
            <>
            <div className="page page-w-loading page-invitations">
                <div className="toolbar">
                    <nav>
                        <Button variant="contained" 
                            children="Create invitation"
                            onClick={ () => this.showInviteForm() }/>
                    </nav>
                    <NullForm className="right">
                        <TextField 
                            autoComplete="off"
                            variant="outlined"
                            value={ search }
                            onKeyDown={ e => e.key === "Enter" && this.search() }
                            onChange={ e => this.setState({ search: e.target.value }) }/>
                        <Button 
                            variant="contained" 
                            children="Search"
                            onClick={ () => this.search() }/>
                    </NullForm>
                </div>
                { loading && <Loader size={ 64 }/> }
                { invitations.length > 0 && 
                    <>
                    <Table cols={ cols } rows={ invitations }/>
                    <Pagination 
                        count={ count } 
                        page={ page } 
                        perPage={ PER_PAGE }
                        onChange={ page => this.setPage(page) }/>
                    </> }
            </div>
            <InvitationDialog
                editId={ editId }
                onClose={ () => this.setState({ editId: null })}
                onInvitationCreated={ (inv) => {
                    this.setState({ editId: inv.id });
                    this.loadInvitations();
                }}
                onInvitationUpdated={ (id) => this.loadInvitations() }
                onInvitationDeleted={ (id) => {
                    this.setState({ editId: null });
                    this.loadInvitations();
                }}/>
            </>
        )
    }
}

export default connect()(PageInvitations)