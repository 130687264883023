
import React from "react"
import { connect } from "react-redux"
import Loader from "common/src/components/Loader"
import UserContacts from "common/src/components/user/Contacts"

class PageContacts extends React.Component {

    render() {

        if (!this.props.current) {
            return <div className="page page-full 
                        page-hid-title page-fw
                        page-w-loading
                        page-contacts">
                        <Loader/>
                    </div>
        }

        return (
            <div className="page page-full 
                            page-hid-title page-fw
                            page-contacts">
                <div className="toolbar">
                    <h1>Notification preferences</h1>
                </div>
                <UserContacts user={ this.props.current } showSave={ false } autoSave/>
            </div>
        )
    }
}


export default connect(state => state.user)(PageContacts)
