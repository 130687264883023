import moment from "moment"
import formatCurrency from "common/src/lib/format/currency"
import formatNumber from "common/src/lib/format/number"
import df from "common/src/lib/date/formats"
//import { defaultRates } from "common/src/lambdalib/userPaymentSettings"
import User from "common/src/components/user/User"
import getClickDomain from "common/src/lib/url/clickDomain"
import Link from "common/src/components/Link"

import { getOrderItemStatusName, getOrderStatusName } from "app/lib/orderStatus"

const dateFormats = {
    "year": "YYYY",
    "month": "MMMM YYYY",
    "day": "MMM Do YY"
};
/*
const renderSum = (gbpAmount, usdAmount) => {

    if (!gbpAmount && !usdAmount) {
        return "0";
    }

    const sum = [];
    gbpAmount && sum.push(formatCurrency(gbpAmount, "gbp", 2));
    usdAmount && sum.push(formatCurrency(usdAmount, "usd", 2));
    return sum.join(" + ");
}

const renderMoneyCol = (row, key, defaultCurrency = "gbp") => {
    if (row.gbp || row.usd) {
        const gbp = row?.gbp || {};
        const usd = row?.usd || {};
        return renderSum(gbp[key] || 0, usd[key] || 0);
    }
    return formatCurrency(row[key], defaultCurrency, 2);
}*/

const commonColumns = [

    {
        id: "gsale",
        name: "Gross sales",
        modes: ["csv", "grid"],
        render: row => formatCurrency(row.grossSaleAmount || 0, "gbp", 2)
    },

    { 
        id: "sale", 
        name: "Sales", 
        modes: ["grid", "csv"],
        render: row => formatCurrency(row.saleAmount || 0, "gbp", 2)
    },

    { 
        id: "gcomm", 
        name: "TF Gross commission", 
        modes: ["grid", "csv"],
        render: row => formatCurrency(row.grossCommissionAmount || 0, "gbp", 2)
    },

    { 
        id: "comm", 
        name: "TF Net Commission", 
        modes: ["grid", "csv"],
        render: row => formatCurrency(row.commissionAmount || 0, "gbp", 2)
    }
];

/*const commonPSEGrossCommission = {
    id: "psecomm",
    name: "~PSE Gross Commission",
    render: (row, inx, ds) => {
        const cm = (row.grossCommissionAmount || 0) * defaultRates.pse;
        return formatCurrency(cm, "gbp", 2)
    }
};

const commonPSECommission = {
    id: "psecomm",
    name: "~PSE Net Commission",
    render: (row, inx, ds) => {
        const cm = (row.commissionAmount || 0) * defaultRates.pse;
        return formatCurrency(cm, "gbp", 2)
    }
};

const commonPSEColumns = [
    commonPSEGrossCommission,
    commonPSECommission
]*/

export const list = [
    { 
        id: "nname", 
        name: "Network",                     
        render: row => row.network.name
    },

    { 
        id: "advertiser", 
        name: "Retailer",
        className: "grid-nowrap"
    },

    { 
        id: "date", 
        name: "Order date", 
        className: "grid-nowrap",
        modes: ["grid"],
        render: row => moment(row.orderDate).format(df.full) 
    },

    {
        id: "csvdate",
        name: "Order date",
        modes: ["csv"],
        render: row => moment(row.orderDate).format(df.date) 
    },

    {
        id: "csvtime",
        name: "Order time",
        modes: ["csv"],
        render: row => moment(row.orderDate).format(df.time) 
    },

    {
        id: "currency",
        name: "Currency",
        modes: ["csv"],
        render: row => row.currency.toUpperCase()
    },
    
    { 
        id: "gsale", 
        name: "Gross Sale", 
        className: "grid-nowrap",
        render: row => formatCurrency(row.grossSaleAmount, row.currency, 2),
        renderCsv: row => formatNumber(row.grossSaleAmount, 2),
        renderSummary: (ds) => {
            const { grossSaleAmount, targetCurrency = "gbp" } = ds.getCustomData();
            return formatCurrency(grossSaleAmount, targetCurrency, 2);
        }
    },

    { 
        id: "sale", 
        name: "Net Sale", 
        className: "grid-nowrap",
        render: row => formatCurrency(row.saleAmount, row.currency, 2),
        renderCsv: row => formatNumber(row.saleAmount, 2),
        renderSummary: (ds) => {
            const { saleAmount, targetCurrency = "gbp" } = ds.getCustomData();
            return formatCurrency(saleAmount, targetCurrency, 2);
        }
    },

    { 
        id: "gcomm", 
        name: "TF Gross Commission", 
        //className: "grid-nowrap",
        render: row => formatCurrency(row.grossCommissionAmount, row.currency, 2),
        renderCsv: row => formatNumber(row.grossCommissionAmount, 2),
        renderSummary: (ds) => {
            const { grossCommissionAmount, targetCurrency = "gbp" } = ds.getCustomData();
            return formatCurrency(grossCommissionAmount, targetCurrency, 2);
        }
    },

    { 
        id: "comm", 
        name: "TF Net Commission", 
        //className: "grid-nowrap",
        render: row => formatCurrency(row.commissionAmount, row.currency, 2),
        renderCsv: row => formatNumber(row.commissionAmount, 2),
        renderSummary: (ds) => {
            const { commissionAmount, targetCurrency = "gbp" } = ds.getCustomData();
            return formatCurrency(commissionAmount, targetCurrency, 2);
        }
    },


    { 
        id: "comm-rate", 
        name: "TF Commission Rate (Effective)", 
        //className: "grid-nowrap",
        render: row => row.saleAmount > 0 ? 
                        formatNumber((row.commissionAmount/row.saleAmount) * 100, 2) + '%' :
                        "",
        renderCsv: row => row.saleAmount > 0 ? 
                            formatNumber((row.commissionAmount/row.saleAmount) * 100, 2) + '%' :
                            ""
    },

    /*{ 
        id: "comm-displayed-rate-click", 
        name: "TF Commission Rate (Click time)", 
        //className: "grid-nowrap",
        render: row => row.retailerCommissionRateClick ? 
                        formatNumber((row.retailerCommissionRateClick) * 100, 2) + '%' :
                        "",
        renderCsv: row => row.retailerCommissionRateClick ? 
                            formatNumber((row.retailerCommissionRateClick) * 100, 2) + '%' :
                            ""
    },*/

    { 
        id: "comm-displayed-rate-order", 
        name: "TF Commission Rate (Order time)", 
        //className: "grid-nowrap",
        render: row => row.retailerCommissionRate ? 
                        formatNumber((row.retailerCommissionRate) * 100, 2) + '%' :
                        "",
        renderCsv: row => row.retailerCommissionRate ? 
                            formatNumber((row.retailerCommissionRate) * 100, 2) + '%' :
                            ""
    },

    { 
        id: "comm-displayed-rate-displayed", 
        name: "Commission rate displayed to PSE", 
        //className: "grid-nowrap",
        render: row => row.retailerCommissionRateClick ? 
                        formatNumber((row.retailerCommissionRateClick * row.pseCommissionRateClick) * 100, 2) + '%' :
                        "",
        renderCsv: row => row.retailerCommissionRateClick ? 
                            formatNumber((row.retailerCommissionRateClick * row.pseCommissionRateClick) * 100, 2) + '%' :
                            ""
    },


    { 
        id: "psegcomm", 
        name: "PSE Gross Commission", 
        //className: "grid-nowrap",
        render: row => {
            return formatCurrency(row.pseGrossCommission, row.currency, 2)
        },
        renderCsv: row => {
            return formatNumber(row.pseGrossCommission, 2)
        }/*,
        renderSummary: (ds) => {
            const { grossCommissionAmount, targetCurrency = "gbp" } = ds.getCustomData();
            return '~ ' + formatCurrency(grossCommissionAmount * defaultRates.pse, targetCurrency, 2);
        }*/
    },

    { 
        id: "psecomm", 
        name: "PSE Net Commission", 
        //className: "grid-nowrap",
        render: row => {
            return formatCurrency(row.pseCommission, row.currency, 2)
        },
        renderCsv: row => {
            return formatNumber(row.pseCommission, 2)
        }/*,
        renderSummary: (ds) => {
            const { commissionAmount, targetCurrency = "gbp" } = ds.getCustomData();
            return '~ ' + formatCurrency(commissionAmount * defaultRates.pse, targetCurrency, 2);
        }*/
    },

    /*{ 
        id: "comm-comm-rate-click", 
        name: "PSE Commission Rate (Click time)", 
        //className: "grid-nowrap",
        render: row => row.pseCommissionRateClick ? 
                        formatNumber((row.pseCommissionRateClick) * 100, 2) + '%' :
                        "",
        renderCsv: row => row.pseCommissionRateClick ? 
                            formatNumber((row.pseCommissionRateClick) * 100, 2) + '%' :
                            ""
    },*/

    { 
        id: "comm-comm-rate-order", 
        name: "PSE Commission Rate (Order time)", 
        //className: "grid-nowrap",
        render: row => row.pseCommissionRate ? 
                        formatNumber((row.pseCommissionRate) * 100, 2) + '%' :
                        "",
        renderCsv: row => row.pseCommissionRate ? 
                            formatNumber((row.pseCommissionRate) * 100, 2) + '%' :
                            ""
    },

    {
        id: "revenue",
        name: "TF Revenue",
        render: row => {
            return formatCurrency(row.commissionAmount - row.pseCommission, row.currency, 2)
        }/*,
        renderSummary: (ds) => {
            const { revenue, targetCurrency = "gbp" } = ds.getCustomData();
            return '~ ' + formatCurrency(revenue, targetCurrency, 2);
        }*/
    },

    { 
        id: "status", 
        name: "Affiliate order status",
        className: "grid-nowrap",
        render: row => {
            // if (row.manualStatus && row.manualStatus !== null) {
            //     return row.manualStatus + " (manually)";
            // }
            if (row.status !== null) {
                return row.status;
            }
            if (row.autoApprovedAt !== null) {
                return "auto approved";
            }
            return "";
        }
    },


    { 
        id: "tf-status", 
        name: "TF order status",
        className: "grid-nowrap",
        render: row => getOrderStatusName(row)
    },
    
    { 
        id: "source", 
        name: "Source",
        render: row => {
            if (row?.click?.moodboardId) {
                return "Moodboard";
            }
            if (row?.click?.catalogueProductId) {
                return "Catalogue";
            }
            if (row?.click?.product?.look) {
                return "Styling session";
                //return row.click.product.look.consultationId ? "Consultation" : "Look";
            }
        }
    },

    {
        id: "createdby",
        name: "Created by",
        className: "grid-nowrap",
        render: row => {
            if (!!row.clickId && !!row.click.product) {
                return row.click.product.look.fri.givenName + " " +  
                        row.click.product.look.fri.familyName;
            }
            if (!!row.clickId && row.click.referenceUser) {
                return row.click.referenceUser.givenName + " " + 
                        row.click.referenceUser.familyName;
            }
            return "";
        }
    },
                    
    /*{ 
        id: "fri", 
        name: "Look by", 
        className: "grid-nowrap",
        render: row => (!!row.clickId && !!row.click.product) ?
                        row.click.product.look.fri.givenName + " " +  
                        row.click.product.look.fri.familyName:
                        "",
        renderCsv: row => (!!row.clickId && !!row.click.product) ?
                            row.click.product.look.fri.givenName + " " +
                            row.click.product.look.fri.familyName :
                        ""
    },

    { 
        id: "contributor", 
        name: "Link by", 
        className: "grid-nowrap",
        render: row => 
            (!!row.clickId && row.click.referenceUser) ? 
                row.click.referenceUser.givenName + " " + row.click.referenceUser.familyName : 
                "",
        renderCsv: row => (!!row.clickId && row.click.referenceUser) ? 
                        row.click.referenceUser.givenName + " " +
                        row.click.referenceUser.familyName :
                    ""
    },*/

    {
        id: "clickedby",
        name: "Clicked by",
        className: "grid-nowrap",
        render: row => 
            (!!row.clickId && row.click.user) ? 
                row.click.user.givenName + " " + row.click.user.familyName : 
                "Anonymous",
        renderCsv: row => 
            (!!row.clickId && row.click.user) ? 
                row.click.user.givenName + " " +
                row.click.user.familyName :
                "Anonymous"
    },

    {
        id: "clickedat",
        name: "Clicked at",
        className: "grid-nowrap",
        render: row => row.click?.createdAt ? moment(row.click.createdAt).format(df.full) : "",
        renderCsv: row => row.click?.createdAt ? moment(row.click.createdAt).format(df.full) : ""
    },

    {
        id: "transactionId",
        name: "Order ID",
        className: "grid-nowrap",
        render: row => {
            if (row.networkId === "rakuten") {
                let details = row.rakutenItems?.[0]?.details;
                if (details) {
                    details = JSON.parse(details);
                    return details['_Order_ID'];
                }
            }
            else {
                return row.orderId;
            }
        }
    },

    {
        id: "link",
        name: "Link",
        render: row => {
            if (row.click && row.click.link) {
                const clickDomain = getClickDomain({ retailer: row.click.link.retailer });
                return (
                    <Link 
                        to={ `https://${ clickDomain }/s/${ row.click.link.hash }` }
                        children={ `https://${ clickDomain }/s/${ row.click.link.hash }` }/>
                )
            }
            return null;
        },
        renderCsv: row => {
            if (row.click && row.click.link) {
                const clickDomain = getClickDomain({ retailer: row.click.link.retailer });
                return `https://${ clickDomain }/s/${ row.click.link.hash }`;
            }
            return null;
        }
    },

    {
        id: "link-product-brand",
        name: "Clicked brand",
        className: "grid-nowrap",
        render: row => {
            if (row.click?.link) {
                const { brand } = row.click.link;
                return brand
            }
            else if (row.click?.product) {
                const { brand } = row.click.product;
                return brand;
            }
            else if (row.click?.catalogueProduct) {
                const { brand } = row.click.catalogueProduct;
                return brand
            }
            return null;
        }
    },

    {
        id: "link-product",
        name: "Clicked product description",
        className: "grid-nowrap",
        render: row => {
            if (row.click?.link) {
                const { name } = row.click.link;
                return name
            }
            else if (row.click?.product) {
                const { name } = row.click.product;
                return name;
            }
            else if (row.click?.catalogueProduct) {
                const { name } = row.click.catalogueProduct;
                return name
            }
            // else {
            //     return row.click?.catalogueProductId
            // }
            return null;
        },
        renderCsv: row => {
            if (row.click?.link) {
                const { brand, name } = row.click.link;
                return `${ brand } / ${ name }`;
            }
            else if (row.click?.product) {
                const { name } = row.click.product;
                return `${ name }`;
            }
            else if (row.click?.catalogueProduct) {
                const { name, brand } = row.click.catalogueProduct;
                return `${ brand } / ${ name }`;
            }
            return null;
        }
    },

    {
        id: "target-url",
        name: "Target URL",
        className: "grid-nowrap",
        render: row => {
            const url = row.click?.usedUrl;
            if (url) {
                return (
                    <Link 
                        to={ url } 
                        children={ url } 
                        target="_blank" 
                        onClick={ e => e.stopPropagation() }
                        rel="noreferrer"/>
                )
            }
            return "";
        },
        renderCsv: row => row.click?.usedUrl || ""
    }
]



export const products = [
    { 
        id: "nname", 
        name: "Network",                     
        render: row => row.order?.network.name
    },

    { 
        id: "advertiser", 
        name: "Retailer",
        className: "grid-nowrap",
        render: row => row.order?.advertiser
    },

    { 
        id: "date", 
        name: "Order date", 
        className: "grid-nowrap",
        modes: ["grid"],
        render: row => moment(row.order?.orderDate).format(df.full) 
    },

    {
        id: "csvdate",
        name: "Order date",
        modes: ["csv"],
        render: row => moment(row.order?.orderDate).format(df.date) 
    },

    {
        id: "csvtime",
        name: "Order time",
        modes: ["csv"],
        render: row => moment(row.order?.orderDate).format(df.time) 
    },

    {
        id: "currency",
        name: "Currency",
        modes: ["csv"],
        render: row => row.order?.currency.toUpperCase()
    },

    { 
        id: "orderId", 
        name: "Order ID",
        className: "grid-nowrap",
        render: row => {
            if (row.order.networkId === "rakuten") {
                let details = row.order.rakutenItems?.[0]?.details;
                if (details) {
                    details = JSON.parse(details);
                    return details['_Order_ID'];
                }
            }
            else {
                return row.order.orderId;
            }
        }
    },
    
    { 
        id: "status", 
        name: "Affiliate order status",
        className: "grid-nowrap",
        render: row => {
            
            if (row.status) {
                return row.status;
            }
            if (row.order.status) {
                return row.order.status;
            }
            if (row.order.autoApprovedAt !== null) {
                return "auto approved";
            }
            return "";
        }
    },

    { 
        id: "tf_status", 
        name: "TF order status",
        className: "grid-nowrap",
        render: row => {
            return getOrderItemStatusName(row.order, row);
        }
    },

    { 
        id: "sale", 
        name: "Sale amount",
        render: row => formatCurrency(row.saleAmount, row.order.currency, 2)
    },

    { 
        id: "comm", 
        name: "TF Net Commission", 
        render: row => formatCurrency(row.commissionAmount, row.order.currency, 2)
    },

    { 
        id: "pse-comm", 
        name: "PSE Net Commission", 
        render: row => formatCurrency(row.pseCommission, row.order.currency, 2)
    },

    { 
        id: "sku", 
        name: "SKU",
        className: "grid-nowrap"
    },

    { 
        id: "brand", 
        name: "Brand",
        className: "grid-nowrap"
    },

    { 
        id: "name", 
        name: "Product",
        className: "grid-nowrap"
    },
    
    { 
        id: "source", 
        name: "Source",
        render: row => {
            if (row.order.click?.moodboardId) {
                return "Moodboard";
            }
            if (row.order.click?.catalogueProductId) {
                return "Catalogue";
            }
            if (row.order.click?.product?.look) {
                return "Styling session";
                //return row.order.click.product.look.consultationId ? "Consultation" : "Look";
            }
        }
    },
                    
    {
        id: "createdby",
        name: "Created by",
        className: "grid-nowrap",
        render: row => {
            if (!!row.order.clickId && !!row.order.click.product) {
                return row.order.click.product.look.fri.givenName + " " +  
                        row.order.click.product.look.fri.familyName;
            }
            if (!!row.order.clickId && row.order.click.referenceUser) {
                return row.order.click.referenceUser.givenName + " " + 
                        row.order.click.referenceUser.familyName;
            }
            return "";
        }
    },

    /*{ 
        id: "fri", 
        name: "Look by", 
        className: "grid-nowrap",
        render: row => (!!row.order.clickId && !!row.order.click.product) ?
                        row.order.click.product.look.fri.givenName + " " +  
                        row.order.click.product.look.fri.familyName:
                        "",
    },

    { 
        id: "contributor", 
        name: "Link by", 
        className: "grid-nowrap",
        render: row => 
            (!!row.order.clickId && row.order.click.referenceUser) ? 
                row.order.click.referenceUser.givenName + " " + row.order.click.referenceUser.familyName : 
                "",
    },*/

    {
        id: "clickedby",
        name: "Clicked by",
        className: "grid-nowrap",
        render: row => 
            (!!row.order.clickId && row.order.click.user) ? 
                row.order.click.user.givenName + " " + row.order.click.user.familyName : 
                "Anonymous",
    },

    {
        id: "link",
        name: "Link",
        render: row => {
            if (row.order.click && row.order.click.link) {
                const clickDomain = getClickDomain({ retailer: row.order.click.link.retailer });
                return (
                    <Link 
                        to={ `https://${ clickDomain }/s/${ row.order.click.link.hash }` }
                        children={ `https://${ clickDomain }/s/${ row.order.click.link.hash }` }/>
                )
            }
            return null;
        }
    },

    {
        id: "link-product-brand",
        name: "Clicked brand",
        className: "grid-nowrap",
        render: row => {
            if (row.order.click?.link) {
                const { brand } = row.order.click.link;
                return brand
            }
            else if (row.order.click?.product) {
                const { brand } = row.order.click.product;
                return brand;
            }
            else if (row.order.click?.catalogueProduct) {
                const { brand } = row.order.click.catalogueProduct;
                return brand
            }
            return null;
        }
    },

    {
        id: "link-product",
        name: "Clicked product description",
        className: "grid-nowrap",
        render: row => {
            if (row.order.click?.link) {
                const { name } = row.order.click.link;
                return name
            }
            else if (row.order.click?.product) {
                const { name } = row.order.click.product;
                return name;
            }
            else if (row.order.click?.catalogueProduct) {
                const { name } = row.order.click.catalogueProduct;
                return name
            }
            // else {
            //     return row.click?.catalogueProductId
            // }
            return null;
        }
    },

    {
        id: "target-url",
        name: "Target URL",
        className: "grid-nowrap",
        render: row => {
            const url = row.order.click?.usedUrl;
            if (url) {
                return (
                    <Link 
                        to={ url } 
                        children={ url } 
                        target="_blank" 
                        onClick={ e => e.stopPropagation() }
                        rel="noreferrer"/>
                )
            }
            return "";
        }
    }
]


export const fri = [
    {
        className: "grid-section-end grid-nowrap",
        id: "fri", 
        name: "Look by", 
        render: row => <User user={ row.fri } passthruClick/>,
        renderCsv: row => row.fri.givenName + " " + row.fri.familyName
    },
    ...commonColumns
];




export const pse = [

    {
        className: "grid-section-end grid-nowrap",
        id: "pse", 
        name: "PSE", 
        render: row => <User user={ row.user } passthruClick/>,
        renderCsv: row => row.user.givenName + " " + row.user.familyName
    },
    ...commonColumns,
    {
        id: "psegrosscomm",
        name: "PSE Gross Commission",
        render: (row, inx, ds) => {
            return formatCurrency(row.pseGrossCommission, "gbp", 2)
        }
    },
    {
        id: "psecomm",
        name: "PSE Net Commission",
        render: (row, inx, ds) => {
            return formatCurrency(row.pseCommission, "gbp", 2)
        }
    }
];




export const contributor = [
    {
        className: "grid-section-end grid-nowrap",
        id: "inf", 
        name: "Link by", 
        render: row => <User user={ row.contributor } passthruClick/>,
        renderCsv: row => row.contributor.givenName + " " + row.contributor.familyName
    },
    ...commonColumns,
    //...commonPSEColumns
];


export const network = [
    { 
        id: "name", 
        name: "Network" 
    },
    ...commonColumns,
    //...commonPSEColumns
];


export const advertiser = [
    { 
        id: "advertiser", 
        name: "Retailer" 
    },
    ...commonColumns,
    //...commonPSEColumns
];



export const campaign = [
    { 
        id: "name", 
        name: "Campaign" 
    }
].concat(commonColumns);



export const date = [
    { 
        id: "period", 
        name: "Period", 
        className: "grid-section-end grid-nowrap",
        render: (row, inx, ds) => {
            const { dateGroup } = ds.getCustomData();
            return moment(row.date_group).format(dateFormats[ dateGroup[0].id ])
        }
    },
    ...commonColumns,
    //...commonPSEColumns
];