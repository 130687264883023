import React from "react"

import Loader from "common/src/components/Loader"
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import { ReactComponent as IconExpand } from "common/src/svg/expand.svg"
import { ReactComponent as IconClear } from "common/src/svg/clear.svg"
import TextField from "@mui/material/TextField"
import Checkbox from "common/src/components/material/Checkbox"
import Radiobox from "common/src/components/material/Radio"
import InputAdornment from '@mui/material/InputAdornment'
import FormControlLabel from '@mui/material/FormControlLabel'

import async from "common/src/lib/js/async"
import hub from "common/src/hub"
import NullForm from "./NullForm"


class PublicSelector extends React.Component {

    state = {
        expanded: false,
        search: "",
        selection: [],
        items: [],
        filtered: [],
        loading: false
    }

    _itemHash = ""
    _selectionHash = ""
    _isMounted = false

    constructor(props) {
        super(props);
        this.onSelectorOpen = this.onSelectorOpen.bind(this);
    }


    componentDidMount() {
        this._isMounted = true;

        if (this.props.group) {
            hub.listen("tag-selector", "open", this.onSelectorOpen);
        }

        if (this.props.value) {
            const items = this.props.items;
            let selection = Array.from(this.props.value || []);
            let selhash = selection.map(t => typeof t === "string" ? t : t.id);
            selhash.sort();
            this._selectionHash = selhash.join(",");
            const hasPlainItems = !!selection.find(s => typeof s === "string");
            const ids = this.props.items.map(i => i.id);
            ids.sort();
            this._itemHash = ids.join(",");

            if (hasPlainItems) {
                if (items.length > 0) {
                    selection = selection.map(s => {
                        if (typeof s === "string") {
                            return items.find(i => i.id === s) || { id: s, name: s };
                        }
                        return s;
                    });
                }
                else this.props.onLoadItems();
            }

            this.setState({ selection });
        }

        if (this.props.expanded) {
            async(() => this.toggle());
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.props.group) {
            hub.remove("tag-selector", "open", this.onSelectorOpen);
        }
    }

    componentDidUpdate(prev) {
        
        let selection = Array.from(this.props.value || []);
        const items = this.props.items;
        const ids = selection.map(t => typeof t === "string" ? t : t.id);
        ids.sort();
        const itemIds = items.map(i => i.id);
        itemIds.sort();
        const selHash = ids.join(",");
        const itemHash = itemIds.join(",");
        const hasPlainItems = !!selection.find(s => typeof s === "string");

        if (hasPlainItems) {
            if (items.length === 0) {
                async(() => this.props.onLoadItems());
            }
        }

        if (this._selectionHash !== selHash) {
            this._selectionHash = selHash;
            if (items.length > 0) {
                selection = selection.map(s => {
                    if (typeof s === "string") {
                        return items.find(i => i.id === s) || { id: s, name: s };
                    }
                    return s;
                });
            }
            async(() => this.setState({ selection }));
        }
        else if (hasPlainItems && this._itemHash !== itemHash) {
            selection = selection.map(s => {
                if (typeof s === "string") {
                    return items.find(i => i.id === s) || { id: s, name: s };
                }
                return s;
            });
            async(() => this.setState({ selection }));
        }

        this._itemHash = itemHash;

        if (this.state.expanded) {
            if (prev.subsetId !== this.props.subsetId) {
                this.props.onLoadItems && this.props.onLoadItems();
            }
            else if (prev.filteredSubsetId !== this.props.filteredSubsetId) {
                this.props.onLoadFiltered && this.props.onLoadFiltered();
            }
        }
    }

    onSelectorOpen({ group, type }) {
        if (this.props.group === group &&
            this.props.type !== type &&
            this.state.expanded) {
            this.setState({ expanded: false })
        }
    }

    onSearchChange(search = "") {
        this.setState({ search });
        if (search.length === 0 || search.length > 1) {
            async(() => this.props.onSearchChange && this.props.onSearchChange(search));
        }
    }

    getSelectionState() {
        const selection = this.state.selection;
        const items = this.props.items;
        if (selection.length === 0) {
            return "All selected"
        }
        const names = selection.map(t => {
            if (typeof t === "string") {
                t = items.find(i => i.id === t);
                return t?.name || "";
            }
            return t?.name || "";
        });
        return names.join(", ");
    }

    onToggleClick(e) {
        e.preventDefault();
        this.toggle();
    }

    onClearClick(e) {
        e.preventDefault();
        this.clear();
    }

    clear() {
        this.setState({ selection: [] });
        this.props.onChange && this.props.onChange([]);
    }

    toggle() {
        const expanded = !this.state.expanded;
        this.setState({ expanded });

        if (expanded && this.props.group) {
            hub.dispatch("tag-selector", "open", {
                group: this.props.group,
                type: this.props.type
            })
        }

        if (expanded && this.props.items.length === 0) {
            this.props.onLoadItems && this.props.onLoadItems();
        }
    }

    selectItem(t) {
        const { track, group, single } = this.props;
        const selection = single ? [] : [].concat(this.state.selection);
        if (!selection.find(s => s.id === t.id)) {
            selection.push(t);
            this.setState({ selection });

            if (track) {
                hub.dispatch("tag-filter", "use", {
                    where: group,
                    type: t.__typename
                });
                hub.dispatch("tag-filter", "select", {
                    type: t.__typename,
                    id: t.id
                });
            }
            this.props.onChange && this.props.onChange(selection);
        }
    }

    unselectItem(t) {
        const selection = [].concat(this.state.selection);
        const inx = selection.findIndex(s => s.id === t.id)
        if (inx !== -1) {
            selection.splice(inx, 1);
            this.setState({ selection });
            this.props.onChange && this.props.onChange(selection);
        }
    }

    toggleItem(t) {
        const selection = [].concat(this.state.selection);
        const inx = selection.findIndex(s => s.id === t.id);
        if (inx === -1) {
            this.selectItem(t);
        }
        else if (!this.props.mustHaveSelection) {
            this.unselectItem(t);
        }
    }

    render() {

        const { expanded, search, selection } = this.state;
        const { noSearch, items = [], filtered = [], loading = false, 
                name = "", placeholder = "", single = false } = this.props;
        const cls = ["public-selector", 
                        this.props.className, 
                        expanded ? "expanded" : "",
                        selection.length ? "selection" : ""].concat(this.props.cls || []);
        const selectionState = this.getSelectionState();

        const SelCtrl = single ? Radiobox : Checkbox;

        return (
            <div className={ cls.join(" ") }>
                <div className="tag-selector-header">
                    <a href="/#"
                        className="tag-selector-toggle"
                        onClick={ e => this.onToggleClick(e) }>
                        <label>{ name }</label>
                        <span>{ selectionState }</span>
                        <IconExpand />
                    </a>
                    <a href="/#" 
                        className="tag-selector-clear"
                        onClick={ e => this.onClearClick(e) }
                        children={ <IconClear/> }/>
                </div>
                { !noSearch && <NullForm className="tag-selector-search">
                    <TextField
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconSearch/>
                                </InputAdornment>
                            )
                        }}
                        onChange={ e => this.onSearchChange(e.target.value) }
                        value={ search }
                        placeholder={ search ? "" : placeholder }/>
                </NullForm> }
                <div className="tag-selector-body tag-selector-list"
                    onWheel={ e => e.stopPropagation() }>
                    { loading && <Loader/> }
                    { !single && selection.map(t => (
                        <FormControlLabel
                            key={ "s-" + (t.id || t) }
                            control={ 
                                <SelCtrl 
                                    color="primary" 
                                    checked={ true } 
                                    onChange={ e => this.unselectItem(t) }/>}
                            label={ t.label || t.name }/>
                    ))}
                    { selection.length > 0 && <hr/> }
                    { items.map((t, inx) => (
                        !filtered.find( f => f.id === t.id ) ?
                        null: 
                        <FormControlLabel
                            key={ t.id || t }
                            control={ 
                                <SelCtrl 
                                    checked={ !!selection.find(s => s.id === t.id) } 
                                    onChange={ e => this.toggleItem(t) }/>}
                            label={ t.label || t.name }/>
                    ))}
                    { items.map((t, inx) => (
                        selection.find( s => s.id === t.id ) ||
                        filtered.find( f => f.id === t.id ) ?
                        null: 
                        <FormControlLabel
                            key={ "f-" + (t.id || t) }
                            control={ 
                                <SelCtrl 
                                    disabled={ true }
                                    checked={ false } 
                                    onChange={ e => this.selectItem(t) }/>}
                            label={ t.label || t.name }/>
                    ))}
                </div>
            </div>
        )
    }
}

export default PublicSelector
