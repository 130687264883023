import React from "react"
import { connect } from "react-redux"
import Products from "common/src/components/catalogue/products/PaginatedProducts"
import TextField from "@mui/material/TextField"
import ProductDetails from "common/src/components/catalogue/ProductDialog"
import DesignerSelector from "common/src/components/catalogue/DesignerSelector"
import AffiliateSelector from "common/src/components/catalogue/AffiliateSelector"
import PriceSelector from "common/src/components/catalogue/PriceSelector"
import SortingSelector from "common/src/components/catalogue/SortingSelector"
//import CategorySelector from "common/src/components/catalogue/CategorySelector"
//import ColorSelector from "common/src/components/catalogue/ColorSelector"
//import MaterialSelector from "common/src/components/catalogue/MaterialSelector"
import RetailerSelector from "common/src/components/catalogue/RetailerSelector"
import GenderSelector from "common/src/components/catalogue/GenderSelector"
import RegionSelector from "common/src/components/catalogue/RegionSelector"
//import { loadColors, loadCategories, loadMaterials } from "common/src/actions/catalogue"
import { loadFacets } from "common/src/actions/catalogue"
import { ui as ddUi } from "common/src/store/dialogs"
import NullForm from "common/src/components/NullForm"

const PER_PAGE = 32;

class PageProducts extends React.Component {

    state = {
        page: 0,
        search: "",
        query: null,
        network: null,
        designer_id: null,
        category_id: null,
        color_id: null,
        material_id: null,
        price: null,
        retailer: null,
        gender: null,
        region: null,
        product: null,
        sorting: null
    }

    componentDidMount() {
        //loadColors();
        //loadCategories();
        //loadMaterials();
        loadFacets();
    }

    onPageChange(page) {
        this.setState({ page });
    }

    search() {
        this.setState({ query: this.state.search });
    }

    onSearchChange(search) {
        this.setState({ search });
    }

    onProductClick(product) {
        this.props.dispatch(ddUi.show("product-details"));
        this.setState({ product });
    }

    render() {

        const { page, search, query, retailer, gender, region,
                designer_id, network, price, sorting,
                //category_id, color_id, material_id,
                product } = this.state;

        return (
            <>
            <div className="page page-products">
                <div className="toolbar">
                    <div className="toolbar-section">
                        <NullForm>
                        <TextField 
                            autoComplete="off"
                            name="search"
                            variant="outlined"
                            value={ search || "" }
                            placeholder="Search"
                            //label={ search ? "" : "Search" }
                            onKeyDown={ e => e.key === "Enter" && this.search() }
                            onChange={ e => this.onSearchChange(e.target.value) }/>
                        </NullForm>
                        <SortingSelector
                            size="normal"
                            withSearch={ !!search }
                            onChange={ s => this.setState({ sorting: s })}/>
                    </div>
                    <div className="toolbar-section">
                        <AffiliateSelector
                            size="normal"
                            multiple
                            checkboxMode
                            onChange={ ds => this.setState({ network: ds.map(d => d.id) }) }/>
                        <DesignerSelector 
                            size="normal"
                            multiple
                            checkboxMode
                            onChange={ ds => this.setState({ designer_id: ds.map(d => d.id) }) }/>
                        <PriceSelector 
                            allowEmpty
                            size="normal"
                            onChange={ price => this.setState({ price: price?.id || null }) }/>
                        {/*<CategorySelector 
                            size="normal"
                            onChange={ cat => this.setState({ category_id: cat ? cat.id : null }) }/>
                        <ColorSelector 
                            size="normal"
                            onChange={ col => this.setState({ color_id: col ? col.id : null }) }/>
                        <MaterialSelector 
                            size="normal"
                            onChange={ mat => this.setState({ material_id: mat ? mat.id : null }) }/>*/}
                    </div>
                    <div className="toolbar-section">
                        <RetailerSelector 
                            size="normal"
                            onChange={ ret => this.setState({ retailer: ret ? ret.id : null }) }/>
                        <GenderSelector 
                            size="normal"
                            onChange={ g => this.setState({ gender: g ? g.id : null }) }/>
                        <RegionSelector 
                            size="normal"
                            onChange={ r => this.setState({ region: r ? r.id : null }) }/>
                    </div>
                </div>
                <Products 
                    autoload
                    setName="main"
                    skip_backend_cache={ true }

                    start={ page * PER_PAGE }
                    page={ page }
                    limit={ PER_PAGE } 
                    perPage={ PER_PAGE }
                    
                    query={ query }
                    designer_id={ designer_id }
                    catalogue_source={ network }
                    //category_id={ category_id }
                    //color_id={ color_id }
                    //material_id={ material_id }
                    retailer={ retailer }
                    gender={ gender }
                    region={ region }
                    price={ price }
                    sort_by={ sorting?.sort_by }
                    sort_dir={ sorting?.sort_dir }

                    onPageChange={ no => this.onPageChange(no) }
                    product={ p => ({
                        onClick: () => this.onProductClick(p)
                    })}/>
            </div>
            <ProductDetails product={ product }/>
            </>
        )
    }
}

export default connect()(PageProducts)
