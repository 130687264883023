import formatCurrency from "common/src/lib/format/currency"
import moment from "moment"
import df from "common/src/lib/date/formats"


export const getProducts = async (order) => {
    
    const details = JSON.parse(order.details) || {};
    const rows = details.transactionParts || [];
    const cols = [
        { id: "amount", name: "Sale", render: i => formatCurrency(i.amount, order.currency, 2) },
        { id: "commissionAmount", name: "TF Commission", 
            render: i => formatCurrency(i.commissionAmount, order.currency, 2) },
        { id: "commissionGroupName", name: "Product" }
    ]

    return { cols, rows };
}


export const getStatusClass = (comm) => {
    switch (comm.status) {
        case "approved": {
            return "ok";
        }
        case "pending": {
            return "pending";
        }
        case "declined": {
            return "notok";
        }
        default: {
            return null;
        }
    }
}

export const hasHistory = (order) => {
    return order.history && order.history.length > 0;
}


export const getHistory = (order) => {
    const cols = [
        { id: "importedAt", name: "Import date",
            render: c => moment(c.importedAt).format(df.full) },
        { id: "status", name: "Status" }
    ]
    const rows = order.history;

    return { cols, rows };
}

export const hasCorrections = () => {
    return false;
}