import { useState, useEffect, useCallback, useMemo } from "react"
import moment from "moment"

import Table from "common/src/components/table/Table"
import Loader from "common/src/components/Loader"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"

import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"

import api from "app/api"
import useDictRef from "common/src/hooks/useDictRef"
import df from "common/src/lib/date/formats"
import NullForm from "common/src/components/NullForm"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { data2csv, downloadCsv } from "common/src/lib/csv"

const roleNames = {
    shopper: "Personal Shopper",
    brand: "Brand",
    visitor: "Just insterested",
    customer: "Customer"
}

function prepareWhere({ query }) {
    const where = {};

    if (query) {
        where._or = [
            {
                email: { _ilike: `%${ query }%` }
            },
            {
                name: { _ilike: `%${ query }%` }
            },
            {
                surname: { _ilike: `%${ query }%` }
            }
        ]
    }

    return where;
}


async function retrieveAll(query) {

    const where = prepareWhere({ query });
    const order = {
        createdAt: "desc"
    }
    let apps = [];
    let offset = 0;
    const limit = 1000;

    while (true) {
        const list = await api.visitorApplication.list({ where, order, offset, limit });

        offset += limit;

        apps = [ ...apps, ...list ];

        if (list.length < limit) {
            break;
        }
    }

    return apps;
}



function VisitorApplications() {

    const [ loading, setLoading ] = useState(false);
    const [ list, setList ] = useState([]);
    const [ query, setQuery ] = useState("");
    const [ downloading, setDownloading ] = useState(false);
    const ref = useDictRef({
        query,
    });

    const refreshCls = useMemo(
        () => [ 'icon', 'icon-svg-fill', loading ? 'spinning' : ''].join(" "), 
        [ loading ]
    );

    const loadApplications = useCallback(
        async () => {
            setLoading(true);
            const where = {};
            const order = {
                createdAt: "desc"
            }

            if (ref.query) {
                where._or = [
                    {
                        email: { _ilike: `%${ ref.query }%` }
                    },
                    {
                        name: { _ilike: `%${ ref.query }%` }
                    },
                    {
                        surname: { _ilike: `%${ ref.query }%` }
                    }
                ]
            }

            const list = await api.visitorApplication.list({ where, order });
            setList(list);
            setLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const cols = useMemo(
        () => {
            return [
                { id: "role", name: "Role", 
                    render: (app => roleNames[app.role]) },
                { id: "name", 
                    name: "Name", 
                    className: "grid-nowrap",
                    render: app =>  app.name + " " + app.surname },
                { id: "email", name: "Email", className: "grid-nowrap" },
                { id: "phone", name: "Phone", render: app => app.phone ? "+" + app.phone : "" },
                { id: "city", name: "City" },
                { id: "bio", name: "Info" },
                { id: "shopper", name: "Shopper", 
                    render: app => {
                        if (app.shopperName) {
                            return (
                                <>
                                { app.shopperName } { app.shopperSurname}<br/>
                                { app.shopperEmail }
                                </>
                            )
                        }
                    },
                    renderCsv: app => {
                        if (app.shopperName) {
                            return `${ app.shopperName } ${ app.shopperSurname} (${ app.shopperEmail })`
                        }
                        return "";
                    }
                },
                { id: "createdAt", name: "Submitted at", 
                    render: app => moment(app.createdAt).format(df.full) },
            ];
        },
        // eslint-disable-next-line
        []
    );

    const download = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const apps = await retrieveAll(ref.query);
            const csv = data2csv(cols, apps);
            await downloadCsv(csv, "visitor-applications.csv");
            setDownloading(false);
        },
        [ query, cols ]
    );

    const search = useCallback(
        () => loadApplications(),
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            loadApplications();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className="page page-w-loading">
            { loading && <Loader size={ 64 }/> }
            <NullForm className="toolbar">
                <TextField
                    autoComplete="off"
                    variant="outlined"
                    onKeyDown={ e => e.key === "Enter" && search() }
                    value={ query }
                    label={ query ? "" : "Search" }
                    onChange={ e => setQuery(e.target.value) }/>
                <Button 
                    variant="contained" 
                    children="Search"
                    onClick={ search }/>

                <nav className="menu right">
                    <div className="menu-item">
                        <a href="/#"
                            className="menu-link"
                            onClick={ e => {
                                e.preventDefault();
                                e.stopPropagation();
                                loadApplications();
                            }}>
                            <IconRefresh className={ refreshCls }/>
                        </a>
                    </div>
                    <div className="menu-item">
                        <a href="/#" className="menu-link"
                            onClick={ download }>
                            { downloading ? 
                                <Loader size={ 24 }/> :
                                <IconDownload/> }
                        </a>
                    </div>
                </nav>
            </NullForm>
            <Table
                className="page-pse-applications-table"
                variant="slim"
                cols={ cols }
                rows={ list }/>
        </div>
    )
}

export default VisitorApplications