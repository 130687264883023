import { useCallback, useEffect, useMemo, useState } from "react"

import { Button } from "@mui/material"

import Loader from "common/src/components/Loader"

import api from "app/api"
import useUpdateEffect from "common/src/hooks/useUpdateEffect";

function getRedirUrl(secret) {

    const env = process.env.REACT_APP_ENV;

    if (env === "live") {
        return `https://api-revolut.thefloorr.com/public/code/${ secret }`;
    }
    else {
        return `https://api-revolut-${ env }.thefloorr.com/public/code/${ secret }`;
    }

}

function PageRevolut() {

    const [ loading, setLoading ] = useState(false);
    const [ settingHook, setSettingHook ] = useState(false);
    const [ info, setInfo ] = useState({});
    const [ , setHooks ] = useState([]);
    const [ hasWebhooks, setHasWebhooks ] = useState(null);

    const isSandbox = useMemo(
        () => process.env.REACT_APP_ENV !== "live",
        []
    );
    
    const loadInfo = useCallback(
        async () => {
            const info = await api.revolut.post("/info", { body: {}});
            setInfo(info)
        },
        []
    );

    const loadWebhooks = useCallback(
        async () => {
            try {
                const { webhooks, error } = await api.revolut.get("/webhooks");
                console.log(webhooks);
                if (webhooks && webhooks.length > 0) {
                    setHasWebhooks(true);
                    setHooks(webhooks);
                }
                else {
                    setHasWebhooks(false);
                }
                if (error) {
                    console.log(error);
                }
            }
            catch (err) {
                console.log(err);
            }
        },
        []
    );

    const openRevolut = useCallback(
        async () => {
            setLoading(true);
            const redir = getRedirUrl(info.apiSecret);
            const domain = isSandbox ? "sandbox-business" : "business";
            const url = `https://${ domain }.revolut.com/app-confirm?client_id=${ info.clientId }`+
                        `&redirect_uri=${ redir }&response_type=code&scope=READ,WRITE#authorise`;
            setLoading(false);

            window.open(url);
        },
        [ isSandbox, info ]
    );

    const setupWebook = useCallback(
        async () => {
            setSettingHook(true);
            await api.revolut.post("/webhooks");
            setSettingHook(false);
            loadWebhooks();
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            loadInfo();
        },
        // eslint-disable-next-line
        []
    );

    useUpdateEffect(
        () => {
            if (info.hasAccessToken) {
                loadWebhooks();
            }
        },
        [ info.hasAccessToken ]
    )

    return (
        <div className="page" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button 
                disabled={ loading }
                startIcon={ loading ? <Loader inline/> : null }
                size="large"
                children="Authorize TF" 
                variant="contained" 
                onClick={ openRevolut }/>
            { hasWebhooks === false && 
                <Button 
                    disabled={ settingHook }
                    startIcon={ settingHook ? <Loader inline/> : null }
                    size="large"
                    children="Setup webhook" 
                    variant="outlined" 
                    onClick={ setupWebook }/>}
        </div>
    )
}

export default PageRevolut