import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { TextField, Button } from "@mui/material"

import Loader from "common/src/components/Loader"
import ProductDialog from "common/src/components/catalogue/ProductDialog"
import Pagination from "common/src/components/Pagination"
import Products from "common/src/components/catalogue/products/PaginatedProducts"
import DesignerSelector from "app/components/pages/catalogue/selector/Designer"

import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useDictRef from "common/src/hooks/useDictRef"

import { ui as ddUi } from "common/src/store/dialogs"
import { alert, confirm } from "common/src/components/dialog/Alert"

import api from "app/api"
import NullForm from "common/src/components/NullForm"

const PER_PAGE = 50;

async function getDesignerFeedRecord(feedDesignerId, designerId) {
    const where = {
        feedDesignerId: { _eq: feedDesignerId }
    };
    if (designerId) {
        where.designerId = { _eq: designerId };
    }
    else {
        where.designerId = { _is_null: true };
    }
    const rec = await api.catalogueDesignerFeed.list({ where }).then(list => list[0]);
    return rec;
}

async function createDesignerFeedRecord(feedDesignerId, feedDesignerName, designerId = null, ban = false) {

    const ex = await getDesignerFeedRecord(feedDesignerId, designerId);

    if (ex) {
        return "exists";
    }

    const res = await api.catalogueDesignerFeed.create({
        designerId,
        feedDesignerId,
        feedDesignerName,
        ban
    });

    return res.id;
}

async function removeQueueRecord(id) {
    await api.catalogueDesignerQueue.remove(id);
}

async function banDesigner(queue) {
    await createDesignerFeedRecord(queue.feedDesignerId, queue.name, null, true);
    await removeQueueRecord(queue.id);
}

async function getDesignerByName(name) {
    const where = {
        name: { _ilike: name }
    }
    const exs = await api.catalogueDesigner.list({ where });
    return exs;
}

async function createDesigner(name, queue) {
    const exs = await getDesignerByName(name);
    if (exs.length > 0) {
        await alert({ message: "Designer with this name already exists" });
        return false;
    }
    const res = await api.catalogueDesigner.create({ name });
    await createDesignerFeedRecord(queue.feedDesignerId, queue.name, res.id, false);
    await removeQueueRecord(queue.id);
    return true;
}

async function assignDesigners(queue, designerIds) {

    for (let i = 0, l = designerIds.length; i < l; i++) {
        await createDesignerFeedRecord(queue.feedDesignerId, queue.name, designerIds[i], false);
    }
    await removeQueueRecord(queue.id);
}


function DesignerAssignForm({ queue, onAssign, onClose, onCreate }) {

    const [ assigning, setAssigning ] = useState(false);
    const [ selection, setSelection ] = useState([]);
    const ref = useDictRef({ queue, selection, onAssign, onClose });

    const assign = useCallback(
        async () => {
            setAssigning(true);
            await assignDesigners(ref.queue, ref.selection.map(d => d.id));
            setAssigning(false);
            ref.onAssign();
            ref.onClose();
        },
        // eslint-disable-next-line
        []
    );

    const onSelectionChange = useCallback(
        (s) => setSelection(s),
        []
    );

    return (
        <div className="page-catalogue-queue-form-centered">
            <DesignerSelector
                preselectName={ queue.name }
                selection={ selection }
                onChange={ onSelectionChange }/>
            <Button
                variant="contained"
                children="Assign"
                startIcon={ assigning ? <Loader inline/> : null }
                disabled={ assigning || selection.length === 0 }
                onClick={ assign }/>
            <Button 
                variant="text" 
                children="Cancel"
                onClick={ onClose }/>
            <Button 
                variant="text" 
                children="Create"
                onClick={ onCreate }/>
        </div>
    )
}


function DesignerCreateForm({ queue, onClose, onCreate, onAssign }) {

    const [ name, setName ] = useState(queue?.name || "");
    const [ creating, setCreating ] = useState(false);
    const ref = useDictRef({ queue, name, onCreate, onClose });

    const create = useCallback(
        async () => {
            setCreating(true);
            const res = await createDesigner(ref.name, ref.queue);
            setCreating(false);
            ref.onClose();
            res === true && ref.onCreate();
        },
        // eslint-disable-next-line
        []
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                create();
            }
        },
        // eslint-disable-next-line
        []
    );
    const onNameChange = useCallback(
        (e) => setName(e.target.value),
        []
    );

    return (
        <NullForm className="page-catalogue-queue-form-centered">
            <TextField 
                autoComplete="off"
                variant="outlined" 
                placeholder="Designer name"
                value={ name }
                disabled={ creating }
                onKeyDown={ onKeyDown }
                onChange={ onNameChange }/>
            <Button
                variant="contained"
                children="Create"
                startIcon={ creating ? <Loader inline/> : null }
                disabled={ creating || name === "" }
                onClick={ create }/>
            <Button 
                variant="text" 
                children="Cancel"
                onClick={ onClose }/>
            <Button 
                variant="text" 
                children="Assign"
                onClick={ onAssign }/>
        </NullForm>
    )
}


function Designer({ designer, onOpenProduct, onChange }) {

    const [ open, setOpen ] = useState(false);
    const [ banning, setBanning ] = useState(false);
    const [ showCreateForm, setShowCreateForm ] = useState(false);
    const [ showAssignForm, setShowAssignForm ] = useState(false);
    const cls = useMemo(
        () => [ "page-catalogue-color", open ? "active" : "" ].join(" "),
        [ open ]
    )

    const openDesigner = useSwallowEventCallback(
        () => {
            setOpen(!open);
        },
        [ open ]
    );

    const onCreateClick = useCallback(
        () => setShowCreateForm(true),
        []
    );

    const onCloseCreateClick = useCallback(
        () => setShowCreateForm(false),
        []
    );

    const onAssignClick = useCallback(
        () => setShowAssignForm(true),
        []
    );

    const onAssignCloseClick = useCallback(
        () => setShowAssignForm(false),
        []
    );

    const onAssignCreateClick = useCallback(
        () => {
            setShowAssignForm(false);
            setShowCreateForm(true);
        },
        []
    );

    const onCreateAssignClick = useCallback(
        () => {
            setShowCreateForm(false);
            setShowAssignForm(true);
        },
        []
    );

    const onBanClick = useCallback(
        async () => {
            try {
                const res = await confirm({ message: "Are you sure?" });
                if (res) {
                    setBanning(true);
                    await banDesigner(designer);
                    setBanning(false);
                    onChange();
                }
            }
            catch (err) {}
        },
        [ onChange, designer ]
    );

    return (
        <div className={ cls }>
            <div>
                <h4>
                    { designer.name }
                    <a href="/#" onClick={ openDesigner }>
                        <IconDown/>
                    </a> 
                </h4>
            </div>

            { showCreateForm && 
                <DesignerCreateForm
                    queue={ designer }
                    onCreate={ onChange }
                    onClose={ onCloseCreateClick }
                    onAssign={ onCreateAssignClick }/> }

            { showAssignForm &&
                <DesignerAssignForm
                    queue={ designer }
                    onAssign={ onChange }
                    onClose={ onAssignCloseClick }
                    onCreate={ onAssignCreateClick }/> }

            { (!showCreateForm && !showAssignForm) && 
                <div className="actions">
                    <Button 
                        variant="contained" 
                        children="Create"
                        onClick={ onCreateClick }/>
                    <Button 
                        variant="outlined" 
                        children="Assign"
                        onClick={ onAssignClick }/>
                    <Button 
                        variant="text" 
                        children="Ban products"
                        disabled={ banning }
                        startIcon={ banning ? <Loader inline/> : null }
                        onClick={ onBanClick }/>
                </div> }

            { open && 
                <Products 
                    autoload
                    setName={ designer.feedDesignerId }
                    perPage={ 16 }
                    brand={ designer.name }
                    product={ p => ({ onClick: () => onOpenProduct(p) })}
                    emptyText="Currently, no products found"/>}
        </div>
    )

}

function DesignerQueue() {

    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState(false);
    const [ query, setQuery ] = useState("");
    const [ count, setCount ] = useState(0);
    const [ queue, setQueue ] = useState([]);
    const [ page, setPage ] = useState(0);
    const [ product, setProduct ] = useState(null);
    const ref = useDictRef({ query, page });

    const loadList = useCallback(
        async () => {
            setLoading(true);

            const where = {};
            const limit = PER_PAGE;
            const offset = ref.page * PER_PAGE;
            const order = { name: "asc" };

            if (ref.query) {
                where.name = { _ilike: `%${ ref.query }%` };
            }

            const queue = await api.catalogueDesignerQueue.list({ where, order, offset, limit });
            const count = await api.catalogueDesignerQueue.count({ where }).then(r => r.count);

            setQueue(queue);
            setCount(count);
            setLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const onSearchKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                loadList();
            }
        },
        // eslint-disable-next-line
        []
    );

    const onSearchChange = useCallback(
        (e) => setQuery(e.target.value),
        []
    );

    const onOpenProduct = useCallback(
        (p) => {
            setProduct(p);
            dispatch(ddUi.show("product-details"));
        },
        // eslint-disable-next-line
        []
    );

    const onDesignerChange = useCallback(
        () => { loadList() },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => { 
            loadList();
            try {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
            catch (err) {}
        },
        // eslint-disable-next-line
        [ page ]
    );

    return (
        <>
        <div className="page page-catalogue-color-queue">
            { loading && <Loader size={ 64 }/> }
            <h2>Designer queue</h2>
            <NullForm className="toolbar">
                <TextField 
                    fullWidth
                    autoComplete="off" 
                    placeholder={ `Search (total: ${ count })`  }
                    variant="outlined"
                    value={ query }
                    onKeyDown={ onSearchKeyDown }
                    onChange={ onSearchChange }/>
                <Button 
                    variant="contained" 
                    children="Search"
                    onClick={ loadList }/>
            </NullForm>

            { queue.map(d => 
                <Designer 
                    designer={ d } 
                    key={ d.id }
                    onChange={ onDesignerChange }
                    onOpenProduct={ onOpenProduct }/>)}

            <Pagination
                page={ page }
                count={ count }
                perPage={ PER_PAGE }
                onChange={ setPage }/>
        </div>
        <ProductDialog product={ product }/>
        </>
    )
}

export default DesignerQueue