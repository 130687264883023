import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button } from "@mui/material"

import userSelector from "common/src/selectors/user/current"
import { ui } from "common/src/store/user"

function ProfileBio() {

    const dispatch = useDispatch();
    const current = useSelector(userSelector);
    const bio = current.bio;

    const onBioForm = useCallback(
        () => dispatch(ui.form("bio")),
        []
    );

    return (
        <div className="profile-card card-bio">
            <div>
                <label>Bio</label>
                <p>{ bio }</p>
                <div className="footer-actions">
                    <Button variant="outlined" 
                        onClick={ onBioForm }
                        color="primary">Edit</Button>
                </div>
            </div>
        </div>
    )
}

export default ProfileBio