import React from "react"
import { connect } from "react-redux"

import { Button, FormControlLabel } from "@mui/material"
import Checkbox from "common/src/components/material/Checkbox"
import SideDialog from "common/src/components/dialog/SideDialog"
//import UserBlock from "common/src/components/user/User"
import AccessTree from "app/components/AccessTree"
//import CopyField from "common/src/components/form/CopyField"

import { ui as ddUi } from "common/src/store/dialogs"
import Loader from "common/src/components/Loader"
import { alert } from "common/src/components/dialog/Alert"
import api from "app/api"
//import hub from "common/src/hub"
import retailers from "common/src/lib/catalogue/retailers"
import async from "common/src/lib/js/async"


const getInitialState = () => {
    return {
        saving: false,
        application: null,
        retailers: [ "all" ],
        userGroups: [],
        groups: [ "User", "GPS"],
        access: {},
        errs: {}
    }
}


class PseApplicationDialog extends React.Component {

    state = getInitialState()
    _isMounted = false
    _submitClicked = false
    
    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prev) {
        const editId = this.props.editId,
                prevEditId = prev.editId;

        if (editId && editId !== prevEditId) {
            async(() => this.loadApplicationById(editId));
            
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDialogClose() {
        this.setState(getInitialState());
        this._submitClicked = false;
        this.props.dispatch(ddUi.hide("dialog-pse-application"));
        this.props.onClose && this.props.onClose();
    }

    setGroup(group, enabled) {
        let groups = this.state.groups;
        let inx = groups.indexOf(group);
        if (inx === -1) {
            groups.push(group);
        }
        else {
            groups.splice(inx, 1);
        }
        this.setState({ groups });
    }

    setAccessKey(key, enabled) {
        const { access } = this.state;

        if (enabled) {
            access[key] = true;
        }
        else {
            delete access[key];
        }

        this.setState({ access });
    }

    setRetailerAccess(retailer, enabled) {
        let { retailers } = this.state;
        const inx = retailers.indexOf(retailer);

        if (inx === -1 && enabled) {
            if (retailer === "all") {
                retailers = [ "all" ];
            }
            else {
                retailers.push(retailer);
            }
        }
        else if (inx !== -1 && !enabled) {
            retailers.splice(inx, 1);
        }

        this.setState({ retailers });
    }

    setUserGroup(gid, enabled) {
        const { userGroups } = this.state;
        const inx = userGroups.indexOf(gid);

        if (inx === -1 && enabled) {
            userGroups.push(gid);
        }
        else if (inx !== -1 && !enabled) {
            userGroups.splice(inx, 1);
        }

        this.setState({ userGroups });
    }


    async loadApplicationById(id) {
        const where = { id: { _eq: id }};
        const application = await api.pseApplication.list({ where })
                                .then(list => list.length > 0 ? list[0] : null);
        let settings = {};

        if (application && application.settings) {
            settings = JSON.parse(application.settings);
        }

        this.setState({ application, ...settings });
    }


    onSubmitClick() {
        this.saveApplication();
    }

    async saveApplication() {

        const { groups, access, retailers, application, userGroups } = this.state;
        const settings = JSON.stringify({ groups, access, retailers, userGroups });

        this.setState({ saving: true });

        if (application) {
            const update = { settings };
            await api.pseApplication.update(application.id, update);
            await this.loadApplicationById(application.id);

            this.props.onApplicationUpdated && this.props.onApplicationUpdated(application.id);
            alert({ message: "Application updated. Old registration code still works." });
        }

        this.setState({ saving: false });
    }


    renderForm() {
        const roleTags = [
            //{ name: "FRI" },
            { name: "GPS", label: "PSE" },
            { name: "Admin" },
            //{ name: "Contributor" }
        ];

        const userGroupsList = this.props.userGroups;
        const { groups, access, saving } = this.state;
        const userRetailers = this.state.retailers;
        const userGroups = this.state.userGroups;
        const allRetailers = userRetailers.indexOf("all") !== -1;
        const isContributor = groups.length === 1 && groups.indexOf("Contributor") !== -1;
        const showAccessTree = groups.indexOf("GPS") !== -1 || groups.indexOf("Admin") !== -1;

        return (
            <>
            <h4>Roles</h4>
            <div className="dialog-invitation-groups">
                <FormControlLabel
                    control={ <Checkbox color="primary" disabled checked/> }
                    label="User"/>
                { roleTags.map(g => 
                    <div key={ g.name }>
                    <FormControlLabel
                        className=""
                        control={ 
                            <Checkbox 
                                color="primary" 
                                checked={ groups.indexOf(g.name) !== -1 } 
                                onChange={ e => this.setGroup(g.name, e.target.checked) }/> }
                        label={ g.label || g.name }/>
                    </div> )}
            </div>

            { showAccessTree && 
                <>
                <h4>Partial access</h4>
                <AccessTree 
                    roles={ groups }
                    access={ access }
                    onChange={ (key, value) => this.setAccessKey(key, value) }/>
                </> }
            
            { isContributor && 
                <>   
                <h4>Retailers</h4>
                <div className="user-details-retailers">
                <FormControlLabel
                    key="all"
                    className="user-details-retailer-all"
                    control={ 
                        <Checkbox 
                            color="primary" 
                            checked={ allRetailers } 
                            onChange={ e => this.setRetailerAccess("all", e.target.checked) }/> }
                    label="All"/>
                { !allRetailers && 
                    retailers.map(r => 
                        <FormControlLabel
                            key={ r.id }
                            className="user-details-retailer"
                            control={ 
                                <Checkbox 
                                    color="primary" 
                                    disabled={ false }
                                    checked={ userRetailers.indexOf(r.id) !== -1 } 
                                    onChange={ e => 
                                        this.setRetailerAccess(r.id, e.target.checked) }/> }
                            label={ r.name }/> )}
                </div>
                </>}

            { userGroupsList.length > 0 && 
                <>
                <h4>Groups</h4>
                <div className="user-details-retailers">
                    {userGroupsList.map(g => 
                        <FormControlLabel
                            key={ g.id }
                            className="user-details-retailer"
                            control={ 
                                <Checkbox 
                                    color="primary" 
                                    disabled={ false }
                                    checked={ userGroups.indexOf(g.id) !== -1 } 
                                    onChange={ e => 
                                        this.setUserGroup(g.id, e.target.checked) }/> }
                            label={ g.name }/> )}
                </div>
                </>}
            
            <div className="dialog-invitation-actions">
                <Button variant="contained"
                    disabled={ saving }
                    startIcon={ saving ? <Loader inline/> : null }
                    onClick={ () => this.onSubmitClick() } 
                    children="Update invitation"/>
            </div>
            </>
        )
    }

    render() {
        const show = this.props.dd['dialog-pse-application'];
        const application = this.state.application;
        
        return (
            <SideDialog 
                className="dialog-pse-application" 
                title="PSE Application" 
                onClose={ () => this.onDialogClose() }
                show={ show }>

                { application && 
                    <div>
                        <h4>{ application.email }</h4>
                    </div> }

                { this.renderForm() }
            </SideDialog>
        )
    }
}

export default connect(state => ({
    dd: state.dialogs,
    userGroups: state.peoplePage.data.userGroups
}))(PseApplicationDialog)


/* 
<LinkButton
                            children="View stats"
                            variant="outlined"
                            to={ getUrl(routes['people-stats'], { id: user.id }) }/>*/