import React from "react"
import { Auth } from '@aws-amplify/auth'
import Link from "common/src/components/Link"
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Checkbox from "common/src/components/material/Checkbox"
import Button from "@mui/material/Button"
import Loader from "common/src/components/Loader"

import isEmail from "validator/es/lib/isEmail"
import hub from "common/src/hub"
import { alert } from "common/src/components/dialog/Alert"
import { resendEmailVerification,  
        getCurrentCheckPromise } from "common/src/actions/user"
import routes from "app/routes"
import getUrl from "common/src/lib/url/get"
import api from "app/api"
import getQueryParam from "common/src/lib/url/getQueryParam"
import async from "common/src/lib/js/async"
import { validatePasswordSync } from "common/src/lib/form/validator/password"


const validators = {
    required: async (value) => !!value,
    isEmail: async (value) => isEmail(value),
    pLen: async (value) => (value+"").length >= 6,
    on: async (value) => value === true || value === "on",
    password: validatePasswordSync,
    handle: async (value) => {
        if (!value) {
            return true;
        }
        const res = await api.unauth.post("/check-handle", { body: { handle: value }});
        return res.success && !res.exists;
    }
};

const fieldValidators = {
    "given_name": {
        required: "Please enter your name"
    },
    "family_name": {
        required: "Please enter your name"
    },
    "email": {
        required: "Please enter your email",
        isEmail: "Please enter a valid email address"
    },
    "password": {
        required: "Please enter your password",
        password: validatePasswordSync
        //pLen: "Your password must be at least 6 characters long"
    },
    "password2": {
        required: "Please confirm your password"
    },
    "agree": {
        on: "You must agree to our Terms and Conditions"
    }
};

const friValidators = {
    "friAgree": {
        on: "You must agree to our FRI Terms and Conditions"
    },
    "handle": {
        required: "Please enter your FRI handle",
        handle: "This handle is already taken"
    }
};


export default class FormSignUp extends React.Component {

    state = {
        b: getQueryParam("b"),
        errors: {},
        given_name: '',
        family_name: '',
        email: '',
        password: '',
        password2: '',
        agree: false,
        friAgree: false,
        subscribe: false
    }

    useInvitation = false
    submitClicked = false
    _isMounted = false

    constructor(props) {
        super(props);
        this.onFailure = this.onFailure.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        hub.listen("auth", "signUp_failure", this.onFailure);
        const iid = getQueryParam("iid");

        if (iid) {
            this.checkInvitation();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        hub.remove("auth", "signUp_failure", this.onFailure);
    }

    onFailure(err) {
        alert({
            //title: "Ooops",
            message: err.message
        })
    }

    async checkInvitation() {
        const iid = getQueryParam("iid");
        const inv = await api.invitation.get(iid);
        this.useInvitation = true;
        if (!inv) {
            this.useInvitation = false;
            return;
        }
        if (inv.accepted) {
            alert({ message: "This invitation link has already been used." });
            this.useInvitation = false;
        }
        if (inv.disabled) {
            alert({ message: "This invitation link has expired." });
            this.useInvitation = false;
        }
    }

    needPrefix() {
        return false;
    }

    error(err) {
        this.props.onError(err);
    }

    cleanupPhoneNumber(value) {
        value = "+" + value.replace(/[-+()]/g, "");
        return value;
    }

    setInput(field, value, checked) {

        const isCheckbox = field === "agree" || field === "friAgree" || field === "subscribe";
        this.setState({ [field]: isCheckbox ? checked : value }, () => {
            if (this.submitClicked) {
                async(() => this.validate());
            }
        });
    }

    getValidationRules() {
        const { regType } = this.props;

        return regType === "fri" ?
                    Object.assign({}, fieldValidators, friValidators) :
                    fieldValidators;
    }

    async validate() {
        let name, vld, value, valid = true, errors = {};
        const rules = this.getValidationRules();

        for (name in rules) {
            value = this.state[name];
            for (vld in rules[name]) {
                if (typeof rules[name][vld] === "string") {
                    let res = await validators[vld](value);
                    if (!res) {
                        errors[name] = rules[name][vld];
                        valid = false;
                        break;
                    }
                }
                else {
                    let res = rules[name][vld](value);
                    if (res !== null) {
                        errors[name] = res;
                        valid = false;
                        break;
                    }
                }
            }
        }

        if (this.state['password'] !== this.state['password2']) {
            if (!errors['password2']) {
                errors['password2'] = "Passwords must match";
                valid = false;
            }
        }

        this.setState({ errors });

        return valid;
    }

    async onSubmitClick() {
        this.submitClicked = true;
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        if (this.state.subscribe) {
            this.subscribe();
        }

        this.setState({ loading: true });
        hub.suspend();

        const { email, password, given_name, family_name } = this.state;
        const signup_info = {
            username: email.toLowerCase(),
            password: password,
            attributes: {
                email: email.toLowerCase(),
                given_name,
                family_name
            }
        };

        const iid = getQueryParam("iid");
        const aid = getQueryParam("aid");

        signup_info.clientMetadata = {};

        if (iid && this.useInvitation) {
            signup_info.clientMetadata.invitationId = iid;
        }
        if (aid) {
            signup_info.clientMetadata.pseApplicationId = aid;
        }

        await Auth.signUp(signup_info)
            .then(() => {
                return Auth.signIn(email.toLowerCase(), password);
            })
            .then(() => Auth.currentAuthenticatedUser())
            .then(user => {
                return user ? 
                    Promise.resolve(getCurrentCheckPromise()) :
                    Promise.resolve()
            })
            .then(user => {
                if (user) {
                    return Promise.all([
                        //regType === "fri" ? agreeToFriTerms() : null,
                        //regType === "fri" ? setFRIGroup() : null,
                        resendEmailVerification(),
                        //phone_number ? resendPhoneVerification() : null
                    ]);
                }
            })
            .then(() => {
                hub.resume(true);
            })
            .then(data => {
                if (this._isMounted) {
                    this.setState({ loading: false });
                    this.changeState('confirmSignUp', data.user.username);
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                console.error(err);
                hub.resume(true);
                this.error(err);
            });
    }

    renderField(name, label, autoFocus = false, defaultValue = "", required = true ) {
        const errs = this.state.errors;
        const value = this.state[name];
        return <TextField label={ label } 
                    fullWidth
                    autoCorrect="off" 
                    autoCapitalize="none"
                    required={ required }
                    autoFocus={ autoFocus }
                    id={ name }
                    key={ name }
                    name={ name }
                    value={ value }
                    onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                    onChange={ e => this.setInput(name, e.target.value, e.target.checked) }
                    variant="outlined"
                    type={ name === "password" || name === "password2" ? 
                            "password" : "text" }
                    error={ !!errs[name] }
                    helperText={ errs[name] }
                    inputProps={{ "data-cy": "signup-form-" + name }}/>
    }

    subscribe() {
    
        const input = {
            firstName: this.state.given_name.trim(),
            lastName: this.state.family_name.trim(),
            email: this.state.email.trim().toLowerCase(),
            newsletterType: "fashion-news",
            info: ""
        };

        api.newsletterRequest.create(input, "affected_rows")
            .catch(err => {});

        api.unauth.post("/subscribe", { body: input })
            .catch(err => {});

        window.ga && window.ga('send', {
            hitType: 'event',
            eventCategory: 'action',
            eventAction: "signup"
        });
    }


    render() {

        const { loading, errors = {}, agree, friAgree, subscribe } = this.state;
        const cls = ["form-sign-up"];
        const { regType } = this.props;
        const b = this.state.b;
        const iid = getQueryParam("iid");
    
        loading && cls.push("hidden")

        return (
            <>
            { loading && <Loader className="page-loading" size={ 116 }/> }
            <div className={ cls.join(" ") }>

                <h1>
                    { regType === "fri" ? "FRI Register" : "Register" }
                </h1>
            
                <section>
                    <div>
                        { this.renderField("given_name", "First name", true) }
                        { this.renderField("family_name", "Last name") }
                    </div>
                </section>
                
                <section>
                    <div>
                        { this.renderField("email", "Email") }
                    </div>
                </section>

                { regType === "fri" &&
                    <section>
                        <div>
                            { this.renderField("handle", "Handle") }
                        </div>
                    </section> }
                
                <section>
                    <div>
                        { this.renderField("password", "Password") }
                        { this.renderField("password2", "Confirm password") }
                    </div>
                </section>
                
                <FormControlLabel 
                    control={<Checkbox color="primary" 
                                        className={ errors.agree ? "error" : "" }
                                        id="agree"
                                        key="agree"
                                        name="agree"
                                        value="1"
                                        inputProps={{ "data-cy": "signup-form-agree" }}
                                        checked={ agree }
                                        onChange={ e => this.setInput("agree", null, e.target.checked) }/>}
                    label={ 
                        <>
                        I agree to THE FLOORR's&nbsp;
                        <Link 
                            target="_blank"
                            rel="noreferrer noopener"
                            to={ getUrl(routes.terms, {}, "web") }
                            children="Terms and Conditions"/>.
                        </>
                     }/>
                { errors.agree && <p className="agree-error">{ errors.agree }</p> }

                { regType === "fri" && 
                    <>
                    <FormControlLabel 
                        control={<Checkbox color="primary" 
                                    className={ errors.friAgree ? "error" : "" }
                                    id="friAgree"
                                    key="friAgree"
                                    name="friAgree"
                                    value="1"
                                    inputProps={{ "data-cy": "signup-form-fri-agree" }}
                                    checked={ friAgree }
                                    onChange={ e => this.setInput("friAgree", null, e.target.checked) }/>}
                        label={ 
                            <>
                            I agree to THE FLOORR's&nbsp;
                            <Link 
                                to={ getUrl(routes.friTerms) }
                                children="FRI Terms and Conditions"/>.
                            </>
                        }/>
                    { errors.friAgree && <p className="agree-error">{ errors.friAgree }</p> }
                    </>
                }

                <FormControlLabel 
                    control={<Checkbox color="primary" 
                                        id="subscribe"
                                        key="subscribe"
                                        name="subscribe"
                                        value="1"
                                        inputProps={{ "data-cy": "signup-form-subscribe" }}
                                        checked={ subscribe }
                                        onChange={ e => this.setInput("subscribe", null, e.target.checked) }/>}
                    label="I want to receive updates from The Floorr"/>
                <p className="note">You can opt out of hearing from us at any
                    time using the unsubscribe link in our emails. 
                    Read our&nbsp;
                        <Link 
                            target="_blank"
                            rel="noreferrer noopener"
                            to={ getUrl(routes.privacy, {}, "web") }
                            children="full privacy notice"/>.</p>

                <Button variant="contained" 
                        size="large"
                        id="register-submit"
                        data-cy="signup-form-submit"
                        onClick={ () => this.onSubmitClick() }
                        children="Register"/>
                
                <p className="secondary-action">
                    Already registered? <Link to={ getUrl(routes.signin, { b, iid }) } children="Log in"/>
                </p>    
            </div>
            </>
        )
    }
}


/*
{ this.renderField("phone_number", "Phone", false, "", false) }
<p className="field-note">Phone number including +X country code</p>
*/