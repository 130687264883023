
import hub from "common/src/hub"
import store from "app/store"
import { data } from "app/store/page/invitations"

const onInvitationRemoved = (id) => {
    store.dispatch(data.list.remove(id));
}

const listeners = {
    init: () => {
        hub.listen("invitation", "removed", onInvitationRemoved);
    }
}

export default listeners