import React, { useCallback, useState } from "react"
import { Button, Select, MenuItem } from "@mui/material"
import Table from "common/src/components/table/Table"
import moment from "moment"
import df from "common/src/lib/date/formats"
import formatCurrency from "common/src/lib/format/currency"
import { setRakutenItemOrderStatus } from "app/actions/page/commissions"

function RakutenItemDetails({ order, item }) {

    const [ showRaw, setShowRaw ] = useState(false);
    const [ showSnaps, setShowSnaps ] = useState(false);
    const [ manualStatus, setManualStatus ] = useState(item.manualStatus);

    const toggleRaw = useCallback(
        () => setShowRaw(prev => !prev),
        []
    );

    const toggleSnaps = useCallback(
        () => setShowSnaps(prev => !prev),
        []
    );

    const onChangeManualStatus = useCallback(
        ({ target: { value }}) => {
            if (value === "unset") {
                value = null;
            }
            setManualStatus(value);
            setRakutenItemOrderStatus(item.id, value);
        },
        [ item ]
    );

    const renderToolbar = useCallback(
        () => {

            return (
                <div className="page-commission-details-toolbar">
                    { item.details && 
                        <Button 
                            variant="outlined" 
                            size="small"
                            onClick={ toggleRaw }
                            children={ showRaw ? "Hide raw details" : "Show raw details" }/> }
                    { (item.snapshots && item.snapshots.length > 1) && 
                        <Button 
                            variant="outlined" 
                            size="small"
                            onClick={ toggleSnaps }
                            children={ showSnaps ? "Hide corrections" : "Show corrections" }/>}

                    { order?.networkId === "rakuten" && 
                    <>
                    <p>Manual status:</p>&nbsp;
                    <Select 
                        size="small"
                        value={ manualStatus || "unset" }
                        onChange={ onChangeManualStatus }>
                        <MenuItem value="unset">Unset</MenuItem>
                        <MenuItem value="approved">Approved</MenuItem>
                        <MenuItem value="rejected">Rejected</MenuItem>
                    </Select>
                    </>
                    }

                </div>
            )
        },
        [ showRaw, showSnaps, item, order, manualStatus, onChangeManualStatus, toggleRaw, toggleSnaps ]
    )

    const renderRawDetails = useCallback(
        () =>  {
            const code = JSON.stringify(JSON.parse(item.details), null, 2);

            return (
                <div className="page-commission-details-raw">
                    <pre>
                        { code }
                    </pre>
                </div>
            )
        },
        [ item ]
    )

    const renderCorrectionDetails = useCallback(
        (c) => {
            const code = JSON.stringify(JSON.parse(c.details), null, 2);

            return (
                <div className="page-commission-details-raw">
                    <pre>
                        { code }
                    </pre>
                </div>
            )
        },
        []
)

    const renderCorrections = useCallback(
        () => {

            const rows = item.snapshots;
            const cols = [
                { id: "processDate", name: "Process date",
                    render: c => moment(c.processDate).format(df.full) },
                { id: "sale", name: "Sale", 
                    render: i => formatCurrency(i.saleAmount, item.currency, 2) },
                { id: "commission", name: "Commission", 
                    render: i => formatCurrency(i.commissionAmount, item.currency, 2) }
            ]

            return (
                <Table 
                    cols={ cols } 
                    rows={ rows }
                    expandable
                    expandKey="id"
                    renderDetails={ c => renderCorrectionDetails(c) }/>
            )
        },
        [ item, renderCorrectionDetails ]
    )

    return (
        <div className="page-commission-details"> 
            { showSnaps && renderCorrections() }
            { renderToolbar() }
            { showRaw && renderRawDetails() }
        </div>
    )
}

export default RakutenItemDetails

