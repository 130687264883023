
export const dateGroupNames = {
    "month": "Month",
    "year": "Year",
    "day": "Day"
};

export const dateGroupItems = [
    { id: "year", name: "Year" },
    { id: "month", name: "Month" },
    { id: "day", name: "Day" }
];