import React from "react"
import { connect } from "react-redux"
import isEmail from "validator/es/lib/isEmail"

import { TextField, FormControlLabel, Button } from "@mui/material"

import Checkbox from "common/src/components/material/Checkbox"
import SideDialog from "common/src/components/dialog/SideDialog"
import Loader from "common/src/components/Loader"
import { ui as ddUi } from "common/src/store/dialogs"
import { createUser } from "app/actions/page/people"
import { alert } from "common/src/components/dialog/Alert"
import cleanupEmail from "common/src/lib/cleanupEmail"
import isAscii from "common/src/lib/form/validator/isAscii"
import api from "app/api"
import NullForm from "common/src/components/NullForm"

async function checkUserExists(email) {
    const where = {
        email: { _ilike: `%${ email }%` }
    }
    const ex = await api.user.list({ where });
    return !!ex.find(u => u.email.toLowerCase() === email.toLowerCase());
}

let initialData = {
    email: "",
    emailVerified: false,
    givenName: "",
    familyName: "",
    phone: "",
    password: "",
    password2: "",
    roles: ["User"]
}


let validators = {
    required: value => !!value,
    isEmail: value => isEmail(value),
    isPhone: value => {
        value = (value+"").replace(/[-+()]/g, "");
        if (!value) return false;
        value = parseInt(value);
        if (!value) return false;
        return true;
    }
}

let fieldValidators = {
    "givenName": {
        required: "Please enter user's name"
    },
    "familyName": {
        required: "Please enter user's surname"
    },
    "email": {
        required: "Please enter user's email",
        isEmail: "Please enter a valid email address"
    },
    "phone": {
        //required: "Please enter user's phone number",
        isPhone: "Please enter a valid phone number with country code"
    }
};

const groupTags = [
    { name: "User" },
    //{ name: "FRI", skip: true },
    { name: "GPS", label: "PSE" },
    { name: "Admin" },
    //{ name: "Contributor", skip: true }
];

class UserForm extends React.Component {

    state = {
        user: { ...initialData },
        errors: {},
        submitting: false
    }
    _isMounted = false
    submitClicked = false

    constructor(props) {
        super(props)
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDialogClose() {
        this.props.dispatch(ddUi.hide("user-form"));
    }


    grpTagOnClick(g) {
        const user = this.state.user;
        const roles = user.roles,
                ex = roles.indexOf(g.name) !== -1;

        let inx = roles.indexOf(g.name);
        if (ex && inx !== -1) {
            roles.splice(inx, 1);
        }
        else if (!ex && inx === -1) {
            roles.push(g.name);
        }

        this.setState({ user: { ...user, roles } });
    }


    handleInputChange(evt) {
        const { name, value, type, checked } = evt.target;
        const checkType = ['radio', 'checkbox'].includes(type);

        const user = { ...this.state.user };
        user[name] = checkType ? checked : value;

        if (name === "email") {
            user[name] = cleanupEmail(user[name]);
        }

        this.setState({ user });

        if (this.submitClicked) {
            this.validate();
        }
    }

    async validate() {
        let name, vld, value, valid = true, errors = {};

        for (name in fieldValidators) {
            value = this.state.user[name];
            if (!value && !fieldValidators[name].required) {
                continue;
            }
            for (vld in fieldValidators[name]) {   
                if (!validators[vld](value)) {
                    errors[name] = fieldValidators[name][vld];
                    valid = false;
                    break;
                }
            }
        }

        const { password, password2 } = this.state.user;

        if (password) {
            const ascii = await isAscii(password);
            if (password.trim().length < 8) {
                errors['password'] = "Please make it at least 8 chars long.";
                valid = false;
            }
            else if (!ascii) {
                errors['password'] = "Password contains unsupported characters.";
                valid = false;
            }
            if (password2 !== password) {
                errors['password2'] = "Passwords don't match";
                valid = false;
            }
        }

        this.setState({errors})
        return valid;
    }

    async onSubmitClick() {
        this.submitClicked = true;
        const valid = await this.validate();
        if (valid) {
            
            this.setState({ submitting: true });

            const exists = await checkUserExists(this.state.user.email);

            if (exists) {
                alert({
                    message: "User with this email already exists"
                });
                this.setState({ submitting: false });
                return;
            }
            
            try {
                const resp = await createUser(this.state.user)
                if (resp.success) {
                    alert({ title: "Success", message: "User has been created" });
                    this.props.onCreate &&
                        this.props.onCreate(this.state.user.email);
                    if (this._isMounted) {
                        this.reset();
                    }
                    this.onDialogClose();
                }
                else {
                    console.log(resp)
                    alert({
                        title: "Failed to create a user",
                        message: "Something went wrong"
                    })
                }
            }
            catch (err) {
                console.log(err)
                alert({
                    title: "Failed to create a user",
                    message: "Something went wrong"
                })
            }
        
            if (this._isMounted) {
                this.setState({ submitting: false });
            }
        }
    }

    reset() {
        this.setState({
            user: { ...initialData },
            errors: {},
            submitting: false
        });
        this.submitClicked = false;   
    }

    onCancelClick() {
        this.reset();
        this.onDialogClose();
    }

    render() {
        let user = this.state.user,
            show = this.props.dd['user-form'],
            errs = this.state.errors,
            submitting = this.state.submitting,
            roles = this.state.user.roles;

        return (
            <SideDialog 
                className="dialog-create-user" 
                title="New user" 
                onClose={ () => this.onDialogClose() }
                show={ show }>

            <NullForm>
            <h4>Roles</h4>
            <div className="user-details-roles">
                { groupTags.map(g => {
                    return (
                        <>
                        <FormControlLabel
                            key={ g.name }
                            className="user-details-retailer"
                            control={ 
                                <Checkbox 
                                    checked={ roles.indexOf(g.name) !== -1 }
                                    disabled={ g.name === "User" }
                                    color="primary" 
                                    onChange={ e => this.grpTagOnClick(g) }/> }
                            label={ g.label || g.name }/> 
                        <br/>
                        </>
                    )
                })}
            </div>

            <TextField
                fullWidth
                autoComplete="off"
                label="Email"
                variant="outlined"
                size="small"
                name="email"
                value={ user.email }
                error={ !!errs.email }
                helperText={ errs.email || "Must be a working email address" }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ this.handleInputChange }/>

            <TextField
                fullWidth
                autoComplete="off"
                label="Name"
                variant="outlined"
                size="small"
                name="givenName"
                value={ user.givenName }
                error={ !!errs.givenName }
                helperText={ errs.givenName }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ this.handleInputChange }/>

            <TextField
                fullWidth
                autoComplete="off"
                label="Surname"
                variant="outlined"
                size="small"
                name="familyName"
                value={ user.familyName }
                error={ !!errs.familyName }
                helperText={ errs.familyName }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ this.handleInputChange }/>

            <TextField
                fullWidth
                autoComplete="off"
                label="Temporary password"
                variant="outlined"
                size="small"
                name="password"
                type="password"
                value={ user.password }
                error={ !!errs.password }
                helperText={ errs.password || "If not set, temporary password will be generated " +
                                            "and sent to user's email or phone. " +
                                            "Please make it at least 8 chars long." }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ this.handleInputChange }/>

            <TextField
                fullWidth
                autoComplete="off"
                label="Repeat password"
                variant="outlined"
                size="small"
                name="password2"
                type="password"
                value={ user.password2 }
                error={ !!errs.password2 }
                helperText={ errs.password2 }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ this.handleInputChange }/>

            <TextField
                fullWidth
                autoComplete="off"
                label="Phone"
                variant="outlined"
                size="small"
                name="phone"
                placeholder="+XXX..."
                value={ user.phone }
                error={ !!errs.phone }
                helperText={ errs.phone || "If set, must be a valid phone number." }
                onKeyDown={ e => e.key === "Enter" && this.onSubmitClick(e) }
                onChange={ this.handleInputChange }/>

            <div className="actions">
                <Button
                    variant="contained"
                    onClick={ () => this.onSubmitClick() }
                    disabled={ submitting }
                    startIcon={ submitting ? <Loader inline/> : null }
                    children="Create user"/>
                <Button
                    variant="outlined"
                    onClick={ () => this.onCancelClick() }
                    children="Cancel"/>
            </div>
            </NullForm>
            </SideDialog>
        )
    }
}

export default connect(state => ({
    dd: state.dialogs
}))(UserForm)