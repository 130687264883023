import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import isActive from "common/src/lib/navActive"
import getUrl from "common/src/lib/url/get"
import user from "common/src/user"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"

function customIsActive(routes, pathname, level) {
    let active = false;
    routes.forEach(r => {
        if (!active && isActive(r, pathname, level)) {
            active = true;
        }
    });
    return active;
}

function MenuNavAction(props) {

    const { item } = props;
    const badge = useMemo(
        () => item.badge !== undefined && item.badge !== null && item.badge !== "" ?
                item.badge : 
                null,
        [ item.badge ]
    );
    const cls = useMemo(
        () => {
            const cls = [ "menu-link" ];
            item.active === true && cls.push("active");
            badge !== null && cls.push("with-badge");
            cls.push(item.className || "");
            return cls.join(" ");
        },
        [ item.active, badge, item.className ]
    );

    const onActionClick = useSwallowEventCallback(
        () => item.onClick && item.onClick(),
        [ item.onClick ]
    );

    return (
        <div className="menu-item">
            <a href="/#" 
                className={ cls }
                onClick={ onActionClick }>
                { item.icon }
                { item.name }
                { badge !== null && <span className="badge">{ badge }</span> }
            </a>
            { (item.children && item.children.length > 0) &&
                <nav className="menu-sub">
                    { item.children.map((i, inx) => 
                        <MenuItem item={ i } key={ inx }/>)}
                </nav> }
            { item.extraChildren }
        </div>
    )

}

function MenuNavLink(props) {
    const { item, level } = props;
    const badge = useMemo(
        () => item.badge !== undefined && item.badge !== null && item.badge !== "" ?
                item.badge : 
                null,
        [ item.badge ]
    );
    const url = useMemo(
        () => item.url || getUrl(item.routes[0]), 
        [ item.url, item.routes ]
    );
    const cls = useMemo(
        () => {
            const cls = [ "menu-link" ];
            item.active === true && cls.push("active");
            badge !== null && cls.push("with-badge");
            item.disabled === true && cls.push("disabled");
            cls.push(item.className || "");
            return cls.join(" ");
        },
        [ item.active, badge, item.className, item.disabled ]
    );
    const exact = useMemo(() => item.exact !== undefined ? item.exact : true, [ item.exact ]);
    
    const isActive = useMemo(
        () => {
            if (item.active !== undefined) {
                return () => item.active;
            }
            else if (item.isActive && typeof item.isActive === "function") {
                return (m, l) => item.isActive(l);
            }
            else if (item.routes) {
                return (m, l) => customIsActive(item.routes, l.pathname, level);
            }
            else {
                return null;
            }
        },
        [ item.isActive, item.routes, level, item.active ]
    );

    const stop = useSwallowEventCallback(
        () => {},
        []
    )

    return (
        <div className="menu-item">
            <NavLink 
                exact={ exact }
                to={ url } 
                disabled={ item.disabled || false }
                activeClassName="active"
                className={ cls }
                onClick={ item.disabled ? stop : item.onClick }
                isActive={ isActive }>
                { item.icon }
                { item.name }
                { badge !== null && <span className="badge">{ badge }</span> }
            </NavLink>
            { (item.children && item.children.length > 0) &&
                <nav className="menu-sub">
                    { item.children.map((i, inx) => 
                        <MenuItem item={ i } key={ inx } level={ 2 }/>)}
                </nav> }
            { item.extraChildren }
        </div>
    )
}

function MenuItem(props) {

    const { item, level = 1 } = props;
    const hasAccess = useMemo(
        () => !item || !item.accessKey || user.hasAccessTo(item.accessKey, item.accessExact),
        [ !!item, item?.accessKey, item?.accessExact ]
    );

    if (!item || !hasAccess) {
        return null;
    }

    if (item === "|") {
        return <div className="menu-separator"></div>
    }
    else if (item.show === false) {
        return null;
    }
    else if (item.url || (item.routes && item.routes.length)) {
        return <MenuNavLink item={ item } level={ level }/>
    }
    else {
        return <MenuNavAction item={ item } level={ level }/>
    }
}

function Menu(props) {

    const { className, menu } = props;
    const cls = useMemo(() => "menu " + (className || ""), [ className ]);

    return (
        <nav className={ cls }>
            { menu.map((i, inx) => <MenuItem key={ inx } item={ i }/>)}
        </nav>
    )
};

export default Menu
