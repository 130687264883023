
import user from "common/src/user"
import hub from "common/src/hub"
import api from "app/api"


export const saveLook = lookId => {
    let userId = user.id();
    return api.userSavedLook
            .create({ userId, lookId }, "returning { userId }")
            .then(() => {
                hub.dispatch("look", "saved", lookId);
            })
            .catch(err => {
                hub.dispatch("error", "look-save", err);
            });
};

export const saveProduct = productId => {
    let userId = user.id();
    return api.userSavedProduct
            .create({ userId, productId }, "returning { userId }")
            .then(() => {
                hub.dispatch("product", "saved", productId);
            })
            .catch(err => {
                hub.dispatch("error", "product-save", err);
            });
};

export const saveCons = consultationId => {
    const userId = user.id();
    return api.userSavedConsultation
            .create({ userId, consultationId, createdAt: (new Date()).toISOString() }, "returning { userId }")
            .then(() => {
                hub.dispatch("consultation", "saved", consultationId);
            })
            .catch(err => {
                hub.dispatch("error", "cons-save", err);
            });
}

export const unsaveCons = consultationId => {
    const userId = user.id();
    return api.userSavedConsultation.remove({ 
        userId: { _eq: userId },
        consultationId: { _eq: consultationId }
     })
     .then(() => {
        hub.dispatch("consultation", "unsaved", consultationId);
    })
    .catch(err => {
        hub.dispatch("error", "cons-unsave", err);
    });
}

export const unsaveLook = lookId => {
    let userId = user.id();
    return api.userSavedLook.remove({
        userId: { _eq: userId },
        lookId: { _eq: lookId }
    })
    .then(() => {
        hub.dispatch("look", "unsaved", lookId);
    })
    .catch(err => {
        hub.dispatch("error", "look-unsave", err);
    });
};

export const unsaveProduct = productId => {
    let userId = user.id();
    return api.userSavedProduct.remove({
        userId: { _eq: userId },
        productId: { _eq: productId }
    })
    .then(() => {
        hub.dispatch("product", "unsaved", productId);
    })
    .catch(err => {
        hub.dispatch("error", "product-unsave", err);
    });
};