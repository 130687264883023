
import React, { useRef } from "react";
import { useSelector } from "react-redux"
import swallowEvent from "common/src/lib/dom/swallowEvent"
import animation from "common/src/lib/animation"
import hub from "common/src/hub"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useUpdateEffect from "common/src/hooks/useUpdateEffect";

function Overlay() {

    const el = useRef(null);
    const anim = useRef(null);
    const show = useSelector(s => s.app.ui.overlay);

    function animate(show) {
        anim.current = animation(el.current, show ? "a-fadein" : "a-fadeout", {
            start: () => {
                show && el.current.classList.add("active");
            },
            end: () => {
                !show && el.current.classList.remove("active");
                anim.current = null;
            }
        });
    };

    const onClick = useSwallowEventCallback(
        () => hub.dispatch("dialog", "overlayClick"),
        []
    );

    useUpdateEffect(
        () => {
            if (el.current) {
                if (anim.current) {
                    anim.current.stop().then(() => animate(show))
                }
                else animate(show);
            }
        },
        [ show ]
    )

    return (
        <div className="dialog-overlay" 
                ref={ el }
                onClick={ onClick }
                onTouchStart={ swallowEvent }></div>
    )
}

export default Overlay
