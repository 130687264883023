import React from "react"
import { connect } from "react-redux"
import moment from "moment"

import Menu from "common/src/components/Menu"
import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
import Loader from "common/src/components/Loader"
import Table from "common/src/components/table/Table"
import User from "common/src/components/user/User"

import UserSelector from "common/src/components/user/UserSelector"
import DateRangeSelector from "common/src/components/filter/DateRange"
import PublicSelector from "common/src/components/PublicSelector"

import async from "common/src/lib/js/async"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import format from "common/src/lib/format/number"

import state2query from "common/src/lib/url/state2query"
import url2state from "common/src/lib/url/url2state"
import statesAreEqual from "common/src/lib/statesAreEqual"
import { dateGroupNames, dateGroupItems } from "./common"

import { loadFriLooks, loadFriDomains, loadFriDomainClicks } from "app/actions/page/fris"


const urlParams = [
    { name: "section", defaultValue: "looks" },
    { name: "page", type: "int", defaultValue: 1, 
        restore: page => Math.max(1, page) - 1,
        store: page => page + 1 },
    { name: "created", stateName: "createdAt", type: "array" },
    { name: "published", stateName: "publishedAt", type: "array" },
    { name: "submitted", stateName: "submittedAt", type: "array" },
    { name: "dates", stateName: "dateRange", type: "array" },
    { name: "pb", stateName: "publishedBy", type: "array", defaultValue: [],
        store: v => v.map(f => f.id) },
    { name: "f", stateName: "fris", type: "array", defaultValue: [],
        store: v => v.map(f => f.id) },
    { name: "db", stateName: "declinedBy", type: "array", defaultValue: [],
        store: v => v.map(f => f.id) },
    { name: "g", stateName: "dateGroup", defaultValue: "month",
        restore: v => [{ id: v, name: dateGroupNames[v] }],
        store: v => v[0].id }
];


const getUrlState = (props) => {
    return url2state(urlParams, props);
}

const getFilterUrl = (state) => {
    return getUrl(routes.reportFris, state2query(state, urlParams));
}

const filterSetup = {
    looks: ["fri", "createdat", "publishedat", "submittedat", "publishedby", "declinedby"],
    bydate: ["groupby", "fri", "createdat", "publishedat", "submittedat", "publishedby", "declinedby"],
    affiliates: ["daterange", "fri"],
    clicks: ["daterange", "fri"]
};


class PageFris extends React.Component {

    state = {
        section: "looks",
        page: 0,
        loading: false,
        data: null,

        createdAt: [],
        publishedAt: [],
        submittedAt: [],
        publishedBy: [],
        declinedBy: [],
        fris: [],
        dateRange: [],

        dateGroup: [{ id: "month", name: "Month" }]
    }

    componentDidMount() {
        const state = getUrlState(this.props);
        this.setState(state, () => this.load());
    }

    componentDidUpdate() {
        const state = getUrlState(this.props);
        const keys = urlParams.map(p => p.stateName || p.name);
        if (!statesAreEqual(state, this.state, keys)) {
            async(() => this.setState({ ...state, data: null }, () => this.load()));
        }
    }

    async load() {
        const { section, 
                createdAt, publishedAt, submittedAt, dateRange, 
                publishedBy, declinedBy, fris, dateGroup } = this.state;
        let data;
        this.setState({ loading: true });

        switch (section) {
            case "looks": {
                data = await loadFriLooks({ createdAt, publishedAt, submittedAt, 
                                            publishedBy, declinedBy, fris });
                break;
            }
            case "bydate": {
                data = await loadFriLooks({ createdAt, publishedAt, submittedAt, 
                                            publishedBy, declinedBy, fris, group: dateGroup[0].id });
                break;
            }
            case "affiliates": {
                data = await loadFriDomains({ dateRange, fris });
                break;
            }
            case "clicks": {
                data = await loadFriDomainClicks({ dateRange, fris });
                break;
            }
            default: {
                data = null;
                break;
            }
        }

        if (this.state.section === section) {
            this.setState({ loading: false, data });
        }
    }


    reload() {
        this.load();
    }


    setSection(section) {
        const state = Object.assign({}, this.state, { section, page: 0 });
        const url = getFilterUrl(state);
        this.setState({ data: null });
        this.props.history.push(url);
    }

    setPage(page) {
        const state = Object.assign({}, this.state, { page });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    setFilter(state) {

        if (state.createdAt) {
            state.publishedAt = [];
            state.submittedAt = [];
        }
        else if (state.publishedAt) {
            state.createdAt = [];
            state.submittedAt = [];
        }
        else if (state.submittedAt) {
            state.createdAt = [];
            state.publishedAt = [];
        }

        const newState = Object.assign({}, this.state, state, { page: 0 });
        const url = getFilterUrl(newState);
        this.props.history.push(url);
    }


    getNav() {
        const { loading, section } = this.state;
        const refreshCls = [ 'icon', 'icon-svg-fill', loading ? 'spinning' : ''];

        return [
            {
                name: "Looks",
                onClick: () => this.setSection("looks"),
                active: section === "looks"
            },
            {
                name: "Looks by date",
                onClick: () => this.setSection("bydate"),
                active: section === "bydate"
            },
            {
                name: "Products by affiliate",
                onClick: () => this.setSection("affiliates"),
                active: section === "affiliates"
            },
            {
                name: "Clicks per Month per Domain per FRI",
                onClick: () => this.setSection("clicks"),
                active: section === "clicks"
            },
            {
                icon: <IconRefresh className={ refreshCls.join(" ") }/>,
                onClick: () => this.reload()
            }
        ]
    }

    renderFilters(filters)  {
        const { dateGroup, dateRange,
                createdAt, publishedAt, submittedAt, 
                publishedBy, declinedBy, fris } = this.state;

        return (
            <>
                { filters.indexOf("groupby") !== -1 && 
                    <PublicSelector
                        name="Group by"
                        cls="tag-selector-v2"
                        noSearch
                        single
                        mustHaveSelection
                        value={ dateGroup }
                        items={ dateGroupItems }
                        filtered={ dateGroupItems }
                        onChange={ dateGroup => this.setFilter({ dateGroup }) }/> }
                { filters.indexOf("daterange") !== -1 && 
                    <DateRangeSelector
                        name="Date range"
                        value={ dateRange }
                        onChange={ dateRange => this.setFilter({ dateRange }) }/> }
                { filters.indexOf("fri") !== -1 && 
                    <UserSelector
                        name="FRI" 
                        group="FRI"
                        className="multiline"
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ fris }
                        onChange={ fris => this.setFilter({ fris }) }/> }
                { filters.indexOf("createdat") !== -1 && 
                    <DateRangeSelector
                        name="Created at"
                        value={ createdAt }
                        onChange={ createdAt => this.setFilter({ createdAt }) }/> }
                { filters.indexOf("publishedat") !== -1 && 
                    <DateRangeSelector
                        name="Published at"
                        value={ publishedAt }
                        onChange={ publishedAt => this.setFilter({ publishedAt }) }/> }
                { filters.indexOf("submittedat") !== -1 && 
                    <DateRangeSelector
                        name="Submitted at"
                        value={ submittedAt }
                        onChange={ submittedAt => this.setFilter({ submittedAt }) }/> }
                { filters.indexOf("publishedby") !== -1 && 
                    <UserSelector
                        name="Published by" 
                        group="FRI"
                        className="multiline"
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ publishedBy }
                        onChange={ publishedBy => this.setFilter({ publishedBy }) }/> }
                { filters.indexOf("declinedby") !== -1 && 
                    <UserSelector
                        name="Declined by" 
                        group="FRI"
                        className="multiline"
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ declinedBy }
                        onChange={ declinedBy => this.setFilter({ declinedBy }) }/> }
            </>
        )
    }

    renderLooks() {
        const rows = this.state.data;
        const cols = [
            { id: "user", 
                name: "FRI", 
                render: c => <User user={ c.fri } /> },
            { id: "looks", 
                name: "Look count", 
                title: "Ever submitted (Ever saved) - excluding consultations", 
                className: "data-col",
                render: c => <><b>{ format(c.looks) } </b><span>({ format(c.totalLooks) })</span></>
            },
            { id: "awaiting", 
                name: "Awaiting (First/Final)", 
                title: "Total submitted (in first review/in final review)",
                className: "data-col", 
                render: c => <><b>{ format(c.awaiting) } </b><span>({ format(c.first) }/{ format(c.final) })</span></> },
            { id: "published", 
                name: "Published", 
                title: "Published at the moment (Ever published) - excluding consultations",
                className: "data-col",
                render: c => <><b>{ format(c.totalPublished) } </b><span>({ format(c.published) })</span></>
            },
            { id: "deleted", 
                name: "Deleted", 
                title: "Deleted",
                className: "data-col",
                render: c => format(c.deleted) },
            { id: "declined", 
                name: "Declined", 
                title: "Ever declined (including re-submitted)",
                className: "data-col",
                render: c => format(c.declined) },
            { id: "consultation", 
                name: "In consultations", 
                title: "Sent to customer (ever created)",
                className: "data-col",
                render: c => <><b>{ format(c.consultationLooksSent) } </b><span>({ format(c.consultationLooks) })</span></>
            },
            { id: "dates", 
                name: "First/Last", 
                title: "First submitted look date / Last submitted look date",
                render: c => {
                    if (c.minSubmittedAt && c.maxSubmittedAt) {
                        return [
                            <nobr key="1">{ moment(c.minSubmittedAt).format("MMM Do YY") }</nobr>,
                            <br key="2"/>,
                            <nobr key="3">{ moment(c.maxSubmittedAt).format("MMM Do YY") }</nobr>
                        ]
                    }
                    return '';
                } }
        ]

        return (
            <>
            <Table cols={ cols } rows={ rows }/>
            { rows.length === 0 && <div className="empty">No looks found</div> }
            </>
        )
    }

    renderLooksByDate() {
        const rows = this.state.data;
        const { dateGroup } = this.state;
        const dateFormats = {
            "year": "YYYY",
            "month": "MMMM YYYY",
            "day": "MMM Do YY"
        };
        const cols = [
        
            { id: "period", 
                name: "Period", 
                render: c => moment(c.date_group).format(dateFormats[ dateGroup[0].id ]) },
            { id: "looks", 
                name: "Look count", 
                title: "Ever submitted (Ever saved) - excluding consultations", 
                className: "data-col",
                render: c => <><b>{ format(c.looks) } </b><span>({ format(c.totalLooks) })</span></>
            },
            { id: "published", 
                name: "Published", 
                title: "Published at the moment (Ever published) - excluding consultations",
                className: "data-col",
                render: c => <><b>{ format(c.totalPublished) } </b><span>({ format(c.published) })</span></>
            },
            { id: "consultation", 
                name: "In consultations", 
                title: "Sent to customer (ever created)",
                className: "data-col",
                render: c => <><b>{ format(c.consultationLooksSent) } </b><span>({ format(c.consultationLooks) })</span></>
            }
        ];

        return (
            <>
            <Table cols={ cols } rows={ rows }/>
            { rows.length === 0 && <div className="empty">No looks found</div> }
            </>
        )
    }

    renderDomains() {
        const grid = this.state.data;
        const cols = [
            { id: "user", name: "FRI", 
                skip: row => !row.fri,
                props: row => ({
                    rowSpan: row.fri ? row.span : 1
                }),
                render: c => <User user={ c.fri } /> 
            },
            { id: "domain", name: "Affiliate" },
            { id: "products", name: "Products", render: c => format(c.products) }
        ]

        return (
            <>
            <Table cols={ cols } rows={ grid } className="page-reports-fri-domains"/>
            { grid.length === 0 && <div className="empty">No affiliates found</div> }
            </>
        )
    }

    renderClicks() {
        const grid = this.state.data;
        const cols = [
            {   id: "date", 
                name: "Month", 
                skip: row => !row.dateSpan,
                props: row => ({ rowSpan: row.date ? row.dateSpan : 1 }),
                render: row => moment(row.date).format("MMM YY")
            },
            {   id: "domain", 
                name: "Affiliate", 
                skip: row => !row.domainSpan, 
                props: row => ({ rowSpan: row.domain ? row.domainSpan : 1 })
            },
            {   id: "fri", name: "FRI", render: row => <User user={ row.fri }/> },
            {   id: "clicks", name: "Clicks", render: row => format(row.clicks) }
        ]

        return (
            <>
            <Table cols={ cols } rows={ grid } className="page-reports-fri-domains"/>
            { grid.length === 0 && <div className="empty">No affiliates found</div> }
            </>
        )
    }

    render() {
        const menu = this.getNav();
        const { section, data, loading } = this.state;

        return (
            <>
            <div className="page page-report-fris">
                <div className="toolbar">
                    <div className="toolbar-title">
                        Look stats
                    </div>
                    <Menu menu={ menu } className="right"/>
                </div>

                { loading && <Loader size={ 64 }/> }

                <div className="page-report-fris-layout">
                    <div className="left">
                        { this.renderFilters(filterSetup[section]) }
                    </div>
                    <div className="right">
                        { (section === "looks" && data) && this.renderLooks() }
                        { (section === "bydate" && data) && this.renderLooksByDate() }
                        { (section === "affiliates" && data) && this.renderDomains() }
                        { (section === "clicks" && data) && this.renderClicks() }
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default connect()(PageFris)

