
import React from "react"
import Authenticator from "common/src/components/authenticator/Authenticator"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import hub from "common/src/hub"
import routes from "app/routes"
import * as appActions from "common/src/actions/app"


class SignInForm extends React.Component {

    _isMounted = false

    constructor(props) {
        super(props);
        this.onAuth = this.onAuth.bind(this);
    }

    onAuth() {
        this.props.history.push(routes.home);
    }

    componentDidMount() {
        this._isMounted = true;
        hub.listen("auth", "signIn", this.onAuth);
        appActions.setTheme("dark");
    }

    componentWillUnmount() {
        this._isMounted = false;
        hub.remove("auth", "signIn", this.onAuth);
        appActions.setTheme("light");
    }

    render() {

        return (
            <div className="page page-signin">
                <Authenticator authState="signIn" showRegister={ false }/>
            </div>
        )
    }
}

export default withRouter(connect(state => state.user)(SignInForm))