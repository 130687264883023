import React, { useRef, useMemo, useCallback } from "react"
import LookCard from "./Card"


function LookCardsBlock(props) {

    const { itemCls, item, index } = props;
    const cls = useMemo(() => `look-cards-item ${ itemCls }`, [ itemCls ]);
    const block = useMemo(
        () => props.renderBlock ? props.renderBlock(item, index) : null,
        [ item, index ]
    );

    return (
        <div className={ cls }>{ block }</div>
    )
}

function LookCardsLook(props) {

    const { item, look, product, selection, itemCls = "" } = props;

    const cls = useMemo(() => `cards-item look-cards-item ${ itemCls }`, [ itemCls ]);

    const lookProps = useMemo(
        () => {
            const lookProps = { ...(typeof look === "function" ? look(item) : look) };
            if (lookProps.selected === undefined && selection) {
                lookProps.selected = selection.indexOf(item.id) !== -1;
            }
            return lookProps;
        },
        [ item, look, selection ]
    );

    const prodProps = useMemo(
        () => {
            const prodProps = typeof product === "function" ? 
                                        product(item) : 
                                        product;
            return typeof prodProps === "function" ? prodProps : { ...prodProps };
        },
        [ item, product ]
    );
    
    const onSelectionChange = useCallback(
        (selected) =>  {

            const sel = [ ...selection ];
            const inx = sel.indexOf(item.id);
    
            if (inx === -1 && selected) {
                sel.push(item.id);
            }
            else if (inx !== -1 && !selected ) {
                sel.splice(inx, 1);
            }

            props.onSelectionChange && props.onSelectionChange(sel);
        },
        [ item, selection, props.onSelectionChange ]
    );

    return (
        <div className={ cls }>
            <LookCard 
                look={ item }
                product={ prodProps }
                onSelectionChange={ onSelectionChange }
                { ...lookProps }/> 
        </div>
    )
}

function LookCardsItem(props) {
    return props.item.blockType === "promo" ? 
            <LookCardsBlock { ...props }/> :
            <LookCardsLook { ...props }/>
}

function LookCards(props) {

    const { looks, before, after } = props;

    const cls = useMemo(() => `cards look-cards ${ props.cls }`, [ props.cls ]);
    
    return (
        <div className={ cls }>
            { before }
            { looks.map((item, index) => 
                <LookCardsItem 
                    { ...props }
                    key={ item.id } 
                    index={ index }
                    item={ item }/> )}
            { after }
        </div>
    )
}



export default LookCards