import React, { useMemo } from "react"

import Table from "./Table"

function RowDetails(props) {

    const { row, index, dataStore, detailsNameClass, detailsValueClass } = props;
    const { rows, cols } = useMemo(
        () => {
            const rows = dataStore.getDetails(row, index);
            if (rows.length === 0) {
                return { rows: null, cols: null };
            }
            const cols = [
                {
                    id: "name",
                    className: detailsNameClass || ""
                },
                {
                    id: "value",
                    className: detailsValueClass || ""
                }
            ];
            return { rows, cols };
        },
        [ row, index ]
    );

    if (rows === null) {
        return null;
    }

    return (
        <Table cols={ cols } rows={ rows } noHeader/>
    )
}

export default RowDetails