import React from "react"
import PublicSelector from "common/src/components/PublicSelector"
import api from "app/api"


const prepareName = (template, user) => {
    if (typeof template === "function") {
        return template(user);
    }
    return user.givenName + " " + user.familyName;
}


class UserSelector extends React.Component {

    state = {
        search: "",
        users: [],
        loading: false
    }

    onSearchChange(search) {
        this.setState({ search }, () => this.load());
    }

    async load() {

        this.setState({ loading: true });

        const where = { _and: [] };
        const { search } = this.state;
        let { group } = this.props;
        const { searchGroup, template } = this.props;

        if (search) {
            where._and.push({
                _or: [
                    { handle: { _ilike: `%${ search }%` }},
                    { givenName: { _ilike: `%${ search }%` }},
                    { familyName: { _ilike: `%${ search }%` }}
                ]
            })
        }

        if (search && searchGroup !== undefined && searchGroup !== null) {
            group = searchGroup;
        }

        if (group) {
            if (Array.isArray(group)) {
                where._and.push({
                    _or: group.map(g => ({ groups: { _ilike: `%${ g }%` }}))
                });
            }
            else {
                where.groups = { _ilike: `%${ group }%` };
            }
        }

        const order = { handle: "asc" };
        const users = await api.user.list({ where, order })
                                .then(list => list.map(u => {
                                    u.name = u.givenName + " " + u.familyName;
                                    if (template) {
                                        u.label = prepareName(template, u);
                                    }
                                    return u;
                                }));

        this.setState({ users, loading: false });
    }

    render() {
        const { users, loading } = this.state;
        const props = Object.assign({}, this.props);
        props.onSearchChange = s => this.onSearchChange(s);
        props.onLoadItems = () => this.load();
        props.name = props.name || "User";
        props.cls = [ "tag-selector-v2", "user-selector" ];
        props.items = users;
        props.filtered = users;
        props.loading = loading;

        return (
            <PublicSelector { ...props }/>
        )
    }
}

export default UserSelector