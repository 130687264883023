import React from "react"
import SignInForm from "./SignIn"
import ForgotPasswordForm from "./ForgotPassword"
import RequireNewPasswordForm from "./RequireNewPassword"
import SignUpForm from "./SignUp"

class Authenticator extends React.Component {

    state = {
        authState: "signIn",
        authData: null
    }

    constructor(props) {
        super(props);
        if (props.authState) {
            this.state.authState = props.authState;
        }
    }

    onError(err) {
        console.error(err);
    }

    onAuthStateChange(authState, authData) {
        this.setState({ authState, authData });
    }

    render() {

        const { authState, authData } = this.state;
        const { showRegister = true, 
                signinProps = null, 
                forgotProps = null,
                signupProps = null,
                requirePasswordProps = null } = this.props;
        const props = {
            authData,
            onAuthStateChange: (a, d) => this.onAuthStateChange(a, d),
            onError: err => this.onError(err),
            showRegister
        }

        return (
            <div className="authenticator">
                { authState === "signIn" && 
                    <SignInForm 
                        { ...props }
                        { ...signinProps }/> }
                { authState === "forgotPassword" && 
                    <ForgotPasswordForm 
                        { ...props }
                        { ...forgotProps }/> }
                { authState === "requireNewPassword" && 
                    <RequireNewPasswordForm 
                        { ...props }
                        { ...requirePasswordProps }/> }
                { authState === "signUp" && 
                    <SignUpForm 
                        { ...props }
                        { ...signupProps }/> }
            </div>
        )
    }
}

export default Authenticator
