import React, { useCallback, useState, useEffect, useMemo } from "react"

import Loader from "common/src/components/Loader"
import Menu from "common/src/components/Menu"
import Table from "common/src/components/table/Table"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"


import api from "app/api"
import downloadUrl from "common/src/lib/downloadUrl"



function PSEAggAnalytics() {

    const [ loading, setLoading ] = useState(true);
    const [ generating, setGenerating ] = useState(false);
    const [ columns, setColumns ] = useState([]);
    const [ rows, setRows ] = useState([]);
    
    const loadReport = useCallback(
        () => {
            const body = {};
            setLoading(true);
            api.reporting.post("/report/pse-agg/data", { body })
                        .then(resp => {
                            setColumns(resp.columns);
                            setRows(resp.rows);
                            setLoading(false);
                        });
        },
        [ ]
    );

    const onGenerateClick = useCallback(
        async () => {
            const body = {};
            setGenerating(true);
            const res = await api.reporting.post("/report/pse-agg/csv", { body });
            setGenerating(false);
            downloadUrl(res.url, "pse-agg-report.csv");
        },
        [ ]
    );

    const menu = useMemo(
        () => {
            return [
                generating ? 
                    {
                        icon: <Loader/>
                    } :
                    {
                        className: "menu-download",
                        name: "Generate csv",
                        icon: <IconDownload className="icon icon-svg-fill icon-download"/>,
                        onClick: onGenerateClick
                    }
            ]
        },
        [ generating, onGenerateClick ]
    );

    console.log(rows)

    useEffect(
        () => { loadReport() },
        [loadReport]
    );

    return (
        <div className="page page-raw-report">
            <div className="toolbar">
                <div className="toolbar-title">
                    PSE aggregated analytics
                </div>
                <Menu menu={menu} className="right" />
            </div>
            <div className="grid-container">
                <Table 
                    variant="slim"
                    cols={ columns } 
                    rows={ rows }
                    className={ loading ? "grid-loading" : "" }/>
            </div>
            { loading && <Loader/> }
        </div>
    )
}

export default PSEAggAnalytics