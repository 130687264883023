
import addListener from "../dom/addListener"
import removeListener from "../dom/removeListener"
import throttle from "lodash/throttle"
import getOffset from "../dom/getOffset"
import getOuterHeight from "../dom/getOuterHeight"
import getPosition from "../dom/getPosition"
import hub from "common/src/hub"

class ScrollWatcher {

    listeners = []
    running = false
    scrollEl = null
    prevScrollY = null
    threshold = 0

    constructor(props) {
        this.onScroll = throttle(this.onScroll.bind(this), 200, { trailing: true });
        props && Object.assign(this, props);
    }

    watch(el, checker, listener, context) {
        if (!this.listeners.find(l => l.el === el)) {
            this.listeners.push({ el, checker, listener, context, state: false });
            if (!this.running) {
                this.start();
            }
        }
    }

    release(el) {
        let inx = this.listeners.findIndex(l => l.el === el);
        if (inx !== -1) {
            this.listeners.splice(inx, 1);
            if (this.listeners.length === 0 && this.running) {
                this.stop();
            }
        }
    }

    _check(checker, state) {

        const { scrollY, viewportHeight, offset, height, scrollHeight } = state;

        if (checker === "inside") {
            if (this.prevScrollY !== null && scrollY <= this.prevScrollY) {
                return false;
            }
            if (this.scrollEl) {
                return offset.top < viewportHeight + this.threshold;
            }
            else {
                return offset.top < scrollY + viewportHeight &&
                        scrollY < offset.top + height;
            }
        }
        // column-reverse layout
        else if (checker === "inside-reverse") {
            if (this.prevScrollY !== null && scrollY >= this.prevScrollY) {
                return false;
            }
            if (this.scrollEl) {
                return height >= scrollY + (scrollHeight - viewportHeight);
            }
            return false;
        }
    }

    onScroll() {

        const   scrollY = this.scrollEl ? this.scrollEl.scrollTop : window.scrollY,
                viewportHeight = this.scrollEl ? getOuterHeight(this.scrollEl) : window.innerHeight,
                scrollHeight = this.scrollEl ? this.scrollEl.scrollHeight : 0;

        this.listeners.forEach(l => {
            const { el, checker, listener, context, state } = l;
            const offset = this.scrollEl ? getPosition(el, this.scrollEl) : getOffset(el);
            const height = getOuterHeight(el);
            const domState = { scrollY, viewportHeight, offset, height, scrollHeight };
            let newState = false;

            if (typeof checker === "function") {
                if (checker.call(context, el, domState)) {
                    newState = true;
                }
            }
            else if (this._check(checker, domState, el)) {
                newState = true;
            }

            if (state !== newState) {
                l.state = newState;
                newState === true && listener.call(context, el, domState);
            }
        });

        this.prevScrollY = scrollY;
    }

    start() {
        addListener(this.scrollEl || window, "scroll", this.onScroll);
        !this.scrollEl && hub.listen("app", "scroll", this.onScroll);
        this.onScroll();
        this.running = true;
    }

    stop() {
        removeListener(this.scrollEl || window, "scroll", this.onScroll);
        !this.scrollEl && hub.remove("app", "scroll", this.onScroll);
        this.running = false;
    }

    check() {
        this.onScroll();
    }
}

let watcher = new ScrollWatcher();

export { ScrollWatcher }
export default watcher;