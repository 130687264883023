import React from "react"
import Loader from "common/src/components/Loader"
import Pagination from "common/src/components/Pagination"
import { Button, TextField } from "@mui/material"
import Checkbox from "common/src/components/material/Checkbox"
import ColorSelector from "common/src/components/catalogue/ColorSelector"
import Products from "common/src/components/catalogue/products/PaginatedProducts"
import ProductDetails from "common/src/components/catalogue/ProductDialog"
import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"
import { confirm } from "common/src/components/dialog/Alert"
import api from "app/api"
import hub from "common/src/hub"
import { loadColors } from "common/src/actions/catalogue"
import store from "app/store"
import { ui as ddUi } from "common/src/store/dialogs"
import NullForm from "common/src/components/NullForm"


const PER_PAGE = 50;

class CatalogueColorQueuePage extends React.Component {

    state = {
        loading: false,
        editId: null,
        assignId: null,
        assignToId: null,
        createId: null,
        creating: false,
        openId: null,
        name: null,
        synonyms: "",
        colors: [],
        selection: [],
        assigning: [],
        ignoring: [],
        search: "",
        count: 0,
        page: 0,
        product: null
    }

    componentDidMount() {
        this.loadList();
        loadColors();
    }

    async loadList() {
        const { search, page } = this.state;
        this.setState({ loading: true });
        const order = { name: "asc" };
        const where = {};
        const limit = PER_PAGE;
        const offset = page * PER_PAGE;

        if (search) {
            where.name = { _ilike: `%${ search }%` }
        }

        const colors = await api.catalogueColorQueue.list({ where, order, limit, offset });
        const count = await api.catalogueColorQueue.count({ where }).then(r => r.count);;

        this.setState({ loading: false, colors, count });
    }

    onPageChange(page) {
        this.setState({ page }, () => this.loadList());
    }

    onProductClick(product) {
        store.dispatch(ddUi.show("product-details"));
        this.setState({ product });
    }

    search() {
        this.setState({ page: 0 }, () => this.loadList());
    }

    toggleAllSelected(selected) {
        const { colors } = this.state;
        if (selected) {
            const selection = colors.map(c => c.id);
            this.setState({ selection });
        }
        else {
            this.setState({ selection: [] });
        }
    }

    toggleSelected(id, selected) {
        const { selection } = this.state;
        const inx = selection.indexOf(id);
        if (selected) {
            if (inx === -1) {
                selection.push(id);
                this.setState({ selection });
            }
        }
        else {
            if (inx !== -1) {
                selection.splice(inx, 1);
                this.setState({ selection });
            }
        }
    }

    async assign(queueId, colorId) {
        const colors = this.state.colors;
        const fcolors = Array.isArray(queueId) ?
                            queueId.map(id => colors.find(c => c.id === id)) :
                            [ colors.find(c => c.id === queueId) ];
        let i, l, j, jl;

        colorId = Array.isArray(colorId) ? colorId : [ colorId ];

        this.setState({ assigning: fcolors.map(c => c.id) });

        for (i = 0, l = fcolors.length; i < l; i++) {
            for (j = 0, jl = colorId.length; j < jl; j++) {
                await api.catalogueColorFeed.create({ 
                    colorId: colorId[j], 
                    feedColorId: fcolors[i].feedColorId, 
                    name: fcolors[i].name 
                });
            }
            await api.catalogueColorQueue.remove(fcolors[i].id);
        }

        hub.dispatch("catalogue", "color-change");
        this.setState({ creating: false, createId: null, 
                        assignId: null, assignToId: null, selection: [],
                        assigning: [] });
        this.loadList();
    }

    async create(queueId) {
        this.setState({ creating: true });

        const { name, synonyms, colors } = this.state;
        const fcolors = Array.isArray(queueId) ?
                            queueId.map(id => colors.find(c => c.id === id)) :
                            [ colors.find(c => c.id === queueId) ];

        const newColor = { name: name || fcolors[0].name, synonyms };
        const colorId = await api.catalogueColor.create(newColor).then(r => r.id);

        let i, l;
        for (i = 0, l = fcolors.length; i < l; i++) {
            await api.catalogueColorFeed.create({ 
                colorId, 
                feedColorId: fcolors[i].feedColorId, 
                name: fcolors[i].name 
            });
            await api.catalogueColorQueue.remove(fcolors[i].id);
        }

        hub.dispatch("catalogue", "color-change");

        this.setState({ 
            creating: false, 
            createId: null, 
            assignId: null, 
            assignToId: null,
            name: null,
            selection: [],
            synonyms: '' });
        this.loadList();
    }

    async ignore(ids) {
        try {
            await confirm({
                title: "Ignore feed color",
                message: "Are you sure?"
            });
        }
        catch (err) {
            return;
        }

        const colors = this.state.colors;
        const fcolors = ids.map(id => colors.find(c => c.id === id));
        let i, l;

        this.setState({ ignoring: ids });

        for (i = 0, l = fcolors.length; i < l; i++) {
            await api.catalogueColorFeed.create({ 
                colorId: null, 
                feedColorId: fcolors[i].feedColorId, 
                name: fcolors[i].name,
                ignore: true
            });
            await api.catalogueColorQueue.remove(fcolors[i].id);
        }

        this.setState({ creating: false, createId: null, 
                        assignId: null, assignToId: null, selection: [],
                        page: 0,
                        ignoring: [] });
        this.loadList();
    }


    openColor(e, c) {
        const openId = this.state.openId;
        e.preventDefault();
        e.stopPropagation();

        if (openId === c.id) {
            this.setState({ openId: null });    
        }
        else {
            this.setState({ openId: c.id });
        }
    }


    renderCreateForm(c) {
        const { createId, creating, name, synonyms } = this.state;

        return (
            <NullForm className="page-catalogue-color-form"  key={ createId }>
                <TextField 
                    autoComplete="off"
                    variant="outlined" 
                    placeholder="Color name"
                    value={ name === null ? c.name : name }
                    disabled={ creating }
                    onKeyDown={ e => (e.key === "Enter" && name !== "") && this.create() }
                    onChange={ e => this.setState({ name: e.target.value }) }/>
                <TextField 
                    autoComplete="off"
                    variant="outlined" 
                    placeholder="Synonyms (comma separated)"
                    value={ synonyms }
                    disabled={ creating }
                    onKeyDown={ e => (e.key === "Enter" && name !== "") && this.create() }
                    onChange={ e => this.setState({ synonyms: e.target.value }) }/>
                <Button
                    variant="contained"
                    children={ "Create" }
                    startIcon={ creating ? <Loader inline/> : null }
                    disabled={ creating || name === "" }
                    onClick={ () => this.create(c.id) }/>
                <Button 
                    variant="text" 
                    children="Cancel"
                    onClick={ () => this.setState({ createId: null }) }/>
            </NullForm>
        )
    }

    renderInfo(c) {
        const { selection, assignId, createId, assigning, ignoring, openId } = this.state;
        const isAssigning = assigning.indexOf(c.id) !== -1;
        const isIgnoring = ignoring.indexOf(c.id) !== -1;
        const cls = ["page-catalogue-color"];
        c.id === openId && cls.push("active");

        return (
            <div className={ cls.join(" ") } key={ c.id }>
                
                <Checkbox 
                    onChange={ e => this.toggleSelected(c.id, e.target.checked) }
                    checked={ selection.indexOf(c.id) !== -1 }/>
                <div>
                    <h4>
                        { c.name }
                        <a href="/#" onClick={ e => this.openColor(e, c) }>
                            <IconDown/>
                        </a> 
                    </h4>
                </div>

                { assignId === c.id && this.renderAssignForm(c, true) }
                { createId === c.id && this.renderCreateForm(c) }

                <div className="actions">
                    <Button 
                        variant="contained" 
                        children="Assign"
                        disabled={ isAssigning }
                        startIcon={ isAssigning ? <Loader inline/> : null }
                        onClick={ () => this.setState({ assignId: c.id, createId: null, assignToId: null }) }/>
                    <Button 
                        variant="outlined" 
                        children="Create"
                        onClick={ () => this.setState({ createId: c.id, assignId: null, assignToId: null }) }/>
                    <Button 
                        variant="text" 
                        children="Ignore"
                        disabled={ isIgnoring }
                        startIcon={ isIgnoring ? <Loader inline/> : null }
                        onClick={ () => this.ignore([ c.id ]) }/>
                </div>  

                { (c.id === openId) && 
                    <Products 
                        autoload
                        setName={ c.feedColorId }
                        feed_color_id={ c.feedColorId }
                        perPage={ 16 }
                        product={ p => ({ onClick: () => this.onProductClick(p) })}
                        emptyText="Currently, no products found"/>}
            </div> 
        )
    }

    renderAssignForm(c, multiple = true) {
        const { assignToId } = this.state;
        return (
            <div className="page-catalogue-color-assign">
                <ColorSelector 
                    multiple={ multiple }
                    onChange={ cs => {
                        if (cs) {
                            if (Array.isArray(cs)) {
                                if (cs.length > 0) {
                                    this.setState({ assignToId: cs.map(c => c.id) });
                                }
                                else {
                                    this.setState({ assignToId: null });
                                }
                            }
                            else {
                                this.setState({ assignToId: cs.id });
                            }
                        }
                        else {
                            this.setState({ assignToId: null });
                        }
                    }}/>
                <Button 
                    variant="contained" 
                    children="Save"
                    onClick={ () => this.assign(c.id, assignToId) }/>
                <Button 
                    variant="text" 
                    children="Cancel"
                    onClick={ () => this.setState({ assignId: null, assignToId: null }) }/>
            </div>
        )
    }

    render() {
        const { loading, colors, editId, selection, assigning, ignoring,
                search, assignId, createId, count, page, product } = this.state;

        const allSelected = colors.length === selection.length && colors.length > 0;

        return (
            <>
            <div className="page page-catalogue-color-queue">
                { loading && <Loader size={ 64 }/> }
                <h2>Color queue</h2>
                <NullForm className="toolbar">
                    <TextField 
                        autoComplete="off"
                        fullWidth 
                        placeholder={ `Search (total: ${ count })` }                        
                        variant="outlined"
                        value={ search }
                        onKeyDown={ e => e.key === "Enter" && this.search() }
                        onChange={ e => this.setState({ search: e.target.value }) }/>
                    <Button 
                        variant="contained" 
                        children="Search"
                        onClick={ () => this.search() }/>
                </NullForm>
                { selection.length > 0 &&
                    <div className="toolbar page-catalogue-color-queue-create">
                        <Checkbox 
                            onChange={ e => this.toggleAllSelected(e.target.checked) }
                            checked={ allSelected }/>
                        <label>Select all</label>
                        <Button 
                            variant="contained" 
                            children="Assign selection"
                            disabled={ assigning.length > 0 }
                            startIcon={ assigning.length > 0 ? <Loader inline/> : null }
                            onClick={ () => this.setState({ assignId: "selection", createId: null, assignToId: null }) }/>
                        <Button 
                            variant="outlined" 
                            children="Create color"
                            onClick={ () => this.setState({ assignId: null, createId: "selection", assignToId: null }) }/>
                        <Button 
                            variant="text" 
                            children="Ignore"
                            disabled={ ignoring.length > 0 }
                            startIcon={ ignoring.length > 0 ? <Loader inline/> : null }
                            onClick={ () => this.ignore(selection) }/>

                        { assignId === "selection" && this.renderAssignForm({ name: '', id: selection }, false) }
                        { createId === "selection" && this.renderCreateForm({ name: '', id: selection }) }
                    </div> }
                { selection.length === 0 && 
                    <div className="toolbar page-catalogue-color-queue-create">
                        <Checkbox 
                            onChange={ e => this.toggleAllSelected(e.target.checked) }
                            checked={ allSelected }/>
                        <label>Select all</label>
                    </div> }
                { colors.map(c => editId === c.id ? this.renderForm() : this.renderInfo(c)) }

                <Pagination
                    page={ page }
                    count={ count }
                    perPage={ PER_PAGE }
                    onChange={ no => this.onPageChange(no) }/>
            </div>
            <ProductDetails 
                product={ product }/>
            </>
        )
    }
}

export default CatalogueColorQueuePage