import React from "react"
import { connect } from "react-redux"
import api from "app/api"
import moment from "moment"
import df from "common/src/lib/date/formats"
import formatNum from "common/src/lib/format/number"

class CatalogueFiles extends React.Component {
    _isMounted = false
    state = {
        files: []
    }

    componentDidMount() {
        this._isMounted = true;        
        this.loadList();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadList() {
        api.catalogueFile
        .list({ order: { filename: "asc" }})
        .then(list => {
            if (this._isMounted) {
                this.setState({ files: list });
            }
        });
    }

    reload(e) {
        e && e.preventDefault();
        this.loadList();
    }

    async download(e, f) {
        e && e.preventDefault();
        api.backend.post("/catalogue/download", { body: { source: f.source.code, id: f.id }});
    }

    async import(e, f) {
        e && e.preventDefault();
        api.backend.post("/catalogue/import", { body: { id: f.id }});
    }


    render() {

        const list = this.state.files;

        return (
            <>
            <div className="page page-catalogue-files page-w-loading">
            <div className="grid-wrapper">
            <table className="grid">
                <thead>
                <tr>
                    <th>Source</th>
                    <th>Filename&nbsp;<a href="/#" onClick={ e => this.reload(e) }>Reload</a></th>
                    <th>Region</th>
                    <th>Downloaded</th>
                    <th>Crawled</th>
                    <th>Imported</th>
                    <th>Size</th>
                    <th>Import state</th>
                </tr>
                </thead>
                <tbody>
                { list.map(f => (
                    <tr key={ f.id }>
                        <td>
                            { f.source.code }
                            { f.variant ? <><br/>{ f.variant }</> : "" }
                        </td>
                        <td>{ f.filename }</td>
                        <td>{ f.region }</td>
                        <td>{ f.downloadedAt ?
                                moment(f.downloadedAt).format(df.full) : '' }<br/>
                            <a href="/#" onClick={ e => this.download(e, f) }>Re-download</a>
                        </td>
                        <td>{ f.crawledAt ?
                                moment(f.crawledAt).format(df.full) : '' }</td>
                        <td>
                            { f.importedAt ?
                                <>  { moment(f.importedAt).format(df.full) }
                                    <br/>
                                    ({ f.lastImportId })
                                    </>
                                : '' }
                        </td>
                        <td>{ formatNum(f.size / 1024 / 1024) } Mb</td>
                        <td>
                            { f.jobRunState }<br/>
                            <a href="/#" onClick={ e => this.import(e, f) }>Re-import</a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
            </div>
            </>
        )
    }
}

export default connect()(CatalogueFiles)