import api from "common/src/api"
import entities from "common/src/api/hasura/entities"


const User = `
    id
    givenName
    familyName
    handle
    avatar
    email
    phone
    groups
    bio
    details
    enabled
    vip
    createdAt
    defaultShopper
    userGroups {
        group {
            id
            name
        }
    }
`;

const UserGroup = `
    id
    name
`

const LogProductClick = `
    id
    createdAt
    usedUrl
    isBot
    userAgent
    userCountry
    campaignId
    catalogueProductId
    moodboardId
    sid
    linkId
    link {
        id
        hash
        createdAt
        targetUrl
        catalogueProductId
        retailer
        name
        sku
        brand
    }
    product {
        id 
        name
        url
        image
        lookId
    }
    user { ${ User } }
    referenceUserId
    referenceUser { ${ User } }
`;


const CatalogueFile = `
    id 
    filename
    downloadedAt
    importedAt
    crawledAt
    size
    jobRunState
    region
    variant
    lastImportId
    source { code }
`;

const Catalogue_Category_Feed = `
    id
    name
    categoryId
    feedCategoryId
    leafCategory
    characteristics {
        characteristic {
            id
            name
        }
    }
`;

const Catalogue_Category_Queue = `
    id
    name
    feedCategoryId
    createdAt
`;

const Catalogue_Category_Feed_Characteristic = `
    categoryFeedId
    characteristicId
`;


const Catalogue_Color_Queue = `
    id
    name
    feedColorId
    createdAt
`;

const Catalogue_Color_Feed = `
    id
    colorId
    feedColorId
    name
`;

const Catalogue_Material_Feed = `
    id
    name
    materialId
    feedMaterialId
`;

const Catalogue_Material_Queue = `
    id
    name
    feedMaterialId
    createdAt
`;

const Catalogue_Characteristic_Feed = `
    id
    name
    characteristicId
    feedCharacteristicId
`;

const Catalogue_Characteristic_Queue = `
    id
    name
    feedCharacteristicId
    createdAt
`;


const Catalogue_Designer_Feed = `
    id
    designerId
    feedDesignerId
    feedDesignerName
    ban
`;

const Catalogue_Designer_Queue = `
    id
    name
    feedDesignerId
    createdAt
`;


const Network = `
    id
    name
`;

const Network_Commission = `
    id
    saleAmount
    approvedSaleAmount
    commissionAmount
    approvedCommissionAmount
    friCommissionAmount
    currency
    advertiser
    transactionDate
    importDate
    details
    commissionId
    clickRefId
    status
    details
    network {
        ${ Network }
    }
    history {
        id
        networkId
        importDate
        details
        status
    }
    click {
        product {
            look {
                id
                fri {
                    id
                    avatar
                    handle
                    familyName
                    givenName 
                }
            }
        }
        referenceUser {
            id 
            email 
            givenName
            familyName
        }
        user {
            id 
            email 
            givenName
            familyName
        }
        link {
            hash
            lookProductId
            catalogueProductId
            retailer
            targetUrl
            name
            sku
            brand
        }
    }
`;


const Network_Order = `
    id
    grossSaleAmount
    saleAmount
    grossCommissionAmount
    commissionAmount
    pseCommissionRate
    pseCommissionRateClick
    retailerCommissionRate
    retailerCommissionRateClick
    currency
    advertiser
    orderDate
    importedAt
    details
    orderId
    clickId
    status
    autoApprovedAt
    details
    networkId
    manualStatus
    network {
        ${ Network }
    }
    snapshots {
        id
        networkId
        importedAt
        details
        status
    }
    corrections {
        id
        orderId
        correctionId
        networkId
        importedAt
        correctionDate
        details
    }
    rakutenItems {
        id
        processDate
        quantity
        sku
        product
        saleAmount
        commissionAmount
        currency
        importedAt
        details
        manualStatus
        snapshots {
            id
            processDate
            saleAmount
            commissionAmount
            details
            quantity
        }
    }
    click {
        referenceUserId
        catalogueProductId
        moodboardId
        usedUrl
        createdAt
        product {
            name
            retailer
            look {
                id
                consultationId
                friId
                fri {
                    id
                    avatar
                    handle
                    familyName
                    givenName 
                    paymentSettings {
                        pseCommission
                    }
                }
            }
        }
        referenceUser {
            id 
            email 
            givenName
            familyName
            paymentSettings {
                pseCommission
            }
        }
        user {
            id 
            email 
            givenName
            familyName
        }
        link {
            hash
            lookProductId
            catalogueProductId
            retailer
            targetUrl
            name
            sku
            brand
        }
    }
    approvedPayments {
        id
        userId
        amount
        currency
        comment
        type
        paid
        revolutDraftId
        revolutReference
    }
`;


const Accounting_FRI_PaymentLog = `
    id
    friId
    fri {
        id
        avatar
        familyName
        givenName 
    }
    month
    value
    currency
    comment
`;


const SearchHistory = `
    id
    searchedAt
    query
    user {
        email
    }
    tagsFound
`;


const Invitation = `
    id
    email
    name
    createdAt
    used
    accepted
    disabled
    usedAt
    userId
    createdByUser {
        id
        avatar
        familyName
        givenName 
    }
    user {
        id
        avatar
        familyName
        givenName 
    }
    users {
        id
        avatar
        familyName
        givenName 
    }
    settings
`;


const User_ApprovedPayment = `
    id
    networkOrderId
    userId
    type
    amount
    currency
    comment
    createdAt
    paid
    revolutDraftId
    revolutReference
    revolutDraft {
        id
        reference
        createdAt
        revolutDraftId
        transactionId
        status
    }
    user {
        id
        avatar
        familyName
        givenName 
    }
`;

const User_PaymentSettings = `
    id
    userId
    friCommission
    contributorCommission
`;

const Link = `
    id
    hash
    createdAt
    targetUrl
    lookProductId
    catalogueProductId
    retailer
    name
    sku
    brand
    lookProduct {
        name
    }
    contributor {
        id
        avatar
        familyName
        givenName 
    }
`;

const PSEApplication = `
    id
    email
    givenName
    familyName
    phone
    city
    instagram
    linkedin
    company
    bio
    accepted
    acceptedAt
    settings
    user {
        id
        avatar
        givenName
        familyName
        email
    }
`

const VisitorApplication = `
    id
    createdAt
    name
    surname
    role
    email
    city
    bio
    phone
    shopperName
    shopperSurname
    shopperEmail
`;

const Catalogue_Designer_Exclusion = `
    id
    designerId
    retailer
    designer {
        id
        name
    }
`

Object.assign(entities, 
{

    UserGroup: { list: UserGroup },
    UserGroup_User: { create: true, remove: true },

    LogProductClick: { list: LogProductClick },

    CatalogueFile: { list: CatalogueFile },

    Catalogue_Category: { list: entities.Catalogue_Category.list, 
                            create: true, update: true, remove: true },
    Catalogue_Designer: { list: entities.Catalogue_Designer.list, create: true, update: true, remove: true },
    Catalogue_Color: { list: entities.Catalogue_Color.list, 
                            create: true, update: true, remove: true },
    Catalogue_Material: { list: entities.Catalogue_Material.list, 
                            create: true, update: true, remove: true },
    Catalogue_Characteristic: { list: entities.Catalogue_Characteristic.list, 
                            create: true, update: true, remove: true },

    Catalogue_Category_Queue: { list: Catalogue_Category_Queue, create: true, update: true, remove: true },
    Catalogue_Category_Feed: { list: Catalogue_Category_Feed, create: true, update: true, remove: true },
    Catalogue_Category_Feed_Characteristic: { list: Catalogue_Category_Feed_Characteristic, create: true, update: true, remove: true },
    Catalogue_Color_Queue: { list: Catalogue_Color_Queue, create: true, update: true, remove: true },
    Catalogue_Color_Feed: { list: Catalogue_Color_Feed, create: true, update: true, remove: true },
    Catalogue_Material_Queue: { list: Catalogue_Material_Queue, create: true, update: true, remove: true },
    Catalogue_Material_Feed: { list: Catalogue_Material_Feed, create: true, update: true, remove: true },
    Catalogue_Characteristic_Queue: { list: Catalogue_Characteristic_Queue, create: true, update: true, remove: true },
    Catalogue_Characteristic_Feed: { list: Catalogue_Characteristic_Feed, create: true, update: true, remove: true },
    Catalogue_Designer_Queue: { list: Catalogue_Designer_Queue, create: true, update: true, remove: true },
    Catalogue_Designer_Feed: { list: Catalogue_Designer_Feed, create: true, update: true, remove: true },

    Catalogue_Designer_Exclusion: { list: Catalogue_Designer_Exclusion, create: true, update: true, remove: true },
    
    Network_Order: { list: Network_Order },
    Network_Commission: { list: Network_Commission },
    Network: { list: Network },
    Network_Order_Rakuten_Item: { update: true },
    Accounting_FRI_PaymentLog: { list: Accounting_FRI_PaymentLog, create: true, update: true, remove: true },
    User_ApprovedPayment: { list: User_ApprovedPayment, create: true, remove: true, update: true },
    User_PaymentSettings: { list: User_PaymentSettings, create: true, update: true },

    SearchHistory: { list: SearchHistory, create: true },

    Invitation: { list: Invitation, create: true, update: true, remove: true },

    Link: { list: Link },

    Connection: { list: true, remove: true },
    PSEApplication: { list: PSEApplication, update: true, apiName: "pseApplication" },
    VisitorApplication: { list: VisitorApplication, apiName: "visitorApplication" }
});

entities.User.list = User;
entities.User.remove = true;
entities.Chat_Participant.update = true;

export const init = () => {
    api.init();
}

export default api