import React, { useCallback, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { Link } from "react-router-dom"
//import Select from "common/src/components/form/Select"

import Table from "common/src/components/table/Table"
import RakutenItemDetails from "./RakutenItemDetails"

import * as partnerize from "./network/partnerize"
import * as rakuten from "./network/rakuten"
import * as cj from "./network/cj"
import * as awin from "./network/awin"
//import * as commonNetworkDetails from "./network/common"


const networkDetails = {
    partnerize, rakuten, cj, awin
};



function CommissionDetails({ commission, onShowClickInfo, onShowCommissionHistory, 
                                getFilterLink }) {

    const [ products, setProducts ] = useState(null);
    const [ showRaw, setShowRaw ] = useState(false);


    const renderRakutenItemDetails = useCallback(
        (order, item) => {
            return (
                <RakutenItemDetails order={ order } item={ item }/>
            )
        },
        []
    );

    const getProducts = useCallback(
        async () =>  {
            const comm = commission;
            const networkId = comm.networkId || comm.network?.id;
            const products = await networkDetails[ networkId ]
                                ?.getProducts(comm, comm.networkId === "rakuten" ? 
                                                renderRakutenItemDetails : 
                                                null) 
                                || null;

            setProducts(products);
        },
        [ commission, renderRakutenItemDetails ]
    );

    useEffect(
        () => {
            getProducts();
        },
        [ getProducts ]
    );


    const renderToolbar = useCallback(
        () => {

            const network = networkDetails[ commission.networkId ];
            const hasHistory = network.hasHistory(commission);
            const hasCorrections = network.hasCorrections(commission);

            return (
                <div className="page-commission-details-toolbar">
                    { !!commission.clickId &&
                        <Button 
                            variant="outlined" 
                            size="small"
                            children="Show click info"
                            onClick={ () => onShowClickInfo(commission) }/> }
                    { hasHistory && 
                        <Button 
                            variant="outlined" 
                            size="small"
                            children="Show history"
                            onClick={ () => onShowCommissionHistory(commission) }/> }
                    { hasCorrections && 
                        <Button 
                            variant="outlined" 
                            size="small"
                            children="Show corrections"
                            onClick={ () => onShowCommissionHistory(commission) }/> }
                    { commission.details && 
                        <Button 
                            variant="outlined" 
                            size="small"
                            onClick={ () => setShowRaw(prev => !prev) }
                            children={ showRaw ? "Hide raw details" : "Show raw details" }/> }

                    {/*<span className="spacer"/>*/}

                    { (getFilterLink && !!commission.clickId) && 
                        <Link 
                            children="Filter by this click"
                            to={ getFilterLink("click", commission.clickId) }/> }
                    { (getFilterLink && !!commission.networkId) && 
                        <Link 
                            children="Filter by this network"
                            to={ getFilterLink("network", commission.networkId) }/> }
                    { (getFilterLink && !!commission.advertiser) && 
                        <Link 
                            children="Filter by this advertiser"
                            to={ getFilterLink("advertiser", commission.advertiser) }/> }
                    { (getFilterLink && !!commission.clickId && commission.click.product) && 
                        <Link 
                            children="Filter by this FRI"
                            to={ getFilterLink("fri", commission.click.product.look.fri.id) }/> }
                    { (getFilterLink && !!commission.clickId && commission.click.referenceUser) && 
                        <Link 
                            children="Filter by this Contributor"
                            to={ getFilterLink("contributor", commission.click.referenceUser.id) }/> }
                    { (getFilterLink && !!commission.clickId) && 
                        <Link 
                            children="Show click"
                            to={ getFilterLink("click-log", commission.clickId) }/> }
                </div>
            )
        },
        [ commission,
            onShowClickInfo, onShowCommissionHistory, 
            getFilterLink,
            showRaw ]
    )

    const renderRawDetails = useCallback(
        () =>  {
            const code = JSON.stringify(JSON.parse(commission.details), null, 2);

            return (
                <div className="page-commission-details-raw">
                    <pre>
                        { code }
                    </pre>
                </div>
            )
        },
        [ commission ]
    )

    return (
        <div className="page-commission-details"> 

            { products && 
                <Table className="page-commission-details-products" { ...products }/> }

            { renderToolbar() }
            { showRaw && renderRawDetails() }
        </div>
    )

}

export default CommissionDetails


/*
{ code && 
                    <div className="page-commission-details-code">
                        { code }
                    </div> }*/