import moment from "moment"
import formatCurrency from "common/src/lib/format/currency"
//import formatNumber from "common/src/lib/format/number"
import df from "common/src/lib/date/formats"

import User from "common/src/components/user/User"


const renderSum = (gbpAmount, usdAmount) => {

    if (!gbpAmount && !usdAmount) {
        return "0";
    }

    const sum = [];
    gbpAmount && sum.push(formatCurrency(gbpAmount, "gbp", 2));
    usdAmount && sum.push(formatCurrency(usdAmount, "usd", 2));
    return sum.join(" + ");
}

const renderMoneyCol = (row, key, defaultCurrency = "gbp") => {
    if (row.gbp || row.usd) {
        const gbp = row?.gbp || {};
        const usd = row?.usd || {};
        return renderSum(gbp[key] || 0, usd[key] || 0);
    }
    return formatCurrency(row[key], defaultCurrency, 2);
}


const common = [
    {
        id: "fri-sales",
        name: "Look Net Sales",
        //modes: ["grid"],
        render: r => renderMoneyCol(r.fri, "saleAmount")
    },

    /*{
        id: "fri-sales-gbp",
        name: "GBP Look Sales",
        modes: ["csv"],
        render: r => formatNumber(r.fri?.gbp?.saleAmount || 0, 2)
    },
    {
        id: "fri-sales-usd",
        name: "USD Look Sales",
        modes: ["csv"],
        render: r => formatNumber(r.fri?.usd?.saleAmount || 0, 2)
    },*/


    {
        id: "fri-comm",
        name: "TF Net Commission",
        //modes: ["grid"],
        render: r => renderMoneyCol(r.fri, "commissionAmount")
    },

    /*{
        id: "fri-comm-gbp",
        name: "GBP Look TF Net Commission",
        modes: ["csv"],
        render: r => formatNumber(r.fri?.gbp?.commissionAmount || 0, 2)
    },
    {
        id: "fri-comm-usd",
        name: "USD Look TF Net Commission",
        modes: ["csv"],
        render: r => formatNumber(r.fri?.usd?.commissionAmount || 0, 2)
    },*/


    {
        id: "cnt-sales",
        name: "Link Net Sales",
        //modes: ["grid"],
        render: r => renderMoneyCol(r.contributor, "saleAmount")
    },
    /*{
        id: "cnt-sales-gbp",
        name: "GBP Link Sales",
        modes: ["csv"],
        render: r => formatNumber(r.contributor?.gbp?.saleAmount || 0, 2)
    },
    {
        id: "cnt-sales-usd",
        name: "USD Link Sales",
        modes: ["csv"],
        render: r => formatNumber(r.contributor?.usd?.saleAmount || 0, 2)
    },*/

    {
        id: "cnt-comm",
        name: "TF Net Commission",
        //modes: ["grid"],
        render: r => renderMoneyCol(r.contributor, "commissionAmount")
    },

    /*{
        id: "cnt-comm-gbp",
        name: "GBP Link TF Net Commission",
        modes: ["csv"],
        render: r => formatNumber(r.contributor?.gbp?.commissionAmount || 0, 2)
    },
    {
        id: "cnt-comm-usd",
        name: "USD Link TF Net Commission",
        modes: ["csv"],
        render: r => formatNumber(r.contributor?.usd?.commissionAmount || 0, 2)
    }*/
]

export const date = [
    {
        id: "month",
        name: "Month",
        render: r => moment(r.date_group).format(df.m)
    },
    ...common
];

export const user = [
    {
        id: "user",
        name: "User",
        render: r => <User user={r.user} passthruClick />,
        renderCsv: r => r.user.givenName + " " + r.user.familyName
    },
    ...common,
    {
        id: "user-comm",
        name: "PSE Net Commission",
        //modes: ["grid"],
        render: r => formatCurrency(r?.commissions?.total || 0, "gbp", 2)
    },
    /*{
        id: "user-comm-gbp",
        name: "GBP PSE Net Commission",
        modes: ["csv"],
        render: r => formatNumber(r.commissions?.gbp?.total || 0, 2)
    },
    {
        id: "user-comm-usd",
        name: "USD PSE Net Commission",
        modes: ["csv"],
        render: r => formatNumber(r.commissions?.usd?.total || 0, 2)
    }*/
];


export const commissions = [
    {
        id: "date",
        name: "Order date",
        render: c => moment(c.orderDate).format(df.full)
    },

    {
        id: "sale",
        name: "Net Sale",
        render: c => formatCurrency(c.saleAmount, c.currency, 2)
    },

    {
        id: "comm",
        name: "TF Net Commission",
        render: c => formatCurrency(c.commissionAmount, c.currency, 2)
    },

    {
        id: "user-role",
        name: "Sale source",
        render: c => c.roles?.map(r => {
            if (r === "FRI") {
                return "Look"
            }
            else if (r === "Contributor") {
                return "Link"
            }
            return r;
        }).join(", ") || ""
    },

    {
        id: "user-comm",
        name: "PSE Net Commission",
        render: c => formatCurrency(c.userCommissionAmount, c.currency, 2)
    },

    {
        id: "status",
        name: "Status",
        render: row => {
            if (row.status !== null) {
                return row.status;
            }
            if (row.autoApprovedAt !== null) {
                return "auto approved";
            }
            return "";
        }
    }
]
