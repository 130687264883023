import React from "react"
import Checkbox from "@mui/material/Checkbox"
import { ReactComponent as CheckboxChecked } from "common/src/svg/checkbox-checked.svg"

class TFCheckbox extends React.Component {

    render() {
        return <Checkbox 
            { ...this.props }
            checkedIcon={ <CheckboxChecked/> }
            indeterminateIcon={ <CheckboxChecked/> }
            icon={ <span className="checkbox-blank"/> }/>
    }
}

export default TFCheckbox