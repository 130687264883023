import React from "react"
import PublicSelector from "common/src/components/PublicSelector"
import networks from "./networks"



class NetworkSelector extends React.Component {

    state = {
        networks,
        loading: false
    }

    load() {}

    render() {

        const { networks } = this.state;
        const props = Object.assign({}, this.props);
        props.onLoadItems = () => this.load();
        props.name = props.name || "Network";
        props.cls = [ "tag-selector-v2", "network-selector" ];
        props.items = networks;
        props.filtered = networks;
        props.loading = false;
        props.noSearch = true;

        return (
            <PublicSelector { ...props }/>
        )
    }
}

export default NetworkSelector