import { useCallback, useMemo, useState, Fragment } from "react"

import { ReactComponent as IconExpand } from "common/src/svg/collapse.svg"
import Cell from "./Cell"
import RowDetails from "./RowDetails"
import cls from "common/src/lib/className"

function Row(props) {

    const { table, row, index, cols, expandedKey, expandKey, rowKey, getRowClass,
            onRowClick, isRowClickable, onRowExpand, multiExpand,
            variant, renderDetails, dataStore, rowAttrs } = props;

    const [ localExpanded, setLocalExpanded ] = useState(false);

    const key = useMemo(
        () => {
            if (!rowKey) {
                return index;
            }
            else if (typeof rowKey === "string") {
                return typeof row[rowKey] !== "undefined" ? 
                        row[rowKey] : 
                        index;
            }
            else {
                return rowKey(row);
            }
        },
        [ index, rowKey, row ]
    );

    const expandable = useMemo(
        () => {
            if (props.expandable && typeof props.expandable === "function") {
                return props.expandable(props.row);
            }
            return props.expandable && (!props.isRowExpandable || props.isRowExpandable(props.row))
        }, 
        [ props.expandable, props.isRowExpandable, props.row ]
    );

    const clickable = useMemo(
        () => !!onRowClick && (!isRowClickable || isRowClickable(row)),
        [ onRowClick, isRowClickable ]
    );

    const isExpanded = useMemo(
        () => multiExpand ? localExpanded : row[expandKey] === expandedKey, 
        [ expandKey, expandedKey, localExpanded ]
    );

    const expandColCls = useMemo(
        () => cls([ "expandable-col", `grid-var-${ variant }` ]),
        [ variant ]
    );

    const rowCls = useMemo(
        () => {
            const rowCls = [];
            (expandable || clickable) && rowCls.push("clickable");
            (expandable && ( isExpanded || localExpanded )) && rowCls.push("expanded");
            getRowClass && rowCls.push(getRowClass(row));
            return cls(rowCls);
        },
        [ getRowClass, expandable, clickable, isExpanded, localExpanded ]
    );

    const rowDetails = useMemo(
        () => isExpanded && dataStore ? <RowDetails { ...props }/> : null,
        [ localExpanded, isExpanded, row, index ]
    );

    const customDetails = useMemo(
        () => isExpanded && renderDetails ? renderDetails(row, index) : null,
        [ localExpanded, isExpanded, row, index, renderDetails ]
    );

    const attrs = useMemo(
        () => rowAttrs ? typeof rowAttrs === "function" ? rowAttrs(row) : rowAttrs : {},
        [ rowAttrs, row ]
    );

    const handleRowClick = useCallback(
        (e) => {
            if (onRowClick || expandable) {
                e && e.preventDefault();
                e && e.stopPropagation();
            }
            onRowClick && onRowClick(row);
            if (expandable && multiExpand) {
                setLocalExpanded(!localExpanded);
                if (expandKey && onRowExpand) {
                    onRowExpand(row[expandKey]);
                }
            }
            else if (expandable && expandKey) {
                const key = row[expandKey];
                table.trigger("toggleRow", expandedKey === key ? null : key);
                onRowExpand && onRowExpand(expandedKey === key ? null : key);
            }
        },
        [ onRowExpand, setLocalExpanded, localExpanded,
            expandKey, row, table, expandable, expandedKey ]
    );


    return (
        <Fragment key={ key }>
            <tr { ...attrs }
                className={ rowCls }
                onClick={ handleRowClick }>
                { (props.expandable && expandKey) && 
                    <td className={ expandColCls }>
                        { expandable && <IconExpand/> }
                    </td> }
                { cols.map((col) => <Cell { ...{ ...props, col, key: col.dataKey || col.id }}/>) }
            </tr>
            { isExpanded &&
                <tr className="expanded-row">
                    <td className="expanded-col" 
                        colSpan={ cols.length + 1 }>
                        { rowDetails }
                        { customDetails }
                    </td>
                </tr>}
        </Fragment>
    )

}

export default Row