import React from "react"
import { connect } from "react-redux"
import { TextField, Button, FormControlLabel } from "@mui/material"
import Checkbox from "common/src/components/material/Checkbox"
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconAdd } from "common/src/svg/plus.svg"
import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"
import { ReactComponent as IconMove } from "common/src/svg/move.svg"
import { alert, confirm } from "common/src/components/dialog/Alert"
import Loader from "common/src/components/Loader"
import TagList from "common/src/components/tag/List"
import Products from "common/src/components/catalogue/products/PaginatedProducts"
import ProductDetails from "common/src/components/catalogue/ProductDialog"
import findParent from "common/src/lib/dom/findParent"
import getOffset from "common/src/lib/dom/getOffset"
import getHeight from "common/src/lib/dom/getHeight"

import { loadCategories } from "common/src/actions/catalogue"
import api from "app/api"
import hub from "common/src/hub"
import async from "common/src/lib/js/async"
import { ui as ddUi } from "common/src/store/dialogs"
import NullForm from "common/src/components/NullForm"


const p2tp = (position) => {
    return ("" + position).padStart(3, '0');
};

class CatalogueCategoriesPage extends React.Component {

    categoriesMap = null
    categoriesList = []
    state = {
        categories: [],
        categoriesMap: null,
        editId: null,
        parentId: null,
        openId: null,
        loading: false,
        creating: false,
        name: "",
        path: "",
        headerMenu: false,
        product: null,
        expanded: []
    }

    componentDidMount() {
        this.loadTree();
    }

    async loadTree(force) {
        this.setState({ loading: true });
        const list = await loadCategories(force);
        this.prepareTree(list);
        this.setState({ loading: false });
    }


    async loadFeedCategories(categoryId) {
        const order = { name: "asc" };
        const where = { categoryId: { _eq: categoryId }};
        const feedCategories = await api.catalogueCategoryFeed.list({ where, order });
        this.setState({ feedCategories });
    }

    async removeFeedCategory(feedCategoryId) {
        const { feedCategories } = this.state;

        const inx = feedCategories.findIndex(c => c.id === feedCategoryId);
        feedCategories.splice(inx, 1);
        this.setState({ feedCategories });

        await api.catalogueCategoryFeed.remove(feedCategoryId);
        hub.dispatch("catalogue", "category-change");
    }

    setStateUrl(path) {
        path = path.replace(/\s/gi, '-');
        path = path.replace(/[^a-z0-9_-]/gi, '');
        path = path.toLowerCase();
        this.setState({ path });
    }

    prepareTree(list) {
        const categories = [];
        const map = {};

        list.forEach(c => {
            map[c.id] = { ...c };
            map[c.id].children = [];

            if (c.level === 0) {
                categories.push(map[c.id]);
            }
            else if (map[ c.parentId ]) {
                map[ c.parentId ].children.push(map[ c.id ]);
                map[ c.id ].parent = map[ c.parentId ];
            }
        });

        this.categoriesMap = map;
        this.categoriesList = [].concat(categories);
        this.setState({ categories });
    }

    getParentsList(node) {
        const { categories } = this.state;
        if (!categories || categories.length === 0) {
            return [];
        }
        const list = [];

        node = this.categoriesMap[ typeof node === "string" ? node : node.id ];

        while (node) {
            list.push(node);
            node = node.parent;
        }

        return list.reverse();
    }

    getChildren(parentId, exceptId) {
        const { categories } = this.state;

        if (parentId) {
            const parentNode = this.categoriesMap[ parentId ];
            return parentNode.children.filter(c => c.id !== exceptId);
        }
        else {
            return categories.filter(c => c.id !== exceptId);
        }
    }

    async create() {
        
        const map = this.categoriesMap;
        const { name, editId, categories, parentId, headerMenu } = this.state;
        const siblings = this.getChildren(parentId, editId);
        let { path } = this.state;
        let url = null;

        if (path) {

            if (siblings.find(s => s.path && s.path.toLowerCase() === path.toLowerCase())) {
                if (parentId) {
                    const parentNode = this.categoriesMap[ parentId ];
                    alert({ message: `Parent category "${ parentNode.name }" already contains subcategory ` +
                                        `with "${ path }" as url` });
                }
                else {
                    alert({ message: `There is already a general category with the url "${ path }"` });
                }
                return;
            }

            const pathList = parentId ? this.getParentsList(parentId).map(c => c.path) : [];
            pathList.push(path);
            url = pathList.join("/");
        }
        else {
            path = null;
        }

        this.setState({ creating: true });

        if (editId === "new") {
            let position, level, treePosition;
            if (parentId === null) {
                position = categories.length;
                level = 0;
                treePosition = p2tp(position);
            }
            else {
                //console.log(map)
                const parent = map[parentId];
                position = parent.children.length;
                level = parent.level + 1;
                treePosition = parent.treePosition + '/' + p2tp(position);
            }
            const newCategory = {
                name, path, parentId, url,
                position, treePosition, level,
                headerMenu
            };
            //console.log(newCategory)
            await api.catalogueCategory.create(newCategory);
        }
        else {
            const payload = { name, path, url, headerMenu };
            await api.catalogueCategory.update(editId, payload);
        }

        this.setState({ creating: false, editId: null });
        this.loadTree(true);
    }

    recalcPositions(parentTreePosition, parentUrl, children) {
        let updates = [];
        children.forEach((c, inx) => {
            const position = inx;
            const treePosition = parentTreePosition ? 
                                    parentTreePosition + '/' + p2tp(position) :
                                    p2tp(position);
            const url = parentUrl ?
                            parentUrl + "/" + c.path :
                            c.path;
            if (c.position !== position || c.treePosition !== treePosition) {
                updates.push({ id: c.id, data: { position, treePosition, url }});
                if (c.children.length > 0) {
                    const subUpdates = this.recalcPositions(treePosition, url, c.children);
                    updates = updates.concat(subUpdates);
                }
            }
        });
        return updates;
    }

    listDeletes(nodes) {
        let deletes = [];

        nodes.forEach(n => {
            deletes.push(n.id);
            const sub = this.listDeletes(n.children);
            deletes = deletes.concat(sub);
        });

        return deletes;
    }

    async remove(id) {
        try {
            await confirm({ title: "Delete category", message: "Are you sure?" });
        }
        catch (err) {
            return;
        }
        this.setState({ loading: true });
        const map = this.categoriesMap;
        const rootlist = this.categoriesList;
        const node = map[id];

        const parentChildren = node.parentId ? map[node.parentId].children : rootlist;
        const parentTreePosition = node.parentId ? map[node.parentId].treePosition : '';
        const parentUrl = node.parentId ? map[node.parentId].url : '';
        const inx = parentChildren.findIndex(c => c.id === id);
        parentChildren.splice(inx, 1);

        const updates = this.recalcPositions(parentTreePosition, parentUrl, parentChildren);
        const deletes = this.listDeletes([node]);

        let i, l;

        for (i = 0, l = deletes.length; i < l; i++) {
            await api.catalogueCategory.remove(deletes[i]);
        }
        for (i = 0, l = updates.length; i < l; i++) {
            await api.catalogueCategory.update(updates[i].id, updates[i].data);
        }

        this.loadTree(true);
    }

    async moveInside(id, parentId) {
        this.setState({ loading: true });
        const map = this.categoriesMap;
        const rootlist = this.categoriesList;
        const node = map[id];

        if (node.parentId === parentId) {
            return;
        }

        const parent = parentId ? map[parentId] : null;
        const toNodes = parentId ? parent.children : rootlist;
        const toParentTreePosition = parentId ? parent.treePosition : "";
        const toParentUrl = parentId ? parent.url : "";
        const fromNodes = node.parentId ? node.parent.children : rootlist;
        const fromParentTreePosition = node.parentId ? node.parent.treePosition : "";
        const fromParentUrl = node.parentId ? node.parent.url : "";
        const fromInx = fromNodes.findIndex(c => c.id === node.id);
        let updates = [];

        if (toParentTreePosition.indexOf(node.treePosition) === 0) {
            alert({ message: "Can't move parent category inside its children categories" });
            return;
        }
        if (node.path && toNodes.find(n => n.path && n.path.toLowerCase() === node.path.toLowerCase())) {
            if (parent) {
                alert({ message: `Parent category "${ parent.name }" already contains subcategory ` +
                                    `with "${ node.path }" as url` });
            }
            else {
                alert({ message: `There is already a general category with the url "${ node.path }"` });
            }
            return;
        }

        this.setState({ loading: true });

        fromNodes.splice(fromInx, 1);
        toNodes.push(node);

        // if moving up the tree within one parent
        if (fromParentTreePosition.indexOf(toParentTreePosition) === 0) {
            updates = this.recalcPositions(toParentTreePosition, toParentUrl, toNodes);
        }
        else {
            updates = this.recalcPositions(toParentTreePosition, toParentUrl, toNodes);
            updates = updates.concat(this.recalcPositions(fromParentTreePosition, fromParentUrl, fromNodes));
        }

        updates.push({ id: node.id, data: { parentId, level: parentId ? parent.level + 1 : 0 }});

        //console.log(updates)
        
        let i, l;

        for (i = 0, l = updates.length; i < l; i++) {
            await api.catalogueCategory.update(updates[i].id, updates[i].data);
        }

        this.loadTree(true);
    }

    async move(id, beforeId) {

        if (id === beforeId) {
            return;
        }

        const map = this.categoriesMap;
        const rootlist = this.categoriesList;
        const node = map[id];
        const beforeNode = map[beforeId];

        let updates = [], toInx, fromInx;

        if (node.parentId === beforeNode.parentId) {
            let nodes = node.parentId ? node.parent.children : rootlist;
            let parentTreePosition = node.parentId ? node.parent.treePosition : "";
            let parentUrl = node.parentId ? node.parent.url : "";
            fromInx = nodes.findIndex(c => c.id === node.id);
            toInx = nodes.findIndex(c => c.id === beforeNode.id);
            nodes.splice(fromInx, 1);
            nodes.splice(toInx, 0, node);
            updates = this.recalcPositions(parentTreePosition, parentUrl, nodes);
            //console.log(updates)
        }
        else {
            const newParent = beforeNode.parentId ? map[ beforeNode.parentId ] : null;
            let toNodes = beforeNode.parentId ? beforeNode.parent.children : rootlist;
            let fromNodes = node.parentId ? node.parent.children : rootlist;
            let toParentTreePosition = beforeNode.parentId ? beforeNode.parent.treePosition : "";
            let toParentUrl = beforeNode.parentId ? beforeNode.parent.url : "";
            let fromParentTreePosition = node.parentId ? node.parent.treePosition : "";
            let fromParentUrl = node.parentId ? node.parent.url : "";
            toInx = toNodes.findIndex(c => c.id === beforeNode.id);
            fromInx = fromNodes.findIndex(c => c.id === node.id);

            if (toParentTreePosition.indexOf(node.treePosition) === 0) {
                alert({ message: "Can't move parent category inside its children categories" });
                return;
            }
            if (node.path && toNodes.find(n => n.path && n.path.toLowerCase() === node.path.toLowerCase())) {
                if (newParent) {
                    alert({ message: `Parent category "${ newParent.name }" already contains subcategory ` +
                                        `with "${ node.path }" as url` });
                }
                else {
                    alert({ message: `There is already a general category with the url "${ node.path }"` });
                }
                return;
            }
            
            fromNodes.splice(fromInx, 1);
            toNodes.splice(toInx, 0, node);

            // if moving up the tree within one parent
            if (fromParentTreePosition.indexOf(toParentTreePosition) === 0) {
                updates = this.recalcPositions(toParentTreePosition, toParentUrl, toNodes);
            }
            else {
                updates = this.recalcPositions(toParentTreePosition, toParentUrl, toNodes);
                updates = updates.concat(this.recalcPositions(fromParentTreePosition, fromParentUrl, fromNodes));
            }

            updates.push({ id: node.id, data: { parentId: beforeNode.parentId, level: beforeNode.level }});

            //console.log(updates)
        }

        this.setState({ loading: true });

        let i, l;

        for (i = 0, l = updates.length; i < l; i++) {
            await api.catalogueCategory.update(updates[i].id, updates[i].data);
        }

        this.loadTree(true);

    }

    catDraggable(catId) {
        return {
            draggable: true,
            onClick: e => {
                e.stopPropagation();
                e.preventDefault();
            },
            onDragStart: e => {
                e.dataTransfer.setData(
                    "application/json", 
                    JSON.stringify({ id: catId })
                );
                e.dataTransfer.setDragImage(
                    document.getElementById("cat-info-" + catId), 
                    0, 0
                );
                async(() => this.setState({ dragging: true }), 50);
            },
            onDragEnd: e => {
                async(() => this.setState({ dragging: false }), 50);
            }
        }
    }

    catDroppable(catId) {

        return {
            
            onDragEnter: e => {
                e.preventDefault();
            },
            onDragOver: e => {
                e.preventDefault();
                const node = findParent(e.target, ".page-catalogue-categories-info");
                const ofs = getOffset(node);
                const h = getHeight(node);
                node.classList.remove("drop-active");
                node.classList.remove("drop-active-inside");

                if (e.clientY - ofs.top < h / 2) {
                    node.classList.add("drop-active");
                }
                else {
                    node.classList.add("drop-active-inside");
                }
            },
            onDragLeave: e => {
                e.preventDefault();
                const node = findParent(e.target, ".page-catalogue-categories-info");
                node.classList.remove("drop-active");
                node.classList.remove("drop-active-inside");
            },
            onDrop: e => {
                e.preventDefault();
                e.stopPropagation();
                const { id } = JSON.parse(e.dataTransfer.getData("application/json"));
                const node = findParent(e.target, ".page-catalogue-categories-info");
                node.classList.remove("drop-active");
                node.classList.remove("drop-active-inside");

                const ofs = getOffset(node);
                const h = getHeight(node);

                if (e.clientY - ofs.top < h / 2) {
                    async(() => this.move(id, catId), 50);
                }
                else {
                    async(() => this.moveInside(id, catId), 50);
                }
            }
            
        }
    }


    onProductClick(product) {
        this.props.dispatch(ddUi.show("product-details"));
        this.setState({ product });
    }

    toggleChildren(e, id) {
        e.preventDefault();
        e.stopPropagation();

        const { expanded } = this.state;
        const inx = expanded.indexOf(id);

        if (inx === -1) {
            expanded.push(id);
        }
        else {
            expanded.splice(inx, 1);
        }

        this.setState({ expanded });
    }

    openCategory(e, c) {
        const openId = this.state.openId;
        e.preventDefault();
        e.stopPropagation();

        if (openId === c.id) {
            this.setState({ openId: null });    
        }
        else {
            this.setState({ 
                openId: c.id, 
                feedCategories: []
            });
            this.loadFeedCategories(c.id);
        }
    }

    renderForm() {
        const { editId, creating, name, path, headerMenu } = this.state;

        return (
            <NullForm className="page-catalogue-categories-form">
                <TextField 
                    autoComplete="off"
                    variant="outlined" 
                    placeholder="Category name"
                    value={ name || "" }
                    disabled={ creating }
                    onKeyDown={ e => (e.key === "Enter" && name) && this.create() }
                    onChange={ e => this.setState({ name: e.target.value }) }/>
                <TextField 
                    autoComplete="off"
                    variant="outlined" 
                    placeholder="URL"
                    value={ path || "" }
                    disabled={ creating }
                    onKeyDown={ e => (e.key === "Enter" && name) && this.create() }
                    onChange={ e => this.setStateUrl( e.target.value ) }/>
                <FormControlLabel 
                    control={ <Checkbox
                                checked={ headerMenu }
                                disabled={ creating }
                                onChange={ e => this.setState({ headerMenu: e.target.checked }) }/> }
                    label="Header menu"/>
                <Button
                    variant="contained"
                    children={ editId === "new" ? "Create" : "Save" }
                    startIcon={ creating ? <Loader inline/> : null }
                    disabled={ creating || !name }
                    onClick={ () => this.create() }/>
                <Button 
                    variant="text" 
                    children="Cancel"
                    onClick={ () => this.setState({ editId: null }) }/>
            </NullForm>
        )
    }

    renderInfo(c) {

        const { openId, feedCategories, expanded } = this.state; 
        const cls = [ "page-catalogue-categories-info" ];
        const parents = this.getParentsList(c);
        const path = parents.map(c => c.path || c.id).join("/");

        if (c.id === openId) {
            //cls.push("active");
        }
        if (expanded.indexOf(c.id) !== -1) {
            cls.push("active");
        }
        if (c.headerMenu) {
            cls.push("in-menu");
        }

        return (
            <>
            <div className={ cls.join(" ") } { ...this.catDroppable(c.id) }>

                <div id={ "cat-info-" + c.id }>
                    <h4>
                        { c.children.length > 0 ?
                            <a href="/#" onClick={ e => this.toggleChildren(e, c.id) }>
                                { c.name }
                                <IconDown/>
                            </a> :
                            c.name }
                        { c.feedCategoriesCount > 0 && 
                            <a href="/#" 
                                onClick={ e => this.openCategory(e, c) }
                                className="more">...</a>
                        }
                    </h4>
                    <p>/{ path }</p>
                </div>

                <div className="actions">
                    <a href="/#" onClick={ (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                                editId: "new",
                                parentId: c.id,
                                name: "",
                                path: "",
                                headerMenu: false
                            });
                        }}>
                        <IconAdd/>
                    </a>
                    <a href="/#" onClick={ (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                                editId: c.id,
                                parentId: c.parentId,
                                name: c.name,
                                path: c.path,
                                headerMenu: c.headerMenu
                            });
                        }}>
                        <IconEdit/>
                    </a>
                    <a href="/#" onClick={ (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.remove(c.id);
                        }}>
                        <IconDelete/>
                    </a>
                    <a href="/#" { ...this.catDraggable(c.id) }>
                        <IconMove/>
                    </a>
                </div>  
                
                { (c.feedCategoriesCount > 0 && c.id === openId) && 
                    <>
                    <div className="page-catalogue-feed-colors">
                        <TagList 
                            tags={ feedCategories } 
                            getLabel={ tag => {
                                let name = tag.name;
                                if (tag.characteristics && tag.characteristics.length > 0) {
                                    let chars = tag.characteristics.map(tc => tc.characteristic.name);
                                    name += ` (${ chars.join(', ') })`;
                                }
                                return name;
                            }}
                            onDelete={ fc => this.removeFeedCategory(fc.id) }/>
                    </div>
                    <Products 
                        autoload
                        setName={ c.id }
                        category_id={ c.id }
                        perPage={ 12 }
                        product={ p => ({ onClick: () => this.onProductClick(p) })}
                        emptyText="Currently, no products found"/>
                    </> }
            </div>
            
            </>
        )
    }

    renderSubtree(children) {

        const { editId, parentId, expanded } = this.state;

        return (
            <ul className="page-catalogue-categories-list">
                { children.map(c => 
                    <li key={ c.id }>
                        { editId === c.id ? this.renderForm() : this.renderInfo(c) }
                        { (editId === "new" && parentId === c.id) && this.renderForm() }
                        { (expanded.indexOf(c.id) !== -1 && c.children.length > 0) && 
                            this.renderSubtree(c.children) }
                    </li>
                )}
            </ul>
        )
    }

    render() {
        const { categories, editId, parentId, loading, product } = this.state;

        return (
            <>
            <div className="page page-catalogue-categories">
                { loading && <Loader size={ 64 }/> }
                <div className="toolbar">
                    <Button 
                        variant="contained" 
                        children="Add category"
                        onClick={ () => this.setState({ 
                            editId: "new", 
                            name: "", 
                            path: "", 
                            parentId: null }) }/>
                </div>
                { (editId === "new" && parentId === null) && this.renderForm(editId) }
                { this.renderSubtree(categories) }
            </div>
            <ProductDetails 
                product={ product }/>
            </>
        )
    }
}

export default connect(state => ({ categories: state.catalogue.data.categories }))(CatalogueCategoriesPage)


/* <p>{ c.treePosition }</p> */