import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

import { Button } from "@mui/material"
import Loader from "common/src/components/Loader"

import { ReactComponent as IconTick } from "common/src/svg/tick.svg"

import { ui as ddUi } from "common/src/store/dialogs"
import { ui } from "common/src/store/user"
import df from "common/src/lib/date/formats"
import userSelector from "common/src/selectors/user/current"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import { resendEmailVerification, checkCurrent } from "common/src/actions/user"
import { getDefaultEmailContact, getDefaultPhoneContact } from "common/src/actions/contacts"


function ProfileDetails() {

    const dispatch = useDispatch();
    const current = useSelector(userSelector);
    const [ resendingEmail, setResendingEmail ] = useState(false);
    const [ verificationSent, setVerificationSent ] = useState(false);
    const { contacts = [],
            givenName, 
            familyName, 
            emailVerified, 
            phoneVerified,
            createdAt } = current;
    const phone = contacts.find(c => c.type === "phone") || getDefaultPhoneContact(current);
    const email = contacts.find(c => c.type === "email") || getDefaultEmailContact(current);     

    const onVerifyEmailClick = useSwallowEventCallback(
        async () => {
            setResendingEmail(true);
        
            try {
                const resp = await resendEmailVerification();

                if (!resp.success) {
                    if (resp.verified) {
                        checkCurrent();
                    }
                }
                else {
                    setVerificationSent(true);
                }
            }
            catch (err) {}

            setResendingEmail(false);
        },
        []
    );

    const onVerifyPhoneClick = useSwallowEventCallback(
        () => dispatch(ddUi.show("verify-phone")),
        []
    );

    const onShowDetailsForm = useSwallowEventCallback(
        () => dispatch(ui.form("details")),
        []
    );

    return (
        <div className="profile-card card-user-details">
            <div>
                <label>Your details</label>
                <h3>{ givenName } { familyName }</h3>
                <p className="email">
                    { email.normalized }
                    { emailVerified && 
                        <span className="email-verified">
                            <IconTick className="icon icon-svg-fill"/>
                        </span>}
                </p>

                { !emailVerified &&
                    <p className="email-not-verified">
                        Email not verified. 
                        { resendingEmail ? 
                            <Loader inline size={ 14 }/> :
                            verificationSent ? 
                                <span>Now check your email</span> :
                                <a href="/#" 
                                    onClick={ onVerifyEmailClick }
                                    children="Re-send code"/>
                        }
                    </p> }

                { phone?.value && 
                    <p className="phone">
                    <label>Phone</label>
                    { phone.value }
                    { phoneVerified && 
                        <span className="email-verified">
                            <IconTick className="icon icon-svg-fill"/>
                        </span>}
                    </p>  }

                { (phone?.value && !phoneVerified) &&
                    <p className="email-not-verified">
                        Phone not verified. 
                        <a href="/#" 
                            onClick={ onVerifyPhoneClick }
                            children="Verify"/>
                    </p> }

                <p className="signedup">
                    Signed up<br/>
                    <span>{ moment(createdAt).format(df.full) }</span>
                </p>

                <div className="footer-actions">
                    <Button variant="outlined" 
                            onClick={ onShowDetailsForm }
                            color="primary"
                            children="Edit details"/>
                </div>
            </div>
        </div>
    )
}

export default ProfileDetails