
const SEP = ",";


const escape = function(value) {
    value = value === null || value === undefined ? '' : value.toString();        
    value = value.replace(/"/g, '""');
    if (value.search(/("|,|\n)/g) >= 0) {
        value = '"' + value + '"';
    }
    return value;
};

const prepareValue = function(row, col, inx, extra) {
    if (col.renderCsv) {
        return col.renderCsv(row, inx, extra);
    }
    else if (col.render) {
        return col.render(row, inx, extra);
    }
    if (Array.isArray(row)) {
        return row[inx] || "";
    }
    return row[col.id] || "";
};

export const data2csv = function(cols, rows, extra) {
    let data = "";
    data += cols.filter(c => !c.skipCsv).map(c => c.name).map(escape).join(SEP) + "\n";

    rows.forEach((row, inx) => {
        data += cols.filter(c => !c.skipCsv).map((col, inx) => prepareValue(row, col, inx, extra)).map(escape).join(SEP);
        if (inx < rows.length - 1) {
            data += "\n";
        }
    });

    return data;
};


export const downloadCsv = function(data, filename="data.csv") {

    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } 
    else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
