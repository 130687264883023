

export default (state, urlParams = []) => {

    const params = {};

    urlParams.forEach(p => {
        let v = state[ p.stateName || p.name ];
        
        if (p.store) {
            v = p.store(v);
        }

        if (v === undefined || v === null) {
            return;
        }

        v = v.toString();

        if (v === "") {
            return;
        }

        params[ p.name ] = v;
    });

    return params;
}