import schema from "./password/schema"

export function validatePasswordSync(value) {
    const res = schema.validate(value, { details: true });

    if (res === true) {
        return null;
    }
    if (res.length === 0) {
        return null;
    }

    return res[0].message;
}

async function validatePassword(value) {
    return validatePasswordSync(value);
}

export default validatePassword