
import { batch } from "react-redux"
import singlePromise from "common/src/lib/js/singlePromise"

import { data, ui } from "app/store/page/searchHistory"
import store from "app/store"
import api from "app/api"
import hub from "common/src/hub"

export const PER_PAGE = 50;

function loadHistory(page) {
    return api.searchHistory.list({
        order: { searchedAt: "desc" },
        offset: page * PER_PAGE,
        limit: PER_PAGE
    }, null, true)
}

export let reloadHistoryList = function() {
    let state = store.getState(),
        page = state.historyPage.ui.history.page;

    return loadHistoryList(page, true);
}

export let loadHistoryList = singlePromise({
    mode: singlePromise.ONE_CANCEL,
    pre: (page = 0, force) => {
        let state = store.getState(),
            currPage = state.historyPage.ui.history.page,
            setup = {
                page: page
            };

        if (state.historyPage.data.history.length > 0 && 
            currPage === page) {
            if (!force) {
                setup.value = {
                    items: state.historyPage.data.history,
                    count: state.historyPage.ui.history.count
                };
                setup.skip = true;
            }
        }
        return setup;
    },
    fn: (page = 0) => {
        store.dispatch(ui.history.loading(true));
        return loadHistory(page);
    },
    resolve: (response, setup, args) => {
        batch(() => {
            store.dispatch(data.history.set(response.items));
            store.dispatch(ui.history.count(response.count));
            store.dispatch(ui.history.page(setup.page || 0));
        })
    },
    reject: err => {
        hub.dispatch("error", "people-history-list", err);
    },
    always: () => {
        store.dispatch(ui.history.loading(false));
    }
})
