import api from "app/api"
import entities from "common/src/api/hasura/entities"

export const PER_PAGE = 50;
//const userGraph = "id givenName familyName avatar";



const dateToTimestamp = (d, time) => {
    if (d.indexOf("T") === -1) {
        d += ' ' + time;
    }
    return d;
};

const setBodyDates = (body, dateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
        body.start = dateToTimestamp(dateRange.startDate, '00:00:00');
        body.end = dateToTimestamp(dateRange.endDate, '23:59:59');
    }
};

const idmap = (list) => {
    return list.map(item => {
        return typeof item === "string" || typeof item === "number" ? item : item.id;
    });
}

const setBodyIds = (body, key, list) => {
    if (list && list.length > 0) {
        body[key] = idmap(list);
    }
};

const setBodyFlag = (body, key, value) => {
    if (value === true || value === false) {
        body[key] = value;
    }
};

const getRequestBody = (filters) => {
    const body = {};
    
    setBodyDates(body, filters);

    let { fris = [], contributors = [], users = [], pses = [] } = filters;
    users = [ ...idmap(users), ...idmap(fris), ...idmap(contributors), ...idmap(pses)];
    users = users.filter((id, inx, self) => self.indexOf(id) === inx).filter(id => !!id);
    if (users.length > 0) {
        setBodyIds(body, "users", users);
    }

    setBodyIds(body, "userGroups", filters.userGroups);
    setBodyIds(body, "types", filters.types);
    setBodyFlag(body, "paid", filters.paid);

    if (typeof filters.draft !== "undefined" && filters.draft !== null) {
        setBodyFlag(body, "draft", filters.draft);
    }
    
    if (filters.query) {
        body.query = filters.query;
    }

    return body;
};

export const loadPayments = async (filters) => {

    const body = getRequestBody(filters);
    const { rows: ids, count } = await api.reporting.post("/report/payments/list", { body });    

    const where = { id: { _in: ids }};
    const order = { createdAt: "desc" };
    const rows = await api.userApprovedPayment.list({ where, order });

    return { rows, count };
}

export const loadPaymentsByUser = async (filters) => {
    const body = getRequestBody(filters);
    const { rows } = await api.reporting.post("/report/payments/byuser", { body });    
    const userIds = rows.map(row => row.userId);
    const graph = entities.User.list + " revolutCounterparty { id }"
    const users = await api.user.list({ where: { id: { _in: userIds }}}, graph);

    return rows.map(row => {
        row.user = users.find(u => u.id === row.userId);
        return row;
    })
}

export const cancelPayment = async (id) => {

    const where = {
        id: { _eq: id },
        paid: { _eq: false }
    };

    await api.userApprovedPayment.remove(where);
}

export const setNotPaid = async (id) => {
    await api.userApprovedPayment.update(id, { paid: false });
}

export const setPaid = async (id) => {
    await api.userApprovedPayment.update(id, { paid: true });
}

export const resetPaymentDraftConnection = async (id) => {
    await api.userApprovedPayment.update(id, { paid: false, revolutDraftId: null });
}

export const createRevolutDraft = async (userId, reference, accountId, amount, currency, pids) => {

    const res = await api.revolut.post("/payments", { body: {
        userId, reference, accountId, amount, currency, pids
    }});

    if (res.success === true) {
        return true;
    }
    if (res.error) {
        if (typeof res.error === "string") {
            return res.error;
        }
        return res.error?.message || "Something went wrong. Payment draft was not created";
    }  
};

export const removeRevolutDraft = async (draftId) => {
    const res = await api.revolut.del("/payments", { queryStringParameters: {
        draftId
    }});
    if (res.success === true) {
        return true;
    }
    if (res.error) {
        if (typeof res.error === "string") {
            return res.error;
        }
        return res.error?.message || "Something went wrong. Payment draft was not created";
    }  
}