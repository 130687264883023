const routes = {
    "home": "/",
    "signin": "/signin",
    "register": "/register",
    "profile": "/profile",
    "profileContacts": "/profile/contacts",
    "content": "/content",
    "terms": "/terms",
    "privacy": "/privacy",

    "search": "/search",
    "searchHistory": "/search-history/:page?",
    "searchSynonyms": "/search-synonyms",
    
    "tags": "/tags/:listName?/:page?",

    "people": "/people/:role?/:page?",
    "people-stats": "/people-stats/:id",
    "invitations": "/invitations/:page?",
    "pseapplications": "/pse-applications/:page?",
    "visitorapplications": "/visitor-applications/:page?",

    "clicks": "/analytics/clicks/:page?",

    "catalogueFiles": "/catalogue/files",
    "catalogueSearch": "/catalogue/search/:page?",
    "catalogueCategories": "/catalogue/categories",
    "catalogueCategoryQueue": "/catalogue/category/queue",
    "catalogueColors": "/catalogue/colors",
    "catalogueColorQueue": "/catalogue/color/queue",
    "catalogueMaterials": "/catalogue/materials",
    "catalogueMaterialQueue": "/catalogue/material/queue",
    "catalogueCharacteristics": "/catalogue/characteristics",
    "catalogueCharacteristicQueue": "/catalogue/characteristic/queue",
    "catalogueIgnored": "/catalogue/ignored",
    "catalogueDesignerQueue": "/catalogue/designer/queue",
    "catalogueDesigners": "/catalogue/designers",
    "catalogueDesignersTop": "/catalogue/designers-top",
    "catalogueDesignersExclusion": "/catalogue/designers-exclusion",

    "products": "/catalogue/products",

    "reportCommissions": "/report/commissions/:section?/:page?",
    "reportClicks": "/report/clicks/:section?/:page?",
    "reportFris": "/report/fris/:section?/:page?",
    "reportLinks": "/report/links/:section?/:page?",
    "reportRaw": "/report/raw",
    "reportPse": "/report/pse",
    "reportPseAgg": "/report/pse-agg",

    "accountingCommissions": "/accounting/commissions/:section?/:page?",
    "accountingSettings": "/accounting/settings",
    "accountingPayments": "/accounting/payments/:section?/:page?",

    "toolsChats": "/tools/chats",
    "toolsConnections": "/tools/connections",
    "toolsRevolut": "/tools/revolut",
    "toolsTiming": "/tools/timing",
    "toolsRemovebg": "/tools/removebg",

    "marketingBanners":  "/marketing/banners",
}

export default routes
