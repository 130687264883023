import React from "react"
import { connect } from "react-redux"
import SideDialog from "common/src/components/dialog/SideDialog"
import { ui as ddUi } from "common/src/store/dialogs"
import Table from "common/src/components/table/Table"

import CommissionDetails from "./CommissionDetails"

import * as partnerize from "./network/partnerize"
import * as rakuten from "./network/rakuten"
import * as cj from "./network/cj"
import * as awin from "./network/awin"


const networkDetails = {
    partnerize, rakuten, cj, awin
};

const dialogName = "commission-history";


class CommissionHistoryDialog extends React.Component {

    state = {
        
    }

    _isMounted = false 

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDialogClose() {
        this.props.onClose && this.props.onClose();
        this.props.dispatch(ddUi.hide(dialogName));
    }


    renderCommissionDetails(r) {
        return (
            <CommissionDetails commission={ r }/>
        )
    }

    renderTable(data) {

        return  (
            <Table 
                cols={ data.cols } 
                rows={ data.rows }
                expandKey="id"
                expandable
                renderDetails={ r => this.renderCommissionDetails(r) }/>
        )
    }

    render() {

        const { order } = this.props;
        const show = order && this.props.dd[dialogName];
        let hasHistory = false, hasCorrections = false, network;

        if (order) {
            network = networkDetails[ order.networkId ];
            hasHistory = network.hasHistory(order);
            hasCorrections = network.hasCorrections(order);
        }

        return (
            <SideDialog 
                className="dialog-commission-history" 
                title="Order history" 
                onClose={ () => this.onDialogClose() }
                show={ show }>

                { hasHistory && this.renderTable(network.getHistory(order)) }
                { hasCorrections && this.renderTable(network.getCorrections(order)) }
            </SideDialog>
        )
    }
}

export default connect(state => ({ dd: state.dialogs }))(CommissionHistoryDialog)