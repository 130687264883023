import React from "react"
import Loader from "common/src/components/Loader"
import { Button, TextField } from "@mui/material"
import Checkbox from "common/src/components/material/Checkbox"
import CharacteristicSelector from "common/src/components/catalogue/CharacteristicSelector"
import { ReactComponent as IconDown } from "common/src/svg/angle-down.svg"
import { confirm } from "common/src/components/dialog/Alert"
import Products from "common/src/components/catalogue/products/PaginatedProducts"
import api from "app/api"
import hub from "common/src/hub"
import { loadCharacteristics } from "common/src/actions/catalogue"
import NullForm from "common/src/components/NullForm"


class CatalogueCharacteristicQueuePage extends React.Component {

    state = {
        loading: false,
        editId: null,
        assignId: null,
        assignToId: null,
        createId: null,
        creating: false,
        name: null,
        synonyms: "",
        characteristics: [],
        selection: [],
        search: "",
        openId: null
    }

    componentDidMount() {
        this.loadList();
        loadCharacteristics();
    }


    toggleItem(e, id) {
        const openId = this.state.openId;
        e.preventDefault();
        e.stopPropagation();

        if (openId === id) {
            this.setState({ openId: null });    
        }
        else {
            this.setState({ openId: id });
        }
    }


    async loadList() {
        const { search } = this.state;
        this.setState({ loading: true });
        const order = { name: "asc" };
        const where = {};

        if (search) {
            where.name = { _ilike: `%${ search }%` }
        }

        await api.catalogueCharacteristicQueue
                .list({ where, order })
                .then(characteristics => this.setState({ characteristics }));
        this.setState({ loading: false });
    }

    search() {
        this.loadList();
    }

    toggleSelected(id, selected) {
        const { selection } = this.state;
        const inx = selection.indexOf(id);
        if (selected) {
            if (inx === -1) {
                selection.push(id);
                this.setState({ selection });
            }
        }
        else {
            if (inx !== -1) {
                selection.splice(inx, 1);
                this.setState({ selection });
            }
        }
    }

    async assign(queueId, characteristicId) {
        const characteristics = this.state.characteristics;
        const fcharacteristics = Array.isArray(queueId) ?
                            queueId.map(id => characteristics.find(c => c.id === id)) :
                            [ characteristics.find(c => c.id === queueId) ];
        let i, l;

        for (i = 0, l = fcharacteristics.length; i < l; i++) {
            await api.catalogueCharacteristicFeed.create({ 
                characteristicId, 
                feedCharacteristicId: fcharacteristics[i].feedCharacteristicId, 
                name: fcharacteristics[i].name 
            });
            await api.catalogueCharacteristicQueue.remove(fcharacteristics[i].id);
        }

        hub.dispatch("catalogue", "characteristic-change");
        this.setState({ creating: false, createId: null, assignId: null, assignToId: null });
        this.loadList();
    }

    async create(queueId) {
        this.setState({ creating: true });

        const { name, synonyms, characteristics } = this.state;
        const fcharacteristics = Array.isArray(queueId) ?
                            queueId.map(id => characteristics.find(c => c.id === id)) :
                            [ characteristics.find(c => c.id === queueId) ];

        const newChar = { name: name || fcharacteristics[0].name, synonyms };
        const characteristicId = await api.catalogueCharacteristic.create(newChar).then(r => r.id);

        let i, l;
        for (i = 0, l = fcharacteristics.length; i < l; i++) {
            await api.catalogueCharacteristicFeed.create({ 
                characteristicId, 
                feedCharacteristicId: fcharacteristics[i].feedCharacteristicId, 
                name: fcharacteristics[i].name 
            });
            await api.catalogueCharacteristicQueue.remove(fcharacteristics[i].id);
        }

        hub.dispatch("catalogue", "characteristic-change");

        this.setState({ 
            creating: false, 
            createId: null, 
            assignId: null, 
            assignToId: null,
            name: null,
            synonyms: '' });
        this.loadList();
    }

    async remove(id) {
        try {
            await confirm({ title: "Delete characteristic", message: "Are you sure?" });
        }
        catch (err) {
            return;
        }
        this.setState({ loading: true });

        await api.catalogueCharacteristic.remove(id);

        this.loadList();
    }

    renderCreateForm(c) {
        const { createId, creating, name, synonyms } = this.state;

        return (
            <NullForm className="page-catalogue-color-form"  key={ createId }>
                <TextField 
                    autoComplete="off"
                    variant="outlined" 
                    placeholder="Characteristic name"
                    value={ name === null ? c.name : name }
                    disabled={ creating }
                    onKeyDown={ e => (e.key === "Enter" && name !== "") && this.create() }
                    onChange={ e => this.setState({ name: e.target.value }) }/>
                <TextField 
                    autoComplete="off"
                    variant="outlined" 
                    placeholder="Synonyms (comma separated)"
                    value={ synonyms }
                    disabled={ creating }
                    onKeyDown={ e => (e.key === "Enter" && name !== "") && this.create() }
                    onChange={ e => this.setState({ synonyms: e.target.value }) }/>
                <Button
                    variant="contained"
                    children={ "Create" }
                    startIcon={ creating ? <Loader inline/> : null }
                    disabled={ creating || name === "" }
                    onClick={ () => this.create(c.id) }/>
                <Button 
                    variant="text" 
                    children="Cancel"
                    onClick={ () => this.setState({ createId: null }) }/>
            </NullForm>
        )
    }

    renderInfo(c) {
        const { selection, assignId, createId, openId } = this.state;
        const cls = ["page-catalogue-color"];

        c.id === openId && cls.push("active")

        return (
            <div className={ cls.join(" ") } key={ c.id }>
                
                <Checkbox 
                    onChange={ e => this.toggleSelected(c.id, e.target.checked) }
                    checked={ selection.indexOf(c.id) !== -1 }/>
                <div className="name">
                    <h4>{ c.name }</h4>
                    <a href="/#" onClick={ e => this.toggleItem(e, c.id) }>
                        <IconDown/>
                    </a> 
                </div>

                { assignId === c.id && this.renderAssignForm(c) }
                { createId === c.id && this.renderCreateForm(c) }

                <div className="actions">
                    <Button 
                        variant="contained" 
                        children="Assign"
                        onClick={ () => this.setState({ assignId: c.id, createId: null, assignToId: null }) }/>
                    <Button 
                        variant="outlined" 
                        children="Create"
                        onClick={ () => this.setState({ createId: c.id, assignId: null, assignToId: null }) }/>
                </div>  

                { openId === c.id && <Products/> }
            </div> 
        )
    }

    renderAssignForm(c) {
        const { assignToId } = this.state;
        return (
            <div className="page-catalogue-color-assign">
                <CharacteristicSelector onChange={ c => this.setState({ assignToId: c ? c.id : null }) }/>
                <Button 
                    variant="contained" 
                    children="Save"
                    onClick={ () => this.assign(c.id, assignToId) }/>
                <Button 
                    variant="text" 
                    children="Cancel"
                    onClick={ () => this.setState({ assignId: null, assignToId: null }) }/>
            </div>
        )
    }

    render() {
        const { loading, characteristics, editId, selection, search, assignId, createId } = this.state;
        return (
            <div className="page page-catalogue-color-queue">
                { loading && <Loader size={ 64 }/> }
                <NullForm className="toolbar">
                    <TextField 
                        fullWidth 
                        autoComplete="off"
                        placeholder="Search" 
                        variant="outlined"
                        value={ search }
                        onKeyDown={ e => e.key === "Enter" && this.search() }
                        onChange={ e => this.setState({ search: e.target.value }) }/>
                    <Button 
                        variant="contained" 
                        children="Search"
                        onClick={ () => this.search() }/>
                </NullForm>
                { selection.length > 0 &&
                    <div className="toolbar page-catalogue-color-queue-create">
                        <Button 
                            variant="contained" 
                            children="Assign selection"
                            onClick={ () => this.setState({ assignId: "selection", createId: null, assignToId: null }) }/>
                        <Button 
                            variant="outlined" 
                            children="Create characteristic"
                            onClick={ () => this.setState({ assignId: null, createId: "selection", assignToId: null }) }/>

                        { assignId === "selection" && this.renderAssignForm({ name: '', id: selection }) }
                        { createId === "selection" && this.renderCreateForm({ name: '', id: selection }) }
                    </div> }
                { characteristics.map(c => editId === c.id ? this.renderForm() : this.renderInfo(c)) }
            </div>
        )
    }
}

export default CatalogueCharacteristicQueuePage