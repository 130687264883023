import React, { useMemo, useCallback } from "react"
import { Link } from "react-router-dom"
import Avatar from "common/src/components/user/Avatar"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"

import swallowEvent from "common/src/lib/dom/swallowEvent"

function stopPropagation(e) {
    e.stopPropagation();
}

function UserBlock(props) {

    const { user: u, passthruClick, variant = "default", short, id,
            nameOnly, handle, handleOrName, link, clickable,
            getName, before, after, onClick,
            avatarProps = {} } = props;

    const className = useMemo(
        () => {
            const cls = ["user-handle", "user-handle-" + variant];
            clickable && cls.push("user-handle-clickable");
            return cls.join(" ");
        },
        [ variant, clickable ]
    );

    const contentBefore = useMemo(
        () => typeof before === "function" ? before(u) : before,
        [ before, u ]
    );
    const contentAfter = useMemo(
        () => typeof after === "function" ? after(u) : after,
        [ after, u ]
    );

    const name = useMemo(
        () => {
            if (!u) {
                return "";
            }
            const fname = nameOnly ? "" : 
                            short ? u.familyName.substring(0, 1) : u.familyName;
            const name = getName ?
                            getName(u) :
                            (handle || handleOrName) ? 
                                u.handle ? 
                                    '@' + u.handle : 
                                    handleOrName ? <>{ u.givenName }&nbsp;{ fname }</> : "@TheFloorr" :
                                <>{ u.givenName }&nbsp;{ fname }</>;
            return name;
        },
        [ nameOnly, short, u, handle, getName, handleOrName ]
    );
    
    const nameLink = useMemo(
        () => {
            if (!u) {
                return null;
            }
            if (!!link && typeof link === "string") {
                return <a href={ link } 
                            data-cy="user-link"
                            onClick={ stopPropagation }
                            className="name" 
                            children={ name }
                            target="_blank"
                            rel="noreferrer noopener"/>
            }
            else if (link === true && routes.fri) {
                const url = getUrl(routes.fri, 
                                    u.handle ?  { handle: u.handle } :
                                                { id: u.id });
                return <Link className="name" 
                                data-cy="user-link"
                                onClick={ stopPropagation }
                                children={ name }
                                to={ url }/>
            }
            else {
                return <span className="name" children={ name }/>;
            }
        },
        [ link, u, name  ]
    );
    
    const clickHandler = useCallback(
        (e) => {
            !passthruClick && swallowEvent(e);
            onClick && onClick(e);
        },
        [ passthruClick, onClick ]
    );

    if (!u) {
        return null;
    }

    return (
        <div className={ className } 
                data-cy="user"
                id={ id } 
                onClick={ clickHandler }>
            <div className="avatar-wrapper">
                <Avatar user={ u } { ...avatarProps }/>
            </div>
            <p>
                { contentBefore }
                { nameLink }
                { contentAfter }
            </p>
            { props.children }
        </div>
    )
}

export default UserBlock