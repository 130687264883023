import React from "react"
import { connect } from "react-redux"

import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import SideDialog from "common/src/components/dialog/SideDialog"

import { ui as ddUi } from "common/src/store/dialogs"
import Loader from "common/src/components/Loader"
import { alert } from "common/src/components/dialog/Alert"
import { createTag } from "app/actions/page/tags"
import NullForm from "common/src/components/NullForm"


class NewTag extends React.Component {

    state = {
        tag: "",
        loading: false
    }
    _isMounted = false

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onFieldChange(tag) {
        this.setState({tag})
    }

    onSubmitClick() {
        this.setState({loading: true});
        const tags = this.state.tag.trim().split("\n");
        const promises = [];
        const type = this.props.type;

        promises.push(createTag(type, tags));

        Promise.all(promises)
            .then(() => {
                alert({
                    title: "Tags created",
                    message: ""
                });
                if (this._isMounted) {
                    this.setState({ tag: "", loading: false });
                }
                this.onDialogClose();
            })
            .finally(resp => {
                if (this._isMounted) {
                    this.setState({loading: false});
                }
            });
    }

    onDialogClose() {
        this.props.dispatch(ddUi.hide("new-tag"));
    }

    render() {
        let show = this.props.dd['new-tag'],
            type = this.props.type || "style",
            loading = this.state.loading,
            header = type === "productStyle" ?
                        "New product style" :
                        type === "hiddenTag" ? 
                            "New hidden tag" :
                            "New " + type;

        return (
            <SideDialog 
                className="new-tag" 
                title={ header }
                onClose={ () => this.onDialogClose() }
                show={ show }>

                <NullForm>
                <TextField
                    fullWidth
                    multiline
                    autoComplete="off"
                    size="small"
                    variant="outlined"
                    value={ this.state.tag }
                    disabled={ loading }
                    onChange={ e => this.onFieldChange(e.target.value) }/>
                </NullForm>
                <Button
                    variant="contained"
                    startIcon={ loading ? <Loader inline/> : null }
                    disabled={ loading }
                    onClick={ () => this.onSubmitClick() }
                    children="Add"/>

            </SideDialog>
        )
    }
}

export default connect(state => ({
    dd: state.dialogs
}))(NewTag)
