import api from "app/api"
import downloadUrl from "common/src/lib/downloadUrl"

const usrGraph = "id avatar givenName familyName";

export const PER_PAGE = 100;

const idmap = (list) => {
    return list.map(item => {
        return typeof item === "string" ? item : item.id;
    });
}

const dateToTimestamp = (d, time) => {
    if (d.indexOf("T") === -1) {
        d += ' ' + time;
    }
    return d;
}

const setBodyDates = (body, dateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
        body.start = dateToTimestamp(dateRange.startDate, '00:00:00');
        body.end = dateToTimestamp(dateRange.endDate, '23:59:59');
    }
}

const setBodyIds = (body, key, list) => {
    if (list && list.length > 0) {
        body[key] = idmap(list);
    }
}



const getRequestBody = (filters) => {
    const body = {};
    
    setBodyDates(body, filters);
    setBodyIds(body, "contributors", filters.contributors);
    setBodyIds(body, "retailers", filters.retailers);
    setBodyIds(body, "userGroups", filters.userGroups);
    setBodyIds(body, "months", filters.months);

    if (filters.query) {
        body.query = filters.query;
    }
    if (filters.group && filters.group.length > 0) {
        body.group = filters.group[0].id;
    }

    return body;
}

export const loadLinks = async (filters, page) => {

    const body = getRequestBody(filters);
    const limit = PER_PAGE;
    const offset = page * PER_PAGE;
    body.limit = limit;
    body.offset = offset;

    const emptySales = { grossSaleAmount: 0, saleAmount: 0, 
                            grossCommissionAmount: 0, commissionAmount: 0 };

    const response = await api.reporting.post("/report/links/list", { body });
    const ids = response.rows;
    const { count, clickCounts = {}, sales = {} } = response.data;

    if (ids && ids.length > 0) {
        const where = { id: { _in: ids }};
        const order = { createdAt: "desc" };
        const links = await api.link.list({ where, order });

        links.forEach(link => {
            link.clicks = clickCounts[link.id]?.count || 0;
            link.firstClickDate = clickCounts[link.id]?.firstClickDate || null;
            link.lastClickDate = clickCounts[link.id]?.lastClickDate || null;
            if (sales[link.id]) {
                Object.assign(link, emptySales, sales[link.id]);
            }
            //link.gbp = gbp[link.id] || { ...emptySales };
            //link.usd = usd[link.id] || { ...emptySales };
        });

        return { links, count };
    }
    else {
        return { links: [], count: 0 };
    }
}



export const loadLinksByDate = async (filters, csv = false) => {

    const body = getRequestBody(filters);
    body.csv = csv;
    const response = await api.reporting.post("/report/links/bydate", { body });    

    if (csv) {
        downloadUrl(response.url, "bydate.csv");
        return;
    }

    const { rows } = response;
    return rows;
}


export const loadLinksByContributor = async (filters, csv = false) => {

    const body = getRequestBody(filters);
    body.csv = csv;
    const response = await api.reporting.post("/report/links/bycontributor", { body });    

    if (csv) {
        downloadUrl(response.url, "bycontributor.csv");
        return;
    }

    const rows = response.rows.filter(r => !!r.referenceUserId);
    const ids = rows.map(r => r.referenceUserId)
                    .filter(id => !!id)
                    .filter((id, inx, self) => self.indexOf(id) === inx);
    
    if (ids.length > 0) {
        const conts = await api.user.list({ where: { id: { _in: ids }}}, usrGraph);
        
        return rows.map(row => {
            row.contributor = conts.find(f => f.id === row.referenceUserId);
            return row;
        });
    }
    return rows;
}
