
export default function(resp) {
    if (resp.success) {
        return { title: "Your email has been verified" };
    }
    else {
        if (resp.status === "VERIFIED") {
            return { title: "Your email is already verified" };
        }
        else if (resp.status === "EXPIRED") {
            return { 
                title: "Verification code has expired",
                message: "You can resend the code from your Profile"
            };
        }
        else if (resp.status === "USED") {
            return { title: "You already used this code" };
        }
        else if (resp.status === "INVALID") {
            return { title: "The code is invalid" };
        }
        else {
            return { title: "Failed to verify your email" };
        }
    }
}