import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { BarChart, Bar, XAxis, YAxis, 
        CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Button } from "@mui/material"

import Menu from "common/src/components/Menu"
import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"
import Loader from "common/src/components/Loader"
import Table from "common/src/components/table/Table"
import Pagination from "common/src/components/Pagination"
import LookCards from "common/src/components/look/Cards"

import UserSelector from "common/src/components/user/UserSelector"
//import CampaignSelector from "common/src/components/network/CampaignSelector"
import PublicSelector from "common/src/components/PublicSelector"
import DateRangeSelector from "common/src/components/filter/DateRange"
import UserAttributionSelector from "../../filter/UserAttributionSelector"
import SearchFilter from "common/src/components/filter/Search"

import ClickDialog from "./ClickDialog"

import DataStore from "common/src/lib/DataStore"

import async from "common/src/lib/js/async"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import { ui as ddUi } from "common/src/store/dialogs"

import state2query from "common/src/lib/url/state2query"
import { default as url2state, list2param } from "common/src/lib/url/url2state"
import statesAreEqual from "common/src/lib/statesAreEqual"
import { dateGroupNames, dateGroupItems } from "./common"

import * as columns from "./datastore/clicks"

import { PER_PAGE, loadClickLog, 
            loadClicksByPse,
            loadClicksByFri, loadClicksByLook,
            loadClicksByDate, loadClicksByContributor } from "app/actions/page/clicks"


const urlParams = [
    { name: "section", defaultValue: "log" },
    { name: "q", stateName: "query" },
    { name: "page", type: "int", defaultValue: 1, 
        restore: page => Math.max(1, page) - 1,
        store: page => page + 1 },
    { name: "start", stateName: "startDate" },
    { name: "end", stateName: "endDate" },
    { name: "withUser", type: "bool", defaultValue: null },
    { name: "withFri", type: "bool", defaultValue: null },
    { name: "withContributor", type: "bool", defaultValue: null },
    { name: "f", stateName: "fris", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "ifs", stateName: "contributors", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "pses", stateName: "pses", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "i", stateName: "infs", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "ls", stateName: "links", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "c", stateName: "campaigns", type: "array", defaultValue: [],
        store: v => list2param(v) },
    { name: "g", stateName: "dateGroup", defaultValue: "month",
        restore: v => [{ id: v, name: dateGroupNames[v] }],
        store: v => v[0].id }
];

const getUrlState = (props) => {
    return url2state(urlParams, props);
};

const getFilterUrl = (state) => {
    return getUrl(routes.reportClicks, state2query(state, urlParams));
};

const filterSetup = {
    log: {
        filters: [ "date", "pse", "fri", "contributor", 
                    "campaign", "attribution", "search" ],
        options: { attribution: { 
                    withUser: "Clicked by", 
                    withFri: "Look by", 
                    withContributor: "Link by" }}
    },
    bypse: {
        filters: [ "date", "pse", "campaign" ],
        options: {}
    },
    byfri: {
        filters: [ "date", "fri", "campaign" ],
        options: {}
    },
    bycontributor: {
        filters: [ "date", "contributor", "campaign" ],
        options: {}
    },
    bydate: {
        filters: [ "dategroup", "date", "pse", 
                    "campaign", "attribution" ],
        options: {  groupItems: dateGroupItems,
                    attribution: { 
                        withUser: "User", 
                        withFri: "FRI", 
                        withContributor: "Contributor" } }
    },
    bylook: {
        filters: [ "date", "contributor", "campaign", "fri" ],
        options: {}
    }
};


class PageClicks extends React.Component {

    state = {
        section: "log",
        startDate: null,
        endDate: null,
        page: 0,
        loading: false,
        downloading: false,
        data: null,
        showDateSelector: false,
        query: "",

        clickInfo: null,

        withUser: null,
        withFri: null,
        withContributor: null,
        fris: [],
        pses: [],
        contributors: [],
        campaigns: [],
        links: [],
        dateGroup: [{ id: "month", name: "Month" }]
    }

    _isMounted = false

    componentDidMount() {
        this.dataStore = new DataStore();
        const state = getUrlState(this.props);
        this.setState(state, () => this.load());
        this._isMounted = true;
    }

    componentWillUnmount() {
        this.dataStore = null;
        this._isMounted = false;
    }

    componentDidUpdate() {
        const state = getUrlState(this.props);
        const keys = urlParams.map(p => p.stateName || p.name);
        if (!statesAreEqual(state, this.state, keys)) {
            async(() => this.setState({ ...state, data: null }, () => this.load()))
        }
    }

    prepareDs(data) {
        const { section } = this.state;

        switch (section) {
            case "log": {
                this.dataStore.setRows(data.clicks);
                this.dataStore.setColumns(columns.list);
                this.dataStore.trigger("change");
                break;
            }
            case "bypse": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.pse);
                this.dataStore.trigger("change");
                break;
            }
            case "byfri": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.fri);
                this.dataStore.trigger("change");
                break;
            }
            case "bycontributor": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.contributor);
                this.dataStore.trigger("change");
                break;
            }
            case "bydate": {
                const { dateGroup } = this.state;
                this.dataStore.setRows(data);
                this.dataStore.setCustomData({ dateGroup });
                this.dataStore.setColumns(columns.date);
                this.dataStore.trigger("change");
                break;
            }
            default: {}
        }
    }

    async load(csv = false) {
        const { section, page } = this.state;
        const filters = this.getFilters();
        let data;
        this.setState({ loading: !csv, downloading: csv });

        switch (section) {
            case "log": {
                data = await loadClickLog(filters, page);
                break;
            }
            case "bypse": {
                data = await loadClicksByPse(filters, csv);
                break;
            }
            case "byfri": {
                data = await loadClicksByFri(filters, csv);
                break;
            }
            case "bycontributor": {
                data = await loadClicksByContributor(filters, csv);
                break;
            }
            case "bylook": {
                data = await loadClicksByLook(filters);
                break;
            }
            case "bydate": {
                data = await loadClicksByDate(filters, csv);
                break;
            }
            default: {
                data = null;
                break;
            }
        }

        if (this.state.section === section && !csv) {
            this.prepareDs(data);
            this.setState({ loading: false, downloading: false, data });
        }
        else if (csv) {
            this.setState({ loading: false, downloading: false });
        }
    }

    reload() {
        this.load();
    }

    setDateRange(range) {
        const state = Object.assign({}, this.state, {
            startDate: range[0] ? moment(range[0]).format("YYYY-MM-DD") : null,
            endDate: range[1] ? moment(range[1]).format("YYYY-MM-DD") : null,
            page: 0
        });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    setSection(section) {
        const state = Object.assign({}, this.state, {
            section,
            page: 0
        })
        const url = getFilterUrl(state);
        this.setState({ data: null }, () => this.props.history.push(url));
    }

    setPage(page) {
        const state = Object.assign({}, this.state, { page });
        const url = getFilterUrl(state);
        async(() => this.props.history.push(url));
    }

    setFilter(state) {
        const newState = Object.assign({}, this.state, state, { page: 0 });
        const url = getFilterUrl(newState);
        async(() => this.props.history.push(url));
    }

    getFilters() {
        const { startDate, endDate, dateGroup,
                contributors, fris, campaigns, links, pses,
                withUser, withFri, withContributor, query } = this.state;
        return { startDate, endDate, dateGroup, query, pses,
                        contributors, fris, campaigns, links,
                        withUser, withFri, withContributor };
    }

    async download() {
        this.load(true);
    }


    getNav() {
        const { loading, section, downloading } = this.state;
        const refreshCls = [ 'icon', 'icon-svg-fill', loading ? 'spinning' : ''];
        const downloadCls = [ 'icon', 'icon-svg-fill' ];
        let showDownload = true;

        if (section === "log" || section === "bylook") {
            showDownload = false;
        }

        return [
            {
                name: "Log",
                onClick: () => this.setSection("log"),
                active: section === "log"
            },
            {
                name: "By date",
                onClick: () => this.setSection("bydate"),
                active: section === "bydate"
            },
            {
                name: "By PSE",
                onClick: () => this.setSection("bypse"),
                active: section === "bypse"
            },
            /*{
                name: "By look creator",
                onClick: () => this.setSection("byfri"),
                active: section === "byfri"
            },
            {
                name: "By link creator",
                onClick: () => this.setSection("bycontributor"),
                active: section === "bycontributor"
            },*/
            /*{
                name: "By Look",
                onClick: () => this.setSection("bylook"),
                active: section === "bylook"
            },*/
            showDownload ? 
            {
                icon: downloading ? 
                        <Loader/> : 
                        <IconDownload className={ downloadCls.join(" ") }/>,
                onClick: () => !downloading && this.download()
            } : null,
            {
                icon: <IconRefresh className={ refreshCls.join(" ") }/>,
                onClick: () => this.reload()
            }
        ]
    }

    openClickInfo(e, clickInfo) {
        e && e.stopPropagation();
        e && e.preventDefault();
        this.setState({ clickInfo });
        this.props.dispatch(ddUi.show("click-info"));
    }

    showSales(click) {
        const url = getUrl(routes.reportCommissions, { q: click.sid });
        async(() => this.props.history.push(url));
    }

    showLink(click) {
        const url = getUrl(routes.reportLinks, { q: click.sid });
        async(() => this.props.history.push(url));
    }

    renderFilters(filters, options = {}) {

        const { fris, pses, contributors, /*campaigns,*/ startDate, endDate, dateGroup,
                withUser, withContributor, withFri, query } = this.state;

        return (
            <>
                { filters.indexOf("dategroup") !== -1 &&
                    <PublicSelector
                        name="Group by"
                        cls="tag-selector-v2"
                        noSearch
                        single
                        mustHaveSelection
                        value={ dateGroup }
                        items={ options.groupItems }
                        filtered={ options.groupItems }
                        onChange={ dateGroup => this.setFilter({ dateGroup }) }/> }
                { filters.indexOf("date") !== -1 &&
                    <DateRangeSelector
                        name="Date range"
                        value={ startDate ? [ startDate, endDate ] : [] }
                        onChange={ r => this.setDateRange(r) }/> }
                { filters.indexOf("search") !== -1 && 
                    <SearchFilter
                        value={ query }
                        onChange={ query => this.setFilter({ query }) }/> }

                { filters.indexOf("pse") !== -1 && 
                    <UserSelector
                        name="PSE"
                        group={["FRI", "Contributor"]}
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ pses }
                        onChange={ pses => this.setFilter({ pses }) }/> }
                        
                { filters.indexOf("fri") !== -1 && 
                    <UserSelector
                        name="Look created by"
                        group="FRI"
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ fris }
                        onChange={ fris => this.setFilter({ fris }) }/> }

                

                { filters.indexOf("contributor") !== -1 && 
                    <UserSelector
                        name="Link created by"
                        group="Contributor"
                        searchGroup=""
                        template={ u => 
                            <>
                            { u.givenName } { u.familyName }<br/>
                            <span className="tag-selector-subname">
                                { u.handle ? '@'+u.handle : u.email }
                            </span>
                            </>
                        }
                        value={ contributors }
                        onChange={ contributors => this.setFilter({ contributors }) }/> }
                    
                {/*{ filters.indexOf("campaign") !== -1 &&
                    <CampaignSelector
                        name="Campaign" 
                        value={ campaigns }
                        onChange={ campaigns => this.setFilter({ campaigns }) }/> }*/}

                { filters.indexOf("attribution") !== -1 &&
                    <UserAttributionSelector 
                        name="User Attribution"
                        value={{ withUser, withFri, withContributor }}
                        groups={ options.attribution }
                        onChange={ value => this.setFilter(value) }/> }
                </>
        )
    }

    renderExpandedClick(click) {
        
        return (
        
            <div className="page-clicks-expanded-toolbar">
                { !!click.product && 
                    <Button
                        variant="outlined"
                        size="small"
                        children="Show look"
                        onClick={ () => this.openClickInfo(null, click) }/> }
                { !!click.catalogueProductId && 
                    <Button
                        variant="outlined"
                        size="small"
                        children="Show catalogue product"
                        onClick={ () => this.openClickInfo(null, click) }/> }
                <span className="spacer"/>
                <Button
                    variant="text"
                    size="small"
                    children="Show sales"
                    onClick={ () => this.showSales(click) }/>
                { !!click.linkId && 
                    <Button
                        variant="text"
                        size="small"
                        children="Show link"
                        onClick={ () => this.showLink(click) }/> }
            </div>
        
        )
    }

    renderLog() {
        const { clicks, count } = this.state.data;
        const { page } = this.state;

        return (
            <>
            <Table 
                dataStore={ this.dataStore }
                expandable
                expandKey="id"
                renderDetails={ c => this.renderExpandedClick(c) }
                detailsNameClass="page-clicks-details-name"
                detailsValueClass="page-clicks-details-value"/>
            { clicks.length === 0 && <div className="empty">No clicks found</div> }
            <Pagination 
                page={ page } 
                perPage={ PER_PAGE } 
                count={ count } 
                onChange={ page => this.setPage(page) }/>
            </>
        )
    }

    renderByDate() {
        const clicks = this.state.data;
        const { dateGroup } = this.state;
        
        const chartDateFormats = {
            "year": "YYYY",
            "month": "MMM YY",
            "day": "D/M/YY"
        };

        const chartData = clicks.map(c => {
            const data = {
                id: c.date_group,
                name: moment(c.date_group).format(chartDateFormats[ dateGroup[0].id ]),
                clicks: c.cnt
            };
            return data;
        }).reverse();

        return (
            <>
            <ResponsiveContainer width="100%" height={ 300 }>
                <BarChart
                    height={ 300 }
                    data={ chartData }>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis angle={ -60 } dy={ 20 } height={ 50 } dataKey="name" margin={{ top: 20 }}/>
                    <YAxis/>
                    <Tooltip />
                    <Bar dataKey="clicks" fill="#62939a" name="Clicks" label="Clicks"/>
                </BarChart>
            </ResponsiveContainer>
            <Table dataStore={ this.dataStore }/>
            { clicks.length === 0 && <div className="empty">No clicks found</div> }
            </>
        )
    }

    renderByPSE() {
        const clicks = this.state.data;
        
        return (
            <>
            <Table dataStore={ this.dataStore }/>
            { clicks.length === 0 && <div className="empty">No clicks found</div> }
            </>
        )
    }

    renderByFRI() {
        const clicks = this.state.data;
        
        return (
            <>
            <Table dataStore={ this.dataStore }/>
            { clicks.length === 0 && <div className="empty">No clicks found</div> }
            </>
        )
    }

    renderByContributor() {
        const clicks = this.state.data;
        
        return (
            <>
            <Table dataStore={ this.dataStore }/>
            { clicks.length === 0 && <div className="empty">No clicks found</div> }
            </>
        )
    }

    renderLookFooter(l) {
        const clicks = l.clicks;        
        const parts = [
            <React.Fragment key="1">Clicks</React.Fragment>, 
            <b key="2">{ clicks }</b>
        ];
        return parts;
    }

    renderByLook() {
        const looks = this.state.data;
        return (
            <LookCards 
                looks={ looks }
                look={ l => ({ footer: this.renderLookFooter(l) })}
                product={{ layers: [ "retailer" ] }}/>
        )
    }


    render() {

        const menu = this.getNav();
        const { section, data, loading, clickInfo } = this.state;

        return (
            <>
            <div className="page page-clicks">
                <div className="toolbar">
                    <div className="toolbar-title">
                        Clicks
                    </div>
                    <Menu menu={ menu } className="right"/>
                </div>

                { loading && <Loader size={ 64 }/> }

                <div className="page-clicks-layout">
                    <div className="left">
                        { this.renderFilters(
                            filterSetup[section].filters,
                            filterSetup[section].options
                        ) }
                    </div>
                    <div className="right">
                        { (section === "log" && data) && this.renderLog() }
                        { (section === "bypse" && data) && this.renderByPSE() }
                        { (section === "byfri" && data) && this.renderByFRI() }
                        { (section === "bycontributor" && data) && this.renderByContributor() }
                        { (section === "bylook" && data) && this.renderByLook() }
                        { (section === "bydate" && data) && this.renderByDate() }
                    </div>
                </div>
            </div>
            <ClickDialog click={ clickInfo }/>
            </>
        )
    }
}

export default connect()(PageClicks);