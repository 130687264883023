import createActionMap, { map2builder } from "common/src/lib/store/createActionMap.js";
import { createReducer } from "@reduxjs/toolkit"


let initialState = {
    fris: [],
    stats: null,
    ui: {
        fris: {
            loading: false
        },
        stats: {
            loading: false
        }
    }
}


let data = createActionMap("consultations/", {
    "fris/": ["set", "reset"],
    "stats/": ["set", "reset"]
})

let ui = createActionMap("consultations/ui/", {
    "fris/": ["loading"],
    "stats/": ["loading"]
});


let reducer = createReducer(initialState, (builder) => {
    map2builder(builder, {
        [data.fris.set]: (state, action) => {
            state.fris = action.payload;
        },
        [data.fris.reset]: (state, action) => {
            state.fris = [];
        },
    
        [data.stats.set]: (state, action) => {
            state.stats = action.payload;
        },
        [data.stats.reset]: (state, action) => {
            state.stats = null;
        },
    
    
        [ui.fris.loading]: (state, action) => {
            state.ui.fris.loading = action.payload;
        },
        [ui.stats.loading]: (state, action) => {
            state.ui.stats.loading = action.payload;
        }
    })
});

export { reducer, data, ui, initialState }
