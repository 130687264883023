import { useRef, useMemo } from "react"
import { getOptionChanges, hasResettingChange } from "common/src/actions/catalogue"

let changeCounter = 0;

function useProductFilters(newState) {
    const stateRef = useRef(null);
    stateRef.current = useMemo(
        () => {

            if (stateRef.current === null) {
                return {
                    filters: newState,
                    hasResettingChanges: false,
                    changes: [],
                    changeCounter
                }
            }

            const changes = getOptionChanges(stateRef.current.filters, newState);

            if (changes.length === 0) {
                stateRef.current.hasResettingChanges = false;
                return stateRef.current;
            }

            //console.log("changes", changes)
            /*changes.forEach(key => console.log(
                stateRef.current.filters[key],
                newState[key]
            ))*/

            const resetting = hasResettingChange(changes);

            return {
                filters: newState,
                hasResettingChanges: resetting,
                hasChanges: changes.length > 0,
                changes,
                changeCounter: ++changeCounter
            }
        },
        [ newState ]
    );

    return stateRef.current;
}

export default useProductFilters;