
import { batch } from "react-redux"
import singlePromise from "common/src/lib/js/singlePromise"

import { data, ui } from "app/store/page/people"
import store from "app/store"
import api from "app/api"
import hub from "common/src/hub"
import settings from "app/settings"
import uuidReg from "common/src/lib/uuidReg"


const PER_PAGE = settings.peoplePerPage;

export const USR_GRAPH = `
    id
    givenName
    familyName
    avatar
    email
    phone
    groups
    bio
    details
    enabled
    defaultShopper
    createdAt
    lastLoggedIn
    firstLoggedIn
    participantInChats(limit:1, order_by:{lastOnlineAt:desc}) {
        lastOnlineAt
    }
`;

/*userGroups {
        group {
            id
            name
        }
    }
    looksAsFri_aggregate(where: {published: {_eq: true}}) {
        aggregate {
            count
        }
    }*/

export function prepareUserWhere({ role, search, gid }) {
    const where = {
        _and: []
    };

    if (search) {
        if (search.match(uuidReg)) {
            where._and.push({ id: { _eq: search }});
            //where.id = { _eq: search };
        }
        else {
            where._or = [
                { givenName: { _ilike: `%${search}%` }},
                { familyName: { _ilike: `%${search}%` }},
                { email: { _ilike: `%${search}%` }}
            ]
        }
    }

    if (gid) {
        where.userGroups = { userGroupId: { _eq: gid }};
    }

    switch (role) {
        case "all": {
            break;
        }
        case "user": {
            where.groups = "User";
            break;
        }
        case "vip": {
            where.groups = "User";
            where.vip = true;
            break;
        }
        case "pse": {
            where._and.push({
                _or: [
                    { groups: { _like: "%FRI%" }},
                    { groups: { _like: "%GPS%" }},
                    { groups: { _like: "%Contributor%" }}
                ]
            });
            break;
        }
        case "fri": {
            where.groups = { _like: "%FRI%" }
            break;
        }
        case "gps": {
            where.groups = { _like: "%GPS%" }
            break;
        }
        case "admin": {
            where.groups = { _like: "%Admin%" }
            break;
        }
        case "contributor": {
            where.groups = { _like: "%Contributor%" }
            break;
        }
        default: {
            break;
        }
    }

    return where;
}

function loadPeople(page) {
    
    const state = store.getState();
    const search = state.peoplePage.ui.people.search;
    const { role = "all", gid = null } = (state.peoplePage.ui.people.filter || {});

    const where = prepareUserWhere({ role, search, gid });

    return api.user.list({
        where, 
        order: { createdAt: "desc" },
        offset: page * PER_PAGE,
        limit: PER_PAGE
    }, USR_GRAPH, true)
}

export let reloadPeopleList = function() {
    let state = store.getState(),
        page = state.peoplePage.ui.people.page;

    return loadPeopleList(page, true);
}

export let loadPeopleList = singlePromise({
    mode: singlePromise.ONE_CANCEL,
    pre: (page = 0, force) => {
        let state = store.getState(),
            currPage = state.peoplePage.ui.people.page,
            setup = {
                page: page
            };

        if (state.peoplePage.data.people.length > 0 && 
            currPage === page) {
            if (!force) {
                setup.value = {
                    items: state.peoplePage.data.people,
                    count: state.peoplePage.ui.people.count
                };
                setup.skip = true;
            }
        }
        return setup;
    },
    fn: (page = 0) => {
        store.dispatch(ui.people.loading(true));
        return loadPeople(page);
    },
    resolve: (response, setup, args) => {
        const items = response.items.map(u => {
            u.lastOnlineAt = null;
            if (u.participantInChats && u.participantInChats.length > 0) {
                u.lastOnlineAt = u.participantInChats[0].lastOnlineAt;
            }
            //u.looksCount = u.looksAsFri_aggregate.aggregate.count;
            return u;
        })
        batch(() => {
            store.dispatch(data.people.set(items));
            store.dispatch(ui.people.count(response.count));
            store.dispatch(ui.people.page(setup.page || 0));
        })
    },
    reject: err => {
        hub.dispatch("error", "people-load-list", err);
    },
    always: () => {
        store.dispatch(ui.people.loading(false));
    }
})



export const loadUserGroups = async () => {
    const state = store.getState(),
        { loaded, loading } = state.peoplePage.ui.userGroups;
    
    if (loaded || loading) {
        return;
    }

    store.dispatch(ui.userGroups.loading(true));

    const groups = await api.userGroup.list({ order: { name: "asc" }});

    batch(() => {
        store.dispatch(data.userGroups.set(groups));
        store.dispatch(ui.userGroups.loading(false));
        store.dispatch(ui.userGroups.loaded(true));
    });
}

function loadRequests(page) {
    let sortDirection = "desc";
    return api.accessRequest.list({
        order: { createdAt: sortDirection },
        offset: page * PER_PAGE, 
        limit: PER_PAGE
    }, null, true);
}

export const reloadRequestsList = function() {
    let state = store.getState(),
        page = state.peoplePage.ui.requests.page;

    return loadRequests(page, true);
}

export const loadRequestsList = singlePromise({
    mode: singlePromise.ONE_CANCEL,
    pre: (page = 0, force) => {
        let state = store.getState(),
            currPage = state.peoplePage.ui.requests.page,
            setup = {
                page: page
            };

        if (state.peoplePage.data.requests.length > 0 && 
            currPage === page) {
            if (!force) {
                setup.value = {
                    items: state.peoplePage.data.requests,
                    count: state.peoplePage.ui.requests.count,
                };
                setup.skip = true;
            }
        }
        return setup;
    },
    fn: (page = 0) => {
        store.dispatch(ui.requests.loading(true));
        return loadRequests(page);
    },
    resolve: (response, setup, args) => {
        store.dispatch(data.requests.set(response.items));
        store.dispatch(ui.requests.count(response.count));
        store.dispatch(ui.requests.page(setup.page));
    },
    reject: err => {
        hub.dispatch("error", "people-requests-list", err);
    },
    always: () => {
        store.dispatch(ui.requests.loading(false));
    }
})




export function setGroup(id, groups) {
    return api.user.update(id, { groups })
    .then(resp => {
        hub.dispatch("users", "updated", id);
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", "users-set-group", err);
    })
}


export function setEnabled(id, enabled) {
    return api.user.update(id, { enabled })
    .then(resp => {
        hub.dispatch("users", "updated", id);
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", "users-set-enabled", err);
    })
}


export function deleteUser(id) {
    return api.user.remove(id)
    .then(() => {
        hub.dispatch("users", "deleted", id);
    })
    .catch(err => {
        console.log(err)
        hub.dispatch("error", "users-delete", err);
    })
}

export function createUser(data) {

    return api.backend.post("/user", {
        body: {
            user: data
        }
    })
    .then(resp => {
        if (resp.id) {
            hub.dispatch("users", "created", resp.id);
        }
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", "users-create", err);
    })
}

export async function setDefaultShopper(id, defaultShopper) {
    if (defaultShopper === true) {
        const prev = await api.user.list({ where: { defaultShopper: { _eq: true }}}, "id")
                                .then(list => list ? list[0] : null)
                                .then(user => user ? user.id : null);
        if (prev) {
            if (prev === id) {
                return;
            }
            await api.user.update(prev, { defaultShopper: false });
        }
    }
    return api.user.update(id, { defaultShopper })
    .then(resp => {
        hub.dispatch("users", "updated", id);
        return resp;
    })
    .catch(err => {
        hub.dispatch("error", "users-set-default-shopper", err);
    })
}


export function setVIP(id, vip) {
    return api.user.update(id, { vip });
}

