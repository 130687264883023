
import React from "react"
import { useSelector } from "react-redux"
import Loader from "common/src/components/Loader"
import DetailsDialog from "common/src/components/user/Details"
import BioDialog from "common/src/components/user/Bio"
import AvatarDialog from "common/src/components/user/AvatarEditor"
import VerifyEmailDialog from "common/src/components/user/VerifyEmail"
import VerifyPhoneDialog from "common/src/components/user/VerifyPhone"

import ProfileDetails from "common/src/components/user/profile/Details"
import ProfileAvatar from "common/src/components/user/profile/Avatar"
import ProfileBio from "common/src/components/user/profile/Bio"

import userSelector from "common/src/selectors/user/current"

function PageProfile() {

    const current = useSelector(userSelector);

    if (!current) {
        return <div className="page page-full 
                    page-hid-title page-fw
                    page-w-loading
                    page-profile">
                    <Loader/>
                </div>
    }

    return (
        <div className="page page-full 
                        page-hid-title page-fw
                        page-profile">
            <div className="cards">
                <ProfileDetails/>
                <ProfileAvatar/>
                <ProfileBio/>
            </div>
            <DetailsDialog/>
            <BioDialog/>
            <AvatarDialog/>
            <VerifyEmailDialog/>
            <VerifyPhoneDialog/>
        </div>
    )
}

export default PageProfile